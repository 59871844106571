import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
// import numberIcon from "../../images/numbericon.svg";
// import successIcon from "../../images/successfultransactionsicon.svg";
// import unSuccessIcon from "../../images/unsuccessfultransactionsicon.svg";
// import pendingIcon from "../../images/pendingtransactionsicon.svg";
// import RoundedPillWidget from "../widgets/RoundedPillWidget";
import {
  deleteOrganizationById,
  getAllOrganizations,
  setOrganization,
} from "../../common/api";
import DeleteModal from "../../common/modal/DeleteModal";
import Modal from "../../common/modal/Modal";
import Table from "../../common/table/Table";
import { notify } from "../../common/toaster/Toaster";
import { getServerError } from "../../common/utils/helpers";
import { useHeader } from "../context/UserProvider";
// import { SVG } from "../../common/icons/Icon";
import FilterBar from "../payouts/components/FilterBar";
import { useTranslation } from "react-i18next";

const OrganizationsTable = () => {
  const { t } = useTranslation();
  useHeader("organizations");
  const [deleteThis, setDeleteThis] = useState(null);
  //   HOOKS
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    status: "",
    created_at__date__gte: null,
    created_at__date__lte: null,
    search: null,
    page: 1,
  });

  const {
    data,
    isLoading: areOrganizationsLoading,
    refetch,
  } = useQuery(
    [
      "getAllOrganizations",
      `?${filters.search}${filters.page}${filters.status}${filters.created_at__date__gte}${filters.created_at__date__lte}`,
    ],
    () => getAllOrganizations(filters),
    {
      retry: 1,
    }
  );

  const { isLoading, mutate: deleteOrganization } = useMutation(
    () => deleteOrganizationById(deleteThis),
    {
      onSuccess: () => {
        notify(t("organizationDeleted"), "success");
        setDeleteThis(null);
        refetch();
        navigate("/organizations");
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );

  return areOrganizationsLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <>
      {/* <div className="widgets mb-6">
        <RoundedPillWidget img={numberIcon} label="PAYOUTS" value="03" />
        <RoundedPillWidget img={successIcon} label="SUCCESSFUL" value="01" />
        <RoundedPillWidget
          img={unSuccessIcon}
          label="UNSSUCCESSFUL"
          value="0"
        />
        <RoundedPillWidget img={pendingIcon} label="PENDING" value="01" />
      </div> */}
      <div className="flex flex-wrap justify-between my-8">
        <div className="flex searchBar md:w-1/2">
          <FilterBar
            filters={filters}
            onFilterChange={(filter) => setFilters({ ...filters, ...filter })}
            isFillter={false}
          />
        </div>
        <div className="md:w-1/3.5">
          <div className="barOptions">
            {/* <button className="rounded-btn p-2">{SVG.fileDownload}</button> */}
          </div>
        </div>
      </div>
      <div className="boarding-card p-0 max-w-none">
        <Table
          columns={[
            {
              lable: "Name",
              accessor: "business_name",
            },
            {
              lable: "Email",
              accessor: "email",
            },
            {
              lable: "Bussiness Type",
              accessor: "business_type",
            },
          ]}
          rows={(data?.response.results ?? []).map(
            ({
              profile: { business_name, email, business_type, status, id },
            }) => ({
              id: id,
              business_name,
              email,
              business_type,
              status: status,
              isActive: status !== "Approved" ? false : true,
            })
          )}
          onRowClicked={({ id }) => {
            navigate(`/organizations/${id}`);
          }}
          onApproveClicked={(id, status) => {
            setOrganization(id, {
              status: status ? "Approved" : "Rejected",
            }).then((data) => {
              console.log(data);
            });
          }}
          onEditClicked={({ id }) => {
            navigate(`/organizations/${id}?edit=true`);
          }}
          onDeleteClicked={({ id }) => {
            setDeleteThis(id);
          }}
          dataCount={data?.count}
          activePage={filters.page}
          onPageClicked={(page) => {
            setFilters({ ...filters, page: page });
          }}
        />
      </div>
      {deleteThis && (
        <Modal close={() => setDeleteThis(null)}>
          <DeleteModal
            title={"Organization"}
            isLoading={isLoading}
            onClick={deleteOrganization}
          />
        </Modal>
      )}
    </>
  );
};

export default OrganizationsTable;
