import wallet_added from "../../../images/wallet-added.svg";
import { Link } from "react-router-dom";
import { ADD_WALLET } from "../../constant/constant";
import { useContext, useState } from "react";
import { SyncWalletContext } from "../../context/SyncWalletContext";
import Button from "../../../common/form-controls/Button";

const WalletAdded = () => {
  const { setScreen } = useContext(SyncWalletContext);

  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const onclick = () => {
    setScreen(ADD_WALLET);
  };

  return (
    <div className="relative">
      {isImageLoaded && <div className="card">
        <div className="card-header">Congratulations</div>
        <p>
          We have succesfully added your first wallet. <br />
          synchronization may take several minutes. you can add another
          <br /> wallet or proceed to your dashboard.
        </p>
        <Button className="w-full" onClick={onclick}>
          + Add Another Wallet
        </Button>
        <Link to="/" className="orange-link mt-6">
          Proceed to the dashboard
        </Link>
      </div>}
      <div className="wallet-added_image">
        <img src={wallet_added} alt="wallet-added" onLoad={() => setIsImageLoaded(true)} />
      </div>
    </div>
  );
};

export default WalletAdded;
