import React, { useEffect } from "react";
import classNames from "classnames";
import Input from "../../../common/form-controls/Input";
import { useTranslation } from "react-i18next";

const PhoneNumber = ({ value, onChange, error, countryCode, ...props }) => {
  useEffect(() => {
    if (countryCode && !value?.startsWith(`+${countryCode} `)) {
      onChange(value.replaceAll(`+${countryCode}`, `+${countryCode} `));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, countryCode]);
  const { t } = useTranslation();

  return (
    <Input
      maxLength="16"
      placeholder={t("phone")}
      value={
        value?.startsWith(`+${countryCode} `)
          ? value
          : countryCode
          ? `+${countryCode} `
          : ""
      }
      onChange={(e) => {
        if (e.target.value.length >= `+${countryCode} `.length) {
          onChange(
            `+${countryCode} ${e.target.value.replaceAll(
              `+${countryCode} `,
              ""
            )}`
          );
        }
      }}
      className={classNames({
        "is-invalid": error,
      })}
      {...props}
    />
  );
};

export default PhoneNumber;
