import React, { useMemo } from "react";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

const indicators = [
  {
    title: "Initiate",
    key: "INITIATE",
    colorClass: "#57008380",
  },
  {
    title: "Canceled",
    key: "CANCELED",
    colorClass: "#3A005780",
  },
  {
    title: "Processed",
    key: "PROCESSED",
    colorClass: "#8900CC80",
  },
  {
    title: "Error",
    key: "ERROR",
    colorClass: "#27003A80",
  },
  {
    title: "Failed",
    key: "FAILED",
    colorClass: "#27003A80",
  },
];

const TransactionsChart = ({ data = {} }) => {
  const { t } = useTranslation();
  const values = useMemo(() => {
    return indicators.reduce(
      (acc, indicator) => {
        if (data[indicator.key] !== undefined) {
          acc.labels.push(
            t(`dashboard.${indicator.title}`) + ` (${data[indicator.key]})`
          );
          acc.datasets.push(data[indicator.key]);
          acc.backgroundColors.push(indicator.colorClass);
        }
        return acc;
      },
      {
        labels: [],
        datasets: [],
        backgroundColors: [],
      }
    );
  }, [data, t]);

  const chartData = {
    labels: values.labels,
    datasets: [
      {
        backgroundColor: values.backgroundColors,
        data: values.datasets,
      },
    ],
  };

  const options = {
    // responsive: false,
    // maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  return (
    <div class="transactions-chart shadow border rounded-lg overflow-hidden p-8">
      <h3 className="font-bold">{t("dashboard.Transactions")}</h3>
      <Pie options={options} data={chartData} />
      <h5 className="flex justify-between mb-1">
        <label>{t("dashboard.Total")}</label>
        <span>{data.TOTAL}</span>
      </h5>
    </div>
  );
};

export default TransactionsChart;

export const IndicationBar = ({
  title = "Title",
  value,
  percentage,
  colorClass = "bg-orange-600",
}) => {
  return (
    <>
      <h5 className="flex justify-between mt-4 mb-1">
        <label>{title}</label>
        <span>{value}</span>
      </h5>
      <div class="w-full bg-gray-200 h-2 rounded">
        <div
          class={`${colorClass} h-2 rounded`}
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
    </>
  );
};
