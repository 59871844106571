import * as Yup from "yup";

const URL =
  // eslint-disable-next-line
  /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/gi;

export const KeySchema = Yup.object().shape({
  name: Yup.string().trim().required("Name is required"),
  type: Yup.string().trim().required("Type is required"),
  platform_url: Yup.string().trim().matches(URL, "Enter a valid url"),
  redirect_uris: Yup.string().trim().matches(URL, "Enter a valid url"),
  mode: Yup.string().trim().required("Mode is required"),
});
