import { useEffect, useRef, cloneElement, isValidElement } from "react";
import { useDarkMode } from "usehooks-ts";
import { useOnClickOutside } from "usehooks-ts";
import { CrossIcon } from "../icons/Icon";
import ReactDOM from "react-dom";

const modalRoot = document.getElementById("modal-root");

const Modal = ({ children, close, modalSize = "modal-md", modalHeader }) => {
  const { isDarkMode } = useDarkMode();
  const modalRef = useRef();

  useOnClickOutside(modalRef, close);

  useEffect(() => {
    const bodyElement = document.querySelector("body");
    bodyElement.classList.add("overlay", "overflow-hidden");
    return () => bodyElement.classList.remove("overlay", "overflow-hidden");
  }, []);

  return (
    <ModalPortal>
      <div className={`${modalSize} modal`} ref={modalRef}>
        <div className="modal-header">
          {modalHeader && modalHeader()}
          <button className="ml-auto mr-4" onClick={close}>
            <CrossIcon svgColor={isDarkMode ? "#ffffff" : "#000000"} />
          </button>
        </div>
        <div className="modal-body">
          {isValidElement(children)
            ? cloneElement(children, { close })
            : children}
        </div>
      </div>
    </ModalPortal>
  );
};

const ModalPortal = ({ children }) => {
  const div = useRef(document.createElement("div"));

  useEffect(() => {
    const element = div.current;
    modalRoot.appendChild(element);

    return () => modalRoot.removeChild(element);
  }, []);

  return ReactDOM.createPortal(children, div.current);
};

export default Modal;
