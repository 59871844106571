import React, { useState } from "react";
import Button from "../../common/form-controls/Button";
import classNames from "classnames";
import { useFormik } from "formik";
import { useHeader } from "../context/UserProvider";
import { useNavigate } from "react-router-dom";
import Modal from "../../common/modal/Modal";
import ProInvoiceCusModal from "../../common/modal/ProInvoiceCusModal";
import { useTranslation } from "react-i18next";
const AddInvoice = () => {
  useHeader("newInvoice");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [Tab, setTab] = useState(null);
  const formik = useFormik({
    initialValues: {
      invoice_type: "PROFESSIONAL",
    },
    onSubmit(values) {
      values.invoice_type === "PROFESSIONAL"
        ? setTab(1)
        : navigate("/simple-invoice");
    },
    validateOnChange: false,
  });

  return (
    <section className="addInvoice grid md:grid-cols-2">
      <form onSubmit={formik.handleSubmit}>
        <div className="heading-lg" style={{ marginBottom: "2rem" }}>
          {t("selectInvoiceType")}
        </div>
        <div className="form-group">
          <div className="radioFun">
            <label
              className={`form-control ${
                formik.values.invoice_type === "PROFESSIONAL" && "active"
              }`}
            >
              <input
                name="invoice_type"
                type="radio"
                value="PROFESSIONAL"
                checked={
                  formik.values.invoice_type === "PROFESSIONAL" && "true"
                }
                onChange={formik.handleChange}
              />
              <span>{t("professional")}</span>
              <ul>
                <li>{t("professionalInvoceItemList")}</li>
                <li>{t("invoiceACustomer")}</li>
                <li>{t("PDFIncluded")}</li>
              </ul>
            </label>
            <label
              className={`form-control ${
                formik.values.invoice_type === "SIMPLE" && "active"
              }`}
            >
              <input
                name="invoice_type"
                type="radio"
                value="SIMPLE"
                checked={formik.values.invoice_type === "SIMPLE" && "checked"}
                onChange={formik.handleChange}
              />
              <span>{t("simpleInvoice")}</span>
              <p>{t("simpleInvoiceDescription")}</p>
              <p></p>
            </label>
          </div>
        </div>
        <Button
          className={classNames("md:mb-6 mb-4 disabled:opacity-50")}
          type="submit"
        >
          {t("continue")}
        </Button>
      </form>
      {Tab && (
        <Modal close={() => setTab(null)}>
          <ProInvoiceCusModal title={t("invoiceCustomer")} />
        </Modal>
      )}
    </section>
  );
};

export default AddInvoice;
