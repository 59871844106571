import { useTranslation } from "react-i18next";
import delete_icon from "../../images/delete-lg.svg";
import ButtonLoader from "../form-controls/ButtonLoader";

const DeleteModal = ({isLoading= false, close, title, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="delete__account">
      <img src={delete_icon} alt="delete-icon" />
      <h3> {`${t("commonLabels.deleteLabel")} ${title}`}</h3>
      <p>{t("deleteModal.message", { value: title.toLowerCase() })}</p>
      <div className="modal__button">
        <button className="btn-brightgray" type="button" onClick={close}>
          {t("commonLabels.cancelLabel")}
        </button>
        <button className="btn-danger" type="button" onClick={onClick} disabled={isLoading}>
        {isLoading ? <ButtonLoader text={t("settingContent.deleting")} /> : t("commonLabels.deleteLabel")}
        </button>
      </div>
    </div>
  );
};

export default DeleteModal;
