
import React from 'react'
import { isNegative } from '../utils/helpers';
import classNames from "classnames";


const DashboardCard = ({ price, worth, cardIcon, percentage = null, value=null }) => {
  return (
    <>
      <div className='dashboard-card'>
        <div className="break-all">
          <div className='dashboard-details mb-3'>
            <h3 className='dashboard-title dark:text-brightgray'>{price}</h3>
            {percentage !== null && <h4 className={classNames({
              "upper-bend": !isNegative(percentage),
              "lower-bend": isNegative(percentage)
            },"dashboard-subtitle")
            }>({percentage}%)</h4>}
            {/* {value !==null &&
              <div className="dashboard-value">{value}</div>
            } */}
          </div>
          <h5 className='text-cadetblue font-gotham-medium text-sm capitalize'>{worth}</h5>
        </div>
        <div className="dashboard-card_img">
          {cardIcon}
        </div>
      </div>
    </>
  )
}

export default DashboardCard;