import React from "react";

const TextArea = ({ className, ...props }) => {
  return (
    <textarea
      className={`w-full ${
        className ? `form-control ${className}` : `form-control`
      }`}
      type="text"
      {...props}
    />
  );
};

export default TextArea;
