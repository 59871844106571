import * as Yup from "yup";

export const ProfileSchema = Yup.object().shape({
  business_name: Yup.string().trim().required("Business Name is required"),
  email: Yup.string()
    .trim()
    .email("Invalid email")
    .required("Email is required"),
  phone: Yup.string()
    .trim()
    .min(10, "Phone not valid")
    .max(16, "Phone not valid")
    .required("Phone is required"),
  website: Yup.string().required().matches(/^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/, "Please enter a valid website url ( https://google.com/ )"),
  business_type: Yup.string().required("Business Type is required"),
  country: Yup.string().required("Country is required"),
  city: Yup.string().required("City is required"),
  address: Yup.string().trim().required("Street Address is required"),
  rejection_reason: Yup.string().when("status", {
    is: "Rejected",
    then: Yup.string().required("Rejection Reason is required").nullable(),
    // otherwise: Yup.string().nullable(),
  }),
  status: Yup.string(),
  description: Yup.string().trim(),
});
