import Input from "../../common/form-controls/Input";
import Label from "../../common/form-controls/Label";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { getRefundById } from "../../common/api";
import { notify } from "../../common/toaster/Toaster";
import { useMutation } from "react-query";
import ButtonLoader from "../form-controls/ButtonLoader";
import {
  InvoiceIdBussScheme,
  InvoiceIdScheme,
} from "../../components/refunds/validation-schema";
import { SVG } from "../icons/Icon";
import { useTranslation } from "react-i18next";
import { hasPermission } from "../withRole/WithPermission";
import BussinessSelect from "../../components/customers/components/BussinessSelect";
import { useUser } from "../../components/context/UserProvider";

const RefundCutomerModal = ({ title, close }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoading, mutate: getRefundFn } = useMutation(
    (values) => getRefundById(values),
    {
      onSuccess: (data) => {
        var refundType = formik.values.refund_type;
        if (!data.results[0]?.type && data.results[0]?.status === "SUCCESS") {
          navigate("/add-refund-transaction", {
            state: { ...data.results[0], business: formik.values.business },
          });
        } else if (data.count === 0 || data.results[0].type === "POS") {
          notify(`${refundType} Not Found`, "error");
        } else if (data.results[0]?.status !== "PROCESSED") {
          notify(`${refundType} Not Processed`, "error");
        } else if (
          data.results[0]?.type === "SIMPLE" &&
          data.results[0]?.status === "PROCESSED"
        ) {
          navigate("/add-refund-simple", {
            state: { ...data.results[0], business: formik.values.business },
          });
        } else if (
          data.results[0]?.type === "PROFESSIONAL" &&
          data.results[0]?.status === "PROCESSED"
        ) {
          navigate("/add-refund-professional", {
            state: { ...data.results[0], business: formik.values.business },
          });
        }
      },
      onError: (error) => {
        Object.entries(error.response.data.error).map(([key, value]) =>
          formik.setFieldError(key, value.error_string)
        );
      },
    }
  );
  const { user } = useUser();

  const formik = useFormik({
    initialValues: {
      invoice_id: "",
      refund_type: "",
    },
    onSubmit(values) {
      values.refund_type === "Invoice"
        ? getRefundFn(`/invoice/?public_id=${values.invoice_id}`)
        : getRefundFn(`/transaction/?transaction_id=${values.invoice_id}`);
    },
    validationSchema:
      hasPermission("refund")("add_refund") && user?.user_role === "Admin"
        ? InvoiceIdBussScheme
        : InvoiceIdScheme,
    validateOnChange: false,
  });

  return (
    <div className="invoiceModal">
      <div className="modalIcon">{SVG.message}</div>
      <h3>{title}</h3>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          {hasPermission("refund")("add_refund") && (
            <BussinessSelect
              className="flex flex-wrap"
              name="business"
              value={parseInt(formik.values.business)}
              onChange={(value) =>
                formik.setFieldValue("business", value.value)
              }
              error={formik.errors.business}
            />
          )}
        </div>
        <div className="form-group">
          <Label>{t("refundType")}</Label>
          <select
            name="refund_type"
            onChange={formik.handleChange}
            value={formik.values.refund_type}
            className="form-control"
          >
            <option value="">{t("select")}</option>
            <option value="Invoice">{t("invoice")}</option>
            <option value="Transaction">{t("transaction")}</option>
          </select>
          {formik.errors.refund_type && (
            <span className="form-error">{formik.errors.refund_type}</span>
          )}
        </div>
        <div className="form-group">
          <Label>
            {formik.values.refund_type === "Transaction"
              ? t("transactionId")
              : t("invoiceId")}
          </Label>
          <Input
            name="invoice_id"
            placeholder={
              formik.values.refund_type === "Transaction"
                ? t("transactionId")
                : t("invoiceId")
            }
            onChange={formik.handleChange}
            value={formik.values.invoice_id}
          />
          {formik.errors.invoice_id && (
            <span className="form-error">{formik.errors.invoice_id}</span>
          )}
        </div>
        <button className="btn-orange" type="submit">
          {isLoading ? <ButtonLoader text={t("checking")} /> : t("continue")}
        </button>
        <button
          style={{ width: "100%" }}
          className="btn-brightgray mt-4"
          type="button"
          onClick={close}
        >
          {t("close")}
        </button>
      </form>
    </div>
  );
};

export default RefundCutomerModal;
