import key_icon from "../../images/key.svg";
import Input from "../../common/form-controls/Input";
import Label from "../../common/form-controls/Label";
import { useTranslation } from "react-i18next";

const KeyModal = ({ isLoading = false, close, title, ApiCre }) => {
  const { t } = useTranslation();
  return (
    <div className="delete__account">
      <img
        src={key_icon}
        alt="key-icon"
        style={{ marginTop: "-4.5rem", height: "120px" }}
      />
      <h3>{title}</h3>
      <div className="form-group" style={{ width: "80%" }}>
        <Label>{t("clientID")}</Label>
        <Input value={ApiCre.access_key} allowCopy={true} />
      </div>
      <div className="form-group" style={{ width: "80%" }}>
        <Label>{t("secretKey")}</Label>
        <Input value={ApiCre.secret_key} allowCopy={true} />
      </div>
      <button
        style={{ width: "80%" }}
        className="btn-brightgray"
        type="button"
        onClick={close}
      >
        {t("close")}
      </button>
    </div>
  );
};

export default KeyModal;
