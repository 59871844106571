import React, { useEffect, useState, useRef } from "react";
import logoImg from "../../images/sappayWhite-logo.svg";
import { NavLink, Link } from "react-router-dom";
import { SVG } from "../../common/icons/Icon";
import classnames from "classnames";
import ThemeSwitch from "../theme-switch/ThemeSwitch";
// import { useDarkMode } from "usehooks-ts";
// import LanguageDropdown from "../../common/language-dropdown/LanguageDropdown";
import { useTranslation } from "react-i18next";
import { useUser } from "../context/UserProvider";
import { useWindowSize, useOnClickOutside } from "usehooks-ts";
import { useMutation } from "react-query";
import { logout } from "../../common/api";
import { hasPermission } from "../../common/withRole/WithPermission";

const Sidebar = () => {
  // const { isDarkMode } = useDarkMode();
  const [open, setToggleState] = useState(false);
  const { t } = useTranslation();
  const [navLinks, setNavLinks] = useState([
    {
      path: "/fund-raising",
      icon: SVG.fundRaising,
      lable: "Fund Raising",
      moduleName: "event",
    },
    {
      path: "/transactions",
      icon: SVG.transactions,
      lable: "transactions",
      moduleName: "transaction",
    },
    {
      path: "/beneficiaries",
      icon: SVG.beneficiary,
      lable: "beneficiaries",
      moduleName: "payoutbeneficiary",
    },
    {
      path: "/payouts",
      icon: SVG.payouts,
      lable: "payouts",
      moduleName: "payout",
    },
    {
      path: "/refunds",
      icon: SVG.refunds,
      lable: "refunds",
      moduleName: "refund",
    },
    {
      path: "/customers",
      icon: SVG.customer,
      lable: "customers",
      moduleName: "customer",
    },
    {
      path: "/employees",
      icon: SVG.employees,
      lable: "employees",
      moduleName: "member",
    },
    {
      path: "/organizations",
      icon: SVG.organization,
      lable: "organizations",
      moduleName: "business",
    },
    {
      path: "/users",
      icon: SVG.users,
      lable: "users",
      moduleName: "user",
    },
    {
      path: "/members",
      icon: SVG.users,
      lable: "members",
      moduleName: "member",
    },
    {
      path: "/providers",
      icon: SVG.providers,
      lable: "providers",
      moduleName: "provider",
    },
    {
      path: "/subscribers",
      icon: SVG.subscribers,
      lable: "subscribers",
      role: "Merchant",
      disabled: true,
    },
    {
      path: "/payment-pages",
      icon: SVG.paymentPages,
      lable: "paymentPages",
      role: "Merchant",
      disabled: true,
    },
  ]);
  const ref = useRef(null);
  const { width, height } = useWindowSize();
  const { user, bussiness } = useUser();

  useOnClickOutside(ref, () => setToggleState(false));
  const { mutate } = useMutation(() => logout());
  const userLogout = () => {
    mutate();
  };
  useEffect(() => {
    if (bussiness?.profile.status === "Approved") {
      setNavLinks(navLinks.filter((item) => item.path !== "/compliance"));
    }
    // eslint-disable-next-line
  }, [bussiness]);

  useEffect(() => {
    const bodyElement = document.querySelector("body");
    if (width < 1023 && open) {
      bodyElement.classList.add("overlay");
    } else {
      bodyElement.classList.remove("overlay");
    }
  }, [open, width]);

  return (
    <>
      <div
        onClick={() => setToggleState(!open)}
        className={classnames({ close: open }, "hamburger lg:hidden")}
      >
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>
      <section
        ref={ref}
        style={{ minHeight: height }}
        className={classnames({ "sidebar-open": open }, "sidebar")}
      >
        <Link to={"/"}>
          <div className="sideBarLogo">
            <img src={logoImg} alt="logo" />
          </div>
        </Link>
        <ul style={{ height: height - 245 }}>
          {user.business_approved && user.business_approved !== "Approved" && (
            <SidebarItem
              icon={SVG.compliance}
              lable={t("compliance")}
              path="/compliance"
              onClick={() => setToggleState(false)}
            />
          )}
          <SidebarItem
            icon={SVG.dashboard}
            lable={t("dashBoard")}
            path="/"
            onClick={() => setToggleState(false)}
          />
          <SidebarItem
            icon={SVG.invoice}
            lable={"Invoices"}
            path="/invoices"
            onClick={() => setToggleState(false)}
          />

          {navLinks.map((item, index) => {
            return user.user_role !== "Admin"
              ? user.business_approved === "Approved" &&
                  user.permissions?.hasOwnProperty(item.moduleName) &&
                  hasPermission(item.moduleName)("navigation_view") && (
                    <SidebarItem
                      key={index}
                      icon={item.icon}
                      lable={t(`${item.lable}`)}
                      path={item.path}
                      onClick={() => setToggleState(false)}
                      disabled={item.disabled}
                    />
                  )
              : user.permissions?.hasOwnProperty(item.moduleName) &&
                  hasPermission(item.moduleName)("navigation_view") && (
                    <SidebarItem
                      key={index}
                      icon={item.icon}
                      lable={t(`${item.lable}`)}
                      path={item.path}
                      onClick={() => setToggleState(false)}
                      disabled={item.disabled}
                    />
                  );
          })}

          <SidebarItem
            icon={SVG.key}
            lable={"Api Keys"}
            path="/api-keys"
            onClick={() => setToggleState(false)}
          />
          <SidebarItem
            icon={SVG.settings}
            lable={t("settings")}
            path="/settings"
            onClick={() => setToggleState(false)}
          />
        </ul>
        <div className="admin-profile">
          <div className="admin-profile-content">
            <img
              src={"https://avatars.dicebear.com/api/identicon/:seed.svg"}
              alt="admin-icon"
            />
            <div className="heading-md  text-white">
              {user && `${user.first_name} ${user.last_name}`}
            </div>
            <button type="button" onClick={userLogout}>
              {SVG.logout}
            </button>
          </div>
        </div>
        <div className="sidebar-bottom">
          {/* <LanguageDropdown /> */}
          <ThemeSwitch />
        </div>
      </section>
    </>
  );
};

const SidebarItem = ({ lable, icon, path, onClick, disabled = false }) => {
  return (
    <li className={disabled ? "nav-link-diasbled" : ""}>
      <NavLink to={path} onClick={onClick}>
        {icon}
        {lable}
      </NavLink>
    </li>
  );
};

export default Sidebar;
