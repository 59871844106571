import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import Table from "../../common/table/Table";
import recoveryIcon from "../../images/recovery.svg";
import calanderIcon from "../../images/calander.svg";
import successIcon from "../../images/successfultransactionsicon.svg";
import unSuccessIcon from "../../images/unsuccessfultransactionsicon.svg";
import currencyIcon from "../../images/totalicon.svg";
import RoundedPillWidget from "../widgets/RoundedPillWidget";
import { useParams } from "react-router-dom";
import { getPayoutById } from "../../common/api";
import { useQuery } from "react-query";
import { hasPermission } from "../../common/withRole/WithPermission";
import ApprovePayoutModel from "./components/ApprovePayoutModel";
import Modal from "../../common/modal/Modal";
import { CURRENCY_SYMBOL } from "../../common/utils/helpers";

const PayoutDetail = ({ moduleName = "payout" }) => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);

  const { data, isLoading } = useQuery(["getPayoutById", id], () =>
    getPayoutById(id)
  );

  return isLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <>
      <div className="widgets mb-6">
        <RoundedPillWidget
          img={currencyIcon}
          label="SETTLED AMOUNT"
          value={data.response.settled_amount ?? "-"}
        />
        <RoundedPillWidget
          img={recoveryIcon}
          label="ACTIVITY"
          value={data.response.id}
        />
        <RoundedPillWidget
          img={calanderIcon}
          label="COMPLETED ON"
          value={
            data.response.status === "APPROVED"
              ? new Date(data.response.created_at).toLocaleDateString()
              : "-"
          }
        />
        {data.response.status === "APPROVED" && (
          <RoundedPillWidget
            img={successIcon || unSuccessIcon}
            label="SUCCESSFUL"
            value={data.response.status}
          />
        )}
      </div>
      <div className="boarding-card p-0 max-w-none">
        <Table
          columns={[
            {
              lable: "REFERENCE",
              accessor: "reference_id",
            },
            {
              lable: "ACTIVITY",
              accessor: "id",
            },
            {
              lable: `FEES (${CURRENCY_SYMBOL})`,
              accessor: "fees",
            },
            {
              lable: "DATE",
              accessor: "date",
            },
            {
              lable: "TIME (UTC)",
              accessor: "time",
            },

            ...(hasPermission(moduleName)("approve") &&
            data?.response.status === "PENDING"
              ? [
                  {
                    lable: "",
                    accessor: "test",
                    custom: true,
                  },
                ]
              : []),
          ]}
          rows={[
            {
              ...data.response,
              fees: data.response.fees ?? "N/A",
              date: new Date(data.response.created_at).toLocaleDateString(),
              time: new Date(data.response.created_at).toLocaleTimeString(),
              test: () => {
                return (
                  <button
                    className="primary-btn rounded"
                    onClick={() => setOpen(true)}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;Pay&nbsp;&nbsp;&nbsp;&nbsp;
                  </button>
                );
              },
            },
          ]}
          allowDelete={false}
          allowEdit={false}
          showStatus={false}
        />
      </div>
      {open && (
        <Modal close={() => setOpen(false)}>
          <ApprovePayoutModel
            title="Approve Payout"
            payout={data.response}
            onClose={() => setOpen(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default PayoutDetail;
