import * as Yup from "yup";

export const UserSchema = Yup.object().shape({
  first_name: Yup.string().trim().required("First name is required"),
  last_name: Yup.string().trim().required("Last name is required"),
  user_role: Yup.string().trim().required("Role is required"),
  email: Yup.string()
    .trim()
    .email("Invalid email address")
    .required("Email is required"),
});
