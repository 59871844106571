import * as Yup from "yup";

export const MemberSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Invalid email address")
    .required("Email is required"),
  first_name: Yup.string().trim().required("Firstname is required"),
  last_name: Yup.string().trim().required("Lastname is required"),
  phone: Yup.string().trim().required("Phone is required"),
  country: Yup.number().required("Country is required"),
  city: Yup.number().required("City is required"),
  groups: Yup.array().of(Yup.number()).min(1, "Atleast 1 group is required"),
  is_active: Yup.boolean().required("IsActive is required"),
});
