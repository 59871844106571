import * as Yup from "yup";

export const ResetPasswordSchema = Yup.object().shape({
  new_password1: Yup.string()
    .trim()
    .required("new password is required"),
  new_password2: Yup.string().trim()
    .test('Passwords must be same', 'Passwords must be same', function (value) {
      return this.parent.new_password1 === value
    }),
});
