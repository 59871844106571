import React from "react";
import { useQuery } from "react-query";
import { getAssets } from "../../../common/api";
import { BeatLoader } from "react-spinners";
import AssetCard from "../../../common/assetcard/AssetCard";
import WarningMsg from "../../../common/warning-msg/WarningMsg";

const YourAssets = () => {
  const { data, isLoading } = useQuery("getAssets", getAssets, { retry: 1 });

  return (
    <div className="your-assets">
      <div className="card-heading maxMd:mx-4">
        <h3 className="text-xl capitalize dark:text-brightgray font-gotham-regular">
          Your Assets
        </h3>
      </div>
      {data && data.response.length > 0 && (
        <div className="assets_content">
          {data.response.map((item, index) => (
            <AssetCard key={index} item={item} />
          ))}
        </div>
      )}
      {isLoading && (
        <div className="w-full flex justify-center">
          <BeatLoader color="#7F2CEA" />
        </div>
      )}
      {data && data.response.length === 0 && (
        <WarningMsg warningText="Currently no assets is avalibale to show" />
      )}
    </div>
  );
};

export default YourAssets;
