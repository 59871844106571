import React from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { createEmployee } from "../../common/api";
import { notify } from "../../common/toaster/Toaster";
import EmployeeForm from "./EmployeeForm";
import { useTranslation } from "react-i18next";

const AddEmployee = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoading, mutate, error } = useMutation(
    (values) => createEmployee(values),
    {
      onSuccess: () => {
        navigate("/employees");
        notify(t("employeeCreated"), "success");
      },
    }
  );
  return (
    <div className="tab-content">
      <div className="section-head">
        <h1>{t("createEmployee")}</h1>
      </div>
      <EmployeeForm onSubmit={mutate} isLoading={isLoading} error = {error} />
    </div>
  );
};

export default AddEmployee;
