import React from "react";
import { Link } from "react-router-dom";
import logoImg from "../../images/sappayPurple-logo.svg";
import Label from "../../common/form-controls/Label";
import Input from "../../common/form-controls/Input";
import Button from "../../common/form-controls/Button";
import { useMutation } from "react-query";
import { forgotPassword } from "../../common/api";
import { useFormik } from "formik";
import classNames from "classnames";
import { ResetSchema } from "./validation-schema";
import { getServerError } from "../../common/utils/helpers";
import ButtonLoader from "../../common/form-controls/ButtonLoader";
import { notify } from "../../common/toaster/Toaster";
import emailcon from "../../images/email.svg";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const {
    data,
    isLoading,
    mutate: resetFn,
  } = useMutation((values) => forgotPassword(values), {
    onError: (error) => {
      notify(getServerError(error), "error");
    },
  });

  const formik = useFormik({
    initialValues: { email: "" },
    onSubmit: resetFn,
    validationSchema: ResetSchema,
    validateOnChange: false,
  });

  return (
    <section className="signup signBg grid items-center">
      <div className="signup-content h-screen">
        <div className="form-card relative">
          <div className="logoImg">
            <img src={logoImg} alt="logo" />
          </div>
          <h3>{t("forgetPassword")}</h3>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <Label>{t("emailAddress")}</Label>
              <div className="form-control-addon">
                <img src={emailcon} alt="email-icon" />
                <Input
                  required
                  placeholder={t("emailAddress")}
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  className={classNames({ "is-invalid": formik.errors.email })}
                />
              </div>
              {formik.errors.email && (
                <span className="form-error">{formik.errors.email}</span>
              )}
            </div>

            <Button
              type="submit"
              className={classNames("md:mb-6 mb-4 disabled:opacity-50")}
              disabled={isLoading}
            >
              {isLoading ? <ButtonLoader text={"sending"} /> : t("send")}
            </Button>
            <div className="flex justify-center">
              {" "}
              <span className="font-xs">{t("backTo")}</span>
              <Link className="ml-1 orange-link" to="/">
                {t("signIn")}
              </Link>
            </div>
          </form>
          {data && (
            <p className="text-success mt-4">{t("passwordResetMessage")}</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
