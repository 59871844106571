import React from "react";
import Modal from "../../common/modal/Modal";
import SimInvoiceCusModal from "../../common/modal/SimInvoiceCusModal";
import { useNavigate } from "react-router-dom";
import { useHeader } from "../context/UserProvider";
import { useTranslation } from "react-i18next";
const SimpleInvoice = () => {
  const { t } = useTranslation();
  useHeader("simpleInvoice");
  const navigate = useNavigate();
  return (
    <Modal close={() => navigate("/invoices")}>
      <SimInvoiceCusModal title={t("Invoice Customer")} />
    </Modal>
  );
};

export default SimpleInvoice;
