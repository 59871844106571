import * as Yup from "yup";

export const RefundSchema = Yup.object().shape({
  total_amount: Yup.string().trim().required("Refund Amount is required"),
});

export const InvoiceIdScheme = Yup.object().shape({
  invoice_id: Yup.string().trim().required("Invoice ID is required"),
  refund_type: Yup.string().trim().required("Select Refund Type"),
});

export const InvoiceIdBussScheme = Yup.object().shape({
  invoice_id: Yup.string().trim().required("Invoice ID is required"),
  refund_type: Yup.string().trim().required("Select Refund Type"),
  business: Yup.string().required(),
});
