import React from "react";
import { useUser } from "../../components/context/UserProvider";

const withRole = (roles) => (Component) => (props) => {
  const { user } = useUser();
  if (Array.isArray(roles) && roles.includes(user?.user_role)) {
    return <Component {...props} />;
  }

  if (
    (typeof roles === "string" || roles instanceof String) &&
    user?.user_role === roles
  ) {
    return <Component {...props} />;
  }
  return null;
};

export default withRole;