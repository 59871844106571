import WalletAdded from "./WalletAdded";

const WalletAddedWrapper = () => {
  return (
    <div className="wallet-added">
      <WalletAdded />
    </div>
  );
};

export default WalletAddedWrapper;
