import * as Yup from "yup";

export const EmployeeSchema = Yup.object().shape({
  name: Yup.string().trim().required("Name is required"),
  email: Yup.string().required("Email is required"),
  country: Yup.string().required("Country is required"),
  city: Yup.string().required("City is required"),
  business: Yup.number().nullable(),
  is_active: Yup.boolean().required("IsActive is required"),
});
