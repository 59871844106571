import dots_icon from "../../../../images/3dots.svg";
import { CustomArrow, Box2Arrow, RightArrowAc } from "../../../../common/icons/Icon";

const AccordionModal = () => {

  return (
    <div className="accordion-modal">
      <div className="w-full">
        <h4>Sep 31, 2021</h4>
      </div>
      <div className="accordion-modal-list">
        <div className="flex items-center">
          <div className="box">
            <CustomArrow svgColor={"#34A853"} />
          </div>
          <div>
            <h4>Buy</h4>
            <p>06:15 AM</p>
          </div>
          <div className="box-2 ml-auto">
            <Box2Arrow />
          </div>
        </div>
        <div className="col-center">
          <div>
            <h4>$ 3,200</h4>
            <p>Ethereum</p>
          </div>
          <RightArrowAc svgColor={"#0D2770"} />
        </div>
        <div className="flex">
          <div className="box-2 mr-4">
            <Box2Arrow />
          </div>
          <div>
            <h4>+8,643.60 LTC</h4>
            <p>42.939403 SOL</p>
          </div>
        </div>
        <button className="dots__icon">
          <img src={dots_icon} alt="dots__icon" />
        </button>
      </div>
      <div className="accordion-modal-list">
        <div className="flex items-center">
          <div className="box arrow-down">
            <CustomArrow svgColor={"#F97030"} />
          </div>
          <div>
            <h4>Deposit</h4>
            <p>08:02 PM</p>
          </div>
          <div className="box-2 ml-auto">
            <Box2Arrow />
          </div>
        </div>
        <div className="col-center">
          <RightArrowAc svgColor={"#0D2770"} />
        </div>
        <div className="flex">
          <div className="box-2 mr-4">
            <Box2Arrow />
          </div>
          <div>
            <h4>+8,643.60 LTC</h4>
            <p>+1000$</p>
          </div>
        </div>
        <button className="dots__icon">
          <img src={dots_icon} alt="dots__icon" />
        </button>
      </div>
      <div className="accordion-modal-list border-0">
        <div className="flex items-center">
          <div className="box arrow-down">
            <CustomArrow svgColor={"#F97030"} />
          </div>
          <div>
            <h4>Deposit</h4>
            <p>08:02 PM</p>
          </div>
          <div className="box-2 ml-auto">
            <Box2Arrow />
          </div>
        </div>
        <div className="col-center">
          <RightArrowAc svgColor={"#0D2770"} />
        </div>
        <div className="flex">
          <div className="box-2 mr-4">
            <Box2Arrow />
          </div>
          <div>
            <h4>+8,643.60 LTC</h4>
            <p>+1000$</p>
          </div>
        </div>
        <button className="dots__icon">
          <img src={dots_icon} alt="dots__icon" />
        </button>
      </div>
      <div className="w-full mt-6"><h4>Aug 31, 2021</h4></div>
      <div className="accordion-modal-list">
        <div className="flex items-center">
          <div className="box">
            <CustomArrow svgColor={"#34A853"} />
          </div>
          <div>
            <h4>Buy</h4>
            <p>06:15 AM</p>
          </div>
          <div className="box-2 ml-auto">
            <Box2Arrow />
          </div>
        </div>
        <div className="col-center">
          <div>
            <h4>$ 3,200</h4>
            <p>Ethereum</p>
          </div>
          <RightArrowAc svgColor={"#0D2770"} />
        </div>
        <div className="flex">
          <div className="box-2 mr-4">
            <Box2Arrow />
          </div>
          <div>
            <h4>+8,643.60 LTC</h4>
            <p>42.939403 SOL</p>
          </div>
        </div>
        <button className="dots__icon">
          <img src={dots_icon} alt="dots__icon" />
        </button>
      </div>
      <div className="accordion-modal-list">
        <div className="flex items-center">
          <div className="box arrow-down">
            <CustomArrow svgColor={"#F97030"} />
          </div>
          <div>
            <h4>Deposit</h4>
            <p>08:02 PM</p>
          </div>
          <div className="box-2 ml-auto">
            <Box2Arrow />
          </div>
        </div>
        <div className="col-center">
          <RightArrowAc svgColor={"#0D2770"} />
        </div>
        <div className="flex">
          <div className="box-2 mr-4">
            <Box2Arrow />
          </div>
          <div>
            <h4>+8,643.60 LTC</h4>
            <p>+1000$</p>
          </div>
        </div>
        <button className="dots__icon">
          <img src={dots_icon} alt="dots__icon" />
        </button>
      </div>
      <div className="accordion-modal-list border-0">
        <div className="flex items-center">
          <div className="box arrow-down">
            <CustomArrow svgColor={"#F97030"} />
          </div>
          <div>
            <h4>Deposit</h4>
            <p>08:02 PM</p>
          </div>
          <div className="box-2 ml-auto">
            <Box2Arrow />
          </div>
        </div>
        <div className="col-center">
          <RightArrowAc svgColor={"#0D2770"} />
        </div>
        <div className="flex">
          <div className="box-2 mr-4">
            <Box2Arrow />
          </div>
          <div>
            <h4>+8,643.60 LTC</h4>
            <p>+1000$</p>
          </div>
        </div>
        <button className="dots__icon">
          <img src={dots_icon} alt="dots__icon" />
        </button>
      </div>
    </div>
  );
};

export default AccordionModal;
