import * as Yup from "yup";

export const DocumentsSchema = Yup.object().shape({
  business_logo: Yup.string().required("Upload business logo"),
  identification_document_front: Yup.string().required(
    "Upload Indentification Document Front"
  ),
  identification_document_back: Yup.string().required(
    "Upload Indentification Document Back"
  ),
});
