import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { getAllTransactions } from "../../common/api";
import Table from "../../common/table/Table";
// import numberIcon from "../../images/numbericon.svg";
// import successIcon from "../../images/successfultransactionsicon.svg";
// import unSuccessIcon from "../../images/unsuccessfultransactionsicon.svg";
// import pendingIcon from "../../images/pendingtransactionsicon.svg";
// import RoundedPillWidget from "../widgets/RoundedPillWidget";
import { CURRENCY_SYMBOL } from "../../common/utils/helpers";
import WithPermission from "../../common/withRole/WithPermission";
import { useHeader } from "../context/UserProvider";
import FilterBar from "../payouts/components/FilterBar";
// import { SVG } from "../../common/icons/Icon";

const statusColor = {
  SUCCESS: {
    color: "#24C45F",
    backgroundColor: "#CBF6DB",
  },
  INITIATE: {
    color: "#FFC134",
    backgroundColor: "#FFF2D6",
  },
  FAILED: {
    color: "#F87171",
    backgroundColor: "#FDD7D7",
  },
  REFUND_PENDING: {
    color: "#FFC134",
    backgroundColor: "#FFF2D6",
  },
  REFUND_APPROVED: {
    color: "#02B2FF",
    backgroundColor: "#D0EDFC",
  },
  REFUND_REJECTED: {
    color: "#F87171",
    backgroundColor: "#FDD7D7",
  },
};

const Transactions = ({ moduleName = "transaction" }) => {
  useHeader("transactions");
  //   HOOKS
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    status: "",
    created_at__date__gte: null,
    created_at__date__lte: null,
    search: null,
    page: 1,
  });

  const { data, isLoading: areTransactionsLoading } = useQuery(
    [
      "getAllTransactions",
      `?${filters.search}${filters.page}${filters.status}${filters.created_at__date__gte}${filters.created_at__date__lte}`,
    ],
    () => getAllTransactions(filters),
    {
      retry: 1,
    }
  );

  return areTransactionsLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <>
      {/* <div className="widgets mb-6">
        <RoundedPillWidget img={numberIcon} label="PAYOUTS" value="03" />
        <RoundedPillWidget img={successIcon} label="SUCCESSFUL" value="01" />
        <RoundedPillWidget
          img={unSuccessIcon}
          label="UNSSUCCESSFUL"
          value="0"
        />
        <RoundedPillWidget img={pendingIcon} label="PENDING" value="01" />
      </div> */}
      <div className="flex flex-wrap justify-between my-8">
        <div className="flex searchBar md:w-1/2">
          <FilterBar
            moduleName={moduleName}
            filters={filters}
            options={[
              { value: "", label: t("all") },
              { value: "INITIATE", label: t("INITIATE") },
              { value: "SUCCESS", label: t("Processed") },
              { value: "FAILED", label: t("Failed") },
              { value: "REFUND_PENDING", label: t("REFUND_PENDING") },
              { value: "REFUND_APPROVED", label: t("REFUND_APPROVED") },
              { value: "REFUND_REJECTED", label: t("REFUND_REJECTED") },
            ]}
            onFilterChange={(filter) => setFilters({ ...filters, ...filter })}
          />
        </div>
        <div className="md:w-1/3.5">
          <div className="barOptions">
            {/* <button className="rounded-btn p-2">{SVG.fileDownload}</button> */}
          </div>
        </div>
      </div>
      <div className="boarding-card p-0 max-w-none">
        <Table
          moduleName={moduleName}
          columns={[
            {
              lable: "Refrence No",
              accessor: "transaction_id",
              copyable: true,
            },
            {
              lable: "Customer",
              accessor: "email",
            },
            {
              lable: "Created At",
              accessor: "created_at",
            },
            {
              lable: "Channel",
              accessor: "channel",
              borderIdentifier: true,
            },
            {
              lable: `Amount(${CURRENCY_SYMBOL})`,
              accessor: "amount",
            },
            {
              lable: "Status",
              accessor: "status",
              chip: true,
            },
          ]}
          rows={(data?.results ?? []).map(
            ({
              transaction_id,
              id,
              amount,
              email,
              invoice,
              payment_processor,
              created_at,
              status,
            }) => ({
              transaction_id,
              id,
              amount,
              email,
              refrence: invoice,
              channel: {
                borderColor: payment_processor?.borderColor,
                value: payment_processor?.name,
              },
              created_at:
                new Date(created_at)
                  .toDateString()
                  .split(" ")
                  .slice(1)
                  .join(" ") +
                ", " +
                new Date(created_at).toLocaleTimeString(),
              status: {
                value: status,
                color: statusColor[status].color,
                backgroundColor: statusColor[status].backgroundColor,
              },
            })
          )}
          onRowClicked={({ id }) => {
            navigate(`/transactions/${id}`);
          }}
          allowDelete={false}
          allowEdit={false}
          showStatus={false}
          dataCount={data?.count}
          activePage={filters.page}
          onPageClicked={(page) => {
            setFilters({ ...filters, page: page });
          }}
        />
      </div>
    </>
  );
};

export default WithPermission("transaction")("view")(Transactions);
