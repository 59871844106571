import React, { useState } from "react";
import Input from "../../common/form-controls/Input";
import Label from "../../common/form-controls/Label";
import Button from "../../common/form-controls/Button";
import { addTransactionRefundData } from "../../common/api";
import classNames from "classnames";
import { useHeader } from "../context/UserProvider";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { amount, getServerError } from "../../common/utils/helpers";
import ButtonLoader from "../../common/form-controls/ButtonLoader";
import { RefundSchema } from "./validation-schema";
import { notify } from "../../common/toaster/Toaster";
import Modal from "../../common/modal/Modal";
import { useNavigate } from "react-router-dom";
import { useUser } from "../context/UserProvider";
import { useLocation } from "react-router-dom";
import { SVG } from "../../common/icons/Icon";
import Success from "../../common/modal/Success";

const AddRefundTransaction = () => {
  useHeader("Add Transaction Refund");
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const [Tab, setTab] = useState(null);
  const { user } = useUser();

  const { isLoading, mutate } = useMutation(
    (values) => addTransactionRefundData(values),
    {
      onSuccess: (data) => {
        setTab(2);
        setTimeout(function () {
          notify("Refund Created", "success");
          navigate("/refunds");
        }, 3000);
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );
  const formik = useFormik({
    initialValues: {
      transaction: data.id,
      total_amount: data.amount,
      status: "PENDING",
      note: "",
      business: data.business,
    },
    onSubmit: (values) => mutate(values),
    validationSchema: RefundSchema,
    validateOnChange: false,
  });

  return (
    <section className="addRefund grid md:grid-cols-2">
      <form>
        <div className="heading-lg" style={{ marginBottom: "2rem" }}>
          Fill Detail
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label>Transaction ID</Label>
            <Input
              placeholder="Invoice ID"
              name="public_id"
              value={data.transaction_id}
            />
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label>Email</Label>
            <Input placeholder="Email" name="email" value={data.email} />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label>Transaction Amount</Label>
            <Input
              placeholder="Invoice Amount"
              name="invoice_amount"
              value={data.amount}
              disabled
            />
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label>Refund Amount</Label>
            <Input
              placeholder="Amount"
              name="total_amount"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.total_amount}
              className={classNames({
                "is-invalid": formik.errors.total_amount,
              })}
              disabled
            />
            {formik.errors.total_amount && (
              <span className="form-error">{formik.errors.total_amount}</span>
            )}
          </div>
        </div>
        {user.user_role === "Admin" && (
          <div className="form-group">
            <Label>Status</Label>
            <select
              className="form-control"
              name="status"
              onChange={formik.handleChange}
              value={formik.values.status}
            >
              <option value="PENDING">Pending</option>
              <option value="APPROVED">Approved</option>
              <option value="REJECTED">Rejected</option>
            </select>
          </div>
        )}
        <div className="form-group">
          <Label>Note(Optional)</Label>
          <textarea
            placeholder="Leave a note for the customer here"
            name="note"
            onChange={formik.handleChange}
            value={formik.values.note}
            className={
              classNames({ "is-invalid": formik.errors.note }) + "form-control"
            }
          />
        </div>
        <Button
          className={classNames("md:mb-6 mb-4 disabled:opacity-50")}
          type="button"
          onClick={() => {
            formik
              .validateForm()
              .then((errors) => Object.keys(errors).length === 0 && setTab(1));
          }}
          disabled={isLoading}
        >
          Save
        </Button>
      </form>
      {Tab === 1 && (
        <Modal close={() => setTab(0)}>
          <div className="invoiceModal">
            <div className="modalIcon">{SVG.review}</div>
            <h3>Review Refund</h3>
            <form onSubmit={formik.handleSubmit}>
              <div className="modalInline">
                <h4>
                  <b>AGAINST TRANSACTION ID</b>{" "}
                  <span>{data.transaction_id}</span>
                </h4>
                <h4>
                  <b>REFUND AMOUNT</b>{" "}
                  <span>{amount(formik.values.total_amount)}</span>
                </h4>
              </div>
              <button className="btn-orange" type="submit">
                {isLoading ? <ButtonLoader text="Sending" /> : "Send"}
              </button>
              <button
                style={{ width: "100%" }}
                className="btn-brightgray mt-4"
                type="button"
                onClick={() => setTab(0)}
              >
                Edit Request
              </button>
            </form>
          </div>
        </Modal>
      )}
      {Tab === 2 && (
        <Modal close={() => navigate("/refunds")}>
          <Success title={"Request Sent!"} msg="Request sent successfully!" />
        </Modal>
      )}
    </section>
  );
};

export default AddRefundTransaction;
