import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getUser } from "../../common/api";
import { getToken } from "../../common/utils/helpers";

export const UserContext = React.createContext(null);

export const UserProvider = ({ children }) => {
  const [headerTitle, setHeaderTitle] = useState("");
  const [bussiness, setBussiness] = useState(null);
  const [user, setUser] = useState({
    pk: null,
    username: null,
    email: null,
    first_name: null,
    last_name: null,
    user_role: null,
    business_approved: null,
    permissions: {},
  });

  useQuery("getUser", getUser, {
    // refetchOnWindowFocus: false,
    // retry: false,
    enabled: getToken() !== undefined && user.pk === null,
    onSuccess: (user) => {
      setUser(user);
    },
  });

  const value = {
    bussiness,
    user: user,
    headerTitle,
    setHeaderTitle,
    setBussiness,
    setUser,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  return React.useContext(UserContext);
};

export const useHeader = (title) => {
  const { t } = useTranslation();
  const { headerTitle, setHeaderTitle } = React.useContext(UserContext);
  useEffect(() => {
    if (title) {
      setHeaderTitle(t(title));
    }
  }, [title, setHeaderTitle, t]);
  return { headerTitle };
};
