import React from "react";

import TabNav from "../../tab-nav/TabNav";
import { useTranslation } from "react-i18next";
import WithPermission from "../../../common/withRole/WithPermission";
import EventDetailTab from "./eventDetailTab";
import CampaignEdit from "./editCampaignForm";
import { useHeader } from "../../context/UserProvider";

const EventDetail = () => {
  const { t } = useTranslation();
  useHeader(" ");

  return (
    <TabNav
      heads={[
        {
          tabName: t("profile"),
          tabContent: <CampaignEdit />,
        },
        {
          tabName: "Event Detail",
          tabContent: <EventDetailTab />,
        },
      ]}
    />
  );
};

export default WithPermission("invoice")("view")(EventDetail);
