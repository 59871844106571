import React, { useState } from "react";
import { useHeader } from "../../context/UserProvider";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getInvoices } from "../../../common/api";

import { BeatLoader } from "react-spinners";
import FilterBar from "../../payouts/components/FilterBar";
import WithPermission from "../../../common/withRole/WithPermission";
import Table from "../../../common/table/Table";
import { CURRENCY_SYMBOL } from "../../../config";
import { amount as dAmount } from "../../../common/utils/helpers";

const EventDetailTab = ({ moduleName = "invoice" }) => {
  useHeader(" ");
  const { t } = useTranslation();

  //   HOOKS

  const [filters, setFilters] = useState({
    status: "",
    created_at__date__gte: null,
    created_at__date__lte: null,
    search: null,
    page: 1,
  });

  const {
    data,
    isLoading: areKeysLoading,
    // refetch,
  } = useQuery(
    [
      "getInvoices",
      `?${filters.search}${filters.page}${filters.status}${filters.created_at__date__gte}${filters.created_at__date__lte}`,
    ],
    () => getInvoices(filters),
    {
      retry: 1,
    }
  );

  return areKeysLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <>
      {/* <div className="widgets mb-6">
        <RoundedPillWidget img={numberIcon} label="INVOICES" value={data.stats?.total_invoices} />
        <RoundedPillWidget img={successIcon} label="SUCCESSFULL" value={data.stats?.successfull_invoices} />
        <RoundedPillWidget
          img={unSuccessIcon}
          label="UNSSUCCESSFULL"
          value={data.stats?.cancelled_invoices}
        />
        <RoundedPillWidget img={pendingIcon} label="PENDING" value={data.stats?.pending_invoices} />
      </div> */}
      <div className="flex flex-wrap justify-between my-8">
        <div className="flex searchBar md:w-1/2">
          <FilterBar
            moduleName={moduleName}
            filters={filters}
            onFilterChange={(filter) => setFilters({ ...filters, ...filter })}
            options={[
              { value: "", label: t("all") },
              { value: "INITIATE", label: t("INITIATE") },
              { value: "PENDING", label: t("PENDING") },
              { value: "PROCESSED", label: t("PROCESSED") },
              { value: "FAILED: ", label: t("FAILED") },
              { value: "CANCELED", label: t("CANCELED") },
              { value: "REFUND_PENDING", label: t("REFUND_PENDING") },
              { value: "REFUNDED", label: t("REFUNDED") },
              { value: "REFUND_CANCELLED", label: t("REFUND_CANCELLED") },
            ]}
          />
        </div>
      </div>
      <div className="boarding-card p-0 max-w-none">
        <Table
          moduleName={moduleName}
          showStatus={false}
          allowDelete={false}
          allowCancel={false}
          allowEdit={false}
          columns={[
            {
              lable: "Invoice No",
              accessor: "public_id",
              copyable: true,
            },
            {
              lable: "Requested",
              accessor: "created_at",
            },
            {
              lable: "Customer",
              accessor: "customer",
            },
            {
              lable: `Amount(${CURRENCY_SYMBOL})`,
              accessor: "amount",
            },
          ]}
          rows={(data?.results ?? []).map(
            ({ public_id, created_at, email, amount, id }) => ({
              id: id,
              public_id,
              created_at:
                new Date(created_at)
                  .toDateString()
                  .split(" ")
                  .slice(1)
                  .join(" ") +
                ", " +
                new Date(created_at).toLocaleTimeString(),
              customer: email,
              amount: dAmount(amount),
            })
          )}
          dataCount={data?.count}
          activePage={filters.page}
          onPageClicked={(page) => {
            setFilters({ ...filters, page: page });
          }}
        />
      </div>
    </>
  );
};

export default WithPermission("invoice")("view")(EventDetailTab);
