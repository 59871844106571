import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { useDarkMode, useWindowSize } from "usehooks-ts";
import { format, parseISO } from "date-fns";
import Loader from "../../../common/loader/Loader";
import { useTranslation } from "react-i18next";
import { BeatLoader } from "react-spinners";

Chart.register(...registerables);

const ChartComponent = ({ statsType, data, isLoading, isSuccess }) => {
  const { isDarkMode } = useDarkMode();

  const { t } = useTranslation();

  const { width } = useWindowSize();

  const gradientBackGround = () => {
    var chart = document.getElementById("chart")?.getContext("2d");
    if (chart) {
      var gradient = chart.createLinearGradient(0, 0, 0, 355);
      gradient.addColorStop(0, "rgba(127, 44, 234, 0.16)");
      gradient.addColorStop(0.6, "rgba(255, 255, 255, 0.25)");
      gradient.addColorStop(1, "rgba(255, 255, 255, 0)");
      return gradient;
    }
  };

  const chartDataSets = useMemo(() => {
    const formatChartData = (time) => {
      switch (statsType) {
        case "one_week":
          return format(parseISO(time), "dd MMM yyyy");
        case "one_day":
          return format(parseISO(time), "hh:mm a");
        case "one_month":
          return format(parseISO(time), "dd MMM yyyy");
        default:
          return format(parseISO(time), "dd MMM yyyy");
      }
    };

    return {
      labels:
        data &&
        !Array.isArray(data.response) &&
        data.response.stats.map((item) => formatChartData(item.time_point)),
      datasets: [
        {
          label: "data",
          fill: true,
          borderColor: "rgba(255,107,0,1)",
          lineTension: 0.3,
          borderWidth: 4,
          data:
            data &&
            !Array.isArray(data.response) &&
            data.response.stats.map((item) => item.value),
          pointRadius: width > 0 && width < 768 ? 10 : 8,
          pointBorderColor: "#FFFFFF",
          pointBorderWidth: 3,
          pointBackgroundColor: "#7F2CEA",
          backgroundColor: gradientBackGround(),
        },
      ],
    };
  }, [data, statsType, width]);

  const options = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        elements: {
          point: {
            radius: 15,
          },
        },
        tooltip: {
          displayColors: false,
          padding: {
            top: 10,
            bottom: 10,
            right: 14,
            left: 14,
          },
          callbacks: {
            label: (item) => {
              return `$ ${item.formattedValue}`;
            },
            title: () => {},
          },
        },
      },

      scales: {
        x: {
          ticks: {
            color: !isDarkMode ? "#92A0AE" : "#A6A6A6",
            font: {
              size: 12,
              family: "GothamMedium",
            },
          },

          grid: {
            drawBorder: false,
            lineWidth: 0,
          },
        },
        y: {
          grid: {
            borderDash: [15, 10],
            color: !isDarkMode ? "#f8f8f8" : "#191C1F",
            drawBorder: false,
          },

          ticks: {
            color: !isDarkMode ? "#92A0AE" : "#A6A6A6",
            font: {
              size: 12,
              family: "GothamMedium",
            },

            stepSize: 5,
            max: 15,
            min: 0,
            callback: (value) => {
              return `$${Intl.NumberFormat("en", {
                notation: "compact",
              }).format(value)}`;
            },
          },
        },
      },
    };
  }, [isDarkMode]);

  const shouldLoaderRender = () => {
    return (!data && !isLoading) || (data && Array.isArray(data.response));
  };

  return (
    <div className={"chart-wrapper"}>
      <div
        className={
          isSuccess && !Array.isArray(data.response)
            ? "w-full h-full"
            : "hidden"
        }
      >
        <Line data={chartDataSets} options={options} id="chart" />
      </div>
      {isLoading && <BeatLoader color="#7F2CEA" />}
      {shouldLoaderRender() && <Loader text={t("commonLabels.no_data")} />}
    </div>
  );
};

export default ChartComponent;
