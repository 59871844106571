import React, { useState, useEffect } from "react";
import Input from "../../common/form-controls/Input";
import Label from "../../common/form-controls/Label";
import Button from "../../common/form-controls/Button";
import { getEditKey, Editkey } from "../../common/api";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useQuery, useMutation } from "react-query";
import ButtonLoader from "../../common/form-controls/ButtonLoader";
import { KeySchema } from "./validation-schema";
import { notify } from "../../common/toaster/Toaster";
import { useHeader } from "../context/UserProvider";
import { useParams } from "react-router";
import BussinessSelect from "../customers/components/BussinessSelect";
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
const EditDetail = () => {
  const { t } = useTranslation();
  useHeader(t("aPIKeyDetail"));

  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading: areKeysLoading } = useQuery(
    ["getEditKey", id],
    getEditKey,
    {
      retry: 1,
    }
  );
  const { isLoading, mutate: EditKeyFn } = useMutation(
    (values) => Editkey(id, values),
    {
      onSuccess: () => {
        notify(t("keyUpdated"), "success");
        navigate("/api-keys", { replace: true });
      },
      onError: (error) => {
        Object.entries(error.response.data.error).map(([key, value]) =>
          formik.setFieldError(key, value.error_string)
        );
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      business: "",
      name: "",
      type: "",
      client_id: "",
      platform_url: "",
      redirect_uris: "",
      mode: "",
    },
    onSubmit: EditKeyFn,
    validationSchema: KeySchema,
    validateOnChange: false,
  });

  useEffect(() => {
    if (data?.name) {
      formik.setValues({
        business: data?.business ?? "",
        name: data.name,
        type: data.type,
        client_id: data.access_key,
        platform_url: data.platform_url,
        redirect_uris: data.redirect_uris,
        mode: data.mode,
      });
    }
    // eslint-disable-next-line
  }, [data]);

  return areKeysLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <section className="grid md:grid-cols-2">
      <form onSubmit={formik.handleSubmit}>
        <BussinessSelect
          className="flex flex-wrap mb-6"
          name="business"
          value={parseInt(formik.values.business)}
          onChange={(value) => formik.setFieldValue("business", value.value)}
          error={formik.errors.business}
        />

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label>{t("name")}</Label>
            <Input
              placeholder={t("name")}
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              className={classNames({ "is-invalid": formik.errors.name })}
            />
            {formik.errors.name && (
              <span className="form-error">{formik.errors.name}</span>
            )}
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label>{t("type")}</Label>
            <select
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
              className={classNames([
                "form-control",
                { "is-invalid": formik.errors.type },
              ])}
            >
              <option value="E_COMMERCE">{t("eCommerce")}</option>
              <option value="POS">{t("pos")}</option>
            </select>
            {formik.errors.type && (
              <span className="form-error">{formik.errors.type}</span>
            )}
          </div>
        </div>

        {formik.values.type === "E_COMMERCE" && (
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <Label>{t("platformURL")}</Label>
              <Input
                placeholder={t("platformURL")}
                name="platform_url"
                onChange={formik.handleChange}
                value={formik.values.platform_url}
                className={classNames({
                  "is-invalid": formik.errors.platform_url,
                })}
              />
              {formik.errors.platform_url && (
                <span className="form-error">{formik.errors.platform_url}</span>
              )}
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <Label>{t("redirectURL")}</Label>
              <Input
                placeholder={t("redirectURL")}
                name="redirect_uris"
                onChange={formik.handleChange}
                value={formik.values.redirect_uris}
                className={classNames({
                  "is-invalid": formik.errors.redirect_uris,
                })}
              />
              {formik.errors.redirect_uris && (
                <span className="form-error">
                  {formik.errors.redirect_uris}
                </span>
              )}
            </div>
          </div>
        )}
        <div className="flex flex-wrap mb-6">
          <Label>{t("clientID")} </Label>
          <Input
            disabled={true}
            allowCopy={true}
            placeholder={t("clientID")}
            name="client_id"
            onChange={formik.handleChange}
            value={formik.values.client_id}
          />
        </div>

        <div className="noticeBox">
          <h4>
            <b>{t("note")}</b>
          </h4>
          <p>{t("editApiKeyNote")}</p>
          <ul>
            <li>{t("apiKeyList1")}</li>
            <li>{t("apiKeyEditListItem")} </li>
            <li>{t("apiKeyList2")}</li>
            <li>{t("apiKeyList3")}</li>
          </ul>
        </div>
        <Button
          className={classNames("md:mb-6 mb-4 disabled:opacity-50")}
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <ButtonLoader text={t("saving")} /> : t("save")}
        </Button>
      </form>
    </section>
  );
};

const KeyLogs = () => {
  const { t } = useTranslation();
  return (
    <section className="grid md:grid-cols-2">
      <div className="heading-lg" style={{ marginBottom: "2rem" }}>
        {t("keyLogs")}
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className=""></div>
        </div>
      </div>
    </section>
  );
};

export default function KeyDetail() {
  const { t } = useTranslation();
  const [openTab, setOpenTab] = useState(1);
  return (
    <>
      <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
          <li className="mr-2" onClick={() => setOpenTab(1)}>
            <button
              className={`inline-block p-4 ${
                openTab === 1 && "border-b-2 border-purple-700 text-purple-700"
              }`}
            >
              {t("detail")}
            </button>
          </li>
          <li className="mr-2" onClick={() => setOpenTab(2)}>
            <button
              className={`inline-block p-4 ${
                openTab === 2 && "border-b-2 border-purple-700 text-purple-700"
              }`}
            >
              {t("keyLogs")}
            </button>
          </li>
        </ul>
      </div>
      {openTab === 1 && <EditDetail />}
      {openTab === 2 && <KeyLogs />}
    </>
  );
}
