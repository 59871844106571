import React from "react";
import Input from "../../common/form-controls/Input";
import Label from "../../common/form-controls/Label";
import Button from "../../common/form-controls/Button";
import { editprovider } from "../../common/api";
import { getProviderCountry } from "../../common/api";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { useQuery } from "react-query";
import { getEditProvider } from "../../common/api";
import { getServerError } from "../../common/utils/helpers";
import ButtonLoader from "../../common/form-controls/ButtonLoader";
import withRole from "../../common/withRole/WithRole";
import Switch from "../../common/switch/Switch";
import { ProviderSchema } from "./validation-schema";
import { notify } from "../../common/toaster/Toaster";
import { useHeader } from "../context/UserProvider";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
// import { SVG } from "../../../src/common/icons/Icon";

const EditProvider = () => {
  useHeader("addprovider");
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { data } = useQuery(["getEditProvider", id], getEditProvider, {
    retry: 1,
  });
  const { data: countryData } = useQuery(
    "getProviderCountry",
    getProviderCountry,
    {
      retry: 1,
    }
  );
  const { isLoading, mutate: editProviderFn } = useMutation(
    (values) => editprovider(id, values),
    {
      onSuccess: () => {
        notify("Provider Updated!", "success");
        navigate("/providers", { replace: true });
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      active: data?.active,
    },
    enableReinitialize: true,
    onSubmit: editProviderFn,
    validationSchema: ProviderSchema,
    validateOnChange: false,
  });

  return (
    <section className="addUser grid md:grid-cols-2">
      <form onSubmit={formik.handleSubmit}>
        <div className="heading-lg" style={{ marginBottom: "2rem" }}>
          {t("editProvider")}
        </div>
        <div className="form-group">
          <Label>{t("name")} </Label>
          <Input
            placeholder={t("typeHere")}
            name="name"
            disabled
            onChange={formik.handleChange}
            value={data?.name}
            className={classNames({ "is-invalid": formik.errors.name })}
          />
          {formik.errors.name && (
            <span className="form-error">{formik.errors.name}</span>
          )}
        </div>
        <div className="flex flex-wrap -mx-3 justify-between">
          <div className="w-full md:w-1/2 px-3 mb-6 ">
            <Label>{t("type")} </Label>
            <select
              name="provider_type"
              disabled
              value={data?.provider_type}
              onChange={formik.handleChange}
              className={classNames([
                "form-control",
                { "is-invalid": formik.errors.provider_type },
              ])}
            >
              <option value="">Select</option>
              <option value="Wallet">Wallet</option>
              <option value="Bank">Bank</option>
            </select>
            {formik.errors.provider_type && (
              <span className="form-error">{formik.errors.provider_type}</span>
            )}
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 ">
            <Label>{t("country")}</Label>
            <select
              name="country"
              disabled
              value={data?.country}
              onChange={formik.handleChange}
              className={classNames([
                "form-control",
                { "is-invalid": formik.errors.country },
              ])}
            >
              <option value="">Select</option>
              {countryData?.response.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </select>
            {formik.errors.country && (
              <span className="form-error">{formik.errors.country}</span>
            )}
          </div>
        </div>
        <Label>{t("configs")} </Label>
        {Object.entries(data?.meta_config ?? []).map(([key, value], index) => (
          <div className="flex flex-wrap -mx-3 justify-between" key={index}>
            <div className="w-full md:w-1/2 px-3 mb-6 ">
              <Input
                placeholder="Key"
                className={classNames({ "is-invalid": formik.errors.metaKey })}
                value={key}
                disabled
              />
              {formik.errors.metaKey && (
                <span className="form-error">{formik.errors.metaKey}</span>
              )}
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 ">
              <Input
                placeholder="Value"
                value={value}
                disabled
                className={classNames({
                  "is-invalid": formik.errors.metaValue,
                })}
              />
              {formik.errors.metaValue && (
                <span className="form-error">{formik.errors.metaValue}</span>
              )}
            </div>
          </div>
        ))}
        <div className="form-group">
          <label className="form-label">{t("approve")}</label>
          <Switch
            className="table-action"
            name="active"
            onChange={formik.handleChange}
            isChecked={formik.values.active}
          />
        </div>
        <Button
          className={classNames("md:mb-6 mb-4 disabled:opacity-50")}
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <ButtonLoader text={t("saving")} /> : t("save")}
        </Button>
      </form>
    </section>
  );
};

export default withRole("Admin")(EditProvider);
