import React, { useState, useMemo } from "react";
import { useQuery } from "react-query";
import { getNotifications, markAsRead } from "../../common/api";
import { getToken } from "../../common/utils/helpers";

export const NotificationContext = React.createContext(null);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const stats = useMemo(() => {
    const unread = notifications.filter((n) => !n.is_read);
    return {
      unread,
    };
  }, [notifications]);

  const { refetch: refetchNotifications } = useQuery(
    "notifications",
    () => getNotifications(),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: getToken() !== undefined,
      onSuccess: (data) => setNotifications(data?.results),
    }
  );

  const markAllAsRead = () => {
    markAsRead();
    setNotifications((prev) =>
      prev.map((n) => ({
        ...n,
        is_read: true,
      }))
    );
  };

  const value = {
    notifications,
    stats,
    refetchNotifications,
    markAllAsRead,
  };
  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  return React.useContext(NotificationContext);
};
