import React, { useEffect } from "react";
import classnames from "classnames";
import { useDarkMode } from "usehooks-ts";

const ThemeSwitch = () => {
  const { isDarkMode, toggle } = useDarkMode();
  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove((isDarkMode && "light") || "dark");
    root.classList.add((isDarkMode && "dark") || "light");
  }, [isDarkMode]);

  return (
    <div className="switch">
      <input type="checkbox" id="switch_toggle" className="toggle-checkbox" />
      <label
        onClick={toggle}
        htmlFor="switch_toggle"
        className={classnames({ "switch-active": isDarkMode }, "toggle-label")}
      ></label>
    </div>
  );
};

export default ThemeSwitch;
