import React from "react";
import { getEditRefundById } from "../../common/api";
import { useHeader } from "../context/UserProvider";
import { BeatLoader } from "react-spinners";
import { useQuery } from "react-query";
import EditSimple from "./EditSimpleRefund";
import EditProfessional from "./EditProfessionalRefund";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

const EditRefund = () => {
  const { t } = useTranslation();
  useHeader(t("editRefund"));
  const { id } = useParams();
  const { isLoading: areDataLoading, data } = useQuery(
    ["getEditRefundById", id],
    getEditRefundById,
    {
      retry: 1,
    }
  );
  return (
    <>
      {areDataLoading ? (
        <BeatLoader
          color="#7F2CEA"
          className="text-center"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -100%)",
          }}
        />
      ) : (
        <>
          {data?.response.invoice.type === "SIMPLE" && (
            <EditSimple data={data?.response} />
          )}
          {data?.response.invoice.type === "PROFESSIONAL" && (
            <EditProfessional data={data?.response} />
          )}
        </>
      )}
    </>
  );
};

export default EditRefund;
