import React from "react";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import { Outlet } from "react-router-dom";
// import useFontFaceObserver from "use-font-face-observer";
// import { fontFamily } from "../../common/utils/helpers";

const Home = () => {
  // TODO: Add font loading
  // const webFontsLoaded = useFontFaceObserver(fontFamily);
  return (
    <main>
      <Sidebar />
      <div className="main-content">
        <Header />
        <div
          className="maxLg:mx-4 maxLg:mt-6 relative"
          style={{
            minHeight: "calc(100vh - 120px)",
          }}
        >
          <Outlet />
        </div>
      </div>
    </main>
  );
};

export default Home;
