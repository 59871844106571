import React, { useState } from "react";
import { getAllKeys, deleteKeyById } from "../../common/api";
import { useMutation, useQuery } from "react-query";
import Table from "../../common/table/Table";
// import numberIcon from "../../images/numbericon.svg";
// import successIcon from "../../images/successfultransactionsicon.svg";
// import unSuccessIcon from "../../images/unsuccessfultransactionsicon.svg";
// import pendingIcon from "../../images/pendingtransactionsicon.svg";
// import RoundedPillWidget from "../widgets/RoundedPillWidget";
import Modal from "../../common/modal/Modal";
import DeleteModal from "../../common/modal/DeleteModal";
import { notify } from "../../common/toaster/Toaster";
import { getServerError } from "../../common/utils/helpers";
import { useHeader } from "../context/UserProvider";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
// import { SVG } from "../../common/icons/Icon";
import FilterBar from "../payouts/components/FilterBar";
import { Link } from "react-router-dom";
import { hasPermission } from "../../common/withRole/WithPermission";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useUser } from "../context/UserProvider";

const ApiKeyTable = ({ moduleName = "apikeymanager" }) => {
  useHeader("apiKeys");
  const [deleteThis, setDeleteThis] = useState(null);
  const { user } = useUser();
  //   HOOKS
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    status: "",
    created_at__date__gte: null,
    created_at__date__lte: null,
    search: null,
    page: 1,
  });

  const {
    data,
    isLoading: areKeysLoading,
    refetch,
  } = useQuery(
    [
      "getAllKeys",
      `?${filters.search}${filters.page}${filters.status}${filters.created_at__date__gte}${filters.created_at__date__lte}`,
    ],
    () => getAllKeys(filters),
    {
      retry: 1,
    }
  );
  const { isLoading, mutate: deleteKey } = useMutation(
    () => deleteKeyById(deleteThis),
    {
      onSuccess: () => {
        refetch();
        setDeleteThis(null);
        notify(t("keyDeleted"), "success");
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );
  const columns = [
    {
      lable: "Name",
      accessor: "name",
    },
    {
      lable: "Type",
      accessor: "type",
    },
    {
      lable: "Usage Count",
      accessor: "usage_count",
    },
    {
      lable: "Last Usage At",
      accessor: "updated_at",
    },
    {
      lable: "Bussiness Name",
      accessor: "business_name",
    },
  ];

  return areKeysLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <>
      {/* <div className="widgets mb-6">
        <RoundedPillWidget img={numberIcon} label="PAYOUTS" value="03" />
        <RoundedPillWidget img={successIcon} label="SUCCESSFUL" value="01" />
        <RoundedPillWidget
          img={unSuccessIcon}
          label="UNSSUCCESSFUL"
          value="0"
        />
        <RoundedPillWidget img={pendingIcon} label="PENDING" value="01" />
      </div> */}
      <div className="flex flex-wrap justify-between my-8">
        <div className="flex searchBar md:w-1/2">
          <FilterBar
            moduleName={moduleName}
            filters={filters}
            onFilterChange={(filter) => setFilters({ ...filters, ...filter })}
          />
        </div>
        <div className="md:w-1/3.5">
          <div className="barOptions">
            {/* <button className="rounded-btn p-2">{SVG.fileDownload}</button> */}
            {hasPermission(moduleName)("add") && (
              <Link to="/api-keys/add">
                <button data-cy="add-btn" className="btn-orange" type="button">
                  {t("generateKey")}
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="boarding-card p-0 max-w-none">
        <Table
          moduleName={moduleName}
          showStatus={false}
          columns={
            user.user_role === "Admin"
              ? columns
              : columns.filter((column) => column.accessor !== "business_name")
          }
          rows={(data?.results ?? []).map(
            ({
              name,
              type,
              business_name,
              access_key,
              platform_url,
              id,
              usage_count,
              updated_at,
            }) => ({
              id: id,
              access_key: access_key,
              name,
              type: _.capitalize(type.replace("_", "-")).toUpperCase(),
              business_name,
              platform_url,
              usage_count,
              updated_at:
                new Date(updated_at)
                  .toDateString()
                  .split(" ")
                  .slice(1)
                  .join(" ") +
                ", " +
                new Date(updated_at).toLocaleTimeString(),
            })
          )}
          onEditClicked={({ id }) => {
            navigate(`/api-keys/edit/${id}`);
          }}
          onRowClicked={({ id }) => {
            navigate(`/api-keys/edit/${id}`);
          }}
          onDeleteClicked={({ id }) => {
            setDeleteThis(id);
          }}
          dataCount={data?.response?.count}
          activePage={filters.page}
          onPageClicked={(page) => {
            setFilters({ ...filters, page: page });
          }}
        />
      </div>
      {deleteThis && (
        <Modal close={() => setDeleteThis(null)}>
          <DeleteModal
            title={"Key"}
            isLoading={isLoading}
            onClick={deleteKey}
          />
        </Modal>
      )}
    </>
  );
};

export default ApiKeyTable;
