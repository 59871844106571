import React, { useState, useEffect, useMemo } from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import {
  createMemberMerchant,
  deleteMemberMerchant,
  editMemberMerchant,
  getAllCountries,
  getCitiesByCountryCode,
  getCountries,
} from "../../../../common/api";
import { useMutation, useQuery } from "react-query";
import Button from "../../../../common/form-controls/Button";
import Table from "../../../../common/table/Table";
import Label from "../../../../common/form-controls/Label";
import Input from "../../../../common/form-controls/Input";
import Selection from "../../../../common/form-controls/Selection";
import { MemberSchema } from "./validation-schema";
import { notify } from "../../../../common/toaster/Toaster";
import { getServerError } from "../../../../common/utils/helpers";
import ButtonLoader from "../../../../common/form-controls/ButtonLoader";
import Switch from "../../../../common/switch/Switch";
import Modal from "../../../../common/modal/Modal";
import DeleteModal from "../../../../common/modal/DeleteModal";
import CitySelect from "../../../signup/components/CitySelect";
import CountrySelect from "../../../signup/components/CountrySelect";
import PhoneNumber from "../../../signup/components/PhoneNumber";
import GroupSelect from "../../../members/components/GroupSelect";
import { useTranslation } from "react-i18next";

const MembersTab = ({ data: { response }, refetch }) => {
  const { t } = useTranslation();
  const [deleteThis, setDeleteThis] = useState(null);
  const [memberBeingEdited, setMemberBeingEdited] = useState({
    member: null,
    editMode: false,
  });

  const [createFormOpen, setCreateFormOpen] = useState(false);

  const { isLoading, mutate: deleteMemberFn } = useMutation(
    () => deleteMemberMerchant(deleteThis),
    {
      onSuccess: () => {
        response.members = response.members.filter(
          (employee) => employee.id !== deleteThis
        );
        setDeleteThis(null);
        notify(t("MemberDeleted"), "success");
        // navigate("/providers");
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );

  if (createFormOpen) {
    return (
      <CreateMembersForm
        organization={{
          name: response.profile.business_name,
          id: response.profile.id,
        }}
        onSuccess={(employee) => {
          refetch();
          setCreateFormOpen(false);
        }}
      />
    );
  }

  return memberBeingEdited.member ? (
    <MembersForm
      initialData={memberBeingEdited.member}
      editMode={memberBeingEdited.editMode}
      organization={{
        name: response.profile.business_name,
        id: response.profile.id,
      }}
    />
  ) : (
    <>
      <div className="section-head">
        <h1>
          {t("members")} ( {response.profile.business_name} )
        </h1>
        <Button className="add-btn" onClick={() => setCreateFormOpen(true)}>
          {t("addMember")}
        </Button>
      </div>
      <div className="boarding-card p-0 max-w-none">
        <Table
          columns={[
            {
              lable: "Name",
              accessor: "name",
            },
            {
              lable: "Email",
              accessor: "email",
            },
            {
              lable: "Role",
              accessor: "role",
            },
          ]}
          rows={(response?.members ?? []).map(
            ({ email, role, is_active, id, first_name, last_name }) => ({
              id: id,
              name: `${first_name} ${last_name}`,
              email: email,
              role,
              status: is_active ? "Active" : "Inactive",
              isActive: is_active,
            })
          )}
          onRowClicked={({ id: memberId }) => {
            setMemberBeingEdited({
              member: response.members.find(({ id }) => id === memberId),
              editMode: false,
            });
          }}
          onEditClicked={({ id: memberId }) => {
            setMemberBeingEdited({
              member: response.members.find(({ id }) => id === memberId),
              editMode: true,
            });
          }}
          onDeleteClicked={({ id }) => {
            setDeleteThis(id);
          }}
        />
      </div>
      {deleteThis && (
        <Modal close={() => setDeleteThis(null)}>
          <DeleteModal
            title="Member"
            isLoading={isLoading}
            onClick={deleteMemberFn}
          />
        </Modal>
      )}
    </>
  );
};

export default MembersTab;

export const MembersForm = ({
  initialData,
  organization,
  editMode = false,
}) => {
  const { t } = useTranslation();
  const [isEditable, setIsEditable] = useState(editMode);
  const [countryCode, setCountryCode] = useState("");

  const { isLoading, mutate: editMemberFn } = useMutation(
    (values) => editMemberMerchant(initialData.id, values),
    {
      onSuccess: () => {
        setIsEditable(false);
        notify(t("memberUpdate"), "success");
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );

  const initialFormatedData = useMemo(() => {
    return initialData
      ? {
          first_name: initialData.first_name,
          last_name: initialData.last_name,
          email: initialData.email,
          phone: initialData.phone ?? "",
          business: initialData.business,
          role: initialData.role,
          groups: initialData.groups,
          country: initialData.country,
          city: initialData.city,
          is_active: initialData.is_active,
        }
      : null;
  }, [initialData]);

  const formik = useFormik({
    initialValues: initialFormatedData ?? null,
    validationSchema: MemberSchema,
    validateOnChange: false,
    onSubmit: editMemberFn,
  });

  useQuery(
    ["country", formik.values.country],
    () => getCountries("", formik.values.country.toString()),
    {
      onSuccess: (data) => {
        setCountryCode(data?.response[0].phone);
      },
    }
  );

  return (
    <div className="tab-content">
      <div className="section-head">
        <h1>{t("updateMembers")}</h1>
        <button className="edit-btn" onClick={() => setIsEditable(!isEditable)}>
          {isEditable ? t("cancel") : t("edit")}
        </button>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-wrap -mx-3 justify-between">
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("firstName")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.first_name,
                },
              ])}
            >
              <Input
                disabled={!isEditable}
                placeholder={t("firstName")}
                name="first_name"
                onChange={formik.handleChange}
                value={formik.values.first_name}
              />
            </div>
            {formik.errors.first_name && (
              <span className="form-error">{formik.errors.first_name}</span>
            )}
          </div>
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("lastName")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.last_name,
                },
              ])}
            >
              <Input
                disabled={!isEditable}
                placeholder={t("lastName")}
                name="last_name"
                onChange={formik.handleChange}
                value={formik.values.last_name}
              />
            </div>
            {formik.errors.last_name && (
              <span className="form-error">{formik.errors.last_name}</span>
            )}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 justify-between">
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("country")}</Label>
            <CountrySelect
              className={`form-control country-select required ${
                formik.errors.country ? "is-invalid" : ""
              }`}
              onChange={(countryInfo) => {
                formik.setFieldValue("country", countryInfo.value);
                formik.setFieldValue("city", "");
              }}
              value={formik.values.country}
            />
            {formik.errors.country && (
              <span className="form-error">{formik.errors.country}</span>
            )}
          </div>
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("city")}</Label>
            <CitySelect
              className={`form-control city-select required ${
                formik.errors.city ? "is-invalid" : ""
              }`}
              countryId={formik.values.country}
              onChange={(value) => formik.setFieldValue("city", value)}
              value={formik.values.city}
            />
            {formik.errors.city && (
              <span className="form-error">{formik.errors.city}</span>
            )}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 justify-between">
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("email")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.email,
                },
              ])}
            >
              <Input
                disabled={!isEditable}
                placeholder={t("email")}
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </div>
            {formik.errors.email && (
              <span className="form-error">{formik.errors.email}</span>
            )}
          </div>
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("phone")}</Label>
            <PhoneNumber
              required
              placeholder={t("phone")}
              countryCode={countryCode}
              onChange={(value) => formik.setFieldValue("phone", value)}
              value={formik.values.phone}
              error={formik.errors.phone}
            />
            {formik.errors.phone && (
              <span className="form-error">{formik.errors.phone}</span>
            )}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 justify-between">
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("organizations")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.business_name,
                },
              ])}
            >
              <Input
                disabled
                placeholder="Bussiness Name"
                value={organization.name}
              />
            </div>
          </div>
          {formik.values.groups && formik.values.groups.length > 0 && (
            <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
              <Label>{t("groups")}</Label>
              <GroupSelect
                className={classNames([
                  "form-control",
                  "select",
                  {
                    "is-invalid": formik.errors.groups,
                  },
                ])}
                value={formik.values.groups}
                onChange={(values) => {
                  formik.setFieldValue(
                    "groups",
                    values.map((group) => group.value)
                  );
                }}
              />
              {formik.errors.groups && (
                <span className="form-error">{formik.errors.groups}</span>
              )}
            </div>
          )}
        </div>
        <div className="form-group">
          <Label>{t("status")}</Label>
          <Switch
            name="is_active"
            disabled={!isEditable}
            isChecked={formik.values.is_active}
            onChange={formik.handleChange}
          />
        </div>
        {isEditable && (
          <Button type="submit" disabled={isLoading}>
            {isLoading ? <ButtonLoader text={t("saving")} /> : t("save")}
          </Button>
        )}
      </form>
    </div>
  );
};

export const CreateMembersForm = ({ organization, onSuccess }) => {
  const { t } = useTranslation();
  const { isLoading, mutate: createMemberFn } = useMutation(
    (values) => createMemberMerchant(values),
    {
      onSuccess: (data) => {
        onSuccess(data);
        notify(t("memberCreate"), "success");
      },
      onError: (error) => {
        Object.entries(error.response.data.error).map(([key, value])=>(
          formik.errors[key] = value.error_string
        ));
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      groups: [],
      business: organization.id,
      role: null,
      country: null,
      city: null,
      is_active: false,
    },
    validationSchema: MemberSchema,
    validateOnChange: false,
    onSubmit: createMemberFn,
  });

  const { data } = useQuery("getAllCountries", getAllCountries, {
    retry: 1,
  });

  // eslint-disable-next-line
  const [countryCode, setCountryCode] = useState("");

  const countryOptions = useMemo(() => {
    if (data?.data.response) {
      return data.data.response.map(({ id, name, phone }) => ({
        value: id,
        label: name,
        countryCode: phone,
      }));
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (formik.values.country) {
      getCitiesByCountryCode(formik.values.country).then(({ response }) => {
        setCountryCode(
          countryOptions.find(
            // eslint-disable-next-line
            (country) => country.value == formik.values.country
          ).countryCode
        );
      });
    }
    // eslint-disable-next-line
  }, [formik.values.country]);

  return (
    <div className="tab-content">
      <div className="section-head">
        <h1>{t("addMember")}</h1>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-wrap -mx-3 justify-between">
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("firstName")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.first_name,
                },
              ])}
            >
              <Input
                required
                placeholder={t("firstName")}
                name="first_name"
                onChange={formik.handleChange}
                value={formik.values.first_name}
              />
            </div>
            {formik.errors.first_name && (
              <span className="form-error">{formik.errors.first_name}</span>
            )}
          </div>
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("lastName")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.last_name,
                },
              ])}
            >
              <Input
                required
                placeholder={t("lastName")}
                name="last_name"
                onChange={formik.handleChange}
                value={formik.values.last_name}
              />
            </div>
            {formik.errors.last_name && (
              <span className="form-error">{formik.errors.last_name}</span>
            )}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 justify-between">
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("country")}</Label>
            <div
              className={classNames([
                "form-control country-select",
                {
                  "is-invalid": formik.errors.country,
                },
              ])}
            >
              <Selection
                required
                placeholder={t("country")}
                options={countryOptions}
                value={formik.values.country}
                onChange={(value) =>
                  formik.setFieldValue("country", value.value)
                }
              />
            </div>
            {formik.errors.country && (
              <span className="form-error">{formik.errors.country}</span>
            )}
          </div>
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("city")}</Label>

            <CitySelect
              className={`form-control city-select required ${
                formik.errors.city ? "is-invalid" : ""
              }`}
              countryId={formik.values.country}
              onChange={(value) => formik.setFieldValue("city", value)}
              value={formik.values.city}
            />
            {formik.errors.city && (
              <span className="form-error">{formik.errors.city}</span>
            )}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 justify-between">
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("email")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.email,
                },
              ])}
            >
              <Input
                required
                placeholder={t("email")}
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </div>
            {formik.errors.email && (
              <span className="form-error">{formik.errors.email}</span>
            )}
          </div>
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("phone")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.phone,
                },
              ])}
            >
              <PhoneNumber
                placeholder={t("phone")}
                countryCode={countryCode}
                onChange={(value) => formik.setFieldValue("phone", value)}
                value={formik.values.phone}
                error={formik.errors.phone}
              />
            </div>
            {formik.errors.phone && (
              <span className="form-error">{formik.errors.phone}</span>
            )}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 justify-between">
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("organizations")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.business_name,
                },
              ])}
            >
              <Input
                disabled
                placeholder={t("organizations")}
                value={organization.name}
              />
            </div>
          </div>
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("role")}</Label>
            <div
              className={classNames([
                "form-control select",
                {
                  "is-invalid": formik.errors.groups,
                },
              ])}
            >
              <GroupSelect
                value={formik.values.groups}
                onChange={(values) => {
                  formik.setFieldValue(
                    "groups",
                    values.map((group) => group.value)
                  );
                }}
                placeholder="SelectGroups"
              />
            </div>
            {formik.errors.role && (
              <span className="form-error">{formik.errors.role}</span>
            )}
          </div>
        </div>
        <div className="form-group">
          <Label>{t("status")}</Label>
          <Switch
            name="is_active"
            isChecked={formik.values.is_active}
            onChange={formik.handleChange}
          />
        </div>

        <Button type="submit" disabled={isLoading}>
          {isLoading ? <ButtonLoader text={t("saving")} /> : t("save")}
        </Button>
      </form>
    </div>
  );
};
