import { createContext, useState } from "react";
import { ADD_WALLET } from "../constant/constant";

export const SyncWalletContext = createContext({});

export const SyncWalletProvider = ({ children }) => {
  const [screen, setScreen] = useState(ADD_WALLET);

  return (
    <SyncWalletContext.Provider value={{ screen, setScreen }}>
      {" "}
      {children}
    </SyncWalletContext.Provider>
  );
};
