import Input from "../../common/form-controls/Input";
import Label from "../../common/form-controls/Label";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { ProInvoiceEmaiSchema } from "../../components/invoices/validation-schema";
import { SVG } from "../icons/Icon";
import { useTranslation } from "react-i18next";
const ProInvoiceCusModal = ({ title, close }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      pro_invoice_email: "",
    },
    onSubmit(values) {
      navigate(`/professional-invoice?${values.pro_invoice_email}`);
    },
    validationSchema: ProInvoiceEmaiSchema,
    validateOnChange: false,
  });
  return (
    <div className="invoiceModal">
      <div className="modalIcon">{SVG.message}</div>
      <h3>{title}</h3>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <Label>{t("email")}</Label>
          <Input
            name="pro_invoice_email"
            placeholder={t("email")}
            onChange={formik.handleChange}
            value={formik.values.pro_invoice_email}
          />
          {formik.errors.pro_invoice_email && (
            <span className="form-error">
              {formik.errors.pro_invoice_email}
            </span>
          )}
        </div>
        <button className="btn-orange" type="submit">
          {t("continue")}
        </button>
        <button
          style={{ width: "100%" }}
          className="btn-brightgray mt-4"
          type="button"
          onClick={close}
        >
          {t("close")}
        </button>
      </form>
    </div>
  );
};

export default ProInvoiceCusModal;
