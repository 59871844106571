import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { createPayout } from "../../../common/api";
import Input from "../../../common/form-controls/Input";
import Label from "../../../common/form-controls/Label";
import Selection from "../../../common/form-controls/Selection";
import { SVG } from "../../../common/icons/Icon";
import { notify } from "../../../common/toaster/Toaster";
import { hasPermission } from "../../../common/withRole/WithPermission";
import BeneficiarySelect from "../../customers/components/BeneficiarySelect";
import BussinessSelect from "../../customers/components/BussinessSelect";
import EmployeeSelect from "../../customers/components/EmployeeSelect";
import { PayoutSchema } from "./schema/validation-schema";

const PayoutModel = ({ title, close, onClose, onSuccess }) => {
  const { t } = useTranslation();
  const { mutate } = useMutation((values) => createPayout(values), {
    onSuccess: () => {
      notify(t("PayoutRequested"), "success");
      onSuccess();
      onClose();
    },
    onError: (error) => {
      Object.entries(error.response.data.error).map(([key, value]) =>
        notify(value.error_string, "error")
      );
      console.log(error, "pay out error");
    },
  });

  const formik = useFormik({
    initialValues: {
      request_amount: "",
    },
    validationSchema: PayoutSchema,
    validateOnChange: false,
    onSubmit: mutate,
  });

  return (
    <div className="invoiceModal">
      <div className="modalIcon">{SVG.payout}</div>
      <h3>{title}</h3>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          {hasPermission("payout")("approve") && (
            <BussinessSelect
              className="flex flex-wrap mb-4"
              name="business"
              value={parseInt(formik.values.business)}
              onChange={(value) =>
                formik.setFieldValue("business", value.value)
              }
              error={formik.errors.business}
            />
          )}
          <Label>{t("payingTo")}</Label>
          <Selection
            required
            className="form-control mb-4"
            name="type"
            options={[
              { value: "MERCHANT", label: t("merchantAdmin") },
              { value: "EMPLOYEE", label: t("employee") },
              { value: "BENEFICIARY", label: t("beneficiary") },
            ]}
            value={formik.values.type}
            onChange={(value) => formik.setFieldValue("type", value.value)}
          />

          {formik.values.type === "BENEFICIARY" && (
            <BeneficiarySelect
              className="flex flex-wrap mb-4"
              name="beneficiary"
              filters={{ business: formik.values.business }}
              value={parseInt(formik.values.beneficiary)}
              onChange={(value) =>
                formik.setFieldValue("beneficiary", value.value)
              }
              error={formik.errors.beneficiary}
            />
          )}

          {formik.values.type === "EMPLOYEE" && (
            <EmployeeSelect
              className="flex flex-wrap mb-4"
              name="employee"
              filters={{ business: formik.values.business }}
              value={parseInt(formik.values.employee)}
              onChange={(value) =>
                formik.setFieldValue("employee", value.value)
              }
              error={formik.errors.employee}
            />
          )}

          <Label>{t("payoutAmount")}</Label>
          <Input
            required
            name="request_amount"
            placeholder={t("enterAmount")}
            onChange={formik.handleChange}
            value={formik.values.request_amount}
          />
          {formik.errors.request_amount && (
            <span className="form-error">{formik.errors.request_amount}</span>
          )}
        </div>
        <button className="btn-orange" type="submit">
          {t("requestPayout")}
        </button>
        <button
          style={{ width: "100%" }}
          className="btn-brightgray mt-4"
          type="button"
          onClick={close}
        >
          {t("cancel")}
        </button>
      </form>
    </div>
  );
};

export default PayoutModel;
