import classNames from "classnames";
import React from "react";
import Input from "../../../../common/form-controls/Input";
import Label from "../../../../common/form-controls/Label";
import { useTranslation } from "react-i18next";
const AccountForm = ({ formik }) => {
  const { t } = useTranslation();

  return (
    <>
      <h5 className="form-label">{t("accountDetail")} </h5>
      <p>{t("accountParagraph")} </p>
      <br />

      <div className="flex flex-wrap -mx-3 justify-between">
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("accountName")} </Label>
          <div
            className={classNames([
              {
                "is-invalid": formik.errors.account_name,
              },
            ])}
          >
            <Input
              required
              placeholder={t("accountName")}
              name="account_name"
              onChange={formik.handleChange}
              value={formik.values.account_name}
            />
          </div>
          {formik.errors.account_name && (
            <span className="form-error">{formik.errors.account_name}</span>
          )}
        </div>
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("accountIBAN")} </Label>
          <div
            className={classNames([
              {
                "is-invalid": formik.errors.account_ibn,
              },
            ])}
          >
            <Input
              required
              placeholder={t("accountIBANPlaceholde")}
              name="account_ibn"
              maxLength={30}
              onChange={formik.handleChange}
              value={formik.values.account_ibn}
            />
          </div>
          {formik.errors.account_ibn && (
            <span className="form-error">Invalid IBAN</span>
          )}
        </div>
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("bankName")} </Label>
          <select
            name="bank_name"
            onChange={formik.handleChange}
            value={formik.values.bank_name}
            className="form-control"
          >
            <option value="">{t("select")}</option>
            {/* <option value="BNP Paribas">BNP Paribas</option>
            <option value="Société Générale">Société Générale</option>
            <option value="Groupe BPCE">Groupe BPCE</option>
            <option value="AXA Banque">AXA Banque</option>
            <option value="Crédit Mutuel Group">Crédit Mutuel Group</option>
            <option value="La Banque Postale">La Banque Postale</option>
            <option value="HSBC France">HSBC France</option>
            <option value="Credit du Nord">Credit du Nord</option>
            <option value="Credit Cooperatif">Credit Cooperatif</option> */}
            <option value="BANK OF AFRICA - BURKINA FASO">
              BANK OF AFRICA BURKINA FASO
            </option>
            <option value="BANQUE AGRICOLE DU FASO">
              BANQUE AGRICOLE DU FASO
            </option>
            <option value="BANQUE ATLANTIQUE BURKINA FASO">
              BANQUE ATLANTIQUE BURKINA FASO
            </option>
            <option value="BANQUE COMMERCIALE DU BAURKINA">
              BANQUE COMMERCIALE DU BAURKINA
            </option>
            <option value="BANQUE DE L'UNION - BURKINA FASO">
              BANQUE DE L'UNION - BURKINA FASO
            </option>
            <option value="BANQUE POSTALE DU BURKINA FASO">
              BANQUE POSTALE DU BURKINA FASO
            </option>
            <option value="BANQUE SAHELO-SAHARIENNE POUR L'INVESTISSEMENT ET LE COMMERCE - BURKINA">
              BANQUE SAHELO-SAHARIENNE POUR L'INVESTISSEMENT ET LE COMMERCE -
              BURKINA
            </option>
            <option value="CORIS BANK INTERNATIONAL">
              CORIS BANK INTERNATIONAL
            </option>
            <option value="ECOBANK - BURKINA">ECOBANK - BURKINA</option>
            <option value="INTERNATIONAL BUSINESS BANK BURKINA">
              INTERNATIONAL BUSINESS BANK BURKINA
            </option>
            <option value="SOCIETE GENERALE - BURKINA FASO">
              SOCIETE GENERALE - BURKINA FASO
            </option>
            <option value="UNITED BANK FOR AFRICA BURKINA">
              UNITED BANK FOR AFRICA BURKINA
            </option>
            <option value="VISTA BANK BURKINA">VISTA BANK BURKINA</option>
            <option value="WENDKUNI BANK INTERNATIONAL">
              WENDKUNI BANK INTERNATIONAL
            </option>
            <option value="CBAO, GROUPE ATTIJARIWAFA BANK, SUCCURSALE DU BURKINA">
              CBAO, GROUPE ATTIJARIWAFA BANK, SUCCURSALE DU BURKINA
            </option>
            <option value="ORABANK - COTE D'IVOIRE, SUCCURSALE DU BURKINA">
              ORABANK - COTE D'IVOIRE, SUCCURSALE DU BURKINA
            </option>
          </select>
          {formik.errors.bank_name && (
            <span className="form-error">{formik.errors.bank_name}</span>
          )}
        </div>

        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("branchCode")} </Label>
          <div
            className={classNames([
              {
                "is-invalid": formik.errors.branch_code,
              },
            ])}
          >
            <Input
              required
              className="no-arrows"
              placeholder={t("accountIBANPlaceholde")}
              name="branch_code"
              onChange={(e) =>
                formik.setFieldValue(
                  "branch_code",
                  e.target.value.substring(0, 4)
                )
              }
              value={formik.values.branch_code}
              type="number"
            />
          </div>
          {formik.errors.branch_code && (
            <span className="form-error">{formik.errors.branch_code}</span>
          )}
        </div>
      </div>
    </>
  );
};

export default AccountForm;
