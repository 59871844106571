import React from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { createCustomerMerchant } from "../../common/api";
import { notify } from "../../common/toaster/Toaster";
import CustomerForm from "./components/CustomerForm";
import { useTranslation } from "react-i18next";
const CustomerAdd = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate, isLoading, error } = useMutation((values) => createCustomerMerchant(values), {
    onSuccess: () => {
      navigate("/customers");
      notify(t("customerCreated"), "success");
    }
  });
  return (
    <div className="tab-content">
      <div className="section-head">
        <h1>{t("createCustomer")}</h1>
      </div>
      <CustomerForm onSubmit={mutate} isLoading= {isLoading} error = {error}  />
    </div>
  );
};

export default CustomerAdd;
