import {
  useEffect,
  useRef,
  cloneElement,
  isValidElement,
  useState,
} from "react";
import { useDarkMode } from "usehooks-ts";
import { useOnClickOutside } from "usehooks-ts";
import { CrossIcon } from "../icons/Icon";
import ReactDOM from "react-dom";
import Button from "../form-controls/Button";
import { useTranslation } from "react-i18next";
import ButtonLoader from "../form-controls/ButtonLoader";

const modalRoot = document.getElementById("modal-root");

const HeaderFooterModal = ({ children, close, heading }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { isDarkMode } = useDarkMode();
  const modalRef = useRef();

  useOnClickOutside(modalRef, close);

  useEffect(() => {
    const bodyElement = document.querySelector("body");
    bodyElement.classList.add("overlay", "overflow-hidden");
    return () => bodyElement.classList.remove("overlay", "overflow-hidden");
  }, []);

  return (
    <ModalPortal>
      <div className="modal modal-md" ref={modalRef}>
        <div className="modal-header border-b border-columbiablue">
          {heading && (
            <h3 className="text-xl font-gotham-bold">{t("changePasswordModal.heading")}</h3>
          )}
          <button className="ml-auto mr-4" onClick={close}>
            <CrossIcon svgColor={isDarkMode ? "#ffffff" : "#000000"} />
          </button>
        </div>
        <div className="modal-body pt-8">
          {isValidElement(children)
            ? cloneElement(children, { close, setIsLoading })
            : children}
        </div>
        <div className="modal-footer border-t border-columbiablue">
          <Button className="btn-brightgray" type="button" onClick={close}>
            {t("commonLabels.cancelLabel")}
          </Button>
          <Button type="submit" form="data-form">
            {isLoading ? (
              <ButtonLoader text={t("changePasswordModal.changing")} />
            ) : (
              t("changePasswordModal.change")
            )}

            {/* {t("changePasswordModal.change")} */}
          </Button>
        </div>
      </div>
    </ModalPortal>
  );
};

const ModalPortal = ({ children }) => {
  const div = useRef(document.createElement("div"));

  useEffect(() => {
    const element = div.current;
    modalRoot.appendChild(element);

    return () => modalRoot.removeChild(element);
  }, []);

  return ReactDOM.createPortal(children, div.current);
};

export default HeaderFooterModal;
