import Select, { components } from "react-select";
import { useDarkMode } from "usehooks-ts";
import search from "../../../../images/search.svg";

const getOptionLabel = (e) => {
  return (
    <div className="flex items-center">
      {e.icon && <img src={e.icon} style={{ width: 24 }} alt={""} />}
      <p className="ml-3">{e.label}</p>
    </div>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={search} style={{ width: 22 }} alt="search" />
    </components.DropdownIndicator>
  );
};

const SelectWallet = ({ options, onChange, value,placeholder }) => {

  const { isDarkMode } = useDarkMode();

const style = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
    marginTop: "0.625rem",
    marginBottom: "0.625rem",
    cursor: "pointer",
    fontFamily: "GothamMedium",
    backgroundColor: "transparent !important",
    width: "95%",
    borderRadius: "0.375rem",
    border:isSelected ? "1px solid #C3CFFF" : isFocused  ? "1px solid #C3CFFF" : "1px solid transparent",
    padding: "8px 12px",
    color: isDarkMode ? "#EBEBEB" : "#000"
    };
  },
};

  return (
    <div className="select-wallet">
      <Select
        placeholder={placeholder}
        options={options}
        getOptionLabel={getOptionLabel}
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        onChange={onChange}
        value={value}
        styles={style}
      />
    </div>
  );
};

export default SelectWallet;
