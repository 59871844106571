import React from "react";
import { BeatLoader } from "react-spinners";
import Button from "../../common/form-controls/Button";
import ButtonLoader from "../../common/form-controls/ButtonLoader";
import Loader from "../../common/loader/Loader";
import WarningMsg from "../../common/warning-msg/WarningMsg";
import Portfolio from "../dashboard/portfolio/Portfolio";
import YourAssets from "../dashboard/your-assets/YourAssets";
import RightSidebar from "../right-sidebar/RightSidebar";
import Accordion from "../wallet/wallet-list/accordion/Accordion";
import WalletActions from "../wallet/wallet-list/wallet-actions/WalletActions";
import SyncSignup from "../sync-signup/SyncSignup";
import WalletList from "../wallet/wallet-list/WalletList";

const ComponentList = () => {
  return (
    <>
      <div style={{ display: "flex" }}>
        <div className="dashboard">
          <h2>Buttons</h2>
          <div className="menu-right flex items-center">
            <div className="lg:mr-4">
              <Button onClick={() => console.log("hello")}>Button</Button>
            </div>
            {/* <LanguageDropdown /> */}
          </div>
          <Portfolio />
          <YourAssets />
          <h2>Loaders</h2>
          <div className="mt-5">
            <Loader text={"commonLabels.no_data"} />
          </div>
          <div className="mt-5 text-center">
            <BeatLoader color="#7F2CEA" />
          </div>
          <div className="mt-5">
            <ButtonLoader text={"Siginin..."} />
          </div>
          <h2>wallet list card</h2>
          <div className="wallet-list-card">
            <ul className="wallet-list">
              <Accordion
                walletItem={{
                  wallet: { name: "Wallet 1" },
                  updated_at: "2020-01-01",
                  total_balance: "20.5",
                  is_active: true,
                  wallet_details: [],
                }}
              >
                <WalletActions
                  id={123}
                  isActive={true}
                  deleteWalletAccordion={() => console.log("hello")}
                  reActiveWalletAcoordion={() => console.log("hello")}
                  deActiveWalletAcoordion={() => console.log("hello")}
                />
              </Accordion>
            </ul>
          </div>
          <h2>warning message</h2>
          <WarningMsg
            warningText="Interop Service is temporarily paused while we upgrade to Interop v2! Thank you for your patience!
    Stay updated for the upcoming Interop v2 launch on our Twitter"
          />
          <h3>pages</h3>
          <SyncSignup />
          <WalletList />
        </div>

        <RightSidebar />
      </div>
    </>
  );
};

export default ComponentList;
