import React from "react";
import AddWallet from "./AddWallet";

const AddWalletWrapper = () => {
  return (
    <section className="mb-5 add-wallet">
      <div className="flex justify-center items-center w-full px-3 flex-col ">

        <AddWallet />
      </div>
    </section>
  );
};
export default AddWalletWrapper;
