import React, { useState } from "react";
import { useQuery } from "react-query";
import { walletStatus } from "../../../common/api";
import { notify } from "../../../common/toaster/Toaster";
import { getServerError } from "../../../common/utils/helpers";

const WalletList = () => {
  const [walletList, setWalletList] = useState([]);

  // eslint-disable-next-line
  const { refetch, isLoading, isError, isFetching } = useQuery(
    "walletStatus",
    walletStatus,
    {
      onSuccess: (data) => {
        setWalletList(data.response);
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );

  // eslint-disable-next-line
  const deleteWalletAccordion = (id) => {
    setWalletList([...walletList.filter((item) => item.id !== id)]);
    refetch();
  };

  // eslint-disable-next-line
  const reActiveWalletAcoordion = (id) => {
    setWalletList([
      ...walletList.map((item) => {
        if (item.id === id) {
          item.is_active = true;
        }
        return item;
      }),
    ]);
    refetch();
  };

  // eslint-disable-next-line
  const deActiveWalletAcoordion = (id) => {
    setWalletList([
      ...walletList.map((item) => {
        if (item.id === id) {
          item.is_active = false;
        }
        return item;
      }),
    ]);
    refetch();
  };

  return <h1>Wallets</h1>;
};

export default WalletList;
