import React from "react";
import dots_icon from "../../images/3dots.svg";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { Menu, MenuButton } from "@szhsin/react-menu";

const DropdownMenu = ({ children }) => {
  return (
    <Menu
      direction="bottom"
      position="anchor"
      align="end"
      arrow={true}
      menuButton={
        <MenuButton className="dots__icon">
          <img src={dots_icon} alt="dots__icon" />
        </MenuButton>
      }
      transition
    >
      {children}
    </Menu>
  );
};

export default DropdownMenu;
