import React, { useEffect } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import LogIn from "./components/login/LogIn";
import SignUp from "./components/signup/SignUp";
import ForgotPassword from "./components/forgot-password/ForgotPassword";
import ResetPassword from "./components/reset-password/ResetPassword";
import Dashboard from "./components/dashboard/Dashboard";
import Home from "./components/home/Home";
import NotFound from "./components/not-found/NotFound";
import withAuthenticationRequired from "./common/utils/withAuthenticationRequired";
import withoutAuthenticationRequired from "./common/utils/withoutAuthenticationRequired";
import Transactions from "./components/transactions/Transactions";
import Settings from "./components/settings/Settings";
import SignupSucees from "./components/signup/SignupSuccess";
import { notify } from "./common/toaster/Toaster";
import ComponentList from "./components/component-list/ComponentList";
import Customers from "./components/customers/Customers";
import Refunds from "./components/refunds/Refunds";
import AddRefundTransaction from "./components/refunds/AddRefundTransaction";
import EditRefundTransaction from "./components/refunds/EditRefundTransaction";
import Payouts from "./components/payouts/Payouts";
import Subscribers from "./components/subscribers/Subscribers";
import PaymentPages from "./components/payment-pages/PaymentPages";
import Compliance from "./components/compliance/Compliance";
import UsersTable from "./components/users/UsersTable";
import AddUser from "./components/users/AddUser";
import EditUser from "./components/users/EditUser";
import OrganizationsTable from "./components/Organizations/OrganizationsTable";
import withRole from "./common/withRole/WithRole";
import ConfirmEmail from "./components/confirm-email/ConfirmEmail";
import OrganizationDetail from "./components/Organizations/OrganizationDetail";
import ProviderTable from "./components/providers/ProviderTable";
import AddProvider from "./components/providers/AddProvider";
import EditProvider from "./components/providers/EditProvider";
import ApiKeyTable from "./components/api_keys/apiTable";
import AddKey from "./components/api_keys/addKey";
import KeyDetail from "./components/api_keys/keyDetail";
import CustomerAdd from "./components/customers/CustomerAdd";
import CustomerEdit from "./components/customers/CustomerEdit";
import MemberAdd from "./components/members/MemberAdd";
import MemberEdit from "./components/members/MemberEdit";
import Members from "./components/members/Members";
import InvoiceTable from "./components/invoices/InvoiceTable";
import AddInvoice from "./components/invoices/AddInvoice";
import ProfessionalInvoice from "./components/invoices/ProfessionalInvoice";
import SimpleInvoice from "./components/invoices/SimpleInvoice";
import TransactionDetail from "./components/transactions/TransactionDetail";
import EditProfessionalInvoice from "./components/invoices/EditProfessionalInvoice";
import { UserProvider } from "./components/context/UserProvider";
import EditRefund from "./components/refunds/EditRefund";
import PayoutDetail from "./components/payouts/PayoutDetail";
import AddSimple from "./components/refunds/AddSimpleRefund";
import AddProfessional from "./components/refunds/AddProfessionalRefund";
import BeneficiariesTable from "./components/beneficiaries/BeneficiariesTable";
import { onMessageListener } from "./firebase";
import { useNotification } from "./components/context/NotificationProvider";
import Employees from "./components/employees/Employees";
import AddEmployee from "./components/employees/AddEmployee";
import EditEmployee from "./components/employees/EditEmployee";
import FundRaisingTable from "./components/fundraising/fundRaisingTable";
import AddCampaign from "./components/fundraising/addCampaign";
import CampaignEdit from "./components/fundraising/components/editCampaignForm";
import EventDetail from "./components/fundraising/components/eventDetail";

// import useFontFaceObserver from "use-font-face-observer";
// import { fontFamily } from "./common/utils/helpers";

const UnAutheticatedApp = () => {
  // TODO: Add font loading
  // const webFontsLoaded = useFontFaceObserver(fontFamily);
  return (
    <>
      {/* {webFontsLoaded && <Outlet />} */}
      {<Outlet />}
    </>
  );
};

const NotificationListener = () => {
  const { refetchNotifications } = useNotification();
  useEffect(() => {
    onMessageListener()
      .then(({ notification }) => {
        refetchNotifications();
        notify(notification.title, "info");
      })
      .catch((err) => console.log("failed: ", err));
    // eslint-disable-next-line
  }, []);

  return <div></div>;
};

const App = () => {
  // Role based routing

  const Organizations = withRole("Admin")(OrganizationsTable);
  const Users = withRole(["Admin", "Merchant"])(UsersTable);
  return (
    <>
      <NotificationListener />
      <Routes>
        <Route path="/" element={withAuthenticationRequired(Home)}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/compliance" element={<Compliance />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/add" element={<AddUser />} />
          <Route path="/users/edit/:id" element={<EditUser />} />
          <Route path="/organizations" element={<Organizations />} />
          <Route path="/organizations/:id" element={<OrganizationDetail />} />
          <Route path="/providers/" element={<ProviderTable />} />
          <Route path="/providers/add" element={<AddProvider />} />
          <Route path="/providers/edit/:id" element={<EditProvider />} />
          <Route path="transactions" element={<Transactions />} />
          <Route path="transactions/:id" element={<TransactionDetail />} />
          <Route path="/employees" element={<Employees />} />
          <Route path="/employee/add" element={<AddEmployee />} />
          <Route path="/employee/:id" element={<EditEmployee />} />
          <Route path="/beneficiaries" element={<BeneficiariesTable />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/add" element={<CustomerAdd />} />
          <Route path="/customers/:id" element={<CustomerEdit />} />
          <Route path="/members" element={<Members />} />
          <Route path="/members/add" element={<MemberAdd />} />
          <Route path="/members/:id" element={<MemberEdit />} />
          <Route path="/refunds" element={<Refunds />} />
          <Route path="/add-refund-simple" element={<AddSimple />} />
          <Route
            path="/add-refund-professional"
            element={<AddProfessional />}
          />
          <Route
            path="/add-refund-transaction"
            element={<AddRefundTransaction />}
          />
          <Route
            path="/edit-refund-transaction/:id"
            element={<EditRefundTransaction />}
          />
          <Route path="/edit-refund/:id" element={<EditRefund />} />
          <Route path="payouts" element={<Payouts />} />
          <Route path="refunds" element={<Refunds />} />
          <Route path="/payouts" element={<Payouts />} />
          <Route path="/payouts/:id" element={<PayoutDetail />} />
          <Route path="subscribers" element={<Subscribers />} />
          <Route path="payment-pages" element={<PaymentPages />} />
          <Route path="/api-keys" element={<ApiKeyTable />} />
          <Route path="/api-keys/add" element={<AddKey />} />
          <Route path="/api-keys/edit/:id" element={<KeyDetail />} />
          <Route path="/invoices" element={<InvoiceTable />} />
          <Route path="/fund-raising" element={<FundRaisingTable />} />
          <Route path="/add-invoice" element={<AddInvoice />} />
          <Route path="/campaign/add" element={<AddCampaign />} />
          <Route path="/campaign/:id" element={<CampaignEdit />} />
          <Route path="/event-detail/:id" element={<EventDetail />} />
          <Route
            path="/professional-invoice"
            element={<ProfessionalInvoice />}
          />
          <Route
            path="/edit-professional-invoice/:id"
            element={<EditProfessionalInvoice />}
          />
          <Route path="/simple-invoice" element={<SimpleInvoice />} />
          <Route path="settings" element={<Settings />} />
        </Route>
        <Route
          path="*"
          element={withoutAuthenticationRequired(UnAutheticatedApp)}
        >
          <Route path="auth/verify-email/:token" element={<ConfirmEmail />} />
          <Route path="login" element={<LogIn />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="signup-success" element={<SignupSucees />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route
            path="auth/password/reset/:uid/:token"
            element={<ResetPassword />}
          />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="components" element={<ComponentList />} />
      </Routes>
    </>
  );
};

export const Providers = () => {
  return (
    <UserProvider>
      <App />
    </UserProvider>
  );
};

export default Providers;
