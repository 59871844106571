import { deleteToken } from "firebase/messaging";
import { messaging } from "../firebase";
import api from "./api-client";
import { removeToken, setToken } from "./utils/helpers";

export const login = (logindata) => {
  return api.post(`/auth/login/`, logindata).then((res) => {
    setToken(res.data.token);
    localStorage.setItem("user", JSON.stringify(res.data.user));
    return res.data;
  });
};

export const getUser = async () => {
  // try to get from local storage else get from api

  // const user = JSON.parse(localStorage.getItem("user"));

  // if (user) {
  //   return user;
  // }

  const data = await api.get(`/auth/user/`);
  localStorage.setItem("user", JSON.stringify(data.data));
  return data.data;
};

export const signUp = (signupData) => {
  signupData.phone = signupData.phone.replace(" ", "");
  return api.post("/auth/register/", signupData);
};

export const logout = async () => {
  const data = api.post("/auth/logout/");
  await removeToken();
  console.log("delete token", await deleteToken(messaging));
  localStorage.removeItem("user");
  await window.location.replace("/login");
  return data;
};
export const forgotPassword = (email) => {
  return api.post("auth/password/reset/", email);
};

export const changePassword = (data) => {
  return api.put("/auth/password/change/", data);
};

export const resetPassword = (resetData) => {
  return api.post(`/auth/password/reset/confirm/`, resetData);
};

export const walletsAdd = async () => {
  const response = await api.get(`/wallet/`);
  return response.data;
};

export const walletsConnect = (walletData) => {
  return api.post("/wallet/connect/", walletData);
};

export const walletStatus = async () => {
  const response = await api.get("/wallet/status");
  return response.data;
};

export const walletsDeactivate = (walletId) => {
  return api.post("/wallet/deactivate/", walletId);
};

export const walletsReactive = (walletId) => {
  return api.post("/wallet/reactive/", walletId);
};

export const walletDelete = (walletId) => {
  return api.post("/wallet/delete/", walletId);
};

export const allStats = async (statsType) => {
  const params = new URLSearchParams();
  params.append("stats_type", statsType.queryKey[1]);
  const response = await api.get(`/all-stats/`, { params });
  return response.data;
};

export const getSettingsNotification = async () => {
  const response = await api.get(`/settings/`);
  return response.data;
};

export const setSettingsNotification = async (values) => {
  const response = await api.post(`/settings/`, values);
  return response.data;
};

export const deleteAccount = () => {
  return api.post("/user/delete/");
};

export const getAssets = async () => {
  const response = await api.get(`/all-assets/`);
  return response.data;
};

export const submitProfile = async (profileData) => {
  profileData.phone = profileData.phone.replace(" ", "");
  const response = await api.put(`/organization/profile/`, profileData);
  console.log(response.data);
  return response.data;
};

export const submitDocuments = async (documentsData) => {
  const data = new FormData();
  if (typeof documentsData.business_logo !== "string") {
    data.append("business_logo", documentsData.business_logo);
  }
  if (typeof documentsData.identification_document_front !== "string") {
    data.append(
      "identification_document_front",
      documentsData.identification_document_front
    );
  }
  if (typeof documentsData.identification_document_back !== "string") {
    data.append(
      "identification_document_back",
      documentsData.identification_document_back
    );
  }
  const response = await api.put(`/organization/documents/`, data);
  console.log(response.data);
  return response.data;
};

export const submitAccount = async (accountData) => {
  const response = await api.put(`/organization/account_details/`, accountData);
  console.log(response.data);
  return response.data;
};

export const getProfileData = async () => {
  const response = await api.get(`/organization/retrieve_profile_data/`);
  return response.data;
};

export const verifyEmail = (key) => {
  return api.get(`/auth/verify-email/${key}/`);
};

export const resendVerificationEmail = () => {
  return api.get(`/accounts/email/`);
};

export const getAllCountries = () => {
  return api.get(`/countries`);
};

export const getCitiesByCountryCode = async (countryId) => {
  const response = await api.get(`/cities/?country_id=${countryId}`);
  //   console.log(response.data);
  return response.data;
};

export const getAllOrganizations = async (params) =>
  api
    .get(`/admin/organization/`, {
      params,
    })
    .then((res) => res.data);

export const getOrganizationById = async (id) => {
  const response = await api.get(`/admin/organization/${id}/`);

  return response.data;
};

export const setOrganization = async (id, data) => {
  const response = await api.put(`/admin/organization/${id}/`, data);
  return response.data;
};

export const deleteOrganizationById = async (id) => {
  const response = await api.delete(`/admin/organization/${id}/`);
  return response.data;
};

export const getAllUsers = async (params) =>
  api
    .get(`/admin/user/`, {
      params,
    })
    .then((res) => res.data.response);

export const adduser = (userdata) => {
  return api.post(`/admin/user/`, userdata).then((res) => {
    return res.data;
  });
};

export const edituser = (id, userdata) => {
  return api.patch(`/admin/user/${id}/`, userdata).then((res) => {
    return res.data;
  });
};

export const getEditUsers = async (id) => {
  const response = await api.get(`/admin/user/${id.queryKey[1]}/`);
  return response.data;
};

export const deleteUserById = async (id) => {
  const response = await api.delete(`/admin/user/${id}/`);
  return response.data;
};

export const getAllProviders = async (params) => {
  const response = await api.get(`/provider/`, params);
  return response.data.response;
};

export const getProviderCountry = async () => {
  const response = await api.get(`/countries/`);
  return response.data;
};

export const addprovider = async (providerData) => {
  const response = await api.post(`/provider/`, providerData);
  return response.data;
};

export const editprovider = async (id, providerData) => {
  const response = await api.put(`/provider/${id}/`, providerData);
  return response.data;
};

export const getEditProvider = async (id) => {
  const response = await api.get(`/provider/${id.queryKey[1]}/`);
  return response.data.response;
};

export const deleteProviderById = async (id) => {
  const response = await api.delete(`/provider/${id}/`);
  return response.data;
};

export const getAllMembers = async (params) =>
  api
    .get(`/member/`, {
      params,
    })
    .then((res) => res.data.response);

export const createMemberMerchant = async (customerData) => {
  const response = await api.post(`/member/`, customerData);
  return response.data;
};

export const getMemberMerchant = async (id) => {
  const response = await api.get(`/member/${id}`);
  return response.data;
};

export const editMemberMerchant = async (id, customerData) => {
  const response = await api.put(`/member/${id}/`, customerData);
  return response.data;
};

export const deleteMemberMerchant = async (id) => {
  const response = await api.delete(`/member/${id}/`);
  return response.data;
};

export const createMember = async (memberData) => {
  console.log(memberData);
  const response = await api.post(`/admin/member/`, memberData);
  return response.data;
};

export const editMember = async (id, memberData) => {
  const response = await api.put(`/admin/member/${id}/`, memberData);
  return response.data;
};

export const deleteMemberById = async (id) => {
  const response = await api.delete(`/admin/member/${id}/`);
  return response.data;
};

export const getAllCustomers = async (params) =>
  api
    .get(`/customer/`, {
      params,
    })
    .then((res) => res.data.response);

export const getEmployees = async (params) =>
  api
    .get(`/employees/`, {
      params,
    })
    .then((res) => res.data.response);

export const createCustomerMerchant = async (customerData) => {
  const response = await api.post(`/customer/`, customerData);
  return response.data;
};

export const getCustomerMerchant = async (id) => {
  const response = await api.get(`/customer/${id}`);
  return response.data;
};

export const getEmployeeById = async (id) => {
  const response = await api.get(`/employees/${id}`);
  return response.data;
};

export const editCustomerMerchant = async (id, customerData) => {
  const response = await api.put(`/customer/${id}/`, customerData);
  return response.data;
};

export const EditEmployeeById = async (id, employeeData) => {
  const response = await api.put(`/employees/${id}/`, employeeData);
  return response.data;
};

export const deleteCustomerMerchant = async (id) => {
  const response = await api.delete(`/customer/${id}/`);
  return response.data;
};

export const deleteEmployee = async (id) => {
  const response = await api.delete(`/employees/${id}/`);
  return response.data;
};

export const createCustomer = async (customerData) => {
  const response = await api.post(`/admin/customer/`, customerData);
  return response.data;
};

export const editCustomer = async (id, customerData) => {
  const response = await api.put(`/admin/customer/${id}/`, customerData);
  return response.data;
};

export const deleteCustomerById = async (id) => {
  const response = await api.delete(`/admin/customer/${id}/`);
  return response.data;
};

export const getAllKeys = async (params) =>
  api
    .get(`/api-manager/`, {
      params,
    })
    .then((res) => res.data.response);

export const addkey = async (keydata) => {
  const response = await api.post(`/api-manager/`, keydata);
  return response.data;
};

export const getEditKey = async (id) => {
  const response = await api.get(`/api-manager/${id.queryKey[1]}/`);
  return response.data.response;
};

export const Editkey = async (id, keydata) => {
  const response = await api.patch(`/api-manager/${id}/`, keydata);
  return response.data;
};

export const deleteKeyById = async (id) => {
  const response = await api.delete(`/api-manager/${id}/`);
  return response.data;
};

export const deleteKeyByIdAdmin = async (id) => {
  const response = await api.delete(`/admin/sandbox/${id}/`);
  return response.data;
};

export const getEditProInvoice = async (id) => {
  const response = await api.get(`/invoice/${id.queryKey[1]}/`);
  return response.data.response;
};
export const addProInvoice = async (invoicedata) => {
  const response = await api.post(`/invoice/`, invoicedata);
  return response.data;
};
export const EditProInvoice = async (id, editpro) => {
  const response = await api.patch(`/invoice/${id}/`, editpro);
  return response.data;
};

export const getInvoices = async (params) =>
  api
    .get(`/invoice/`, {
      params,
    })
    .then((res) => res.data.response);

export const addInvoice = async (invoicedata) => {
  const response = await api.post(`/invoice/`, invoicedata);
  return response.data;
};

export const getInvoiceById = async (id) => {
  const response = await api.get(`/invoice/${id.queryKey[1]}/`);
  return response.data.response;
};
export const editInvoice = async (id, invoicedata) => {
  const response = await api.patch(`invoice/${id}/`, invoicedata);
  return response.data;
};
export const cancelInvoiceById = async (data) => {
  const response = await api.post(`/cancel_invoice/`, data);
  return response.data;
};

export const getAllTransactions = async (params) =>
  api
    .get(`/transaction/`, {
      params,
    })
    .then((res) => res.data.response);

export const getTransactionById = async (id) => {
  const response = await api.get(`/transaction/${id}/`);
  return response.data;
};

export const getTransactionByInvoiceId = async (invoiceId) => {
  const response = await api.get(
    `/transaction/?invoice__public_id=${invoiceId}`
  );
  return response.data;
};

export const addEvent = async (eventdata) => {
  const data = new FormData();
  Object.entries(eventdata).forEach(([key, value]) => {
    if (value !== null) {
      data.append(key, value);
    }
  });
  const response = await api.post(`/event/`, data);
  return response.data;
};

export const getAllEvents = async (params) =>
  api
    .get(`/event/`, {
      params,
    })
    .then((res) => res.data.response);

export const getSpecificEvent = async (id) => {
  const response = await api.get(`/event/${id}`);
  return response.data;
};

export const editSpecficEvent = async (id, newEventData) => {
  const data = new FormData();
  Object.entries(newEventData).forEach(([key, value]) => {
    if (
      ((key === "cover" && typeof newEventData.cover !== "string") ||
        (key === "logo" && typeof newEventData.logo !== "string") ||
        (key !== "logo" && key !== "cover")) &&
      value !== null
    ) {
      data.append(key, value);
    }
  });

  const response = await api.put(`/event/${id}/`, data);
  return response.data;
};

export const deleteEvent = async (id) => {
  const response = await api.delete(`/event/${id}/`);
  return response.data;
};

export const getRefunds = async (params) =>
  api
    .get(`/refund/`, {
      params,
    })
    .then((res) => res.data.response);

export const getRefundById = async (params) => {
  const response = await api.get(params);
  return response.data.response;
};
export const getEditTransactionRefundById = async (id) => {
  const response = await api.get(`/refund/${id.queryKey[1]}`);
  return response.data;
};
export const EditTransactionRefundData = async (id, refundData) => {
  const response = await api.patch(`/refund/${id}/`, refundData);
  return response.data;
};

export const getEditRefundById = async (id) => {
  const response = await api.get(`/refund/${id.queryKey[1]}`);
  return response.data;
};

export const addRefundData = async (refundData) => {
  const response = await api.post(`/refund/`, refundData);
  return response.data;
};

export const addTransactionRefundData = async (refundData) => {
  const response = await api.post(`/refund/`, refundData);
  return response.data;
};

export const updateRefundData = async (id, refundData) => {
  const response = await api.patch(`/refund/${id}/`, refundData);
  return response.data;
};

export const deleteRefundById = async (id) => {
  const response = await api.delete(`/refund/${id}/`);
  return response.data;
};
export const getAllGroups = async () => {
  const response = await api.get(`/groups/`);
  return response.data;
};
export const createPayout = async (data) =>
  api.post(`/payout/`, data).then((res) => res.data);

export const getAllPayouts = async (params) =>
  api
    .get(`/payout/`, {
      params,
    })
    .then((res) => res.data.response);

export const updatePayout = async (id, data) =>
  api.put(`/payout/${id}/`, data).then((res) => res.data);

export const getPayoutById = async (id) =>
  api.get(`/payout/${id}/`).then((res) => res.data);

export const getAllBeneficiaries = async (page, params) =>
  api
    .get(`/payout_beneficiary/${page?.queryKey[1] || ""}`, { params })
    .then((res) => res.data);

export const createBeneficiary = async (data) =>
  api.post(`/payout_beneficiary/`, data).then((res) => res.data);

export const getAllEmployees = async (page, params) =>
  api
    .get(`/employees/${page?.queryKey[1] || ""}`, { params })
    .then((res) => res.data);

export const createEmployee = async (data) =>
  api.post(`/employees/`, data).then((res) => res.data);

export const getBeneficiaries = async (params) =>
  api
    .get(`/payout_beneficiary/`, {
      params,
    })
    .then((res) => res.data.response);

export const addBeneficiary = async (beneficiaryData) => {
  const response = await api.post(`/payout_beneficiary/`, beneficiaryData);
  return response.data;
};
export const deleteBeneficiaryById = async (id) => {
  const response = await api.delete(`/payout_beneficiary/${id}/`);
  return response.data;
};

export const getCountries = async (searchString, countryId) => {
  const response = await api.get(
    `/countries/?search=${
      typeof searchString === "string" ? searchString : ""
    }&country_id=${typeof countryId === "string" ? countryId : ""}`
  );
  return response.data;
};

export const getCities = async (searchString, countryId, cityId = "") => {
  const response = await api.get(
    `/cities/?country_id=${
      typeof countryId === "number" ? countryId : ""
    }&search=${
      typeof searchString === "string" ? searchString : ""
    }&city_id=${cityId}`
  );
  return response.data;
};

export const getLiveBalance = async () => {
  const response = await api.get(`/get-balance`);
  return response.data;
};

export const getStats = async (params) => {
  const response = await api.get(`/stats/`, { params });
  return response.data;
};

export const registerFCMDevice = async (data) => {
  const response = await api.post(`/device/`, data);
  return response.data;
};

export const getNotifications = async (params) => {
  const response = await api.get(`/notification/`, { params });
  return response.data;
};

export const readNotification = async (id) => {
  const response = await api.get(`/notification/read_notification/?id=${id}`);
  return response.data;
};

export const markAsRead = async () => {
  const response = await api.get(`/notification/mark_as_all_read/`);
  return response.data;
};

export const changeLng = async (lng) => {
  const response = await api.post(`/profile_settings/`, lng);
  return response.data;
};
export const changeTempLng = async (lng) => {
  const response = await api.post(`/business-settings/`, lng);
  return response.data;
};
export const getTempLng = async () => {
  const response = await api.get(`/business-settings`);
  return response.data;
};

export const getLng = async () => {
  const response = await api.get(`/profile_settings`);
  return response.data;
};

export default api;
