import React from "react";
import { useFormik } from "formik";
import Button from "../../../common/form-controls/Button";
import Label from "../../../common/form-controls/Label";
import Switch from "../../../common/switch/Switch";
import classNames from "classnames";
import Input from "../../../common/form-controls/Input";

import ButtonLoader from "../../../common/form-controls/ButtonLoader";
import { useTranslation } from "react-i18next";
import BussinessSelect from "../../customers/components/BussinessSelect";
import { useUser } from "../../context/UserProvider";
import FileSelect from "../../profile-data-form/children/documents-form/components/FileSelect";
import { EventSchema } from "./validation-schema";
import TextArea from "../../../common/form-controls/TextArea";

function CampaignForm({
  initialValues = {
    title: "",
    description: "",
    cover: "",
    logo: "",
    business: null,
    amount_required: "",
    deadline: "",
    is_published: false,
  },
  onSubmit,
  isLoading,
  validationSchema,
  error,
}) {
  const { t } = useTranslation();
  const { user } = useUser();
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema ?? EventSchema,
    validateOnChange: false,
    onSubmit: (values) => onSubmit(values),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {user.user_role === "Admin" && (
        <div className={classNames("w-full mb-6")}>
          <BussinessSelect
            className="flex flex-wrap"
            name="business"
            value={parseInt(formik.values.business)}
            onChange={(value) => formik.setFieldValue("business", value.value)}
            error={formik.errors.business}
          />
        </div>
      )}
      <div className="flex flex-wrap -mx-3 justify-between">
        <div className={classNames("w-full md:w-full px-3 mb-6 ")}>
          <Label>{t("title")}</Label>
          <Input
            required
            placeholder={t("title")}
            name="title"
            onChange={formik.handleChange}
            value={formik.values.title}
            className={classNames({
              "is-invalid": formik.errors.title,
            })}
          />
          {formik.errors.title && (
            <span className="form-error">{formik.errors.title}</span>
          )}
        </div>
      </div>

      <div className="flex flex-wrap -mx-3 justify-between">
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <div className="form-group">
            <Label>{t("amountRequired")}</Label>
            <Input
              inputMode="numeric"
              pattern="[0-9]*"
              type="text"
              name="amount_required"
              placeholder="500"
              onChange={formik.handleChange}
              value={formik.values.amount_required}
            />
            {formik.errors.amount_required && (
              <span className="form-error">
                {formik.errors.amount_required}
              </span>
            )}
          </div>
        </div>

        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <div className="form-group">
            <Label>{t("deadline")}</Label>
            <Input
              type="date"
              min={new Date().toISOString().split("T")[0]}
              value={formik.values.deadline}
              style={{
                width: "100%",
                height: "unset",
              }}
              name="deadline"
              onChange={formik.handleChange}
            />
            {formik.errors.deadline && (
              <span className="form-error">{formik.errors.deadline}</span>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-wrap -mx-3 justify-between">
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <div className="form-group ">
            <Label>{t("cover")} (recomended size 2400 x 1600 pixels)</Label>
            <FileSelect
              htmlFor="cover"
              name="cover"
              file={formik.values.cover}
              onChange={(value) =>
                formik.setFieldValue("cover", value.currentTarget.files[0])
              }
              error={formik.errors.cover}
              height="56px"
            />
          </div>
        </div>

        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <div className="form-group">
            <Label>{t("logo")} (recomended size 50 x 50 pixels)</Label>
            <FileSelect
              htmlFor="logo"
              name="logo"
              file={formik.values.logo}
              onChange={(value) =>
                formik.setFieldValue("logo", value.currentTarget.files[0])
              }
              error={formik.errors.logo}
              height="56px"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap -mx-3 justify-between">
        <div className={classNames("w-full md:w-2/2 px-3 mb-6 ")}>
          <Label>{t("description")}</Label>
          <div
            className={classNames([
              {
                "is-invalid": formik.errors.description,
              },
            ])}
          >
            <TextArea
              type="text"
              placeholder={t("description")}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
            />
          </div>
          {formik.errors.description && (
            <span className="form-error">{formik.errors.description}</span>
          )}
        </div>
      </div>

      <div className="form-group">
        <Label>{t("published")}</Label>
        <Switch
          name="is_published"
          isChecked={formik.values.is_published}
          onChange={formik.handleChange}
        />
      </div>

      <Button type="submit" onClick={() => formik.validateForm()}>
        {isLoading ? <ButtonLoader text={t("sending")} /> : t("save")}
      </Button>
    </form>
  );
}

export default CampaignForm;
