import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { BeatLoader } from "react-spinners";
import { getAllPayouts } from "../../common/api";
import { useHeader, useUser } from "../context/UserProvider";
// import numberIcon from "../../images/numbericon.svg";
// import successIcon from "../../images/successfultransactionsicon.svg";
// import unSuccessIcon from "../../images/unsuccessfultransactionsicon.svg";
// import pendingIcon from "../../images/pendingtransactionsicon.svg";
// import RoundedPillWidget from "../widgets/RoundedPillWidget";
import Table from "../../common/table/Table";
// import { SVG } from "../../common/icons/Icon";
import Modal from "../../common/modal/Modal";
import PayoutModel from "./components/PayoutModel";
import FilterBar from "./components/FilterBar";
import { hasPermission } from "../../common/withRole/WithPermission";
import { CURRENCY_SYMBOL } from "../../common/utils/helpers";
import { useTranslation } from "react-i18next";

const statusColor = {
  APPROVED: {
    color: "#24C45F",
    backgroundColor: "#CBF6DB",
  },
  REJECTED: {
    color: "#F87171",
    backgroundColor: "#FDD7D7",
  },
  PENDING: {
    color: "#FFC134",
    backgroundColor: "#FFF2D6",
  },
  INPROCESS: {
    color: "#0251b8",
    backgroundColor: "#b6c4d6",
  },
};

const Payouts = ({ moduleName = "payout" }) => {
  const { t } = useTranslation();
  useHeader("payouts");
  const navigate = useNavigate();
  const { user } = useUser();
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState({
    status: "",
    created_at__date__gte: null,
    created_at__date__lte: null,
    search: null,
    page: 1,
  });

  const columns = [
    {
      lable: "Reference Id",
      accessor: "reference_id",
      copyable: true,
    },
    {
      lable: "Bussiness Name",
      accessor: "business_name",
    },
    {
      lable: `Type`,
      accessor: "type",
    },
    {
      lable: `Requested Amount (${CURRENCY_SYMBOL})`,
      accessor: "request_amount",
    },
    {
      lable: `Settled Amount (${CURRENCY_SYMBOL})`,
      accessor: "settled_amount",
    },
    {
      lable: "Date",
      accessor: "created_at",
    },
    {
      lable: "Status",
      accessor: "status",
      chip: true,
    },
    {
      lable: `Posting Status`,
      accessor: "posting_status",
    },
    {
      lable: `Posting Id`,
      accessor: "account_transaction_id",
    },
  ];

  const { data, isLoading, refetch } = useQuery(
    [
      "getAllPayouts",
      `?${filters.search}${filters.page}${filters.status}${filters.created_at__date__gte}${filters.created_at__date__lte}`,
    ],
    () => getAllPayouts(filters),
    {
      retry: 1,
    }
  );

  return isLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <>
      {/* <div className="widgets mb-6">
        <RoundedPillWidget img={numberIcon} label="PAYOUTS" value="03" />
        <RoundedPillWidget img={successIcon} label="SUCCESSFUL" value="01" />
        <RoundedPillWidget
          img={unSuccessIcon}
          label="UNSSUCCESSFUL"
          value="0"
        />
        <RoundedPillWidget img={pendingIcon} label="PENDING" value="01" />
      </div> */}
      <div className="flex flex-wrap justify-between my-8">
        <div className="flex searchBar md:w-1/2">
          <FilterBar
            moduleName={moduleName}
            filters={filters}
            onFilterChange={(filter) => setFilters({ ...filters, ...filter })}
            options={[
              { label: t("all"), value: "" },
              { label: t("APPROVED"), value: "APPROVED" },
            ]}
          />
        </div>
        <div className="md:w-1/3.5">
          <div className="barOptions">
            {/* <button className="rounded-btn p-2">{SVG.fileDownload}</button> */}
            {hasPermission(moduleName)("add") && (
              <button
                data-cy="add-btn"
                className="btn-orange"
                type="button"
                onClick={() => setOpen(true)}
              >
                {t("requestPayout")}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="boarding-card p-0 max-w-none">
        <Table
          moduleName={moduleName}
          columns={
            user.user_role === "Admin"
              ? columns
              : columns.filter(
                  ({ accessor }) =>
                    accessor !== "business_name" ||
                    accessor !== "account_transaction_id" ||
                    accessor !== "posting_status"
                )
          }
          rows={(data?.results ?? []).map(
            ({
              reference_id,
              type,
              created_at,
              business_name,
              request_amount,
              settled_amount,
              account_transaction_id,
              posting_status,
              status,
              id,
            }) => ({
              reference_id,
              posting_status,
              account_transaction_id,
              type,
              business_name,
              id,
              created_at:
                new Date(created_at)
                  .toDateString()
                  .split(" ")
                  .slice(1)
                  .join(" ") +
                ", " +
                new Date(created_at).toLocaleTimeString(),
              request_amount,
              settled_amount: settled_amount || "-",
              status: {
                value: status,
                color: statusColor[status].color,
                backgroundColor: statusColor[status].backgroundColor,
              },
            })
          )}
          onRowClicked={
            hasPermission(moduleName)("view_payout")
              ? ({ id }) => {
                  navigate(`/payouts/${id}`);
                }
              : null
          }
          allowDelete={false}
          allowEdit={false}
          showStatus={false}
          dataCount={data?.results.length}
          activePage={filters.page}
          onPageClicked={(page) => {
            setFilters({ ...filters, page: page });
          }}
        />
      </div>
      {open && (
        <Modal close={() => setOpen(false)}>
          <PayoutModel
            title={t("requestPayout")}
            onSuccess={refetch}
            onClose={() => setOpen(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default Payouts;
