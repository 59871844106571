import React, { useEffect } from "react";
import Label from "../../common/form-controls/Label";
import Input from "../../common/form-controls/Input";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { changePassword } from "../../common/api";
import { ChangePasswordSchema } from "./validation-schema";
import { useTranslation } from "react-i18next";
import { notify } from "../../common/toaster/Toaster";
import classNames from "classnames";
import { getServerError } from "../../common/utils/helpers";

const ChangePassword = ({ setIsLoading }) => {
  const { t } = useTranslation();

  const {
    isLoading,
    data,
    mutate: changePasswordFn,
  } = useMutation((values) => changePassword(values), {
    onSuccess: () => {
      notify(t("changePasswordModal.success_message"));
    },
    onError: (error) => {
      notify(getServerError(error), "error");
    },
  });

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    onSubmit: changePasswordFn,
    validationSchema: ChangePasswordSchema,
    validateOnChange: false,
  });

  return (
    <div className="change-password">
      <form onSubmit={formik.handleSubmit} id="data-form">
        <div className="form-group">
          <Label>{t("changePasswordModal.old_password")}</Label>
          <Input
            required
            visiblePassword={true}
            type="password"
            placeholder={t("changePasswordModal.old_password")}
            name="old_password"
            value={formik.values.old_password}
            onChange={formik.handleChange}
            className={classNames({
              "is-invalid": formik.errors.old_password,
            })}
          />
          {formik.errors.old_password && (
            <span className="form-error">{t(formik.errors.old_password)}</span>
          )}
        </div>
        <div className="form-group">
          <Label>{t("changePasswordModal.new_password")}</Label>
          <Input
            required
            visiblePassword={true}
            type="password"
            name="new_password"
            onChange={formik.handleChange}
            value={formik.values.new_password}
            placeholder={t("changePasswordModal.new_password")}
            className={classNames({
              "is-invalid": formik.errors.new_password,
            })}
          />
          {formik.errors.new_password && (
            <span className="form-error"> {t(formik.errors.new_password)}</span>
          )}
        </div>
        <div className="form-group">
          <Label>{t("changePasswordModal.confirm_password")}</Label>
          <Input
            required
            visiblePassword={true}
            type="password"
            name="confirm_password"
            onChange={formik.handleChange}
            value={formik.values.confirm_password}
            placeholder={t("changePasswordModal.confirm_password")}
            className={classNames({
              "is-invalid": formik.errors.confirm_password,
            })}
          />
          {formik.errors.confirm_password && (
            <span className="form-error">
              {t(formik.errors.confirm_password)}
            </span>
          )}
        </div>
      </form>
      {data && (
        <p className="text-success mt-4">Your password changed successfully</p>
      )}
    </div>
  );
};

export default ChangePassword;
