import * as Yup from "yup";
import IBAN from "iban";

export const AccountSchema = Yup.object().shape({
  account_type: Yup.string().required("Account Type is required"),
  account_name: Yup.string().when("account_type", {
    is: "bank",
    then: Yup.string().required("Account Name is required"),
  }),
  account_ibn: Yup.string().when("account_type", {
    is: "bank",
    then: Yup.string()
      .trim()
      .test("IBAN", "IBAN must be valid", (val) => IBAN.isValid(val))
      .required("Account IBAN is required"),
  }),
  bank_name: Yup.string().when("account_type", {
    is: "Bank",
    then: Yup.string().required("Bank Name is required"),
  }),
  branch_code: Yup.string().when("account_type", {
    is: "bank",
    then: Yup.string().required("Branch Code is required"),
  }),
  card_number: Yup.number().when("account_type", {
    is: "card",
    then: Yup.number()
      .min(10000000000000, "Card Number not valid")
      .max(9999999999999999, "Card Number not valid")
      .required("Card Number is required"),
  }),
  card_expiry_date: Yup.date()
    .nullable()
    .when("account_type", {
      is: "card",
      then: Yup.date().required("Card Expiry Date is required"),
    }),
  cvv: Yup.number().when("account_type", {
    is: "card",
    then: Yup.number()
      .min(100, "CVV not valid")
      .max(999, "CVV not valid")
      .required("CVV is required"),
  }),
});
