import * as Yup from "yup";
import IBAN from "iban";

export const BeneficiarySchema = Yup.object().shape({
  name: Yup.string().trim().required("Name is required"),
  bank_name: Yup.string().trim().required("Bank name is required"),
  iban: Yup.string()
    .trim()
    .test("IBAN", "IBAN must be valid", (val) => IBAN.isValid(val))
    .required("IBAN is required"),
  //business: Yup.string().required("Business is required"),
});
