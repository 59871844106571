import * as Yup from "yup";

export const EventSchema = Yup.object().shape({
  title: Yup.string().trim().required("Title is required"),
  description: Yup.string().trim().required("Description is required"),
  // cover: Yup.string().required("Upload cover  image"),
  cover: Yup.mixed()
    .test("fileSize", "File size is small minimum size is 500KB", (value) => {
      if (!value || typeof value === "string") return true; // Skip validation if no file is provided
      const minSize = 0.5 * 1024 * 1024; // Set the maximum file size (e.g., 10MB)
      return value.size >= minSize; // Return true if file size is within the allowed limit
    })
    .test("fileFormat", "Invalid file format", (value) => {
      if (!value || typeof value === "string") return true; // Skip validation if no file is provided

      const supportedFormats = ["jpg", "jpeg", "png"]; // Define the supported file formats

      const fileFormat = value.name.split(".").pop().toLowerCase(); // Get the file format

      return supportedFormats.includes(fileFormat); // Return true if file format is valid
    })
    .required("Upload cover photo"),

  logo: Yup.string().required("Upload business logo  "),
  amount_required: Yup.string()
    .matches(/^[0-9]*$/, "Amount should be only in numbers")
    .required("Amount is required"),
  deadline: Yup.string().trim().required("Deadline is required"),
  is_published: Yup.boolean().required("IsActive is required"),
});
