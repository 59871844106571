import React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import logoImg from "../../images/sappayPurple-logo.svg";
import keyIcon from "../../images/key.svg";
import emailcon from "../../images/email.svg";
import { Link } from "react-router-dom";
import Input from "../../common/form-controls/Input";
import Label from "../../common/form-controls/Label";
import Button from "../../common/form-controls/Button";
import { login } from "../../common/api";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { getServerError } from "../../common/utils/helpers";
import ButtonLoader from "../../common/form-controls/ButtonLoader";
import { LoginSchema } from "./validation-schema";
import { notify } from "../../common/toaster/Toaster";
import { useUser } from "../context/UserProvider";
import { getFCMToken } from "../../firebase";
import { useNotification } from "../context/NotificationProvider";
import { getLng } from "../../common/api";
import LanguageDropdown from "../../common/language-dropdown/LanguageDropdown";

const LogIn = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { refetchNotifications } = useNotification();
  const { setUser } = useUser();

  const { isLoading, mutate: loginFn } = useMutation(
    (values) => login(values),
    {
      onSuccess: ({ user }) => {
        getFCMToken();
        getLng().then((data) => {
          i18n.changeLanguage(
            data.response.language === "ENGLISH" ? "en" : "fr"
          );
        });
        refetchNotifications();
        if (user.hasOwnProperty("business_approved")) {
          user.user_role = "Merchant";
        }
        setUser(user);
        navigate(user.business_approved === "Approved" ? "/" : "/compliance", {
          replace: true,
        });
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    onSubmit: loginFn,
    validationSchema: LoginSchema,
    validateOnChange: false,
  });

  return (
    <section className="signup login grid items-center signBg">
      <div className="signup-content">
        <div className="form-card mx-auto relative">
          <div className="logoImg text-center">
            <img src={logoImg} alt="logo" />
          </div>
          <div class="flex flex-wrap">
            <div className="w-full md:w-1/2">
              <h3>{t("signIn")}</h3>
            </div>
            <div className="w-full md:w-1/2 flex justify-end">
              <LanguageDropdown />
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <Label>{t("email")}</Label>
              <div className="form-control-addon">
                <img src={emailcon} alt="email-icon" />
                <Input
                  required
                  placeholder={t("email")}
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  className={classNames({ "is-invalid": formik.errors.email })}
                />
              </div>
              {formik.errors.email && (
                <span className="form-error">{formik.errors.email}</span>
              )}
            </div>
            <div className="form-group">
              <Label>{t("password")}</Label>
              <div className="form-control-addon">
                <img src={keyIcon} alt="icon" />
                <Input
                  required
                  visiblePassword={true}
                  type="password"
                  placeholder={t("password")}
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  className={classNames({
                    "is-invalid": formik.errors.password,
                  })}
                />
              </div>
              {formik.errors.password && (
                <span className="form-error">{formik.errors.password}</span>
              )}
            </div>
            <div className="form-group">
              <div className="flex items-center">
                <Link to={"/forgot-password"} className="ml-auto orange-link">
                  {t("forgetPassword")}?
                </Link>
              </div>
            </div>

            <Button
              className={classNames("md:mb-6 mb-4 disabled:opacity-50")}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? <ButtonLoader text={t("signing")} /> : t("signIn")}
            </Button>
            <div className="flex">
              <p className="font-xs">{t("notRegisteredYet?")}</p>{" "}
              <Link to={"/signup"} className="ml-1 orange-link">
                {t("signUpNow")}
              </Link>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default LogIn;
