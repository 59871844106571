import { useContext } from "react";
import { ADD_WALLET, CONNECT_WALLET, WALLET_ADDED } from "../constant/constant";
import {
  SyncWalletContext,
} from "../context/SyncWalletContext";
import AddWallet from "../wallet/add-wallet/AddWalletWrapper";
import ConnectWallet from "../wallet/connect-wallet/ConnectWalletWrapper";
import WalletAdded from "../wallet/wallet-added/WalletAddedWrapper";


const SyncSignup = () => {
  const { screen } = useContext(SyncWalletContext);
  switch (screen) {
    case ADD_WALLET:
      return <AddWallet />;
    case CONNECT_WALLET:
      return <ConnectWallet />;
    case WALLET_ADDED:
      return <WalletAdded />;
    default:
      <AddWallet />;
  }
};

export default SyncSignup;
