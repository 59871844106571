import React from "react";
import Label from "../../../../common/form-controls/Label";
import FileSelect from "./components/FileSelect";
import { useTranslation } from "react-i18next";
// import { REACT_APP_BACKEND_URL } from "../../../../config";

const DocumentsForm = ({ formik }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="noticeBox">
        <h4>
          <b>{t("note")}</b>
        </h4>
        <p>{t("documentNoteInstruction")}</p>
        <ul>
          <li>{t("documentNoteLi1")} </li>
          <li>{t("documentNoteLi2")}</li>
        </ul>
      </div>
      <div className="form-group">
        <Label className="required">{t("businessLogo")}</Label>
        <FileSelect
          htmlFor="business_logo"
          file={formik.values.business_logo}
          onChange={(value) =>
            formik.setFieldValue("business_logo", value.currentTarget.files[0])
          }
          error={formik.errors.business_logo}
        />
      </div>

      <div className="form-group">
        <Label className="required">
          {t("indentificationDocumentFrontSide")}
        </Label>
        <FileSelect
          htmlFor="identification_document_front"
          file={formik.values.identification_document_front}
          onChange={(value) =>
            formik.setFieldValue(
              "identification_document_front",
              value.currentTarget.files[0]
            )
          }
          error={formik.errors.identification_document_front}
        />
      </div>

      <div className="form-group">
        <Label className="required">
          {t("indentificationDocumentBackSide")}{" "}
        </Label>
        <FileSelect
          htmlFor="identification_document_back"
          file={formik.values.identification_document_back}
          onChange={(value) =>
            formik.setFieldValue(
              "identification_document_back",
              value.currentTarget.files[0]
            )
          }
          error={formik.errors.identification_document_back}
        />
      </div>
    </>
  );
};

export default DocumentsForm;
