import React, { useState, useEffect, useMemo } from "react";
import Input from "../../common/form-controls/Input";
import Label from "../../common/form-controls/Label";
import Button from "../../common/form-controls/Button";
import { EditProInvoice } from "../../common/api";
import classNames from "classnames";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { amount, getServerError } from "../../common/utils/helpers";
import ButtonLoader from "../../common/form-controls/ButtonLoader";
import { ProInvoiceSchema } from "./validation-schema";
import { notify } from "../../common/toaster/Toaster";
import { useNavigate } from "react-router-dom";
import { useHeader } from "../context/UserProvider";
import { SVG } from "../../common/icons/Icon";
import Modal from "../../common/modal/Modal";
import Success from "../../common/modal/Success";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import { useUser } from "../../components/context/UserProvider";
import { getEditProInvoice } from "../../common/api";
import BussinessSelect from "../customers/components/BussinessSelect";
import { useTranslation } from "react-i18next";
const EditProfessionalInvoice = () => {
  const { t } = useTranslation();
  useHeader("editInvoice");
  const navigate = useNavigate();
  const { user } = useUser();
  const { id } = useParams();
  const [publicId, setpublicId] = useState(false);
  const { data } = useQuery(["getEditProInvoice", id], getEditProInvoice, {
    retry: 1,
  });
  const [Tab, setTab] = useState(0);

  const { isLoading, mutate } = useMutation(
    (values) => EditProInvoice(id, { ...values, amount: total }),
    {
      onSuccess: (data) => {
        setTab(2);
        setpublicId(data.response.public_id);
        notify(t("invoiceUpdated"), "success");
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      customer: {
        email: "",
      },
      type: "PROFESSIONAL",
      due_date: "",
      tax: "",
      business: "",
      discount_format: "PERCENTAGE",
      discount_amount: "",
      note: "",
      items: [{ name: "", quantity: "", unit_amount: "" }],
    },
    onSubmit: (values) => mutate(values),
    validationSchema: ProInvoiceSchema,
    validateOnChange: false,
  });

  useEffect(() => {
    if (data?.type) {
      formik.setValues({
        business: data.business,
        customer: {
          email: data.email,
        },
        type: data.type,
        due_date: data.due_date,
        tax: data.tax,
        discount_format: data.discount_format,
        discount_amount: data.discount_amount,
        note: data.note,
        items: data.items,
      });
    }
    // eslint-disable-next-line
  }, [data]);
  const subtotal = useMemo(
    () =>
      formik.values.items.reduce(
        (partialSum, item) =>
          parseFloat(partialSum) +
          parseFloat(
            item.unit_amount === "" ? 0 : item.unit_amount * item.quantity
          ),
        0
      ),
    [formik.values.items]
  );

  const discountamount =
    formik.values.discount_format === "PERCENTAGE"
      ? (subtotal * formik.values.discount_amount ?? 0) / 100
      : formik.values.discount_amount;

  const total =
    (subtotal * formik.values.tax ?? 0) / 100 + subtotal - discountamount;

  return (
    <section className="addInvoice grid md:grid-cols-2">
      {Tab === 0 && (
        <form>
          <div className="heading-lg" style={{ marginBottom: "2rem" }}>
            {t("fillDetail")}
          </div>
          {user.user_role === "Admin" && (
            <div className={classNames("w-full mb-6")}>
              <BussinessSelect
                className="flex flex-wrap mb-6"
                name="business"
                value={parseInt(formik.values.business)}
                onChange={(value) =>
                  formik.setFieldValue("business", value.value)
                }
                error={formik.errors.business}
              />
            </div>
          )}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <Label>{t("customer")}</Label>
              <Input
                placeholder="Customer Email"
                name="customer.email"
                onChange={formik.handleChange}
                value={formik.values.customer.email}
                className={classNames({ "is-invalid": formik.errors.customer })}
              />
              {formik.errors.customer?.email && (
                <span className="form-error">
                  {formik.errors.customer?.email}
                </span>
              )}
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <Label>{t("dueDate")} </Label>
              <Input
                name="due_date"
                type="date"
                min={new Date().toISOString().split("T")[0]}
                onChange={formik.handleChange}
                value={formik.values.due_date}
                className={classNames({ "is-invalid": formik.errors.due_date })}
              />
              {formik.errors.due_date && (
                <span className="form-error">{formik.errors.due_date}</span>
              )}
            </div>
          </div>
          <div className="form-group">
            <Label>{t("tax")}(%)</Label>
            <Input
              placeholder="Tax"
              name="tax"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.tax}
              className={classNames({ "is-invalid": formik.errors.tax })}
            />
            {formik.errors.tax && (
              <span className="form-error">{formik.errors.tax}</span>
            )}
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <Label>{t("discountFormat")} </Label>
              <select
                name="discount_format"
                type="number"
                value={formik.values.discount_format}
                onChange={formik.handleChange}
                className={classNames([
                  "form-control",
                  { "is-invalid": formik.errors.discount_format },
                ])}
              >
                <option value="PERCENTAGE"> {t("percentage")}</option>
                <option value="FLAT_AMOUNT">{t("flatAmount")}</option>
              </select>
              {formik.errors.discount_format && (
                <span className="form-error">
                  {formik.errors.discount_format}
                </span>
              )}
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <Label>{t("discountAmount")} </Label>
              <Input
                placeholder="Discount Amount"
                name="discount_amount"
                onChange={formik.handleChange}
                value={formik.values.discount_amount}
                className={classNames({
                  "is-invalid": formik.errors.discount_amount,
                })}
              />
              {formik.errors.discount_amount && (
                <span className="form-error">
                  {formik.errors.discount_amount}
                </span>
              )}
            </div>
          </div>
          <div className="form-group">
            <Label>{t("note(Optional)")} </Label>
            <textarea
              placeholder={t("note")}
              name="note"
              onChange={formik.handleChange}
              value={formik.values.note}
              className="form-control"
            />
          </div>
          <div className="heading-lg" style={{ marginBottom: "1rem" }}>
            {t("invoiceItem")}
          </div>
          {formik.values.items.map((row, index) => {
            return (
              <div className="flex flex-wrap -mx-3 mb-6" key={index}>
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <Label>{t("invoiceItem")}</Label>
                  <Input
                    name="name"
                    placeholder={t("invoiceItem")}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `items[${index}].name`,
                        e.target.value
                      );
                    }}
                    value={row.name}
                  />
                </div>
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <Label>{t("quantity")}</Label>
                  <Input
                    name="quantity"
                    placeholder={t("quantity")}
                    type="number"
                    onChange={(e) => {
                      formik.setFieldValue(
                        `items[${index}].quantity`,
                        e.target.value
                      );
                    }}
                    value={row.quantity}
                  />
                </div>
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <Label>{t("unitAmount")}</Label>
                  <Input
                    name="unit_amount"
                    placeholder={t("unitAmount")}
                    type="number"
                    onChange={(e) => {
                      formik.setFieldValue(
                        `items[${index}].unit_amount`,
                        e.target.value
                      );
                    }}
                    value={row.unit_amount}
                  />
                </div>
                <span
                  className="keyDelete"
                  onClick={() =>
                    formik.setFieldValue(
                      "items",
                      formik.values.items.filter(({ id }) => index !== id)
                    )
                  }
                >
                  {SVG.delete}
                </span>
              </div>
            );
          })}
          <div className="section-head">
            <Button
              className="add-btn"
              onClick={() => {
                formik.setFieldValue("items", [
                  ...formik.values.items,
                  {
                    id: formik.values.items.length,
                    name: "",
                    quantity: "",
                    unit_amount: "",
                  },
                ]);
              }}
            >
              {t("addItem")}
            </Button>
          </div>
          <div className="form-group float-right">
            <Label>{t("total")}</Label>
            <strong>{amount(total)}</strong>
          </div>
          <Button
            data-cy="save"
            className={classNames("md:mb-6 mb-4 disabled:opacity-50")}
            type="button"
            onClick={() => {
              formik
                .validateForm()
                .then(
                  (errors) => Object.keys(errors).length === 0 && setTab(1)
                );
            }}
            disabled={isLoading}
          >
            {isLoading ? <ButtonLoader text={t("saving")} /> : `${t("save")}`}
          </Button>
        </form>
      )}
      {Tab === 1 && (
        <Modal close={() => setTab(0)}>
          <div className="invoiceModal">
            <div className="modalIcon">{SVG.review}</div>
            <h3>{t("reviewRequest")}</h3>
            <form onSubmit={formik.handleSubmit}>
              <div className="modalInline">
                <h4>
                  <b>{t("sender")}</b> <span>{user.email}</span>
                </h4>
                <h4>
                  <b>{t("receiverInvoice")} </b>{" "}
                  <span>{formik.values.customer.email}</span>
                </h4>
                <h4>
                  <b>{t("total")}</b> <span>{amount(total)}</span>
                </h4>
              </div>
              <button className="btn-orange" type="submit">
                {isLoading ? (
                  <ButtonLoader text={t("sending")} />
                ) : (
                  `${t("send")}`
                )}
              </button>
              <button
                style={{ width: "100%" }}
                className="btn-brightgray mt-4"
                type="button"
                onClick={() => setTab(0)}
              >
                {t("editRequest")}
              </button>
            </form>
          </div>
        </Modal>
      )}
      {Tab === 2 && (
        <Modal close={() => navigate("/invoices")}>
          <Success
            title={t("Submitted!")}
            msg={t(
              `${t("requestMessage")} ${publicId} ${t("sentMessage")} ${
                formik.values.customer.email
              }`
            )}
            id={publicId}
          />{" "}
        </Modal>
      )}
    </section>
  );
};

export default EditProfessionalInvoice;
