import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { useDarkMode } from "usehooks-ts";

Chart.register(...registerables);

const NetworkFeesChart = () => {
  const { isDarkMode } = useDarkMode();

  const gradientBackGround = () => {
    var chart = document.getElementById("network-chart")?.getContext("2d");
    if (chart) {
      var gradient = chart.createLinearGradient(0, 0, 0, 355);
      gradient.addColorStop(0, "rgba(127, 44, 234, 0.16)");
      gradient.addColorStop(0.2, "rgba(255, 255, 255, 0.25)");
      gradient.addColorStop(1, "rgba(255, 255, 255, 0)");
      return gradient;
    }
  };

  const chartDataSets = {
    labels: [
      "12",
      "93",
      "12",
      "93",
      "12",
      "93",
      "12",
      "93",
      "12",
      "93",
      "12",
      "93",
      "12",
    ],
    datasets: [
      {
        label: "data",
        fill: true,
        borderColor: "rgba(127, 44, 234, 1)",
        lineTension: 0.3,
        borderWidth: 2.5,
        data: [43, 54, 31, 32, 54, 23, 34, 43, 21, 35, 53, 34, 25],
        pointRadius: 0,
        pointBorderColor: "#FFFFFF",
        pointBorderWidth: 0,
        pointBackgroundColor: "#7F2CEA",
        backgroundColor: gradientBackGround(),
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      elements: {
        point: {
          radius: 15,
        },
      },
    },

    scales: {
      x: {
        ticks: {
          padding: 20,
          crossAlign: "start",
          color: !isDarkMode ? "#92A0AE" : "#A6A6A6",
          font: {
            size: 12,
            family: "GothamMedium",
          },

          callback: () => null,
        },

        grid: {
          drawBorder: false,
          lineWidth: 0,
        },
      },
      y: {
        grid: {
          borderDash: [15, 10],
          color: !isDarkMode ? "#f8f8f8" : "#191C1F",
          drawBorder: false,
        },

        ticks: {
          color: !isDarkMode ? "#92A0AE" : "#A6A6A6",
          font: {
            size: 12,
            family: "GothamMedium",
          },

          stepSize: 5,
          max: 15,
          min: 0,
          callback: () => null,
        },
      },
    },
  };

  return (
    <div className="network-chart-wrapper mt-4">
      <div className="w-full h-full">
        <Line data={chartDataSets} options={options} id="network-chart" />
      </div>
    </div>
  );
};

export default NetworkFeesChart;
