import React, { useState } from "react";
import { useDarkMode } from "usehooks-ts";
import Switch from "../../common/switch/Switch";
import { SVG } from "../icons/Icon";

const TableRow = ({
  columnsList = [],
  values,
  allowEdit,
  allowDelete,
  allowApprove,
  showStatus,
  onEditClicked,
  onDeleteClicked,

  onApproveClicked,
  ...props
}) => {
  const { isDarkMode } = useDarkMode();
  const [copyIcon, setCopyIcon] = useState(SVG.contentCopy);

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    setCopyIcon(SVG.tick);
    setTimeout(() => {
      setCopyIcon(SVG.contentCopy);
    }, 1000);
  };
  return (
    <tr {...props}>
      {columnsList.map(
        (
          {
            accessor,
            chip = false,
            custom = false,
            customNA = "-",
            copyable = false,
            borderIdentifier = false,
          },
          index
        ) => {
          return (
            <td
              key={index}
              data-cy={accessor}
              className="px-6 py-4 whitespace-nowrap"
              role="cell"
            >
              {custom ? (
                typeof values[accessor] === "function" ? (
                  values[accessor](values)
                ) : (
                  <div style={{ textAlign: "center" }}>{customNA}</div>
                )
              ) : chip ? (
                <span
                  className={`chip-${accessor} px-3 py-1 rounded text-white ${
                    values[accessor].isPositive ? "positive" : "negative"
                  } font-semibold text-xs flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease`}
                  style={{
                    backgroundColor: values[accessor].backgroundColor,
                    color: values[accessor].color,
                  }}
                >
                  {values[accessor].value}
                </span>
              ) : borderIdentifier ? (
                <div
                  className={`td-${accessor} border-l-4 pl-2 text-sm ${
                    isDarkMode ? "text-slate-200" : "text-gray-500"
                  }`}
                  style={{
                    borderLeftColor: values[accessor]?.borderColor,
                  }}
                >
                  {values[accessor]?.value}
                </div>
              ) : (
                <div
                  className={`td-${accessor} text-sm ${
                    isDarkMode ? "text-slate-200" : "text-gray-500"
                  }`}
                >
                  {values[accessor]}
                </div>
              )}
              {copyable && (
                <button
                  className="copy-btn"
                  type="button"
                  // style={{ position: "absolute", right: "0.5rem" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopy(values[accessor]);
                  }}
                >
                  {copyIcon}
                </button>
              )}
            </td>
          );
        }
      )}

      {showStatus && (
        <td
          data-cy="status"
          className="px-6 py-4 whitespace-nowrap"
          role="cell"
        >
          <span
            className={`px-4 py-2 rounded text-white ${
              values["isActive"] ? "bg-green-400" : "bg-red-400"
            } font-semibold text-sm flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease`}
          >
            {values["status"]}
          </span>
        </td>
      )}

      {allowApprove && (
        <td className="px-6 whitespace-nowrap" role="cell">
          <div
            className={`text-sm ${
              isDarkMode ? "text-slate-200" : "text-gray-500"
            }`}
          >
            <Switch
              className="table-action"
              isChecked={values["isActive"]}
              onChange={(e) => onApproveClicked(values["id"], e.target.checked)}
            />
          </div>
        </td>
      )}

      {allowEdit && (
        <td className="px-6 py-4 whitespace-nowrap" role="cell">
          <div
            className={`text-sm ${
              isDarkMode ? "text-slate-200" : "text-gray-500"
            }`}
          >
            <button
              data-cy="edit-btn"
              type="button"
              className="table-action"
              onClick={(e) => {
                e.stopPropagation();
                onEditClicked(values);
              }}
            >
              {SVG.edit}
            </button>
          </div>
        </td>
      )}
      {allowDelete && (
        <td className="px-6 py-4 whitespace-nowrap" role="cell">
          <div
            className={`text-sm ${
              isDarkMode ? "text-slate-200" : "text-gray-500"
            }`}
          >
            <button
              data-cy="delete-btn"
              type="button"
              className="table-action"
              onClick={(e) => {
                e.stopPropagation();
                onDeleteClicked(values);
              }}
            >
              {SVG.delete}
            </button>
          </div>
        </td>
      )}
    </tr>
  );
};

export default TableRow;
