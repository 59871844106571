import { useState } from "react";
import Input from "../form-controls/Input";
import { SVG } from "../icons/Icon";
import { useTranslation } from "react-i18next";
import {
  REACT_APP_CHECKOUT_URL,
  REACT_APP_FUNDRAISING_URL,
} from "../../config";
const ShareModel = ({ id, fundraising = false }) => {
  const { t } = useTranslation();
  const [copyIcon, setCopyIcon] = useState(SVG.contentCopy);

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    setCopyIcon(SVG.tick);
    setTimeout(() => {
      setCopyIcon(SVG.contentCopy);
    }, 1000);
  };
  const baseUrl = fundraising
    ? REACT_APP_FUNDRAISING_URL
    : REACT_APP_CHECKOUT_URL;

  return (
    <div>
      <h3>{t("share")} </h3>
      <div className="shareable-links mt-6 mb-14">
        <button
          onClick={() =>
            window.open(
              `https://wa.me/?text=${encodeURIComponent(
                `Invoice Id : ${id}\nInvoice Link : ${baseUrl}/${id}`
              )}`
            )
          }
        >
          {SVG.whatsApp}
          <span>WhatsApp</span>
        </button>
        <button
          onClick={() =>
            window.open(
              `mailto:?subject=${encodeURIComponent(
                `Sappay Invoice : ${id}`
              )}&body=${encodeURIComponent(
                `Invoice Id : ${id}\nInvoice Link : ${baseUrl}/${id}`
              )}`
            )
          }
        >
          {SVG.email}
          <span>Email</span>
        </button>
      </div>
      <div className="link-holder flex gap-x-4 form-control">
        <Input
          className="w-full "
          formControl={false}
          disabled
          readOnly
          value={`${baseUrl}/${id}`}
        />
        <button
          className="btn-orange flex gap-x-2 w-auto"
          type="button"
          onClick={() => handleCopy(`${baseUrl}/${id}`)}
        >
          {copyIcon}
        </button>
      </div>
    </div>
  );
};

export default ShareModel;
