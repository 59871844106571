import { React, useState } from "react";
import Logo from "../logo/Logo";
import wallet from "../../images/wallet-added.svg";

const SignupSucees = () => {

  const [isImageLoaded, setIsImageLoaded] = useState(false)


  return (
    <div className="signup-success">
      <div className="h-screen flex flex-col items-center justify-center svg-content">
      {isImageLoaded &&<div className="logo lg:mb-16 mb-10">
          <Logo svgColor={"#FA6D00"} textColor={"text-catalinablue"} />
        </div>}
        {isImageLoaded && <div className="card relative maxMd:mx-4">
          <h3>Congratulations</h3>
          <p className="text-center text-lg mt-4">
            You have successfully signed up. Please check your email for
            verification.
          </p>
        </div>}
        <div className="wallet-added_image">
          <img src={wallet} alt="wallet" onLoad={() => setIsImageLoaded(true)} />
        </div>
      </div>
    </div>
  );
};

export default SignupSucees;
