import React from "react";
import { useWindowSize } from "usehooks-ts";
import Button from "../../common/form-controls/Button";
import NetworkFeesChart from "./NetworkFeesChart";

const RightSidebar = () => {

  const { height } = useWindowSize();

  const articals = [
    {
      title: "How to Use Zapper’s Mo...",
      time: "5 min. read",
      icon: "📱"
    },
    {
      title: "How to Use Zapper to....",
      time: "5 min. read",
      icon: "🏠"
    }, {
      title: " How to Revoke Token Al...",
      time: "5 min. read",
      icon: "🚥"
    },
  ]

  return (
    <section className="right-sidebar" style={{ height: height }}>
      <div className="right-sidebar__content">
        <div className="main-heading mb-3">
          <h3 className="heading-lg dark:text-brightgray font-gotham-medium">
            Widgets
          </h3>
        </div>
        <div className="network-fees">
          <div className="item-card">
            <div className="card-heading">
              <h3 className="text-base capitalize dark:text-brightgray font-gotham-medium">
                Network Fees
              </h3>
            </div>
            <div className="card-body">
              <ul className="network-fees__list">
                <li>
                  <div>
                    <h2>Standard</h2>
                    <p>~3 min</p>
                  </div>
                  <div>
                    <h2>16</h2>
                    <p>$0.95</p>
                  </div>
                </li>
                <li>
                  <div>
                    <h2>Fast</h2>
                    <p>~30 sec</p>
                  </div>
                  <div>
                    <h2>21</h2>
                    <p>$1.54</p>
                  </div>
                </li>
              </ul>
              <div className="token">
                <div className="token__body">
                  <h2>Gas Trend (1w)</h2>
                  <div className="token__info">
                    <div className="flex items-center">
                      <div className="token__info-heading">13</div>
                      <p>gwei</p>
                      <h6>-71.74%</h6>
                    </div>
                    <span>
                      Avg.Fee
                    </span>
                  </div>
                </div>
                <NetworkFeesChart />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="item-card">
            <div className="card-heading">
              <h3 className="text-base capitalize dark:text-brightgray font-gotham-medium">
                News & Articles
              </h3>
            </div>
            <div className="card-body">
              <ul>
                {articals.map((item, index) =>
                  <li key={index}>
                    <a href="#/">
                      <div className="artical">
                        <div className="artical__icon">{item.icon}</div>
                        <div>
                          <div className="artical__title">{item.title}</div>
                          <div className="artical__time">{item.time}</div>
                        </div>
                      </div>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="item-card">
            <div className="card-heading">
              <h3 className="text-base capitalize dark:text-brightgray font-gotham-medium">
                Invite Friends
              </h3>
            </div>
            <div className="card-body">
              <h3>
                Get a 10$ Credit for future purchases for each friends that buys a tax plan on Coin Tracker
              </h3>
              <div className="mt-4">
                <Button onClick={() => { return }}>Earn $10</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RightSidebar;
