import React, { useState } from "react";
import { SVG } from "../icons/Icon";

const Input = ({
  className = "",
  formControl = true,
  isInvalid = null,
  allowCopy = false,
  visiblePassword = false,
  disabled = false,
  inputMode,
  pattern,
  value = "",
  type = "text",
  ...props
}) => {
  const [copyIcon, setCopyIcon] = useState(SVG.contentCopy);
  const [inputType, setInputType] = useState(type);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setCopyIcon(SVG.copied);
    setTimeout(() => {
      setCopyIcon(SVG.contentCopy);
    }, 1000);
  };

  return (
    <div
      className={`${
        formControl
          ? `form-control ${className ? className : ""}`
          : `${className}`
      }${disabled ? "disabled" : ""}`}
      style={{ position: "relative" }}
      disabled={disabled}
    >
      {type === "textarea" ? (
        <textarea
          disabled={disabled}
          style={{
            width: "100%",
            height: "100%",
            paddingRight: allowCopy ? "2rem" : "",
          }}
          value={value}
          {...props}
        />
      ) : (
        <input
          inputMode={inputMode}
          pattern={pattern}
          disabled={disabled}
          className="dark-mode-bg-color"
          style={{
            width: "100%",
            height: "100%",
            paddingRight: allowCopy ? "2rem" : "",
          }}
          type={inputType}
          value={value}
          {...props}
        />
      )}
      {allowCopy && (
        <button
          type="button"
          style={{ position: "absolute", right: "0.5rem" }}
          onClick={handleCopy}
        >
          {copyIcon}
        </button>
      )}
      {visiblePassword && (
        <button
          type="button"
          style={{ position: "absolute", right: "0.5rem", top: "13px" }}
          onClick={() => {
            if (inputType === "password") setInputType("text");
            else setInputType("password");
          }}
        >
          {inputType === "password" ? SVG.hidePassword : SVG.visiblePassword}
        </button>
      )}
    </div>
  );
};

export default Input;
