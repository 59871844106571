import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { notify } from "../../common/toaster/Toaster";
import CampaignForm from "../fundraising/components/campaignForm";
import { addEvent } from "../../common/api";

function AddCampaign() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoading, mutate, error } = useMutation(
    (values) => addEvent(values),
    {
      onSuccess: () => {
        navigate("/fund-raising");
        notify(t("campaignCreated"), "success");
      },
    }
  );
  return (
    <div className="tab-content">
      <div className="section-head">
        <h1>{t("createEvent")}</h1>
      </div>
      <CampaignForm onSubmit={mutate} isLoading={isLoading} error={error} />
    </div>
  );
}

export default AddCampaign;
