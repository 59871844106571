import React, { useState } from "react";
import { getInvoices, cancelInvoiceById } from "../../common/api";
import { useMutation, useQuery } from "react-query";
import Table from "../../common/table/Table";
// import numberIcon from "../../images/numbericon.svg";
// import successIcon from "../../images/successfultransactionsicon.svg";
// import unSuccessIcon from "../../images/unsuccessfultransactionsicon.svg";
// import pendingIcon from "../../images/pendingtransactionsicon.svg";
// import RoundedPillWidget from "../widgets/RoundedPillWidget";
import Modal from "../../common/modal/Modal";
import { notify } from "../../common/toaster/Toaster";
import { getServerError } from "../../common/utils/helpers";
import { useHeader } from "../context/UserProvider";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import SimInvoiceEdit from "../../common/modal/SimInvoiceEdit";
import WithPermission from "../../common/withRole/WithPermission";
import { amount as dAmount } from "../../common/utils/helpers";
import { useTranslation } from "react-i18next";
// import { SVG } from "../../common/icons/Icon";
import FilterBar from "../payouts/components/FilterBar";
import { CURRENCY_SYMBOL } from "../../common/utils/helpers";
import { hasPermission } from "../../common/withRole/WithPermission";
import { Link } from "react-router-dom";
import { SVG } from "../../common/icons/Icon";
import ShareModel from "../../common/modal/ShareModel";

const statusColor = {
  INITIATE: {
    color: "#fff",
    backgroundColor: "skyblue",
  },
  REFUND_PENDING: {
    color: "red",
    backgroundColor: "#FFF2D6",
  },
  PENDING: {
    color: "grey",
    backgroundColor: "#FFF2D6",
  },
  SUCCESS: {
    color: "#fff",
    backgroundColor: "lightgreen",
  },
  REFUND_APPROVED: {
    color: "#fff",
    backgroundColor: "#b6c4d6",
  },
  REFUNDED: {
    color: "#fff",
    backgroundColor: "#b6c4d6",
  },
  REFUND_CANCELLED: {
    color: "#fff",
    backgroundColor: "#FDD7D7",
  },
  CANCELED: {
    color: "#fff",
    backgroundColor: "#FDD7D7",
  },
  FAILED: {
    color: "#F87171",
    backgroundColor: "#FDD7D7",
  },
};

const InvoiceTable = ({ moduleName = "invoice" }) => {
  useHeader("Invoices");
  const { t } = useTranslation();
  const [Tab, setTab] = useState(null);
  const [shareThis, setShareThis] = useState(null);
  //   HOOKS
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    status: "",
    created_at__date__gte: null,
    created_at__date__lte: null,
    search: null,
    page: 1,
  });

  const {
    data,
    isLoading: areKeysLoading,
    refetch,
  } = useQuery(
    [
      "getInvoices",
      `?${filters.search}${filters.page}${filters.status}${filters.created_at__date__gte}${filters.created_at__date__lte}`,
    ],
    () => getInvoices(filters),
    {
      retry: 1,
    }
  );
  const { mutate: cancelKey } = useMutation(
    (values) => cancelInvoiceById(values),
    {
      onSuccess: () => {
        refetch();
        notify(t("invoiceCancelled"), "success");
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );
  return areKeysLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <>
      {/* <div className="widgets mb-6">
        <RoundedPillWidget img={numberIcon} label="INVOICES" value={data.stats?.total_invoices} />
        <RoundedPillWidget img={successIcon} label="SUCCESSFULL" value={data.stats?.successfull_invoices} />
        <RoundedPillWidget
          img={unSuccessIcon}
          label="UNSSUCCESSFULL"
          value={data.stats?.cancelled_invoices}
        />
        <RoundedPillWidget img={pendingIcon} label="PENDING" value={data.stats?.pending_invoices} />
      </div> */}
      <div className="flex flex-wrap justify-between my-8">
        <div className="flex searchBar md:w-1/2">
          <FilterBar
            moduleName={moduleName}
            filters={filters}
            onFilterChange={(filter) => setFilters({ ...filters, ...filter })}
            options={[
              { value: "", label: t("all") },
              { value: "INITIATE", label: t("INITIATE") },
              { value: "PENDING", label: t("PENDING") },
              { value: "PROCESSED", label: t("SUCCESS") },
              { value: "FAILED", label: t("FAILED") },
              { value: "CANCELED", label: t("CANCELED") },
              { value: "REFUND_PENDING", label: t("REFUND_PENDING") },
              { value: "REFUND_CANCELLED", label: t("REFUND_APPROVED") },
            ]}
          />
        </div>
        <div className="md:w-1/3.5">
          <div className="barOptions">
            {/* <button className="rounded-btn p-2">{SVG.fileDownload}</button> */}
            {hasPermission(moduleName)("add") && (
              <Link to="/add-invoice">
                <button data-cy="add-btn" className="btn-orange" type="button">
                  {t("addInvoice")}
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="boarding-card p-0 max-w-none">
        <Table
          moduleName={moduleName}
          showStatus={false}
          allowDelete={false}
          allowCancel={true}
          columns={[
            {
              lable: "Invoice No",
              accessor: "public_id",
              copyable: true,
            },
            {
              lable: "Requested",
              accessor: "created_at",
            },
            {
              lable: "Customer",
              accessor: "customer",
            },
            {
              lable: `Amount(${CURRENCY_SYMBOL})`,
              accessor: "amount",
            },
            {
              lable: "Type",
              accessor: "type",
            },
            {
              lable: "Status",
              accessor: "status",
              chip: true,
            },
            {
              lable: "Link",
              accessor: "link",
              custom: true,
            },
            {
              lable: "Cancel",
              accessor: "cancel",
              custom: true,
              customNA: "",
            },
          ]}
          rows={(data?.results ?? []).map(
            ({ public_id, created_at, email, amount, type, status, id }) => ({
              id: id,
              public_id,
              created_at:
                new Date(created_at)
                  .toDateString()
                  .split(" ")
                  .slice(1)
                  .join(" ") +
                ", " +
                new Date(created_at).toLocaleTimeString(),
              customer: email,
              amount: dAmount(amount),
              type,
              status: {
                value: status,
                color: statusColor[status]?.color,
                backgroundColor: statusColor[status]?.backgroundColor,
              },
              cancel:
                status === "INITIATE" &&
                (() => (
                  <div className="text-center">
                    <button
                      data-cy="delete-btn"
                      type="button"
                      className="table-action"
                      onClick={(e) => {
                        e.stopPropagation();
                        status !== "CANCELED" &&
                          cancelKey({ invoice_id: public_id });
                      }}
                    >
                      {SVG.cancel}
                    </button>
                  </div>
                )),
              link: () => (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShareThis(public_id);
                  }}
                >
                  {SVG.share}
                </button>
              ),
            })
          )}
          onEditClicked={(rowData) => {
            rowData.type === "SIMPLE" && setTab(rowData.id);
            rowData.type === "PROFESSIONAL" &&
              navigate(`/edit-professional-invoice/${rowData.id}`);
          }}
          onRowClicked={(rowData) => {
            rowData.type === "SIMPLE" && setTab(rowData.id);
            rowData.type === "PROFESSIONAL" &&
              navigate(`/edit-professional-invoice/${rowData.id}`);
          }}
          dataCount={data?.count}
          activePage={filters.page}
          onPageClicked={(page) => {
            setFilters({ ...filters, page: page });
          }}
        />
      </div>
      {Tab && (
        <Modal close={() => setTab(null)}>
          <SimInvoiceEdit
            title={t("invoiceCustomer")}
            id={Tab}
            onSuccess={() => refetch()}
          />
        </Modal>
      )}
      {shareThis && (
        <Modal modalSize="share-model" close={() => setShareThis(false)}>
          <ShareModel id={shareThis} />
        </Modal>
      )}
    </>
  );
};

export default WithPermission("invoice")("view")(InvoiceTable);
