import React from "react";
import logoImg from "../../images/sappayPurple-logo.svg";
import Label from "../../common/form-controls/Label";
import Input from "../../common/form-controls/Input";
import Button from "../../common/form-controls/Button";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { notify } from "../../common/toaster/Toaster";
import { getServerError } from "../../common/utils/helpers";
import { resetPassword } from "../../common/api";
import ButtonLoader from "../../common/form-controls/ButtonLoader";
import { ResetPasswordSchema } from "./validation-schema";
import classNames from "classnames";

const ResetPassword = () => {
  const { uid, token } = useParams();

  const navigate = useNavigate();

  const { isLoading, mutate: resetPasswordFn } = useMutation(
    (values) => resetPassword(values),
    {
      onSuccess: (response) => {
        notify(response.data.detail);
        setTimeout(() => {
          // TODO: condition based
          navigate("/compliance", { replace: true });
        }, 2000);
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );

  const formik = useFormik({
    initialValues: { new_password1: "", new_password2: "", uid, token },
    onSubmit: resetPasswordFn,
    validationSchema: ResetPasswordSchema,
    validateOnChange: false,
  });

  return (
    <section className="signup signBg grid items-center">
      <div className="signup-content">
        <div className="form-card">
          <div className="logoImg">
            <img src={logoImg} alt="logo" />
          </div>
          <h3>Reset Password</h3>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <Label>New Password</Label>
              <Input
                required
                type="password"
                placeholder="New Password"
                name="new_password1"
                onChange={formik.handleChange}
                value={formik.values.new_password1}
                className={classNames({
                  "is-invalid": formik.errors.new_password1,
                })}
              />
              {formik.errors.new_password1 && (
                <span className="form-error">
                  {formik.errors.new_password1}
                </span>
              )}
            </div>
            <div className="form-group">
              <Label>Confirm Password</Label>
              <Input
                required
                type="password"
                placeholder="Confirm Password"
                name="new_password2"
                onChange={formik.handleChange}
                value={formik.values.new_password2}
                className={classNames({
                  "is-invalid": formik.errors.new_password2,
                })}
              />
              {formik.errors.new_password2 && (
                <span className="form-error">
                  {formik.errors.new_password2}
                </span>
              )}
            </div>
            <Button
              className={classNames("md:mb-6 mb-4 mt-2 disabled:opacity-50")}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <ButtonLoader text={"Resetting"} />
              ) : (
                "Reset Password"
              )}
            </Button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
