import classNames from "classnames";
import React from "react";
import { BeatLoader } from "react-spinners";
import Input from "../../common/form-controls/Input";
import Label from "../../common/form-controls/Label";
import { useHeader } from "../context/UserProvider";
import RoundedPillWidget from "../widgets/RoundedPillWidget";
import numberIcon from "../../images/numbericon.svg";
import currencyIcon from "../../images/totalicon.svg";
import successIcon from "../../images/successfultransactionsicon.svg";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getTransactionById } from "../../common/api";
import { amount } from "../../common/utils/helpers";

const TransactionDetail = () => {
  useHeader("transactionDetail");
  const { id } = useParams();

  const { data, isLoading } = useQuery(["getTransactionById", id], () =>
    getTransactionById(id)
  );

  return isLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <>
      <div className="widgets mb-6">
        <RoundedPillWidget
          img={numberIcon}
          label="ID"
          value={data.response.transaction_id}
        />
        <RoundedPillWidget
          img={currencyIcon}
          label="AMOUNT"
          value={amount(data.response.amount)}
        />
        <RoundedPillWidget
          img={currencyIcon}
          label="FEES"
          value={amount(0.0)}
        />
        <RoundedPillWidget
          img={successIcon}
          label="STATUS"
          value={data.response.status.toLocaleLowerCase()}
        />
      </div>
      <div className="tab-content">
        <div className="section-head">
          <h1>Detail</h1>
        </div>
        <div className="detail">
          <div className="flex flex-wrap -mx-3 justify-between">
            <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
              <Label>OFFLINE REFERENCE</Label>
              <Input disabled value={data.response.invoice} />
            </div>
            <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
              <Label>CHANNEL</Label>
              <Input disabled value={data.response.payment_processor?.name} />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 justify-between">
            <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
              <Label>PAYMENT DATE</Label>
              <Input
                disabled
                value={new Date(data.response.created_at)
                  .toDateString()
                  .split(" ")
                  .slice(1)
                  .join(" ")}
              />
            </div>
            <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
              <Label>PAYMENT TIME (UTC)</Label>
              <Input
                disabled
                value={new Date(data.response.created_at).toLocaleString(
                  "en-US",
                  { hour: "numeric", minute: "numeric", hour12: true }
                )}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 justify-between">
            <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
              <Label>CUSTOMER</Label>
              <Input disabled value={data.response.email} />
            </div>
            <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
              <Label>MESSAGE</Label>
              <Input
                type="textarea"
                disabled
                value={data.response.meta.message ?? "N/A"}
              />
            </div>
          </div>
          <a
            href={`/customers/${data.response.customer_id}`}
            className="btn-primary text-center block w-full mb-4"
          >
            View Customer Detail
          </a>
        </div>
      </div>
    </>
  );
};

export default TransactionDetail;
