import ChartComponent from "../chart/Chart";
import React, { useState } from "react";
// import classNames from "classnames";
import { useQuery } from "react-query";
import { allStats } from "../../../common/api";
import { getServerError } from "../../../common/utils/helpers";
import { notify } from "../../../common/toaster/Toaster";
import DashboardCard from "../../../common/dashboardcard/DashboardCard";
import { IconThree } from "../../../common/icons/Icon";
import { IconTwo } from "../../../common/icons/Icon";
import { IconOne } from "../../../common/icons/Icon";
import { useDarkMode } from "usehooks-ts";

const Portfolio = () => {
  // const statsTypeValues = [
  //   { label: "1D", value: "one_day" },
  //   { label: "1W", value: "one_week" },
  //   { label: "1M", value: "one_month" },
  //   { label: "3M", value: "three_month" },
  //   { label: "1Y", value: "one_year" },
  //   { label: "All", value: "one_year" },
  // ];
  
  // eslint-disable-next-line
  const [statsType, setStatsType] = useState({
    label: "All",
    value: "one_year",
  });
  const { data, isSuccess, isLoading } = useQuery(
    ["allStatus", statsType.value],
    allStats,
    {
      retry: 1,
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );
  const { isDarkMode } = useDarkMode();

  return (
    <div className="dashboard">
      <div className="card-heading maxMd:mx-4">
        <h3 className="text-xl capitalize dark:text-brightgray font-gotham-regular">
          Overview
        </h3>
      </div>
      {data && !Array.isArray(data.response) && (
        <div className="dashboard_content">
          <DashboardCard
            price={`$${data.response.portfolio_value.toFixed(2)}`}
            worth={"Net Worth"}
            cardIcon={<IconTwo svgColor={isDarkMode ? "#fff" : "#CA8700"} />}
            percentage={data.response.portfolio_change_percentage}
            value={data.response.portfolio_change_value.toFixed(2)}
          />
          <DashboardCard
            price={Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(data.response[`one_day_portfolio_change_in_value`])}
            worth={"Change (1D)"}
            cardIcon={<IconOne svgColor={isDarkMode ? "#fff" : "#33B4B9"} />}
            percentage={data.response[`one_day_portfolio_change_in_percentage`]}
          />
          <DashboardCard
            price={Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(
              data.response[`${statsType.value}_portfolio_change_in_value`]
            )}
            worth={"Change (1Y)"}
            cardIcon={<IconThree svgColor={isDarkMode ? "#fff" : "#483994"} />}
            percentage={
              data.response[`${statsType.value}_portfolio_change_in_percentage`]
            }
          />
        </div>
      )}
      <div className="dashboard-card">
        <ChartComponent
          statsType={statsType.value}
          data={data}
          isLoading={isLoading}
          isSuccess={isSuccess}
        />
      </div>
    </div>
  );
};
export default Portfolio;
