import React, { useState } from "react";
import Input from "../../common/form-controls/Input";
import Label from "../../common/form-controls/Label";
import { useFormik } from "formik";
import { BeneficiarySchema } from "./validation-schema";
import Success from "../../common/modal/Success";
import { SVG } from "../../common/icons/Icon";
import { useMutation } from "react-query";
import { notify } from "../../common/toaster/Toaster";
import { addBeneficiary } from "../../common/api";
import ButtonLoader from "../../common/form-controls/ButtonLoader";
import BussinessSelect from "../customers/components/BussinessSelect";
import { useUser } from "../context/UserProvider";
import { useTranslation } from "react-i18next";
const AddBeneficiaryModal = ({ title, onSuccess, close }) => {
  const { t } = useTranslation();
  const [Tab, setTab] = useState(0);
  const { user } = useUser();

  const { isLoading, mutate } = useMutation(
    (values) => addBeneficiary(values),
    {
      onSuccess: () => {
        setTab(2);
        setTimeout(function () {
          notify(t("beneficiaryCreated"), "success");
          onSuccess();
          close();
        }, 3000);
      },
      onError: (error) => {
        Object.entries(error.response.data.error).map(([key, value]) =>
          formik.setFieldError(key, value.error_string)
        );
        setTab(0);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      bank_name: "",
      iban: "",
      business: user?.user_role === "Admin" ? "" : "",
    },
    onSubmit: (values) => mutate(values),
    validationSchema: BeneficiarySchema,
    validateOnChange: false,
  });

  return (
    <div className="invoiceModal">
      {Tab === 0 && (
        <>
          <div className="modalIcon">{SVG.message}</div>
          <h3 style={{ marginBottom: "2rem" }}>{title}</h3>
          <form>
            <BussinessSelect
              className="flex flex-wrap mb-6"
              name="business"
              value={parseInt(formik.values.business)}
              onChange={(value) =>
                formik.setFieldValue("business", value.value)
              }
              error={formik.errors.business}
            />
            <div className="form-group">
              <Label>{t("name")}</Label>
              <Input
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              {formik.errors.name && (
                <span className="form-error">{formik.errors.name}</span>
              )}
            </div>
            <div className="form-group">
              <Label>{t("bankName")}</Label>
              <select
                name="bank_name"
                onChange={formik.handleChange}
                value={formik.values.bank_name}
                className="form-control"
              >
                <option value="">{t("select")}</option>
                <option value="BANK OF AFRICA - BURKINA FASO">
                  BANK OF AFRICA BURKINA FASO
                </option>
                <option value="BANQUE AGRICOLE DU FASO">
                  BANQUE AGRICOLE DU FASO
                </option>
                <option value="BANQUE ATLANTIQUE BURKINA FASO">
                  BANQUE ATLANTIQUE BURKINA FASO
                </option>
                <option value="BANQUE COMMERCIALE DU BAURKINA">
                  BANQUE COMMERCIALE DU BAURKINA
                </option>
                <option value="BANQUE DE L'UNION - BURKINA FASO">
                  BANQUE DE L'UNION - BURKINA FASO
                </option>
                <option value="BANQUE POSTALE DU BURKINA FASO">
                  BANQUE POSTALE DU BURKINA FASO
                </option>
                <option value="BANQUE SAHELO-SAHARIENNE POUR L'INVESTISSEMENT ET LE COMMERCE - BURKINA">
                  BANQUE SAHELO-SAHARIENNE POUR L'INVESTISSEMENT ET LE COMMERCE
                  - BURKINA
                </option>
                <option value="CORIS BANK INTERNATIONAL">
                  CORIS BANK INTERNATIONAL
                </option>
                <option value="ECOBANK - BURKINA">ECOBANK - BURKINA</option>
                <option value="INTERNATIONAL BUSINESS BANK BURKINA">
                  INTERNATIONAL BUSINESS BANK BURKINA
                </option>
                <option value="SOCIETE GENERALE - BURKINA FASO">
                  SOCIETE GENERALE - BURKINA FASO
                </option>
                <option value="UNITED BANK FOR AFRICA BURKINA">
                  UNITED BANK FOR AFRICA BURKINA
                </option>
                <option value="VISTA BANK BURKINA">VISTA BANK BURKINA</option>
                <option value="WENDKUNI BANK INTERNATIONAL">
                  WENDKUNI BANK INTERNATIONAL
                </option>
                <option value="CBAO, GROUPE ATTIJARIWAFA BANK, SUCCURSALE DU BURKINA">
                  CBAO, GROUPE ATTIJARIWAFA BANK, SUCCURSALE DU BURKINA
                </option>
                <option value="ORABANK - COTE D'IVOIRE, SUCCURSALE DU BURKINA">
                  ORABANK - COTE D'IVOIRE, SUCCURSALE DU BURKINA
                </option>
              </select>
              {formik.errors.bank_name && (
                <span className="form-error">{formik.errors.bank_name}</span>
              )}
            </div>
            <div className="form-group">
              <Label>{t("AccountNumber")}</Label>
              <Input
                name="iban"
                onChange={formik.handleChange}
                value={formik.values.iban}
              />
              {formik.errors.iban && (
                <span className="form-error">{formik.errors.iban}</span>
              )}
            </div>
            <button
              data-cy="validate-account"
              className="btn-orange"
              type="button"
              onClick={() => {
                formik
                  .validateForm()
                  .then(
                    (errors) => Object.keys(errors).length === 0 && setTab(1)
                  );
              }}
            >
              {t("validateAccount")}
            </button>
            <button
              style={{ width: "100%" }}
              className="btn-brightgray mt-4"
              type="button"
              onClick={close}
            >
              {t("close")}
            </button>
          </form>
        </>
      )}
      {Tab === 1 && (
        <>
          <div className="modalIcon">{SVG.review}</div>
          <h3>{t("reviewRequest")}</h3>
          <form onSubmit={formik.handleSubmit}>
            <div className="modalInline">
              <h4>
                <b>{t("bank")}</b> <span>{formik.values.bank_name}</span>
              </h4>
              <h4>
                <b>{t("iban")}</b> <span>{formik.values.iban}</span>
              </h4>
            </div>
            <button className="btn-orange" type="submit">
              {isLoading ? (
                <ButtonLoader text="Sending" />
              ) : (
                t("confirmAndAddBeneficiary")
              )}
            </button>
            <button
              style={{ width: "100%" }}
              className="btn-brightgray mt-4"
              type="button"
              onClick={() => setTab(0)}
            >
              {t("editRequest")}
            </button>
          </form>
        </>
      )}
      {Tab === 2 && (
        <Success
          title={t("beneficiaryAdded")}
          msg={t("beneficiaryAddedSuccessfully")}
        />
      )}
    </div>
  );
};

export default AddBeneficiaryModal;
