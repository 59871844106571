const Button = ({ children,onClick, className, ...props }) => (
  <button className={className ? `btn-orange ${className}` : `btn-orange`} onClick={onClick} type="button" {...props}>
    {children}
  </button>
);

export default Button;



