import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { getAllGroups } from "../../../common/api";
import Selection from "../../../common/form-controls/Selection";

const GroupSelect = ({ value, onChange, ...props }) => {
  const { data, isLoading } = useQuery("getAllGroups", getAllGroups, {
    retry: 1,
  });

  const groupOptions = useMemo(() => {
    if (data?.response) {
      return data.response.map(({ id, name, permissions }) => ({
        value: id,
        label: name.replace(/_/g, " "),
        permissions,
      }));
    }
    return [];
  }, [data]);

  return (
    !isLoading && (
      <Selection
        isMulti={true}
        options={groupOptions}
        value={value}
        onChange={onChange}
        {...props}
      />
    )
  );
};

export default GroupSelect;
