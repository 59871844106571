import React, { useState } from "react";
import Input from "../../common/form-controls/Input";
import Label from "../../common/form-controls/Label";
import Button from "../../common/form-controls/Button";
import { useHeader } from "../context/UserProvider";
import { addRefundData } from "../../common/api";
import classNames from "classnames";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { amount } from "../../common/utils/helpers";
import ButtonLoader from "../../common/form-controls/ButtonLoader";
import { RefundSchema } from "./validation-schema";
import { notify } from "../../common/toaster/Toaster";
import Modal from "../../common/modal/Modal";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../context/UserProvider";
import { SVG } from "../../common/icons/Icon";
import Success from "../../common/modal/Success";
import { useTranslation } from "react-i18next";

const AddSimple = () => {
  const { t } = useTranslation();
  useHeader("simpleRefund");
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const [Tab, setTab] = useState(null);
  const { user } = useUser();

  const { isLoading, mutate } = useMutation((values) => addRefundData(values), {
    onSuccess: (data) => {
      setTab(2);
      setTimeout(function () {
        notify(t("simpleRefundCreated"), "success");
        navigate("/refunds");
      }, 3000);
    },
    onError: (error) => {
      Object.entries(error.response.data.error).map(([key, value]) =>
        notify(value.error_string, "error")
      );
    },
  });
  const formik = useFormik({
    initialValues: {
      invoice: data.id,
      total_amount: data.amount,
      status: "PENDING",
      note: data.note,
      business: data.business,
    },
    onSubmit: (values) => mutate(values),
    validationSchema: RefundSchema,
    validateOnChange: false,
  });

  return (
    <section className="addRefund grid md:grid-cols-2">
      <form>
        <div className="heading-lg" style={{ marginBottom: "2rem" }}>
          {t("fillDetail")}
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label>{t("invoiceId")}</Label>
            <Input
              placeholder="Invoice ID"
              name="public_id"
              value={data.public_id}
            />
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label>{t("email")}</Label>
            <Input placeholder="Email" name="email" value={data.email} />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label>{t("invoiceAmount")}</Label>
            <Input
              placeholder="Invoice Amount"
              name="invoice_amount"
              type="number"
              value={data.amount}
            />
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label>{t("refundAmount")}</Label>
            <Input
              placeholder="Amount"
              name="total_amount"
              type="number"
              onChange={formik.handleChange}
              value={data.amount}
              className={classNames({
                "is-invalid": formik.errors.total_amount,
              })}
              disabled
            />
            {formik.errors.total_amount && (
              // <span className="form-error">{formik.errors.total_amount}</span>
              <span className="form-error">Amount is required</span>
            )}
          </div>
        </div>
        {user.user_role === "Admin" && (
          <div className="form-group">
            <Label>Status</Label>
            <select
              className="form-control"
              name="status"
              onChange={formik.handleChange}
              value={formik.values.status}
            >
              <option value="PENDING">Pending</option>
              <option value="APPROVED">Approved</option>
              <option value="REJECTED">Rejected</option>
            </select>
          </div>
        )}
        <div className="form-group">
          <Label>{t("note")}</Label>
          <textarea
            placeholder={t("customerNote")}
            name="note"
            onChange={formik.handleChange}
            value={t("newInvoiceadded")}
            className={
              classNames({ "is-invalid": formik.errors.note }) + "form-control"
            }
          />
          {formik.errors.email && (
            <span className="form-error">{formik.errors.note}</span>
          )}
        </div>
        <Button
          data-cy="save"
          className={classNames("md:mb-6 mb-4 disabled:opacity-50")}
          type="button"
          onClick={() => {
            formik
              .validateForm()
              .then((errors) => Object.keys(errors).length === 0 && setTab(1));
          }}
          disabled={isLoading}
        >
          {t("save")}
        </Button>
      </form>
      {Tab === 1 && (
        <Modal close={() => setTab(0)}>
          <div className="invoiceModal">
            <div className="modalIcon">{SVG.review}</div>
            <h3>{t("reviewRefund")}</h3>
            <form onSubmit={formik.handleSubmit}>
              <div className="modalInline">
                <h4>
                  <b>{t("againstId")}</b> <span>{formik.values.invoice}</span>
                </h4>
                <h4>
                  <b>{t("sender")}</b> <span>{user.email}</span>
                </h4>
                <h4>
                  <b>{t("receiverInvoice")}</b>{" "}
                  <span>{data.customer.email}</span>
                </h4>
                <h4>
                  <b>{t("refundAmount")}</b>{" "}
                  <span>{amount(formik.values.total_amount)}</span>
                </h4>
              </div>
              <button className="btn-orange" type="submit">
                {isLoading ? <ButtonLoader text={t("sending")} /> : t("send")}
              </button>
              <button
                style={{ width: "100%" }}
                className="btn-brightgray mt-4"
                type="button"
                onClick={() => setTab(0)}
              >
                {t("editRequest")}
              </button>
            </form>
          </div>
        </Modal>
      )}
      {Tab === 2 && (
        <Modal close={() => navigate("/refunds")}>
          <Success title={t("requestSent")} msg={t("sentSuccess")} />
        </Modal>
      )}
    </section>
  );
};

export default AddSimple;
