import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useMemo,
} from "react";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import Label from "../../../common/form-controls/Label";
import classNames from "classnames";
import Input from "../../../common/form-controls/Input";
import Selection from "../../../common/form-controls/Selection";
import { ProfileSchema } from "../../profile-data-form/children/profile-form/validation-schema";
import Button from "../../../common/form-controls/Button";
import { notify } from "../../../common/toaster/Toaster";
import { DocumentsSchema } from "../../profile-data-form/children/documents-form/validation-schema";
import { AccountSchema } from "../../profile-data-form/children/account-form/validation-schema";
import TextArea from "../../../common/form-controls/TextArea";
import { useQuery } from "react-query";
import { getAllCountries, setOrganization } from "../../../common/api";
import { getServerError } from "../../../common/utils/helpers";
import { useTranslation } from "react-i18next";
import CitySelect from "../../signup/components/CitySelect";
import Table from "../../../common/table/Table";
import { SVG } from "../../../common/icons/Icon";

const ProfileTab = ({ data: { response } }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [isEditable, setIsEditable] = useState(
    new URLSearchParams(search).get("edit") ?? false
  );
  const profileRef = useRef(null);
  const documentsRef = useRef(null);
  const accountRef = useRef(null);

  const handleSubmit = () => {
    // run function in children & update the "data"
    const { profile, isValid: isProfileValid } = profileRef.current;
    const { isValid: areDocumentsValid } = documentsRef.current;
    const { bank_account, isValid: isBankValid } = accountRef.current;

    if (isProfileValid && areDocumentsValid && isBankValid) {
      setOrganization(response.profile.id, {
        profile,
        // documents,
        bank_account,
      }).then(
        () => {
          setIsEditable(false);
          notify(t("profileUpdatedSuccessfully"), "success");
        },
        (error) => notify(getServerError(error), "error")
      );
    } else {
      notify("Please fill all the fields properly", "error");
    }
  };

  return (
    <div className="tab-content">
      <BussinessSection
        ref={profileRef}
        initialData={{
          business_name: response.profile.business_name,
          business_type: response.profile.business_type,
          phone: response.profile.phone,
          email: response.profile.email,
          website: response.profile.website,
          description: response.profile.description,
          address: response.profile.address,
          country: response.profile.country,
          city: response.profile.city,
          status: response.profile.status,
        }}
        editable={isEditable}
        onEditClick={() => setIsEditable(!isEditable)}
      />

      <AccountsSection
        ref={accountRef}
        initialData={{
          account_type: "Bank",
          account_name: response.bank_account.account_name,
          account_ibn: response.bank_account.account_ibn,
          bank_name: response.bank_account.bank_name,
          branch_code: response.bank_account.branch_code,
          card_number: response.bank_account.card_number,
          card_expiry_date: response.bank_account.card_expiry_date,
          cvv: response.bank_account.cvv,
        }}
        editable={isEditable}
      />
      <DocumentSection
        ref={documentsRef}
        initialData={{
          business_logo: response.documents.business_logo,
          identification_document_front:
            response.documents.identification_document_front,
          identification_document_back:
            response.documents.identification_document_back,
        }}
        created_at={response.documents.created_at}
        editable={isEditable}
      />
      {isEditable && <Button onClick={handleSubmit}>{t("save")}</Button>}
    </div>
  );
};

export default ProfileTab;

export const BussinessSection = forwardRef(
  ({ initialData, editable, onEditClick }, ref) => {
    const formik = useFormik({
      initialValues: initialData,
      validationSchema: ProfileSchema,
      validateOnChange: true,
    });

    useImperativeHandle(ref, () => ({
      profile: formik.values,
      isValid: formik.isValid,
    }));
    const { t } = useTranslation();
    const { data: countriesRes } = useQuery(
      "getAllCountries",
      getAllCountries,
      {
        retry: 1,
      }
    );

    const countryOptions = useMemo(() => {
      if (countriesRes?.data) {
        return countriesRes.data?.response.map(({ id, name }) => ({
          value: id,
          label: name,
        }));
      }
      return [];
    }, [countriesRes]);

    return (
      <div className="section-holder">
        <div className="section-head">
          <h1>{t("profileDetail")}</h1>
          <button className="edit-btn" onClick={onEditClick}>
            {editable ? t("cancel") : t("edit")}
          </button>
        </div>
        <form>
          <div className="form-group">
            <Label>{t("status")}</Label>
            <div
              className={classNames([
                "form-control",
                {
                  "is-invalid": formik.errors.status,
                  disabled: !editable,
                  "bg-green-400": formik.values.status === "Approved",
                  "bg-amber-300": formik.values.status === "Pending",
                  "bg-red-400": formik.values.status === "Rejected",
                },
              ])}
            >
              <Selection
                className={classNames([
                  "data-cy-status",
                  {
                    "bg-green-400": formik.values.status === "Approved",
                    "bg-amber-300": formik.values.status === "Pending",
                    "bg-red-400": formik.values.status === "Rejected",
                  },
                ])}
                textColor="#fff"
                disabled={!editable}
                placeholder="Select Bussiness Status"
                options={[
                  { value: "Approved", label: t("approved") },
                  { value: "Pending", label: t("pending") },
                  { value: "Rejected", label: t("rejected") },
                ]}
                value={formik.values.status}
                onChange={(value) =>
                  formik.setFieldValue("status", value.value)
                }
              />
            </div>
            {formik.errors.status && (
              <span className="form-error">{formik.errors.status}</span>
            )}
          </div>

          {formik.values.status === "Rejected" && (
            <div className="form-group">
              <Label>Reason of Rejection</Label>
              <TextArea
                className={classNames([
                  {
                    "is-invalid": formik.errors.rejection_reason,
                  },
                ])}
                disabled={!editable}
                placeholder="The documents are not valid"
                name="rejection_reason"
                onChange={formik.handleChange}
                value={formik.values.rejection_reason}
              />
              {formik.errors.rejection_reason && (
                <span className="form-error">
                  {formik.errors.rejection_reason}
                </span>
              )}
            </div>
          )}

          <div className="flex flex-wrap -mx-3 justify-between">
            <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
              <Label>{t("bussinessName")}</Label>
              <div
                className={classNames([
                  {
                    "is-invalid": formik.errors.business_name,
                  },
                ])}
              >
                <Input
                  disabled={!editable}
                  placeholder={t("bussinessName")}
                  name="business_name"
                  onChange={formik.handleChange}
                  value={formik.values.business_name}
                />
              </div>
              {formik.errors.business_name && (
                <span className="form-error">
                  {formik.errors.business_name}
                </span>
              )}
            </div>

            <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
              <Label>{t("bussinessType")}</Label>
              <div
                className={classNames([
                  "form-control",
                  {
                    "is-invalid": formik.errors.business_type,
                  },
                ])}
              >
                <Selection
                  disabled={!editable}
                  placeholder="Select Bussiness Type"
                  options={[
                    { value: "Individual", label: t("individual") },
                    { value: "Private Sector", label: t("privateSector") },
                    { value: "Public Sector", label: t("publicSector") },
                    { value: "NGOs", label: t("NGOs") },
                  ]}
                  value={formik.values.business_type}
                  onChange={(value) =>
                    formik.setFieldValue("business_type", value.value)
                  }
                />
              </div>
              {formik.errors.business_type && (
                <span className="form-error">
                  {formik.errors.business_type}
                </span>
              )}
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 justify-between">
            <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
              <Label>{t("bussinessEmail")}</Label>
              <div
                className={classNames([
                  {
                    "is-invalid": formik.errors.email,
                  },
                ])}
              >
                <Input
                  disabled={!editable}
                  placeholder="jonh@example.com"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </div>
              {formik.errors.email && (
                <span className="form-error">{formik.errors.email}</span>
              )}
            </div>
            <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
              <Label>{t("bussinessPhone")}</Label>
              <div
                className={classNames([
                  {
                    "is-invalid": formik.errors.phone,
                  },
                ])}
              >
                <Input
                  disabled={!editable}
                  placeholder="+1 (123) 456-7890"
                  name="phone"
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                />
              </div>
              {formik.errors.phone && (
                <span className="form-error">{formik.errors.phone}</span>
              )}
            </div>
          </div>

          <div className="form-group">
            <Label>{t("bussinessWebsite")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.website,
                },
              ])}
            >
              <Input
                disabled={!editable}
                placeholder="www.example.com"
                name="website"
                onChange={formik.handleChange}
                value={formik.values.website}
              />
            </div>
            {formik.errors.website && (
              <span className="form-error">{formik.errors.website}</span>
            )}
          </div>

          <div className="form-group">
            <Label>{t("description")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.description,
                },
              ])}
            >
              <TextArea
                disabled={!editable}
                placeholder={t("description")}
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
              />
            </div>
            {formik.errors.description && (
              <span className="form-error">{formik.errors.description}</span>
            )}
          </div>

          <div className="flex flex-wrap -mx-3  justify-start">
            <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
              <Label>{t("country")}</Label>
              <div
                className={classNames([
                  "form-control",
                  {
                    "is-invalid": formik.errors.country,
                  },
                ])}
              >
                <Selection
                  className="country-select"
                  disabled={!editable}
                  placeholder="Country Code"
                  options={countryOptions}
                  value={formik.values.country}
                  onChange={(value) =>
                    formik.setFieldValue("country", value.value)
                  }
                />
              </div>
              {formik.errors.country && (
                <span className="form-error">{formik.errors.country}</span>
              )}
            </div>
            <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
              <Label>{t("city")}</Label>
              <div
                className={classNames([
                  "form-control",
                  {
                    "is-invalid": formik.errors.city,
                  },
                ])}
              >
                <CitySelect
                  disabled={!editable}
                  className={`required ${
                    formik.errors.city ? "is-invalid" : ""
                  } city-select`}
                  countryId={formik.values.country}
                  onChange={(value) => formik.setFieldValue("city", value)}
                  value={formik.values.city}
                />
              </div>
              {formik.errors.city && (
                <span className="form-error">{formik.errors.city}</span>
              )}
            </div>
          </div>

          <div className="form-group">
            <Label>{t("streetAddress")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.address,
                },
              ])}
            >
              <Input
                disabled={!editable}
                placeholder={t("houseNumber")}
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address}
              />
            </div>
            {formik.errors.street_address && (
              <span className="form-error">{formik.errors.address}</span>
            )}
          </div>
        </form>
      </div>
    );
  }
);

export const DocumentSection = forwardRef(
  ({ initialData, editable, created_at }, ref) => {
    const { t } = useTranslation();
    const formik = useFormik({
      initialValues: initialData,
      validationSchema: DocumentsSchema,
      validateOnChange: true,
    });

    useImperativeHandle(ref, () => ({
      documents: formik.values,
      isValid: formik.isValid,
    }));

    return (
      <div className="section-holder">
        <div className="section-head">
          <h1>{t("documents")}</h1>
        </div>
        <div className="mb-8">
          <Table
            allowEdit={false}
            allowDelete={false}
            showStatus={false}
            columns={[
              {
                lable: "FILE",
                accessor: "file",
              },
              {
                lable: "UPLOADED ON",
                accessor: "uploaded_on",
              },
              {
                lable: "VISIT",
                accessor: "visit",
                custom: true,
              },
            ]}
            rows={Object.entries(formik.initialValues).map(([key, value]) => ({
              file: key.replaceAll("_", " ").toUpperCase(),
              uploaded_on: new Date(created_at)
                .toDateString()
                .split(" ")
                .slice(1)
                .join(" "),
              visit: () => (
                <button
                  onClick={() => {
                    window.open(`${value}`, "_blank", "noreferrer");
                  }}
                >
                  {SVG.open_in_new}
                </button>
              ),
            }))}
          />
        </div>
      </div>
    );
  }
);

export const AccountsSection = forwardRef(({ initialData, editable }, ref) => {
  const formik = useFormik({
    initialValues: initialData,
    validationSchema: AccountSchema,
    validateOnChange: true,
  });
  const { t } = useTranslation();
  useImperativeHandle(ref, () => ({
    bank_account: formik.values,
    isValid: formik.isValid,
  }));

  return (
    <div className="section-holder">
      <div className="section-head">
        <h1 style={{ "text-transform": "capitalize" }}>
          {formik.values.account_type} {t("Account")}
        </h1>
      </div>
      <form>
        <div className="flex flex-wrap -mx-3 justify-between">
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("accountName")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.account_name,
                },
              ])}
            >
              <Input
                disabled={!editable}
                placeholder={t("accountName")}
                name="account_name"
                onChange={formik.handleChange}
                value={formik.values.account_name}
              />
            </div>
            {formik.errors.account_name && (
              <span className="form-error">{formik.errors.account_name}</span>
            )}
          </div>
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("accountIBAN")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.account_ibn,
                },
              ])}
            >
              <Input
                disabled={!editable}
                placeholder={t("accountIBAN")}
                name="account_ibn"
                maxLength={30}
                onChange={formik.handleChange}
                value={formik.values.account_ibn}
              />
            </div>
            {formik.errors.account_ibn && (
              <span className="form-error">{formik.errors.account_ibn}</span>
            )}
          </div>
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("bankName")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.bank_name,
                },
              ])}
            >
              <Input
                disabled={!editable}
                placeholder={t("bankName")}
                name="bank_name"
                onChange={formik.handleChange}
                value={formik.values.bank_name}
              />
            </div>
            {formik.errors.bank_name && (
              <span className="form-error">{formik.errors.bank_name}</span>
            )}
          </div>

          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("branchCode")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.branch_code,
                },
              ])}
            >
              <Input
                disabled={!editable}
                className="no-arrows"
                placeholder={t("branchCode")}
                name="branch_code"
                onChange={(e) =>
                  formik.setFieldValue(
                    "branch_code",
                    e.target.value.substring(0, 4)
                  )
                }
                value={formik.values.branch_code}
                type="number"
              />
            </div>
            {formik.errors.branch_code && (
              <span className="form-error">{formik.errors.branch_code}</span>
            )}
          </div>
        </div>
      </form>
    </div>
  );
});
