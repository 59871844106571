import { useState } from "react";

const TabNav = ({ heads = [] }) => {
  const [active, setActive] = useState(heads.length > 0 ? 0 : null);
  return (
    heads.length > 0 && (
      <div className="tab-based-componenet">
        <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
          <ul
            class="flex flex-wrap -mb-px text-sm font-medium text-center"
            id="myTab"
            data-tabs-toggle="#myTabContent"
            role="tablist"
          >
            {heads.map(({ tabName }, index) => (
              <li class="mr-2" role="presentation" key={index}>
                <button
                  class={`inline-block p-4 rounded-t-lg border-b-2 ${
                    active === index
                      ? "border-purple-700 text-purple-700"
                      : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                  }`}
                  id="profile-tab"
                  data-tabs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  onClick={() => setActive(index)}
                >
                  {tabName}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div id="myTabContent">
          <div
            class="p-4 rounded-lg "
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            {heads[active].tabContent}
          </div>
        </div>
      </div>
    )
  );
};

export default TabNav;
