import React, { useState } from "react";
import { deleteEvent, getAllEvents } from "../../common/api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Table from "../../common/table/Table";
import { useHeader, useUser } from "../context/UserProvider";
import { BeatLoader } from "react-spinners";
import WithPermission from "../../common/withRole/WithPermission";
// import { SVG } from "../../common/icons/Icon";
import FilterBar from "../payouts/components/FilterBar";
import { hasPermission } from "../../common/withRole/WithPermission";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Modal from "../../common/modal/Modal";
import DeleteModal from "../../common/modal/DeleteModal";
import { notify } from "../../common/toaster/Toaster";
import { getServerError } from "../../common/utils/helpers";
import { SVG } from "../../common/icons/Icon";
import ShareModel from "../../common/modal/ShareModel";
import { startCase } from "lodash";
const statusColor = {
  true: {
    color: "#fff",
    backgroundColor: "skyblue",
  },
  false: {
    color: "white",
    backgroundColor: "green",
  },
};

const FundRaisingTable = ({ moduleName = "invoice" }) => {
  //   HOOKS
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useUser();
  const queryClient = useQueryClient();
  const [shareThis, setShareThis] = useState(null);
  const [deleteThis, setDeleteThis] = useState(null);
  useHeader(t("fundRaising"));
  const [filters, setFilters] = useState({
    status: "",
    created_at__date__gte: null,
    created_at__date__lte: null,
    search: null,
    page: 1,
  });

  const columns = [
    {
      lable: "Reference No",
      accessor: "public_id",
      copyable: true,
    },
    {
      lable: "Campaign Name",
      accessor: "title",
    },
    {
      lable: "Business",
      accessor: "business_name",
    },
    {
      lable: "Target Meet",
      accessor: "collected_amount",
    },
    {
      lable: "Amount Required",
      accessor: "amount_required",
    },
    {
      lable: "End Date",
      accessor: "deadline",
    },
    {
      lable: "view Count",
      accessor: "view_count",
    },
    {
      lable: "Status",
      accessor: "is_published",
      chip: true,
    },
    {
      lable: "Link",
      accessor: "link",
      custom: true,
    },
  ];

  const { data, isLoading: areKeysLoading } = useQuery(
    [
      "getAllEvents",
      `?${filters.search}${filters.page}${filters.status}${filters.created_at__date__gte}${filters.created_at__date__lte}`,
    ],
    () => getAllEvents(filters),
    {
      retry: 1,
    }
  );

  const { isLoading, mutate: deleteMemberFn } = useMutation(
    () => deleteEvent(deleteThis),
    {
      onSuccess: () => {
        setDeleteThis(null);
        notify(t("campaignDeleted"), "success");
      },
      onSettled: () => queryClient.invalidateQueries("getAllEvents"),
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );

  return areKeysLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <>
      <div className="flex flex-wrap justify-between my-8">
        <div className="flex searchBar md:w-1/2">
          <FilterBar
            moduleName={moduleName}
            filters={filters}
            onFilterChange={(filter) => setFilters({ ...filters, ...filter })}
            /* options={[
              { value: "", label: t("all") },
              { value: "INITIATE", label: t("INITIATE") },
              { value: "PENDING", label: t("PENDING") },
              { value: "PROCESSED", label: t("PROCESSED") },
              { value: "FAILED: ", label: t("FAILED") },
              { value: "CANCELED", label: t("CANCELED") },
              { value: "REFUND_PENDING", label: t("REFUND_PENDING") },
              { value: "REFUNDED", label: t("REFUNDED") },
              { value: "REFUND_CANCELLED", label: t("REFUND_CANCELLED") },
            ]}*/
          />
        </div>
        <div className="md:w-1/3.5">
          <div className="barOptions">
            {/* <button className="rounded-btn p-2">{SVG.fileDownload}</button> */}
            {hasPermission(moduleName)("add") && (
              <Link to="/campaign/add">
                <button data-cy="add-btn" className="btn-orange" type="button">
                  {t("createEvent")}
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="boarding-card p-0 max-w-none">
        <Table
          moduleName={moduleName}
          allowDelete={true}
          allowCancel={true}
          showStatus={false}
          columns={
            user.user_role === "Admin"
              ? columns
              : columns.filter((column) => column.accessor !== "business_name")
          }
          dataCount={data?.count}
          activePage={filters.page}
          onPageClicked={(page) => {
            setFilters({ ...filters, page: page });
          }}
          rows={(data?.results ?? []).map(
            ({
              public_id,
              title,
              business_name,
              is_published,
              deadline,
              id,
              public_url,
              view_count,
              amount_required,
              collected_amount,
            }) => ({
              id,
              public_id,
              title: startCase(title),
              business_name,
              public_url,
              deadline,
              amount_required,
              collected_amount,
              view_count: `${view_count.toString()}`,
              is_published: {
                value: is_published ? "Active" : "Inactive",
                color: statusColor[is_published]?.color,
                backgroundColor: statusColor[is_published]?.backgroundColor,
              },

              //  status: is_published ? "Active" : "Inactive",
              //   isActive: is_published,

              link: () => (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShareThis(public_id);
                  }}
                >
                  {SVG.share}
                </button>
              ),
            })
          )}
          onRowClicked={({ id: campaignID }) => {
            navigate(`/event-detail/${campaignID}`);
          }}
          onEditClicked={({ id: campaignID }) => {
            navigate(`/event-detail/${campaignID}`);
          }}
          onDeleteClicked={({ id }) => {
            setDeleteThis(id);
          }}
        />
      </div>
      {deleteThis && (
        <Modal close={() => setDeleteThis(null)}>
          <DeleteModal
            title={t("campaign")}
            isLoading={isLoading}
            onClick={deleteMemberFn}
          />
        </Modal>
      )}

      {shareThis && (
        <Modal modalSize="share-model" close={() => setShareThis(false)}>
          <ShareModel id={shareThis} fundraising={true} />
        </Modal>
      )}
    </>
  );
};

export default WithPermission("invoice")("view")(FundRaisingTable);
