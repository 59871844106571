import React, { useEffect, useState } from "react";
import { useHeader, useUser } from "../context/UserProvider";
import RevenueChart from "./chart/merchant/RevenueChart";
// import RefundChart from "./chart/merchant/RefundChart";
import TransactionsChart from "./chart/merchant/TransactionsChart";
// import InvoicesChart from "./chart/merchant/InvoicesChart";
import Selection from "../../common/form-controls/Selection";
import { amount } from "../../common/utils/helpers";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { getStats, getUser } from "../../common/api";
import { BeatLoader } from "react-spinners";
import BussinessSelect from "../customers/components/BussinessSelect";

const getDateBefore = (days) =>
  new Date(Date.now() - days * 24 * 60 * 60 * 1000).toISOString().split("T")[0];
const statCards = {
  "Commission Earned": {
    key: "total_earning",
    isAmount: true,
  },
  Balance: {
    key: "current_balance",
    isAmount: true,
  },
  "Total Payouts": {
    key: "total_payouts",
    isAmount: true,
  },
  "Pending Payouts": {
    key: "pending_payouts",
    isAmount: true,
  },
  Refunds: {
    key: "total_refunds",
    isAmount: true,
  },
  "Total Charges Paid": {
    key: "total_charges_paid",
    isAmount: true,
  },
  "Total Transaction Value": {
    key: "transaction_value",
    isAmount: true,
  },
  "Transaction Volume": {
    key: "transaction_volume",
    isAmount: false,
  },
  "Active Integration": {
    key: "active_api_count",
    isAmount: false,
  },
  "Total Employees": {
    key: "total_employees",
    isAmount: false,
  },
  "Active Merchants": {
    key: "total_active_merchants",
    isAmount: false,
  },
  "Inactive Merchants": {
    key: "total_inactive_merchants",
    isAmount: false,
  },
  "Offline Merchants": {
    key: "total_offline_merchants",
    isAmount: false,
  },
  "Total Churn Merchants": {
    key: "total_churn_merchants",
    isAmount: false,
  },
  "Integration Success Rate": {
    key: "integration_success_rate",
    isAmount: false,
  },
  "Support Tickets": {
    key: "technical_support_ticket",
    isAmount: false,
  },
  "Technical UpTime": {
    key: "technical_uptime",
    isAmount: false,
  },
  "Average Approval Time": {
    key: "time_to_approved",
    isAmount: false,
  },
  "API calls/hour": {
    key: "api_call_rate_per_hour",
    isAmount: false,
  },
  "API calls/day": {
    key: "api_call_rate_per_day",
    isAmount: false,
  },
  "API calls/month": {
    key: "api_call_rate_per_month",
    isAmount: false,
  },
};

const Dashboard = () => {
  useHeader("dashBoard");
  const { t } = useTranslation();
  const filterOption = [
    {
      value: getDateBefore(0),
      label: t("dashboard.filters.Today"),
    },
    {
      value: getDateBefore(7),
      label: t("dashboard.filters.This Week"),
    },
    {
      value: getDateBefore(30),
      label: t("dashboard.filters.Last 30 Days"),
    },
    {
      value: getDateBefore(90),
      label: t("dashboard.filters.Last 90 Days"),
    },
    {
      value: getDateBefore(365),
      label: t("dashboard.filters.This Year"),
    },
  ];
  const { setUser } = useUser();

  const [startDate, setStartDate] = useState(filterOption[2].value);
  const [bussiness, setBussiness] = useState(null);

  const handleChange = ({ value }) => {
    setStartDate(value);
  };

  const { data: stats, isLoading } = useQuery(
    ["stats", startDate, bussiness],
    () =>
      getStats({
        start_date: startDate,
        end_date: new Date().toISOString().split("T")[0],
        merchant: bussiness,
      })
  );

  useEffect(() => {
    getUser().then((data) => setUser(data));
  }, [setUser]);

  return (
    <div className="merchantDashboard">
      <div className="w-full mb-8 ml-auto flex justify-end gap-x-3.5 max-w-[50%]">
        <div className="form-control mt-2 p-1 rounded-3xl max-w-[50%]">
          <Selection
            placeholder="FILTERS"
            options={filterOption}
            value={startDate}
            onChange={(value) => handleChange(value)}
          />
        </div>
        <BussinessSelect
          showLabel={false}
          placeholder={t("selectBusiness")}
          className="form-control mt-2 p-1 rounded-3xl max-w-[50%]"
          onChange={({ value }) => setBussiness(value)}
          value={bussiness}
          defaultValue={true}
        />
      </div>
      {isLoading ? (
        <BeatLoader
          color="#7F2CEA"
          className="text-center"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -100%)",
          }}
        />
      ) : (
        <>
          <div className="flex flex-wrap -mx-3 mb-6">
            {Object.entries(statCards).map(([key, value]) => {
              const isPostitive = Math.random() < 0.5;
              if (
                stats?.response[value.key] !== undefined &&
                stats?.response[value.key] !== null
              ) {
                return (
                  <div
                    key={value.key}
                    className="w-full md:w-1/4 px-3 mb-6 md:mb-0"
                  >
                    <div class="shadow border rounded-lg text-gray-500 text-sm p-6 mb-3">
                      <h3 className="font-bold">{t(`dashboard.${key}`)}</h3>
                      <div className="flex justify-between text-black items-baseline">
                        <h1 className="text-xl font-bold mt-2 ">
                          {value.isAmount
                            ? amount(stats?.response[value.key])
                            : stats?.response[value.key]}
                        </h1>
                        <small
                          className={
                            isPostitive ? "text-green-600" : "text-red-600"
                          }
                        >
                          {/* {isPostitive ? "▲" : "▼"} 5% */}
                        </small>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-3/5 px-3 mb-6 md:mb-0">
              <RevenueChart
                data={stats?.response.total_revenue}
                totalRevenue={stats?.response.transaction_value}
              />
              {/* <RefundChart /> */}
            </div>
            <div className="w-full md:w-2/5 px-3 mb-6 md:mb-0">
              <TransactionsChart data={stats?.response.transaction_stats} />
              {/* <InvoicesChart /> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
