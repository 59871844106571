export const SVG = {
  compliance: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M12 22q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2q1.625 0 3.075.475 1.45.475 2.675 1.325L16.3 5.275q-.95-.6-2.025-.938Q13.2 4 12 4 8.675 4 6.338 6.337 4 8.675 4 12t2.338 5.663Q8.675 20 12 20q3.325 0 5.663-2.337Q20 15.325 20 12q0-.45-.05-.9t-.15-.875L21.425 8.6q.275.8.425 1.65.15.85.15 1.75 0 2.075-.788 3.9-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm-1.4-5.4-4.25-4.25 1.4-1.4 2.85 2.85 10-10.025 1.4 1.4Z" />
    </svg>
  ),
  fundRaising: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 96 960 960"
      width="24"
    >
      <path d="M480 427 354 301l126-126 126 126-126 126ZM40 896V736q0-29 20.5-49.5T110 666h141q17 0 32.5 8.5T310 698q29 42 74 65t96 23q51 0 96-23t75-65q11-15 26-23.5t32-8.5h141q29 0 49.5 20.5T920 736v160H660V777q-36 33-82.5 51T480 846q-51 0-97-18t-83-51v119H40Zm120-300q-45 0-77.5-32.5T50 486q0-46 32.5-78t77.5-32q46 0 78 32t32 78q0 45-32 77.5T160 596Zm640 0q-45 0-77.5-32.5T690 486q0-46 32.5-78t77.5-32q46 0 78 32t32 78q0 45-32 77.5T800 596Z" />
    </svg>
  ),
  dashboard: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill=""
    >
      <path
        d="M21 20.0003C21 20.2655 20.8946 20.5198 20.7071 20.7074C20.5196 20.8949 20.2652 21.0003 20 21.0003H4C3.73478 21.0003 3.48043 20.8949 3.29289 20.7074C3.10536 20.5198 3 20.2655 3 20.0003V9.49027C2.99989 9.33788 3.03462 9.18749 3.10152 9.05057C3.16841 8.91365 3.26572 8.79384 3.386 8.70027L11.386 2.47827C11.5615 2.34172 11.7776 2.26758 12 2.26758C12.2224 2.26758 12.4385 2.34172 12.614 2.47827L20.614 8.70027C20.7343 8.79384 20.8316 8.91365 20.8985 9.05057C20.9654 9.18749 21.0001 9.33788 21 9.49027V20.0003V20.0003ZM7 15.0003V17.0003H17V15.0003H7Z"
        fill=""
      />
    </svg>
  ),
  open_in_new: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        id="open_in_new_2"
        d="M2 14V2H8V3.33333H3.33333V12.6667H12.6667V8H14V14H2ZM6.46667 10.4667L5.53333 9.53333L11.7333 3.33333H9.33333V2H14V6.66667H12.6667V4.26667L6.46667 10.4667Z"
        fill="#9C6BFE"
      />
    </svg>
  ),
  emptyTable: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="129"
      height="129"
      viewBox="0 0 129 128"
      fill="none"
    >
      <g id="mdi:tab-plus">
        <path
          id="Vector"
          d="M16.4999 16C13.6709 16 10.9578 17.1238 8.95745 19.1242C6.95706 21.1246 5.83325 23.8377 5.83325 26.6667V101.333C5.83325 104.162 6.95706 106.875 8.95745 108.876C10.9578 110.876 13.6709 112 16.4999 112H112.5C115.329 112 118.042 110.876 120.042 108.876C122.043 106.875 123.167 104.162 123.167 101.333V26.6667C123.167 23.8377 122.043 21.1246 120.042 19.1242C118.042 17.1238 115.329 16 112.5 16H16.4999ZM16.4999 26.6667H69.8333V48H112.5V101.333H16.4999V26.6667ZM53.8333 53.3333V69.3333H37.8333V80H53.8333V96H64.4999V80H80.4999V69.3333H64.4999V53.3333H53.8333Z"
          fill="#EBEBEB"
        />
      </g>
    </svg>
  ),
  plus: (
    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
      <path d="M9.25 15v-4.25H5v-1.5h4.25V5h1.5v4.25H15v1.5h-4.25V15Z" />
    </svg>
  ),
  filter: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      style={{ marginTop: "-2px" }}
    >
      <path d="M3 18v-2h6v2Zm0-5v-2h12v2Zm0-5V6h18v2Z" />
    </svg>
    // <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
    //   <path d="M9.5 16q-.208 0-.354-.146T9 15.5v-4.729L4.104 4.812q-.187-.25-.052-.531Q4.188 4 4.5 4h11q.312 0 .448.281.135.281-.052.531L11 10.771V15.5q0 .208-.146.354T10.5 16Zm.5-6.375L13.375 5.5H6.604Zm0 0Z" />
    // </svg>
  ),
  wallets: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M22 6H15C13.4087 6 11.8826 6.63214 10.7574 7.75736C9.63214 8.88258 9 10.4087 9 12C9 13.5913 9.63214 15.1174 10.7574 16.2426C11.8826 17.3679 13.4087 18 15 18H22V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V6ZM15 8H23V16H15C13.9391 16 12.9217 15.5786 12.1716 14.8284C11.4214 14.0783 11 13.0609 11 12C11 10.9391 11.4214 9.92172 12.1716 9.17157C12.9217 8.42143 13.9391 8 15 8V8ZM15 11V13H18V11H15Z"
        fill=""
      />
    </svg>
  ),
  transactions: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3C2.44772 3 2 3.44772 2 4V20C2 20.5523 2.44772 21 3 21H21C21.5523 21 22 20.5523 22 20V4C22 3.44772 21.5523 3 21 3H3ZM14.6667 12V14.8H6.66667V16.2H14.6667V19L18 15.5L14.6667 12ZM9.33333 7.7993V5L6 8.5L9.33333 12V9.2H17.3333V7.8L9.33333 7.7993Z"
        fill=""
      />
    </svg>
  ),
  fileDownload: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M12 15.575q-.2 0-.375-.063-.175-.062-.325-.212l-3.6-3.6q-.275-.275-.275-.7 0-.425.275-.7.275-.275.712-.288.438-.012.713.263L11 12.15V5q0-.425.288-.713Q11.575 4 12 4t.713.287Q13 4.575 13 5v7.15l1.875-1.875q.275-.275.713-.263.437.013.712.288.275.275.275.7 0 .425-.275.7l-3.6 3.6q-.15.15-.325.212-.175.063-.375.063ZM6 20q-.825 0-1.412-.587Q4 18.825 4 18v-2q0-.425.287-.713Q4.575 15 5 15t.713.287Q6 15.575 6 16v2h12v-2q0-.425.288-.713Q18.575 15 19 15t.712.287Q20 15.575 20 16v2q0 .825-.587 1.413Q18.825 20 18 20Z" />
    </svg>
  ),
  email: (
    <svg
      viewBox="0 0 60 60"
      preserveAspectRatio="xMidYMid meet"
      focusable="false"
      style={{
        pointerEvents: "none",
        display: "block",
        width: "100%",
        height: "100%",
      }}
    >
      <g class="style-scope yt-icon">
        <g fill-rule="nonzero" fill="none" class="style-scope yt-icon">
          <path
            d="M28.4863253 59.9692983c-6.6364044-.569063-11.5630204-2.3269561-16.3219736-5.8239327C4.44376366 48.4721168 3e-7 39.6467924 3e-7 29.9869344c0-14.8753747 10.506778-27.18854591 25.2744118-29.61975392 6.0281072-.9924119 12.7038532.04926445 18.2879399 2.85362966C57.1386273 10.0389054 63.3436516 25.7618627 58.2050229 40.3239688 54.677067 50.3216743 45.4153135 57.9417536 34.81395 59.5689067c-2.0856252.3201125-5.0651487.5086456-6.3276247.4003916z"
            fill="#888"
            class="style-scope yt-icon"
          ></path>
          <path
            d="M40.531502 19.160814h-22c-1.74 0-2.986 1.2375-3 3v16c0 1.7625 1.26 3 3 3h22c1.74 0 3-1.2375 3-3v-16c0-1.7625-1.26-3-3-3zm0 6l-11 7-11-7v-3l11 7 11-7v3z"
            fill="#FFF"
            class="style-scope yt-icon"
          ></path>
        </g>
      </g>
    </svg>
  ),
  whatsApp: (
    <svg
      viewBox="0 0 60 60"
      preserveAspectRatio="xMidYMid meet"
      focusable="false"
      style={{
        pointerEvents: "none",
        display: "block",
        width: "100%",
        height: "100%",
      }}
    >
      <g class="style-scope yt-icon">
        <g fill="none" fill-rule="evenodd" class="style-scope yt-icon">
          <circle
            cx="30"
            cy="30"
            r="30"
            fill="#25D366"
            class="style-scope yt-icon"
          ></circle>
          <path
            d="M39.7746 19.3513C37.0512 16.5467 33.42 15 29.5578 15C21.6022 15 15.1155 21.6629 15.1155 29.8725C15.1155 32.4901 15.7758 35.0567 17.0467 37.3003L15 45L22.6585 42.9263C24.7712 44.1161 27.148 44.728 29.5578 44.728C37.5134 44.728 44 38.0652 44 29.8555C44 25.8952 42.498 22.1558 39.7746 19.3513ZM29.5578 42.2295C27.3956 42.2295 25.2829 41.6346 23.4508 40.5127L23.0051 40.2408L18.4661 41.4646L19.671 36.9093L19.3904 36.4334C18.1855 34.4618 17.5583 32.1841 17.5583 29.8555C17.5583 23.0397 22.9556 17.4986 29.5743 17.4986C32.7763 17.4986 35.7968 18.7904 38.0581 21.119C40.3193 23.4476 41.5737 26.5581 41.5737 29.8555C41.5572 36.6884 36.1764 42.2295 29.5578 42.2295ZM36.1434 32.966C35.7803 32.779 34.0142 31.8782 33.6841 31.7592C33.354 31.6402 33.1064 31.5722 32.8754 31.9462C32.6278 32.3201 31.9511 33.153 31.7365 33.4079C31.5219 33.6629 31.3238 33.6799 30.9607 33.4929C30.5976 33.306 29.4422 32.915 28.0558 31.6572C26.9829 30.6714 26.2567 29.4476 26.0421 29.0907C25.8275 28.7167 26.0256 28.5127 26.2072 28.3258C26.3722 28.1558 26.5703 27.8839 26.7518 27.6799C26.9334 27.4589 26.9994 27.3059 27.115 27.068C27.2305 26.813 27.181 26.6091 27.082 26.4221C26.9994 26.2351 26.2732 24.3994 25.9761 23.6686C25.679 22.9377 25.3819 23.0397 25.1673 23.0227C24.9528 23.0057 24.7217 23.0057 24.4741 23.0057C24.2265 23.0057 23.8469 23.0907 23.5168 23.4646C23.1867 23.8385 22.2459 24.7394 22.2459 26.5581C22.2459 28.3938 23.5333 30.1445 23.7149 30.3994C23.8964 30.6544 26.2567 34.3938 29.8714 36.0085C30.7297 36.3994 31.4064 36.6204 31.9345 36.7904C32.7928 37.0793 33.5851 37.0283 34.2123 36.9433C34.9055 36.8414 36.3415 36.0425 36.6551 35.1756C36.9522 34.3088 36.9522 33.5609 36.8697 33.4079C36.7541 33.255 36.5065 33.153 36.1434 32.966Z"
            fill="white"
            class="style-scope yt-icon"
          ></path>
        </g>
      </g>
    </svg>
  ),
  taxes: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 20.993V9H15C14.7348 9 14.4804 8.89464 14.2929 8.70711C14.1054 8.51957 14 8.26522 14 8V2H3.998C3.447 2 3 2.455 3 2.992V21.008C3.00027 21.2712 3.105 21.5235 3.2912 21.7095C3.47739 21.8955 3.72981 22 3.993 22H20.007C20.1383 21.9991 20.2682 21.9723 20.3892 21.9212C20.5101 21.8701 20.6199 21.7957 20.7121 21.7022C20.8043 21.6087 20.8772 21.4979 20.9266 21.3762C20.976 21.2545 21.0009 21.1243 21 20.993ZM16 7H21L16 2.003V7ZM9 15H7V19H9V15ZM11 11H13V19H11V11ZM17 13H15V19H17V13Z"
        fill=""
      />
    </svg>
  ),
  plans: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M22 9.999V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H13V9.999H22ZM11 15.999V21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V15.999H11V15.999ZM11 3V13.999H2V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H11ZM21 3C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V7.999H13V3H21Z"
        fill=""
      />
    </svg>
  ),
  settings: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill=""
    >
      <path
        d="M5.33405 4.54409C6.36078 3.62437 7.56673 2.92709 8.87605 2.49609C9.25043 2.96512 9.72571 3.34372 10.2666 3.60376C10.8074 3.8638 11.3999 3.99858 12 3.99809C12.6002 3.99858 13.1927 3.8638 13.7335 3.60376C14.2744 3.34372 14.7497 2.96512 15.124 2.49609C16.4334 2.92709 17.6393 3.62437 18.666 4.54409C18.4476 5.10254 18.3577 5.70303 18.403 6.30098C18.4484 6.89894 18.6278 7.47899 18.928 7.99809C19.2276 8.51818 19.6406 8.96404 20.1363 9.30252C20.632 9.64099 21.1976 9.86336 21.791 9.95309C22.0716 11.302 22.0716 12.6942 21.791 14.0431C20.631 14.2211 19.561 14.9031 18.928 15.9981C18.6277 16.5173 18.4481 17.0976 18.4028 17.6957C18.3574 18.2938 18.4474 18.8945 18.666 19.4531C17.6392 20.3725 16.4333 21.0694 15.124 21.5001C14.7496 21.0313 14.2742 20.6528 13.7334 20.393C13.1925 20.1331 12.6001 19.9985 12 19.9991C11.3999 19.9986 10.8074 20.1334 10.2666 20.3934C9.72571 20.6535 9.25043 21.0321 8.87605 21.5011C7.56682 21.0704 6.36087 20.3735 5.33405 19.4541C5.5527 18.8955 5.64269 18.2948 5.59733 17.6967C5.55197 17.0986 5.37243 16.5183 5.07205 15.9991C4.77234 15.4792 4.3593 15.0335 3.86363 14.6952C3.36797 14.3569 2.80242 14.1347 2.20905 14.0451C1.92836 12.6959 1.92836 11.3033 2.20905 9.95409C2.80251 9.86436 3.36811 9.64198 3.86379 9.30351C4.35946 8.96504 4.77245 8.51919 5.07205 7.99909C5.37228 7.47999 5.55173 6.89994 5.59709 6.30198C5.64245 5.70403 5.55254 5.10354 5.33405 4.54509V4.54409ZM13.5 14.5961C13.844 14.4005 14.1458 14.1389 14.3883 13.8262C14.6307 13.5135 14.8088 13.156 14.9125 12.7742C15.0161 12.3924 15.0433 11.9938 14.9923 11.6015C14.9413 11.2091 14.8132 10.8307 14.6153 10.4881C14.4175 10.1455 14.1539 9.84536 13.8396 9.60504C13.5253 9.36471 13.1666 9.18892 12.7841 9.0878C12.4016 8.98667 12.0029 8.9622 11.6109 9.0158C11.2189 9.0694 10.8414 9.2 10.5 9.40009C9.81663 9.80075 9.31942 10.4555 9.11694 11.2213C8.91445 11.9872 9.02312 12.8021 9.41923 13.4882C9.81534 14.1742 10.4667 14.6758 11.2313 14.8833C11.9958 15.0909 12.8114 14.9876 13.5 14.5961V14.5961Z"
        fill=""
      />
    </svg>
  ),
  logout: (
    <svg
      width="22"
      height="22"
      viewBox="0 0 26 26"
      fill=""
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3289 19.2733H21.2871C21.1477 19.2733 21.017 19.3355 20.9299 19.4452C20.7266 19.6973 20.5088 19.9404 20.2793 20.1717C19.3409 21.1307 18.2293 21.895 17.0061 22.4222C15.7388 22.9687 14.3767 23.249 13.001 23.2465C11.6098 23.2465 10.2622 22.9678 8.99589 22.4222C7.77265 21.895 6.66109 21.1307 5.72268 20.1717C4.78258 19.2159 4.03297 18.0831 3.51537 16.8359C2.97806 15.5432 2.70796 14.1703 2.70796 12.75C2.70796 11.3297 2.98097 9.95689 3.51537 8.6641C4.03234 7.41579 4.77586 6.29201 5.72268 5.32835C6.6695 4.36469 7.77025 3.60562 8.99589 3.07783C10.2622 2.53225 11.6098 2.25353 13.001 2.25353C14.3922 2.25353 15.7398 2.52928 17.0061 3.07783C18.2317 3.60562 19.3325 4.36469 20.2793 5.32835C20.5088 5.56259 20.7237 5.80573 20.9299 6.0548C21.017 6.16451 21.1506 6.22678 21.2871 6.22678H23.3289C23.5119 6.22678 23.6251 6.01922 23.5235 5.86207C21.2958 2.32766 17.3982 -0.0118156 12.969 4.48878e-05C6.01021 0.0178356 0.430942 5.78498 0.500646 12.8805C0.570351 19.8633 6.14091 25.5 13.001 25.5C17.4185 25.5 21.2987 23.1635 23.5235 19.638C23.6222 19.4808 23.5119 19.2733 23.3289 19.2733ZM25.9109 12.5632L21.7896 9.2423C21.6356 9.11776 21.412 9.23044 21.412 9.4291V11.6826H12.2923C12.1645 11.6826 12.06 11.7893 12.06 11.9198V13.5803C12.06 13.7107 12.1645 13.8175 12.2923 13.8175H21.412V16.0709C21.412 16.2696 21.6385 16.3823 21.7896 16.2577L25.9109 12.9368C25.9386 12.9146 25.9611 12.8863 25.9765 12.8539C25.992 12.8216 26 12.786 26 12.75C26 12.714 25.992 12.6785 25.9765 12.6461C25.9611 12.6138 25.9386 12.5854 25.9109 12.5632Z"
        fill="#fff"
      />
    </svg>
  ),
  customer: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M0 18v-1.575q0-1.1 1.1-1.763Q2.2 14 4 14q.325 0 .613.025.287.025.562.075-.35.5-.513 1.075Q4.5 15.75 4.5 16.4V18Zm6 0v-1.6q0-1.625 1.663-2.638Q9.325 12.75 12 12.75q2.7 0 4.35 1.012Q18 14.775 18 16.4V18Zm13.5 0v-1.6q0-.65-.175-1.225-.175-.575-.5-1.075.275-.05.563-.075Q19.675 14 20 14q1.8 0 2.9.662 1.1.663 1.1 1.763V18ZM4 13q-.825 0-1.412-.588Q2 11.825 2 11t.588-1.413Q3.175 9 4 9t1.412.587Q6 10.175 6 11q0 .825-.588 1.412Q4.825 13 4 13Zm16 0q-.825 0-1.413-.588Q18 11.825 18 11t.587-1.413Q19.175 9 20 9q.825 0 1.413.587Q22 10.175 22 11q0 .825-.587 1.412Q20.825 13 20 13Zm-8-1q-1.25 0-2.125-.875T9 9q0-1.25.875-2.125T12 6q1.25 0 2.125.875T15 9q0 1.25-.875 2.125T12 12Z" />
    </svg>
  ),
  beneficiary: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M3 22v-.575q0-.6.325-1.1.325-.5.9-.75.65-.275 1.337-.425Q6.25 19 7 19t1.438.15q.687.15 1.337.425.575.25.9.75.325.5.325 1.1V22Zm10 0v-.575q0-.6.325-1.1.325-.5.9-.75.65-.275 1.337-.425Q16.25 19 17 19t1.438.15q.687.15 1.337.425.575.25.9.75.325.5.325 1.1V22Zm-6-4q-.825 0-1.412-.587Q5 16.825 5 16q0-.825.588-1.413Q6.175 14 7 14t1.412.587Q9 15.175 9 16q0 .825-.588 1.413Q7.825 18 7 18Zm10 0q-.825 0-1.412-.587Q15 16.825 15 16q0-.825.588-1.413Q16.175 14 17 14t1.413.587Q19 15.175 19 16q0 .825-.587 1.413Q17.825 18 17 18Zm-5-2-3-3 1.05-1.05 1.2 1.175V11h1.5v2.125l1.2-1.175L15 13ZM2 10v-.575q0-.6.325-1.1.325-.5.9-.75.65-.275 1.337-.425Q5.25 7 6 7q.5 0 .988.075.487.075.937.2-.425.425-.675.975Q7 8.8 7 9.425V10Zm6 0v-.575q0-.6.325-1.1.325-.5.9-.75.65-.275 1.337-.425Q11.25 7 12 7t1.438.15q.687.15 1.337.425.575.25.9.75.325.5.325 1.1V10Zm9 0v-.575q0-.625-.25-1.175t-.675-.975q.45-.125.938-.2Q17.5 7 18 7q.75 0 1.438.15.687.15 1.337.425.575.25.9.75.325.5.325 1.1V10ZM6 6q-.825 0-1.412-.588Q4 4.825 4 4t.588-1.413Q5.175 2 6 2t1.412.587Q8 3.175 8 4q0 .825-.588 1.412Q6.825 6 6 6Zm6 0q-.825 0-1.412-.588Q10 4.825 10 4t.588-1.413Q11.175 2 12 2t1.413.587Q14 3.175 14 4q0 .825-.587 1.412Q12.825 6 12 6Zm6 0q-.825 0-1.413-.588Q16 4.825 16 4t.587-1.413Q17.175 2 18 2q.825 0 1.413.587Q20 3.175 20 4q0 .825-.587 1.412Q18.825 6 18 6Z" />
    </svg>
  ),
  refunds: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M12 23q-2.8 0-5.15-1.275Q4.5 20.45 3 18.325V21H1v-6h6v2H4.525q1.2 1.8 3.163 2.9Q9.65 21 12 21q1.875 0 3.513-.712 1.637-.713 2.85-1.926 1.212-1.212 1.925-2.85Q21 13.875 21 12h2q0 2.275-.862 4.275-.863 2-2.363 3.5t-3.5 2.362Q14.275 23 12 23Zm-.9-4v-1.3q-1.175-.275-1.912-1.012Q8.45 15.95 8.1 14.75l1.65-.65q.3 1.025.938 1.537.637.513 1.462.513t1.412-.388q.588-.387.588-1.212 0-.725-.612-1.175-.613-.45-2.188-1.025-1.475-.525-2.162-1.25-.688-.725-.688-1.9 0-1.025.713-1.863.712-.837 1.937-1.087V5h1.75v1.25q.9.075 1.638.725.737.65 1.012 1.525l-1.6.65q-.2-.575-.65-.962-.45-.388-1.25-.388-.875 0-1.337.375-.463.375-.463 1.025 0 .65.575 1.025.575.375 2.075.875 1.8.65 2.4 1.525.6.875.6 1.925 0 .725-.25 1.275-.25.55-.662.937-.413.388-.963.625-.55.238-1.175.363V19ZM1 12q0-2.275.863-4.275.862-2 2.362-3.5t3.5-2.363Q9.725 1 12 1q2.8 0 5.15 1.275Q19.5 3.55 21 5.675V3h2v6h-6V7h2.475q-1.2-1.8-3.163-2.9Q14.35 3 12 3q-1.875 0-3.512.712-1.638.713-2.85 1.925-1.213 1.213-1.926 2.85Q3 10.125 3 12Z" />
    </svg>
  ),
  payouts: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M6 22q-1.25 0-2.125-.875T3 19v-3h3V2l1.5 1.5L9 2l1.5 1.5L12 2l1.5 1.5L15 2l1.5 1.5L18 2l1.5 1.5L21 2v17q0 1.25-.875 2.125T18 22Zm12-2q.425 0 .712-.288Q19 19.425 19 19V5H8v11h9v3q0 .425.288.712.287.288.712.288ZM9 9V7h6v2Zm0 3v-2h6v2Zm8-3q-.425 0-.712-.288Q16 8.425 16 8t.288-.713Q16.575 7 17 7t.712.287Q18 7.575 18 8t-.288.712Q17.425 9 17 9Zm0 3q-.425 0-.712-.288Q16 11.425 16 11t.288-.713Q16.575 10 17 10t.712.287Q18 10.575 18 11t-.288.712Q17.425 12 17 12Z" />
    </svg>
  ),
  payout: (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill=""
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2960_3368"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="128"
        height="128"
      >
        <rect width="128" height="128" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2960_3368)">
        <path
          d="M32 110.401C29.4222 110.401 27.2658 109.533 25.5307 107.798C23.7991 106.067 22.9333 103.912 22.9333 101.334V92.2676H38.9333V20.2676L45.3333 24.0009L52 20.2676L58.6667 24.0009L65.3333 20.2676L72 24.0009L78.6667 20.2676L85.3333 24.0009L92 20.2676L98.6667 24.0009L105.067 20.2676V101.334C105.067 103.912 104.201 106.067 102.469 107.798C100.734 109.533 98.5778 110.401 96 110.401H32ZM96 106.668C97.5111 106.668 98.7769 106.156 99.7973 105.132C100.821 104.111 101.333 102.845 101.333 101.334V26.6676H42.6667V92.2676H90.6667V101.334C90.6667 102.845 91.1787 104.111 92.2027 105.132C93.2231 106.156 94.4889 106.668 96 106.668ZM50.6667 44.5342V40.8009H78.1333V44.5342H50.6667ZM50.6667 60.5342V56.8009H78.1333V60.5342H50.6667ZM89.8667 46.4009C88.8889 46.4009 88.0231 46.0222 87.2693 45.2649C86.512 44.5111 86.1333 43.6454 86.1333 42.6676C86.1333 41.6898 86.512 40.8222 87.2693 40.0649C88.0231 39.3111 88.8889 38.9342 89.8667 38.9342C90.8445 38.9342 91.7102 39.3111 92.464 40.0649C93.2213 40.8222 93.6 41.6898 93.6 42.6676C93.6 43.6454 93.2213 44.5111 92.464 45.2649C91.7102 46.0222 90.8445 46.4009 89.8667 46.4009ZM89.8667 62.4009C88.8889 62.4009 88.0231 62.0222 87.2693 61.2649C86.512 60.5111 86.1333 59.6454 86.1333 58.6676C86.1333 57.6898 86.512 56.8222 87.2693 56.0649C88.0231 55.3111 88.8889 54.9342 89.8667 54.9342C90.8445 54.9342 91.7102 55.3111 92.464 56.0649C93.2213 56.8222 93.6 57.6898 93.6 58.6676C93.6 59.6454 93.2213 60.5111 92.464 61.2649C91.7102 62.0222 90.8445 62.4009 89.8667 62.4009ZM32 106.668H86.9333V96.0009H26.6667V101.334C26.6667 102.845 27.1769 104.111 28.1973 105.132C29.2213 106.156 30.4889 106.668 32 106.668Z"
          fill="#9C6BFE"
        />
      </g>
    </svg>
  ),
  employees: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M2 20q-.425 0-.712-.288Q1 19.425 1 19v-3q0-.825.588-1.413Q2.175 14 3 14h3.275q.5 0 .95.25.45.25.725.675.725.975 1.788 1.525Q10.8 17 12 17q1.225 0 2.288-.55 1.062-.55 1.762-1.525.325-.425.762-.675.438-.25.913-.25H21q.825 0 1.413.587Q23 15.175 23 16v3q0 .425-.288.712Q22.425 20 22 20h-5q-.425 0-.712-.288Q16 19.425 16 19v-1.275q-.875.625-1.887.95Q13.1 19 12 19q-1.075 0-2.1-.337-1.025-.338-1.9-.963V19q0 .425-.287.712Q7.425 20 7 20Zm10-4q-.95 0-1.8-.438-.85-.437-1.425-1.212-.425-.625-1.062-.987Q7.075 13 6.325 13q.55-.925 2.325-1.463Q10.425 11 12 11q1.575 0 3.35.537 1.775.538 2.325 1.463-.725 0-1.375.363-.65.362-1.075.987-.55.8-1.4 1.225Q12.975 16 12 16Zm-8-3q-1.25 0-2.125-.875T1 10q0-1.25.875-2.125T4 7q1.25 0 2.125.875T7 10q0 1.25-.875 2.125T4 13Zm16 0q-1.25 0-2.125-.875T17 10q0-1.25.875-2.125T20 7q1.25 0 2.125.875T23 10q0 1.25-.875 2.125T20 13Zm-8-3q-1.25 0-2.125-.875T9 7q0-1.25.875-2.125T12 4q1.25 0 2.125.875T15 7q0 1.25-.875 2.125T12 10Z" />
    </svg>
  ),
  subscribers: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M10 12q-1.65 0-2.825-1.175Q6 9.65 6 8q0-1.65 1.175-2.825Q8.35 4 10 4q1.65 0 2.825 1.175Q14 6.35 14 8q0 1.65-1.175 2.825Q11.65 12 10 12Zm5.55 8.4-3.45-3.45 1.4-1.4 2.05 2.05 5.05-5.05 1.4 1.4ZM2 20v-2.8q0-.825.425-1.55.425-.725 1.175-1.1 1.275-.65 2.875-1.1Q8.075 13 10 13q.75 0 1.463.075.712.075 1.387.225L9.2 16.95 12.25 20Z" />
    </svg>
  ),
  paymentPages: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M11 18h2v-1h2v-5h-4v-1h4V9h-2V8h-2v1H9v5h4v1H9v2h2Zm-7 4V2h10l6 6v14Z" />
    </svg>
  ),
  bank: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill="none">
      <path d="M4.825 17.075v-7H7v7Zm6.075 0v-7h2.2v7ZM1.8 21.15v-2.275h20.4v2.275ZM17 17.075v-7h2.175v7ZM1.8 8.25V5.925L12 .825l10.2 5.1V8.25Zm4.95-2.275h10.5Zm0 0h10.5L12 3.375Z" />
    </svg>
  ),
  identity: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill="none">
      <path d="M17.25 13.575q.325 0 .538-.213.212-.212.212-.537 0-.325-.212-.538-.213-.212-.538-.212h-2.5q-.325 0-.537.212-.213.213-.213.538 0 .325.213.537.212.213.537.213Zm0 3q.325 0 .538-.213.212-.212.212-.537 0-.325-.212-.538-.213-.212-.538-.212h-2.5q-.325 0-.537.212-.213.213-.213.538 0 .325.213.537.212.213.537.213ZM15.2 6.8h4.725q.95 0 1.613.663.662.662.662 1.612v10.85q0 .95-.662 1.613-.663.662-1.613.662H4.075q-.95 0-1.613-.662-.662-.663-.662-1.613V9.075q0-.95.662-1.612.663-.663 1.613-.663H8.8V4q0-.9.65-1.55T11 1.8h2q.9 0 1.55.65T15.2 4Zm-4.125 2h1.85V4.075h-1.85ZM12 14.5Zm-3 .575q.625 0 1.062-.438.438-.437.438-1.062t-.438-1.063q-.437-.437-1.062-.437t-1.062.437q-.438.438-.438 1.063t.438 1.062q.437.438 1.062.438Zm-3 3h6v-.45q0-.425-.238-.788-.237-.362-.662-.562-.5-.225-1.012-.337-.513-.113-1.088-.113-.575 0-1.087.113-.513.112-1.013.337-.425.2-.662.562Q6 17.2 6 17.625Zm2.8-9H4.075v10.85h15.85V9.075H15.2q-.025.825-.625 1.412-.6.588-1.425.588h-2.3q-.825 0-1.425-.588-.6-.587-.625-1.412Z" />
    </svg>
  ),
  business: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill="none">
      <path d="M2.725 21.275V6.725h4v-4h10.55v8h4v10.55H13V17h-2v4.275ZM5 19h2v-2H5Zm0-4h2v-2H5Zm0-4h2V9H5Zm4 4h2v-2H9Zm0-4h2V9H9Zm0-4h2V5H9Zm4 8h2v-2h-2Zm0-4h2V9h-2Zm0-4h2V5h-2Zm4 12h2v-2h-2Zm0-4h2v-2h-2Z" />
    </svg>
  ),
  organization: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M8.75 22.425q-1.15 0-2.062-.6-.913-.6-1.413-1.65-.4.525-1.012.812-.613.288-1.263.288-1.275 0-2.137-.875Q0 19.525 0 18.275q0-1.075.7-1.938.7-.862 1.775-.987-.35-.5-.537-1.063-.188-.562-.188-1.187 0-1 .513-1.875Q2.775 10.35 3.7 9.8q.125.45.337.962.213.513.488.888-.35.275-.55.662-.2.388-.2.813 0 1.4 1.15 1.725 1.15.325 2.175.525l.475.8q-.275.8-.475 1.363-.2.562-.2 1.012 0 .75.538 1.313.537.562 1.312.562.95 0 1.575-.85t1.025-2q.4-1.15.613-2.325.212-1.175.337-1.8l1.95.525q-.225 1.125-.55 2.575-.325 1.45-.912 2.762-.588 1.313-1.55 2.213-.963.9-2.488.9Zm1.35-7.1Q8.95 14.3 8.013 13.412q-.938-.887-1.613-1.724-.675-.838-1.037-1.676Q5 9.175 5 8.275 5 6.65 6.113 5.537 7.225 4.425 8.85 4.425q.1 0 .175.012.075.013.175.013-.1-.25-.15-.5Q9 3.7 9 3.425q0-1.25.875-2.125T12 .425q1.25 0 2.125.875T15 3.425q0 .275-.05.512-.05.238-.15.488h.35q1.5 0 2.55.962 1.05.963 1.25 2.388-.45-.075-1.012-.075-.563 0-1.038.05-.175-.575-.637-.95-.463-.375-1.113-.375-.875 0-1.362.513-.488.512-1.338 1.487h-.925Q10.65 7.4 10.163 6.912q-.488-.487-1.313-.487-.8 0-1.325.525Q7 7.475 7 8.275q0 .575.325 1.187.325.613.913 1.3.587.688 1.425 1.463.837.775 1.862 1.675Zm5.125 7.1q-.55 0-1.087-.15-.538-.15-1.038-.475.275-.35.55-.825t.5-.875q.275.175.55.25.275.075.55.075.8 0 1.337-.562.538-.563.538-1.338 0-.475-.2-1.025-.2-.55-.475-1.35l.475-.8q1.05-.2 2.188-.525Q20.25 14.5 20.25 13.1q0-1-.737-1.45-.738-.45-1.613-.45-1.05 0-2.45.4-1.4.4-3.275 1.025l-.525-1.95q1.95-.625 3.475-1.05 1.525-.425 2.8-.425 1.725 0 3.025 1.025 1.3 1.025 1.3 2.875 0 .625-.188 1.187-.187.563-.537 1.063 1.075.125 1.775.987.7.863.7 1.938 0 1.25-.862 2.125-.863.875-2.138.875-.65 0-1.262-.288-.613-.287-1.013-.812-.5 1.05-1.413 1.65-.912.6-2.087.6Z" />
    </svg>
  ),
  users: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M1 20v-2.8q0-.85.438-1.563.437-.712 1.162-1.087 1.55-.775 3.15-1.163Q7.35 13 9 13t3.25.387q1.6.388 3.15 1.163.725.375 1.162 1.087Q17 16.35 17 17.2V20Zm18 0v-3q0-1.1-.612-2.113-.613-1.012-1.738-1.737 1.275.15 2.4.512 1.125.363 2.1.888.9.5 1.375 1.112Q23 16.275 23 17v3ZM9 12q-1.65 0-2.825-1.175Q5 9.65 5 8q0-1.65 1.175-2.825Q7.35 4 9 4q1.65 0 2.825 1.175Q13 6.35 13 8q0 1.65-1.175 2.825Q10.65 12 9 12Zm10-4q0 1.65-1.175 2.825Q16.65 12 15 12q-.275 0-.7-.062-.425-.063-.7-.138.675-.8 1.037-1.775Q15 9.05 15 8q0-1.05-.363-2.025Q14.275 5 13.6 4.2q.35-.125.7-.163Q14.65 4 15 4q1.65 0 2.825 1.175Q19 6.35 19 8Z" />
    </svg>
  ),
  add: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
    </svg>
  ),
  view: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M12 16q1.875 0 3.188-1.312Q16.5 13.375 16.5 11.5q0-1.875-1.312-3.188Q13.875 7 12 7q-1.875 0-3.188 1.312Q7.5 9.625 7.5 11.5q0 1.875 1.312 3.188Q10.125 16 12 16Zm0-1.8q-1.125 0-1.912-.788Q9.3 12.625 9.3 11.5t.788-1.913Q10.875 8.8 12 8.8t1.913.787q.787.788.787 1.913t-.787 1.912q-.788.788-1.913.788Zm0 4.8q-3.65 0-6.65-2.038-3-2.037-4.35-5.462 1.35-3.425 4.35-5.463Q8.35 4 12 4q3.65 0 6.65 2.037 3 2.038 4.35 5.463-1.35 3.425-4.35 5.462Q15.65 19 12 19Z" />
    </svg>
  ),
  providers: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M4 13.525 6.525 11 4 8.475 1.475 11ZM17.5 13 20 9l2.5 4ZM1 18q-.425 0-.712-.288Q0 17.425 0 17v-.575q0-1.1 1.1-1.763Q2.2 14 4 14q.325 0 .613.025.287.025.562.075-.35.5-.513 1.075Q4.5 15.75 4.5 16.4V18Zm6 0q-.425 0-.713-.288Q6 17.425 6 17v-.6q0-1.625 1.663-2.638Q9.325 12.75 12 12.75q2.7 0 4.35 1.012Q18 14.775 18 16.4v.6q0 .425-.288.712Q17.425 18 17 18Zm12.5 0v-1.6q0-.65-.175-1.225-.175-.575-.5-1.075.275-.05.563-.075Q19.675 14 20 14q1.8 0 2.9.662 1.1.663 1.1 1.763V17q0 .425-.288.712Q23.425 18 23 18ZM12 12q-1.25 0-2.125-.875T9 9q0-1.25.875-2.125T12 6q1.25 0 2.125.875T15 9q0 1.25-.875 2.125T12 12Z" />
    </svg>
  ),
  edit: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="m19.3 8.925-4.25-4.2 1.4-1.4q.575-.575 1.413-.575.837 0 1.412.575l1.4 1.4q.575.575.6 1.388.025.812-.55 1.387ZM4 21q-.425 0-.712-.288Q3 20.425 3 20v-2.825q0-.2.075-.387.075-.188.225-.338l10.3-10.3 4.25 4.25-10.3 10.3q-.15.15-.337.225-.188.075-.388.075Z" />
    </svg>
  ),
  share: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M18 22q-1.25 0-2.125-.875T15 19q0-.175.025-.363.025-.187.075-.337l-7.05-4.1q-.425.375-.95.587Q6.575 15 6 15q-1.25 0-2.125-.875T3 12q0-1.25.875-2.125T6 9q.575 0 1.1.212.525.213.95.588l7.05-4.1q-.05-.15-.075-.337Q15 5.175 15 5q0-1.25.875-2.125T18 2q1.25 0 2.125.875T21 5q0 1.25-.875 2.125T18 8q-.575 0-1.1-.213-.525-.212-.95-.587L8.9 11.3q.05.15.075.337Q9 11.825 9 12t-.025.362q-.025.188-.075.338l7.05 4.1q.425-.375.95-.588Q17.425 16 18 16q1.25 0 2.125.875T21 19q0 1.25-.875 2.125T18 22Z" />
    </svg>
  ),
  notificationBell: (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="39.9995" height="39.9995" rx="19.9998" fill="#7F2CEA" />
      <mask
        id="mask0_4309_61270"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="9"
        y="9"
        width="22"
        height="22"
      >
        <rect
          x="9.11377"
          y="9.11328"
          width="21.7719"
          height="21.7719"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_4309_61270)">
        <path
          d="M10.9282 18.1831C10.9282 16.8526 11.1967 15.594 11.7338 14.4075C12.2702 13.2203 13.0374 12.1958 14.0353 11.334L15.328 12.604C14.5115 13.3146 13.8765 14.1498 13.4229 15.1096C12.9693 16.07 12.7425 17.0945 12.7425 18.1831H10.9282ZM27.2571 18.1831C27.2571 17.0945 27.0303 16.07 26.5768 15.1096C26.1232 14.1498 25.4882 13.3146 24.6717 12.604L25.9644 11.334C26.9623 12.1958 27.7298 13.2203 28.2668 14.4075C28.8032 15.594 29.0715 16.8526 29.0715 18.1831H27.2571ZM12.7425 26.3475V24.5332H14.5569V18.1831C14.5569 16.9282 14.9349 15.813 15.6908 14.8375C16.4468 13.8626 17.4295 13.2239 18.6391 12.9215V10.9258H21.3606V12.9215C22.5701 13.2239 23.5529 13.8626 24.3089 14.8375C25.0648 15.813 25.4428 16.9282 25.4428 18.1831V24.5332H27.2571V26.3475H12.7425ZM19.9998 29.069C19.5009 29.069 19.0739 28.8915 18.7189 28.5365C18.3633 28.1809 18.1855 27.7536 18.1855 27.2547H21.8142C21.8142 27.7536 21.6367 28.1809 21.2817 28.5365C20.9261 28.8915 20.4988 29.069 19.9998 29.069Z"
          fill="white"
        />
      </g>
    </svg>
  ),
  delete: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M7 21q-.825 0-1.412-.587Q5 19.825 5 19V6q-.425 0-.713-.287Q4 5.425 4 5t.287-.713Q4.575 4 5 4h4q0-.425.288-.713Q9.575 3 10 3h4q.425 0 .713.287Q15 3.575 15 4h4q.425 0 .712.287Q20 4.575 20 5t-.288.713Q19.425 6 19 6v13q0 .825-.587 1.413Q17.825 21 17 21Zm2-5q0 .425.288.712Q9.575 17 10 17t.713-.288Q11 16.425 11 16V9q0-.425-.287-.713Q10.425 8 10 8t-.712.287Q9 8.575 9 9Zm4 0q0 .425.288.712.287.288.712.288t.713-.288Q15 16.425 15 16V9q0-.425-.287-.713Q14.425 8 14 8t-.712.287Q13 8.575 13 9Z" />
    </svg>
  ),
  cancel: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        id="cancel_2"
        d="M5.59992 11.333L7.99992 8.93301L10.3999 11.333L11.3333 10.3997L8.93325 7.99967L11.3333 5.59967L10.3999 4.66634L7.99992 7.06634L5.59992 4.66634L4.66659 5.59967L7.06659 7.99967L4.66659 10.3997L5.59992 11.333ZM7.99992 14.6663C7.0777 14.6663 6.21103 14.4912 5.39992 14.141C4.58881 13.7912 3.88325 13.3163 3.28325 12.7163C2.68325 12.1163 2.20836 11.4108 1.85859 10.5997C1.50836 9.78856 1.33325 8.9219 1.33325 7.99967C1.33325 7.07745 1.50836 6.21079 1.85859 5.39967C2.20836 4.58856 2.68325 3.88301 3.28325 3.28301C3.88325 2.68301 4.58881 2.2079 5.39992 1.85767C6.21103 1.5079 7.0777 1.33301 7.99992 1.33301C8.92214 1.33301 9.78881 1.5079 10.5999 1.85767C11.411 2.2079 12.1166 2.68301 12.7166 3.28301C13.3166 3.88301 13.7915 4.58856 14.1413 5.39967C14.4915 6.21079 14.6666 7.07745 14.6666 7.99967C14.6666 8.9219 14.4915 9.78856 14.1413 10.5997C13.7915 11.4108 13.3166 12.1163 12.7166 12.7163C12.1166 13.3163 11.411 13.7912 10.5999 14.141C9.78881 14.4912 8.92214 14.6663 7.99992 14.6663Z"
        fill="#A3A3A3"
      />
    </svg>
  ),
  arrowRight: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="m9.4 18.8-2.2-2.2 4.6-4.6-4.6-4.6 2.2-2.2 6.8 6.8Z" />
    </svg>
  ),
  arrowLeft: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M14 18.8 7.2 12 14 5.2l2.2 2.2-4.6 4.6 4.6 4.6Z" />
    </svg>
  ),
  doubleArrowRight: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="m6.25 18.8-2.2-2.2L8.625 12 4.05 7.4l2.2-2.2 6.8 6.8Zm6.9 0-2.2-2.2 4.6-4.6-4.6-4.6 2.2-2.2 6.8 6.8Z" />
    </svg>
  ),
  doubleArrowLeft: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M10.85 18.8 4.05 12l6.8-6.8 2.2 2.2-4.6 4.6 4.6 4.6Zm6.9 0-6.8-6.8 6.8-6.8 2.2 2.2-4.575 4.6 4.575 4.6Z" />
    </svg>
  ),
  key: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      {" "}
      <path d="M12.917 13A6.002 6.002 0 0 1 1 12a6 6 0 0 1 11.917-1H23v2h-2v4h-2v-4h-2v4h-2v-4h-2.083zM7 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8z" />{" "}
    </svg>
  ),
  invoice: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path d="M0 20.5V6.475L10 0.5L20 6.475V20.5H0ZM10 12.15L17.8 7.5L10 2.85L2.2 7.5L10 12.15Z" />
    </svg>
  ),
  message: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path
        d="M46.9996 35.1999L4.33294 6.93327V67.9999H55.7996V71.7333H0.599609V0.266602H93.3996V44.7999H89.6663V6.93327L46.9996 35.1999ZM46.9996 30.6666L87.533 3.99993H6.46628L46.9996 30.6666ZM81.6663 86.2666L79.1329 83.7333L90.8663 71.7333H63.7996V67.9999H90.9996L78.9996 55.9999L81.6663 53.4666L98.0663 69.8666L81.6663 86.2666ZM4.33294 6.93327V71.0666V44.7999V45.8666V3.99993V6.93327Z"
        fill="#9C6BFE"
      />
    </svg>
  ),
  review: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path
        d="M42.0003 58.0003C46.0003 58.0003 49.7567 57.0669 53.2696 55.2003C56.7789 53.3336 59.8225 50.7114 62.4003 47.3336C59.8225 43.9558 56.7789 41.3336 53.2696 39.4669C49.7567 37.6003 46.0003 36.6669 42.0003 36.6669C38.0003 36.6669 34.2438 37.6003 30.7309 39.4669C27.2216 41.3336 24.178 43.9558 21.6003 47.3336C24.178 50.7114 27.2216 53.3336 30.7309 55.2003C34.2438 57.0669 38.0003 58.0003 42.0003 58.0003ZM42.0003 61.7336C36.6669 61.7336 31.8456 60.4003 27.5363 57.7336C23.2234 55.0669 19.9114 51.6003 17.6003 47.3336C19.9114 43.0669 23.2234 39.6003 27.5363 36.9336C31.8456 34.2669 36.6669 32.9336 42.0003 32.9336C47.3336 32.9336 52.1567 34.2669 56.4696 36.9336C60.7789 39.6003 64.0892 43.0669 66.4003 47.3336C64.0892 51.6003 60.7789 55.0669 56.4696 57.7336C52.1567 60.4003 47.3336 61.7336 42.0003 61.7336ZM42.0003 52.6669C40.4892 52.6669 39.2234 52.1549 38.2029 51.1309C37.1789 50.1105 36.6669 48.8447 36.6669 47.3336C36.6669 45.8225 37.1789 44.5549 38.2029 43.5309C39.2234 42.5105 40.4892 42.0003 42.0003 42.0003C43.5114 42.0003 44.7789 42.5105 45.8029 43.5309C46.8234 44.5549 47.3336 45.8225 47.3336 47.3336C47.3336 48.8447 46.8234 50.1105 45.8029 51.1309C44.7789 52.1549 43.5114 52.6669 42.0003 52.6669ZM0.933594 83.0669V0.933594H83.0669V83.0669H0.933594ZM4.66693 79.3336H79.3336V15.3336H4.66693V79.3336Z"
        fill="#9C6BFE"
      />
    </svg>
  ),
  sent: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path
        d="M39.2663 66.7329L72.3329 33.6663L69.6663 30.9996L39.2663 61.3996L24.0663 46.1996L21.3996 48.8663L39.2663 66.7329ZM46.9996 93.3996C40.5996 93.3996 34.5783 92.1765 28.9356 89.7303C23.2894 87.2876 18.3774 83.9774 14.1996 79.7996C10.0218 75.6218 6.70983 70.7098 4.26361 65.0636C1.82094 59.4209 0.599609 53.3996 0.599609 46.9996C0.599609 40.5996 1.82094 34.5765 4.26361 28.9303C6.70983 23.2876 10.0218 18.3774 14.1996 14.1996C18.3774 10.0218 23.2894 6.70983 28.9356 4.26361C34.5783 1.82094 40.5996 0.599609 46.9996 0.599609C53.3996 0.599609 59.4227 1.82094 65.0689 4.26361C70.7116 6.70983 75.6218 10.0218 79.7996 14.1996C83.9774 18.3774 87.2894 23.2876 89.7356 28.9303C92.1783 34.5765 93.3996 40.5996 93.3996 46.9996C93.3996 53.3996 92.1783 59.4209 89.7356 65.0636C87.2894 70.7098 83.9774 75.6218 79.7996 79.7996C75.6218 83.9774 70.7116 87.2876 65.0689 89.7303C59.4227 92.1765 53.3996 93.3996 46.9996 93.3996ZM46.9996 89.6663C58.9107 89.6663 68.9996 85.5329 77.2663 77.2663C85.5329 68.9996 89.6663 58.9107 89.6663 46.9996C89.6663 35.0885 85.5329 24.9996 77.2663 16.7329C68.9996 8.46628 58.9107 4.33294 46.9996 4.33294C35.0885 4.33294 24.9996 8.46628 16.7329 16.7329C8.46628 24.9996 4.33294 35.0885 4.33294 46.9996C4.33294 58.9107 8.46628 68.9996 16.7329 77.2663C24.9996 85.5329 35.0885 89.6663 46.9996 89.6663Z"
        fill="#9C6BFE"
      />
    </svg>
  ),
  link: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3782_58553"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3782_58553)">
        <path
          d="M2.5 17.5V2.5H10V4.16667H4.16667V15.8333H15.8333V10H17.5V17.5H2.5ZM8.08333 13.0833L6.91667 11.9167L14.6667 4.16667H11.6667V2.5H17.5V8.33333H15.8333V5.33333L8.08333 13.0833Z"
          fill="#9C6BFE"
        />
      </g>
    </svg>
  ),
  contentCopy: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M9 18q-.825 0-1.412-.587Q7 16.825 7 16V4q0-.825.588-1.413Q8.175 2 9 2h9q.825 0 1.413.587Q20 3.175 20 4v12q0 .825-.587 1.413Q18.825 18 18 18Zm0-2h9V4H9v12Zm-4 6q-.825 0-1.413-.587Q3 20.825 3 20V7q0-.425.288-.713Q3.575 6 4 6t.713.287Q5 6.575 5 7v13h10q.425 0 .713.288.287.287.287.712t-.287.712Q15.425 22 15 22ZM9 4v12V4Z" />
    </svg>
  ),
  copied: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M5 21q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h4.175q.275-.875 1.075-1.438Q11.05 1 12 1q1 0 1.788.562.787.563 1.062 1.438H19q.825 0 1.413.587Q21 4.175 21 5v14q0 .825-.587 1.413Q19.825 21 19 21Zm0-2h14V5h-2v3H7V5H5v14Zm7-14q.425 0 .713-.288Q13 4.425 13 4t-.287-.713Q12.425 3 12 3t-.712.287Q11 3.575 11 4t.288.712Q11.575 5 12 5Z" />
    </svg>
  ),
  tick: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="m9.55 18-5.7-5.7 1.425-1.425L9.55 15.15l9.175-9.175L20.15 7.4Z" />
    </svg>
  ),
  notification: (
    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
      <path
        d="M3.333 15.833v-1.75h1.646V8.354q0-1.708 1.042-3.052 1.041-1.344 2.687-1.76V1.667h2.584v1.875q1.646.416 2.677 1.76Q15 6.646 15 8.354v5.729h1.667v1.75Zm6.667 2.5q-.708 0-1.188-.479-.479-.479-.479-1.187h3.334q0 .708-.479 1.187-.48.479-1.188.479Z"
        fill="#8900cc"
      />
    </svg>
  ),

  hidePassword: (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2001 14.0672L10.4001 11.3005C10.0112 11.4227 9.61964 11.5145 9.22542 11.5759C8.83075 11.6367 8.4223 11.6672 8.00008 11.6672C6.3223 11.6672 4.82786 11.2034 3.51675 10.2759C2.20564 9.34785 1.25564 8.14497 0.666748 6.66719C0.900081 6.0783 1.19453 5.53097 1.55008 5.02519C1.90564 4.51985 2.31119 4.06719 2.76675 3.66719L0.933415 1.80052L1.86675 0.867188L14.1334 13.1339L13.2001 14.0672ZM8.00008 9.66719C8.1223 9.66719 8.23608 9.66163 8.34141 9.65052C8.44719 9.63941 8.56119 9.61719 8.68341 9.58385L5.08342 5.98385C5.05008 6.10608 5.02786 6.22008 5.01675 6.32585C5.00564 6.43119 5.00008 6.54497 5.00008 6.66719C5.00008 7.50052 5.29164 8.20897 5.87475 8.79252C6.4583 9.37563 7.16675 9.66719 8.00008 9.66719ZM12.8667 9.96719L10.7501 7.86719C10.8279 7.6783 10.889 7.48674 10.9334 7.29252C10.9779 7.09785 11.0001 6.88941 11.0001 6.66719C11.0001 5.83385 10.7085 5.12541 10.1254 4.54185C9.54186 3.95874 8.83342 3.66719 8.00008 3.66719C7.77786 3.66719 7.56942 3.68941 7.37475 3.73385C7.18053 3.7783 6.98897 3.84497 6.80008 3.93385L5.10008 2.23385C5.55564 2.04497 6.0223 1.90319 6.50008 1.80852C6.97786 1.7143 7.47786 1.66719 8.00008 1.66719C9.67786 1.66719 11.1723 2.13097 12.4834 3.05852C13.7945 3.98652 14.7445 5.18941 15.3334 6.66719C15.0779 7.32274 14.7419 7.93119 14.3254 8.49252C13.9085 9.05341 13.4223 9.54497 12.8667 9.96719ZM9.78341 6.90052L7.78341 4.90052C8.09453 4.84497 8.38075 4.86985 8.64208 4.97519C8.90297 5.08097 9.12786 5.23385 9.31675 5.43385C9.50564 5.63385 9.64186 5.86452 9.72542 6.12585C9.80853 6.38674 9.82786 6.64497 9.78341 6.90052Z"
        fill="black"
      />
    </svg>
  ),
  visiblePassword: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="visibility">
        <mask
          id="mask0_6732_20994"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="16"
        >
          <rect id="Bounding box" width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_6732_20994)">
          <path
            id="visibility_2"
            d="M8.00008 10.668C8.83341 10.668 9.54175 10.3763 10.1251 9.79297C10.7084 9.20964 11.0001 8.5013 11.0001 7.66797C11.0001 6.83464 10.7084 6.1263 10.1251 5.54297C9.54175 4.95964 8.83341 4.66797 8.00008 4.66797C7.16675 4.66797 6.45841 4.95964 5.87508 5.54297C5.29175 6.1263 5.00008 6.83464 5.00008 7.66797C5.00008 8.5013 5.29175 9.20964 5.87508 9.79297C6.45841 10.3763 7.16675 10.668 8.00008 10.668ZM8.00008 9.46797C7.50008 9.46797 7.07508 9.29297 6.72508 8.94297C6.37508 8.59297 6.20008 8.16797 6.20008 7.66797C6.20008 7.16797 6.37508 6.74297 6.72508 6.39297C7.07508 6.04297 7.50008 5.86797 8.00008 5.86797C8.50008 5.86797 8.92508 6.04297 9.27508 6.39297C9.62508 6.74297 9.80008 7.16797 9.80008 7.66797C9.80008 8.16797 9.62508 8.59297 9.27508 8.94297C8.92508 9.29297 8.50008 9.46797 8.00008 9.46797ZM8.00008 12.668C6.37786 12.668 4.90008 12.2152 3.56675 11.3096C2.23341 10.4041 1.26675 9.19019 0.666748 7.66797C1.26675 6.14575 2.23341 4.93186 3.56675 4.0263C4.90008 3.12075 6.37786 2.66797 8.00008 2.66797C9.6223 2.66797 11.1001 3.12075 12.4334 4.0263C13.7667 4.93186 14.7334 6.14575 15.3334 7.66797C14.7334 9.19019 13.7667 10.4041 12.4334 11.3096C11.1001 12.2152 9.6223 12.668 8.00008 12.668Z"
            fill="black"
          />
        </g>
      </g>
    </svg>
  ),
};

export const Arrow = ({ svgColor }) => {
  return (
    <svg
      width="33"
      height="10"
      viewBox="0 0 34 14"
      fill={svgColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.9362 2L13.5906 1.53981L13.0898 0.827672L12.4224 1.38677L12.9362 2ZM19.9685 12L19.3141 12.4602L19.7799 13.1226L20.4366 12.6488L19.9685 12ZM34 2L24.7971 2.80015L30.0915 10.37L34 2ZM28.383 5.92857L27.9244 5.27293L27.9149 5.27982L28.383 5.92857ZM1.51376 12.6132L13.4499 2.61323L12.4224 1.38677L0.486241 11.3868L1.51376 12.6132ZM12.2818 2.46019L19.3141 12.4602L20.6229 11.5398L13.5906 1.53981L12.2818 2.46019ZM20.4366 12.6488L28.8511 6.57732L27.9149 5.27982L19.5004 11.3512L20.4366 12.6488Z" />
    </svg>
  );
};

export const DownArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="10"
      viewBox="0 0 34 14"
      fill="none"
    >
      <path
        d="M12.9362 12L13.5906 12.4602L13.0898 13.1723L12.4224 12.6132L12.9362 12ZM19.9685 2L19.3141 1.53981L19.7799 0.877423L20.4366 1.35125L19.9685 2ZM34 12L24.7971 11.1998L30.0915 3.62999L34 12ZM28.383 8.07143L27.9244 8.72707L27.9149 8.72018L28.383 8.07143ZM1.51376 1.38677L13.4499 11.3868L12.4224 12.6132L0.486241 2.61323L1.51376 1.38677ZM12.2818 11.5398L19.3141 1.53981L20.6229 2.46019L13.5906 12.4602L12.2818 11.5398ZM20.4366 1.35125L28.8511 7.42268L27.9149 8.72018L19.5004 2.64875L20.4366 1.35125Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export const RightArrow = ({ svgColor }) => {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.172 7.00072L0.222 11.9507L1.636 13.3647L8 7.00072L1.636 0.636719L0.222 2.05072L5.172 7.00072Z"
        fill={svgColor}
      />
    </svg>
  );
};

export const RightArrowAc = ({ svgColor }) => {
  return (
    <svg
      width="8"
      height="15"
      viewBox="0 0 8 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-5.5758e-07 2.24406L-6.34333e-07 0.488156L7.25592 7.74408L0 15L4.43883e-05 13.2441L-5.5758e-07 2.24406Z"
        fill={svgColor}
      />
    </svg>
  );
};

export const CrossIcon = ({ svgColor }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99992 6.23438L14.1874 0.046875L15.9549 1.81438L9.76742 8.00187L15.9549 14.1894L14.1874 15.9569L7.99992 9.76937L1.81242 15.9569L0.0449219 14.1894L6.23242 8.00187L0.0449219 1.81438L1.81242 0.046875L7.99992 6.23438Z"
        fill={svgColor}
      />
    </svg>
  );
};

export const Box2Arrow = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7355 0.582031H2.09914C1.26234 0.582031 0.583984 1.32822 0.583984 2.2487V15.582C0.583984 16.5025 1.26234 17.2487 2.09914 17.2487H3.08398V18.082H4.75065V17.2487H13.084V18.082H14.7507V17.2487H15.7355C16.5723 17.2487 17.2507 16.5025 17.2507 15.582V2.2487C17.2507 1.32822 16.5723 0.582031 15.7355 0.582031ZM2.25065 15.582V2.2487H15.584V3.91536H14.7507V5.58203H15.584V12.2487H14.7507V13.9154H15.584V15.582H2.25065ZM13.6732 12.4928L12.4947 13.6713L10.6106 11.7872C10.1144 12.0804 9.53549 12.2487 8.91732 12.2487C8.29914 12.2487 7.72027 12.0804 7.22401 11.7872L5.33991 13.6713L4.1614 12.4928L6.0455 10.6087C5.75226 10.1124 5.58398 9.53354 5.58398 8.91537C5.58398 8.29719 5.75226 7.71832 6.0455 7.22206L4.1614 5.33795L5.33991 4.15944L7.22401 6.04355C7.72027 5.75031 8.29914 5.58203 8.91732 5.58203C9.53549 5.58203 10.1144 5.75031 10.6106 6.04355L12.4947 4.15944L13.6732 5.33795L11.7891 7.22206C12.0824 7.71832 12.2507 8.29719 12.2507 8.91537C12.2507 9.53354 12.0824 10.1124 11.7891 10.6087L13.6732 12.4928ZM10.584 8.91536C10.584 9.83584 9.83779 10.582 8.91732 10.582C7.99684 10.582 7.25065 9.83584 7.25065 8.91536C7.25065 7.99489 7.99684 7.2487 8.91732 7.2487C9.83779 7.2487 10.584 7.99489 10.584 8.91536Z"
        fill="white"
      />
    </svg>
  );
};

export const CustomArrow = ({ svgColor }) => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.36137 3.18813V17H6.63863V3.18813L1.21815 8.42856L0 7.25087L7.5 0L15 7.25087L13.7818 8.42856L8.36137 3.18813Z"
        fill={svgColor}
      />
    </svg>
  );
};

export const IconThree = ({ svgColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill={svgColor}
    >
      <circle cx="24" cy="24" r="24" fill="#B0E0E3" />
      <path
        d="M11.5 22.75H36.5V34C36.5 34.3315 36.3683 34.6495 36.1339 34.8839C35.8995 35.1183 35.5815 35.25 35.25 35.25H12.75C12.4185 35.25 12.1005 35.1183 11.8661 34.8839C11.6317 34.6495 11.5 34.3315 11.5 34V22.75ZM30.25 12.75H35.25C35.5815 12.75 35.8995 12.8817 36.1339 13.1161C36.3683 13.3505 36.5 13.6685 36.5 14V20.25H11.5V14C11.5 13.6685 11.6317 13.3505 11.8661 13.1161C12.1005 12.8817 12.4185 12.75 12.75 12.75H17.75V10.25H20.25V12.75H27.75V10.25H30.25V12.75Z"
        fill="#24A1A6"
      />
      <path
        d="M18.264 25.905L18.616 27.291L19.958 26.961V33H21.63V25.245H20.475L18.264 25.905ZM30.3846 25.3H28.4596L26.5896 28.391L24.7526 25.3H22.7726L25.7316 29.964V33H27.4256V29.931L30.3846 25.3Z"
        fill="white"
      />
    </svg>
  );
};

export const IconTwo = ({ svgColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill={"none"}
    >
      <circle cx="24" cy="24" r="24" fill="#E5E5F0" />
      <path
        d="M14.8246 7.03398L10.4922 11.3242L18.839 19.7638L23.1715 15.4736L14.8246 7.03398Z"
        fill="#322A7C"
      />
      <path
        opacity="0.5"
        d="M14.8246 7.03398L10.4922 11.3242L18.839 19.7638L23.1715 15.4736L14.8246 7.03398Z"
        fill="#FAFAFA"
      />
      <path
        d="M14.8697 7.5699L11.0469 11.3555L18.8543 19.2497L22.6772 15.4641L14.8697 7.5699Z"
        stroke="#322A7C"
        strokeWidth="0.271949"
        strokeMiterlimit="10"
      />
      <path
        d="M17.7842 14.3392C18.2226 13.905 18.1369 13.1069 17.5928 12.5567C17.0486 12.0065 16.252 11.9124 15.8135 12.3466C15.375 12.7808 15.4607 13.5789 16.0049 14.1291C16.5491 14.6794 17.3457 14.7734 17.7842 14.3392Z"
        fill="#322A7C"
      />
      <path
        d="M22.2576 2.99985L14.3008 5.88672L19.9173 21.3866L27.8742 18.4997L22.2576 2.99985Z"
        fill="#322A7C"
      />
      <path
        opacity="0.5"
        d="M22.2576 2.99985L14.3008 5.88672L19.9173 21.3866L27.8742 18.4997L22.2576 2.99985Z"
        fill="#FAFAFA"
      />
      <path
        d="M22.0092 3.69881L14.9883 6.24609L20.2419 20.7444L27.2627 18.1971L22.0092 3.69881Z"
        stroke="#322A7C"
        strokeWidth="0.271949"
        strokeMiterlimit="10"
      />
      <path
        d="M21.7451 13.9352C22.5504 13.643 22.9064 12.5869 22.5402 11.5764C22.174 10.5658 21.2244 9.98348 20.4191 10.2757C19.6138 10.5678 19.2578 11.6239 19.624 12.6345C19.9902 13.645 20.9398 14.2274 21.7451 13.9352Z"
        fill="#322A7C"
      />
      <path
        d="M36.5 19.2367H27.75C25.7609 19.2367 23.8532 20.0274 22.4467 21.4348C21.0402 22.8422 20.25 24.7511 20.25 26.7415C20.25 28.7318 21.0402 30.6407 22.4467 32.0481C23.8532 33.4555 25.7609 34.2462 27.75 34.2462H36.5V36.7478C36.5 37.0795 36.3683 37.3976 36.1339 37.6322C35.8995 37.8668 35.5815 37.9986 35.25 37.9986H12.75C12.4185 37.9986 12.1005 37.8668 11.8661 37.6322C11.6317 37.3976 11.5 37.0795 11.5 36.7478V16.7352C11.5 16.4034 11.6317 16.0853 11.8661 15.8507C12.1005 15.6162 12.4185 15.4844 12.75 15.4844H35.25C35.5815 15.4844 35.8995 15.6162 36.1339 15.8507C36.3683 16.0853 36.5 16.4034 36.5 16.7352V19.2367ZM27.75 21.7383H37.75V31.7446H27.75C26.4239 31.7446 25.1521 31.2175 24.2145 30.2792C23.2768 29.341 22.75 28.0684 22.75 26.7415C22.75 25.4145 23.2768 24.142 24.2145 23.2037C25.1521 22.2654 26.4239 21.7383 27.75 21.7383ZM27.75 25.4907V27.9923H31.5V25.4907H27.75Z"
        fill="#322A7C"
      />
    </svg>
  );
};

export const IconOne = ({ svgColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill={svgColor}
    >
      <circle cx="24" cy="24" r="24" fill="#ECD1A1" />
      <path
        d="M11.5 22.75H36.5V34C36.5 34.3315 36.3683 34.6495 36.1339 34.8839C35.8995 35.1183 35.5815 35.25 35.25 35.25H12.75C12.4185 35.25 12.1005 35.1183 11.8661 34.8839C11.6317 34.6495 11.5 34.3315 11.5 34V22.75ZM30.25 12.75H35.25C35.5815 12.75 35.8995 12.8817 36.1339 13.1161C36.3683 13.3505 36.5 13.6685 36.5 14V20.25H11.5V14C11.5 13.6685 11.6317 13.3505 11.8661 13.1161C12.1005 12.8817 12.4185 12.75 12.75 12.75H17.75V10.25H20.25V12.75H27.75V10.25H30.25V12.75Z"
        fill="#BD711A"
      />
      <path
        d="M18.264 25.905L18.616 27.291L19.958 26.961V33H21.63V25.245H20.475L18.264 25.905ZM30.6596 29.15V29.128C30.6596 28.5853 30.5606 28.083 30.3626 27.621C30.1646 27.1517 29.8823 26.7447 29.5156 26.4C29.1563 26.0553 28.7236 25.7877 28.2176 25.597C27.719 25.399 27.169 25.3 26.5676 25.3H23.5646V33H26.5676C27.169 33 27.719 32.901 28.2176 32.703C28.7236 32.505 29.1563 32.2337 29.5156 31.889C29.8823 31.5443 30.1646 31.1373 30.3626 30.668C30.5606 30.1987 30.6596 29.6927 30.6596 29.15ZM28.8886 29.172C28.8886 29.5167 28.83 29.8283 28.7126 30.107C28.6026 30.3857 28.445 30.6277 28.2396 30.833C28.0416 31.0383 27.7996 31.196 27.5136 31.306C27.2276 31.416 26.9123 31.471 26.5676 31.471H25.2586V26.829H26.5676C26.9123 26.829 27.2276 26.8877 27.5136 27.005C27.7996 27.115 28.0416 27.2727 28.2396 27.478C28.445 27.6833 28.6026 27.929 28.7126 28.215C28.83 28.501 28.8886 28.8127 28.8886 29.15V29.172Z"
        fill="white"
      />
    </svg>
  );
};
