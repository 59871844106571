import React, { useState } from "react";
import { getRefunds, deleteRefundById } from "../../common/api";
import { useMutation, useQuery } from "react-query";
import Table from "../../common/table/Table";
// import numberIcon from "../../images/numbericon.svg";
// import successIcon from "../../images/successfultransactionsicon.svg";
// import unSuccessIcon from "../../images/unsuccessfultransactionsicon.svg";
// import pendingIcon from "../../images/pendingtransactionsicon.svg";
// import RoundedPillWidget from "../widgets/RoundedPillWidget";
import Modal from "../../common/modal/Modal";
import DeleteModal from "../../common/modal/DeleteModal";
import { notify } from "../../common/toaster/Toaster";
import {
  amount,
  CURRENCY_SYMBOL,
  getServerError,
} from "../../common/utils/helpers";
import { useHeader } from "../context/UserProvider";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import RefundCutomerModal from "../../common/modal/RefundCustomerModal";
// import { SVG } from "../../common/icons/Icon";
import FilterBar from "../payouts/components/FilterBar";
import { hasPermission } from "../../common/withRole/WithPermission";
import { useTranslation } from "react-i18next";

const Refunds = ({ moduleName = "refund" }) => {
  useHeader("refunds");
  const [Tab, setTab] = useState(null);
  const [deleteThis, setDeleteThis] = useState(null);
  //   HOOKS
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    status: "",
    created_at__date__gte: null,
    created_at__date__lte: null,
    search: null,
    page: 1,
  });

  const {
    data,
    isLoading: areRefundsLoading,
    refetch,
  } = useQuery(
    [
      "getRefunds",
      `?${filters.search}${filters.page}${filters.status}${filters.created_at__date__gte}${filters.created_at__date__lte}`,
    ],
    () => getRefunds(filters),
    {
      retry: 1,
    }
  );
  const { isLoading, mutate: deleteKey } = useMutation(
    () => deleteRefundById(deleteThis),
    {
      onSuccess: () => {
        refetch();
        setDeleteThis(null);
        notify("Refund Deleted", "success");
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );
  return areRefundsLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <>
      {/* <div className="widgets mb-6">
        <RoundedPillWidget img={numberIcon} label="PAYOUTS" value="03" />
        <RoundedPillWidget img={successIcon} label="SUCCESSFUL" value="01" />
        <RoundedPillWidget
          img={unSuccessIcon}
          label="UNSSUCCESSFUL"
          value="0"
        />
        <RoundedPillWidget img={pendingIcon} label="PENDING" value="01" />
      </div> */}
      <div className="flex flex-wrap justify-between my-8">
        <div className="flex searchBar md:w-1/2">
          <FilterBar
            moduleName={moduleName}
            filters={filters}
            onFilterChange={(filter) => setFilters({ ...filters, ...filter })}
          />
        </div>
        <div className="md:w-1/3.5">
          <div className="barOptions">
            {/* <button className="rounded-btn p-2">{SVG.fileDownload}</button> */}
            {hasPermission(moduleName)("add") && (
              <button
                data-cy="add-btn"
                className="btn-orange"
                type="button"
                onClick={() => setTab(1)}
              >
                {t("addRefund")}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="boarding-card p-0 max-w-none">
        <Table
          showStatus={false}
          columns={[
            {
              lable: `Refund Amount (${CURRENCY_SYMBOL})`,
              accessor: "total_amount",
            },
            {
              lable: "Refund Nature",
              accessor: "refund_nature",
            },
            {
              lable: "Customer",
              accessor: "customer",
            },
            {
              lable: "Refund Id",
              accessor: "public_id",
              copyable: true,
            },
            {
              lable: "Date & Time",
              accessor: "created_at",
            },
            {
              lable: "Status",
              accessor: "status",
            },
          ]}
          rows={(data?.results ?? []).map(
            ({
              id,
              total_amount,
              invoice,
              created_at,
              transaction,
              status,
            }) => ({
              id: id,
              total_amount: amount(total_amount),
              created_at:
                new Date(created_at)
                  .toDateString()
                  .split(" ")
                  .slice(1)
                  .join(" ") +
                ", " +
                new Date(created_at).toLocaleTimeString(),
              refund_nature: invoice ? invoice.type : "N/A",
              customer: invoice
                ? invoice.email
                : transaction
                ? transaction.email
                : "N/A",
              public_id: invoice
                ? invoice.public_id
                : transaction
                ? transaction.transaction_id
                : "N/A",
              status,
            })
          )}
          onEditClicked={(rowData) => {
            rowData.refund_nature !== "N/A"
              ? navigate(`/edit-refund/${rowData.id}`)
              : navigate(`/edit-refund-transaction/${rowData.id}`);
          }}
          onRowClicked={(rowData) => {
            rowData.refund_nature !== "N/A"
              ? navigate(`/edit-refund/${rowData.id}`)
              : navigate(`/edit-refund-transaction/${rowData.id}`);
          }}
          onDeleteClicked={({ id }) => {
            setDeleteThis(id);
          }}
          dataCount={data?.count}
          activePage={filters.page}
          onPageClicked={(page) => {
            setFilters({ ...filters, page: page });
          }}
        />
      </div>
      {deleteThis && (
        <Modal close={() => setDeleteThis(null)}>
          <DeleteModal
            title={"Refund"}
            isLoading={isLoading}
            onClick={deleteKey}
          />
        </Modal>
      )}
      {Tab && (
        <Modal close={() => setTab(null)}>
          <RefundCutomerModal
            title={t("refundCustomer")}
            onSuccess={() => refetch()}
          />
        </Modal>
      )}
    </>
  );
};

export default Refunds;
