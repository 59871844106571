import React from "react";
import classNames from "classnames";
import { useQuery } from "react-query";
import { getAllOrganizations } from "../../../common/api";
import Label from "../../../common/form-controls/Label";
import withRole from "../../../common/withRole/WithRole";
import Selection from "../../../common/form-controls/Selection";
import { useTranslation } from "react-i18next";
const BussinessSelect = ({
  name,
  value = "",
  defaultValue = false,
  onChange,
  error = null,
  className = "",
  showLabel = true,
  ...props
}) => {
  const { t } = useTranslation();
  const { data } = useQuery(
    "getAllOrganizations",
    () =>
      getAllOrganizations({
        pagination: "False",
      }),
    {
      retry: 1,
    }
  );

  return (
    <div className={className}>
      {showLabel && <Label>{t("bussiness")} </Label>}
      <Selection
        name={name || "business"}
        options={(defaultValue
          ? [
              {
                id: null,
                business_name: "All Business",
              },
            ]
          : []
        )
          .concat(data?.response ?? [])
          .map(({ id, business_name }) => ({
            value: id,
            label: business_name ?? `business id: ${id}`,
          }))}
        defaultVal={
          defaultValue
            ? {
                value: null,
                label: "All Business",
              }
            : null
        }
        value={value}
        onChange={onChange}
        className={classNames([
          "bussiness-select",
          { "form-control": showLabel },
          { "is-invalid": error },
        ])}
        {...props}
      />
      {error && <span className="form-error">{error}</span>}
    </div>
  );
};

export default withRole("Admin")(BussinessSelect);
