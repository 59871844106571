import React from "react";
import withRole from "../withRole/WithRole";
import { useTranslation } from "react-i18next";

const ModeChip = ({ isLive = false }) => {
  const { t } = useTranslation();
  return (
    <span
      className={`${
        isLive
          ? "border-green-700 text-green-600"
          : "border-red-400 text-red-600"
      } mr-4 px-4 py-2 rounded-full border font-semibold text-sm flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease`}
    >
      {isLive ? (
        <>
          <div className="show-web">{t("liveMode")}</div>
          <div className="show-mobile"> {t("mobileLiveMode")}</div>
        </>
      ) : (
        <>
          <div className="show-web">{t("testMode")}</div>
          <div className="show-mobile">{t("mobileTestMode")}</div>
        </>
      )}
    </span>
  );
};

export default withRole("Merchant")(ModeChip);
