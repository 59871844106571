import React from "react";
import withRole from "../../common/withRole/WithRole";
import { useQuery } from "react-query";
import { getLiveBalance } from "../../common/api";
import { amount } from "../../common/utils/helpers";
import { useTranslation } from "react-i18next";

const LiveBalance = () => {
  const { t } = useTranslation();
  const { data } = useQuery("getLiveBalance", getLiveBalance, {
    refetchOnWindowFocus: false,
    retry: 1,
  });

  return (
    <span className="mr-4 px-4 py-2 rounded-full border font-semibold text-sm flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease">
      <div className="show-web">
        {t("liveBalance")} &nbsp; {amount(data?.response.balance ?? "N/A")}
      </div>
      <div className="show-mobile">
        {t("balanceOnMobile")} &nbsp; {amount(data?.response.balance ?? "N/A")}
      </div>
    </span>
  );
};

export default withRole("Merchant")(LiveBalance);
