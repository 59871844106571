import ConnectWallet from "./ConnectWallet";

const ConnectWalletWrapper = () => {
  return (
    <div className="connect-wallets">
      <ConnectWallet />
    </div>
  );
};

export default ConnectWalletWrapper;
