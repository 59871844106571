import { useHeader, useUser } from "../context/UserProvider";
import { useTranslation } from "react-i18next";
import ChangePassword from "../change-password/ChangePassword";
import { useState } from "react";
import DeleteModal from "../../common/modal/DeleteModal";
import Modal from "../../common/modal/Modal";
import HeaderFooterModal from "../../common/modal/HeaderFooterModal";
import LanguageDropdown from "../../common/language-dropdown/LanguageDropdown";
import TempLanguageDropdown from "../../common/language-dropdown/TempLanguageDropdown";
import { useMutation } from "react-query";
import { deleteAccount, logout } from "../../common/api";

const Settings = () => {
  useHeader("settings");
  const { t } = useTranslation();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const { user } = useUser();
  const { mutate: logoutUser } = useMutation(() => logout());
  const { isLoading, mutate: deleteUser } = useMutation(() => deleteAccount(), {
    onSuccess: () => {
      logoutUser();
    },
  });

  const onEdit = () => {
    setIsEditOpen(true);
  };

  const closeEditModal = () => {
    setIsEditOpen(false);
  };

  return (
    <section className="settings">
      <div className="account">
        <div className="card-heading maxMd:mx-4">
          <h3 className="text-lg font-gotham-regular">
            {t("settingContent.accountText")}
          </h3>
        </div>
        <div className="shadow px-4 py-8 sm:rounded-lg">
          <div className="account-settings__form">
            <form>
              <div className="form-group-row">
                <label>{t("settingContent.email")}</label>
                {user && <p>{user.email}</p>}
              </div>
              <div className="form-group-row">
                <label>{t("password")}</label>
                <p className="password">****************</p>
                <button className="btn-edit" type="button" onClick={onEdit}>
                  {t("changePassword")}
                </button>
              </div>
              <div className="form-group-row"></div>
            </form>
          </div>
        </div>
      </div>
      <div className="account">
        <div className="card-heading maxMd:mx-4">
          <h3 className="text-lg font-gotham-regular">
            {t("settingContent.languageText")}
          </h3>
        </div>
        <div className="shadow px-4 py-8 sm:rounded-lg">
          <div className="account-settings__form mb-4">
            <div className="form-group-row">
              <label>{t("dashboardLanguage")}</label>
              <LanguageDropdown withLable={true} />
            </div>
            <div className="form-group-row"></div>
          </div>
          {user.is_owner && (
            <div className="account-settings__form">
              <div className="form-group-row">
                <label>{t("templateLanguage")}</label>
                <TempLanguageDropdown withLable={true} />
              </div>
              <div className="form-group-row"></div>
            </div>
          )}
        </div>
      </div>
      {isEditOpen && (
        <HeaderFooterModal close={closeEditModal} heading="Change Password">
          <ChangePassword />
        </HeaderFooterModal>
      )}
      {isDeleteOpen && (
        <Modal close={() => setIsDeleteOpen(false)}>
          <DeleteModal
            title={t("deleteModal.deleteAccountTitle")}
            isLoading={isLoading}
            onClick={deleteUser}
          />
        </Modal>
      )}
    </section>
  );
};
export default Settings;
