import React, { useState, useMemo } from "react";
import { useFormik } from "formik";
import Button from "../../../../common/form-controls/Button";
import Table from "../../../../common/table/Table";
import classNames from "classnames";
import Input from "../../../../common/form-controls/Input";
import Label from "../../../../common/form-controls/Label";
import { useMutation } from "react-query";
import {
  createCustomer,
  deleteCustomerById,
  editCustomer,
} from "../../../../common/api";
import Switch from "../../../../common/switch/Switch";
import { CustomerSchema } from "./validation-schema";
import ButtonLoader from "../../../../common/form-controls/ButtonLoader";
import { notify } from "../../../../common/toaster/Toaster";
import { getServerError } from "../../../../common/utils/helpers";
import { SVG } from "../../../../../src/common/icons/Icon";
import Modal from "../../../../common/modal/Modal";
import DeleteModal from "../../../../common/modal/DeleteModal";
import { useTranslation } from "react-i18next";
import CitySelect from "../../../signup/components/CitySelect";
import CountrySelect from "../../../signup/components/CountrySelect";

const CustomersTab = ({ data: { response }, refetch }) => {
  const { t } = useTranslation();
  const [deleteThis, setDeleteThis] = useState(null);
  const [customerBeingEdited, setCustomerBeingEdited] = useState({
    customer: null,
    editMode: false,
  });

  const [createFormOpen, setCreateFormOpen] = useState(false);

  const { isLoading, mutate: deleteCustomerFn } = useMutation(
    () => deleteCustomerById(deleteThis),
    {
      onSuccess: () => {
        response.customers = response.customers.filter(
          (customer) => customer.id !== deleteThis
        );
        setDeleteThis(null);
        notify(t("customerDeleted"), "success");
        // navigate("/providers");
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );

  if (createFormOpen) {
    return (
      <CreateCustomerForm
        organization={{
          name: response.profile.business_name,
          id: response.profile.id,
        }}
        onSuccess={(customer) => {
          refetch();
          setCreateFormOpen(false);
        }}
      />
    );
  }

  return customerBeingEdited.customer ? (
    <CustomerForm
      initialData={customerBeingEdited.customer}
      editMode={customerBeingEdited.editMode}
      organization={{
        name: response.profile.business_name,
        id: response.profile.id,
      }}
    />
  ) : (
    <>
      <div className="section-head">
        <h1>
          {t("customers")} ( {response.profile.business_name} )
        </h1>
        <Button className="add-btn" onClick={() => setCreateFormOpen(true)}>
          {t("addCustomer")}
        </Button>
      </div>
      <div className="boarding-card p-0 max-w-none">
        <Table
          columns={[
            {
              lable: "Name",
              accessor: "name",
            },
            {
              lable: "Email",
              accessor: "email",
            },
          ]}
          rows={(response?.customers ?? []).map(
            ({ name, email, is_active, id }) => ({
              id: id,
              name,
              email,
              status: is_active ? "Active" : "Inactive",
              isActive: is_active,
            })
          )}
          onRowClicked={({ id: customerID }) => {
            setCustomerBeingEdited({
              customer: response.customers.find(({ id }) => id === customerID),
              editMode: false,
            });
          }}
          onEditClicked={({ id: customerID }) => {
            setCustomerBeingEdited({
              customer: response.customers.find(({ id }) => id === customerID),
              editMode: true,
            });
          }}
          onDeleteClicked={({ id }) => {
            setDeleteThis(id);
          }}
        />
      </div>
      {deleteThis && (
        <Modal close={() => setDeleteThis(null)}>
          <DeleteModal
            title="Customer"
            isLoading={isLoading}
            onClick={deleteCustomerFn}
          />
        </Modal>
      )}
    </>
  );
};

export default CustomersTab;

export const CustomerForm = ({
  initialData,
  organization,
  editMode = false,
}) => {
  const [isEditable, setIsEditable] = useState(editMode);
  const { t } = useTranslation();
  const initialFormatedData = useMemo(() => {
    return initialData
      ? {
          name: initialData.name,
          email: initialData.email,
          business: initialData.business,
          country: initialData.country,
          city: initialData.city,
          is_active: initialData.is_active,
          details: Array.isArray(initialData.details)
            ? initialData.details
            : [],
        }
      : null;
  }, [initialData]);

  const { isLoading, mutate: editCustomerFn } = useMutation(
    (values) => editCustomer(initialData.id, values),
    {
      onSuccess: () => {
        setIsEditable(false);
        notify(t("customerUpdated"), "success");
        // navigate("/organizations", { replace: true });
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );

  const formik = useFormik({
    initialValues: initialFormatedData,
    validationSchema: CustomerSchema,
    validateOnChange: false,
    onSubmit: editCustomerFn,
  });

  return (
    <div className="tab-content">
      <div className="section-head">
        <h1>{t("customer")}</h1>
        <button className="edit-btn" onClick={() => setIsEditable(!isEditable)}>
          {isEditable ? "Cancel" : "Edit"}
        </button>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-wrap -mx-3 justify-between">
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("name")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.name,
                },
              ])}
            >
              <Input
                disabled={!isEditable}
                placeholder={t("name")}
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </div>
            {formik.errors.name && (
              <span className="form-error">{formik.errors.name}</span>
            )}
          </div>
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("email")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.email,
                },
              ])}
            >
              <Input
                disabled={!isEditable}
                placeholder={t("email")}
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </div>
            {formik.errors.email && (
              <span className="form-error">{formik.errors.email}</span>
            )}
          </div>
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("organizations")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.business,
                },
              ])}
            >
              <Input
                disabled
                placeholder={t("organization")}
                value={organization.name}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 justify-between">
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("country")}</Label>

            <CountrySelect
              disabled={!isEditable}
              className={`form-control country-select select required ${
                formik.errors.country ? "is-invalid" : ""
              }`}
              onChange={(countryInfo) => {
                formik.setFieldValue("country", countryInfo.value);
                formik.setFieldValue("city", "");
              }}
              value={formik.values.country}
              placeholder="SelectCountry"
            />
            {formik.errors.country && (
              <span className="form-error">{formik.errors.country}</span>
            )}
          </div>
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("city")}</Label>
            <CitySelect
              disabled={!isEditable}
              className={`form-control city-select select required ${
                formik.errors.city ? "is-invalid" : ""
              }`}
              countryId={formik.values.country}
              onChange={(value) => formik.setFieldValue("city", value)}
              value={formik.values.city}
              placeholder="SelectCity"
            />
            {formik.errors.city && (
              <span className="form-error">{formik.errors.city}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <Label>{t("status")}</Label>
          <Switch
            name="is_active"
            disabled={!isEditable}
            isChecked={formik.values.is_active}
            onChange={formik.handleChange}
          />
        </div>

        <div className="form-group">
          <div className="section-head">
            <Label>{t("Details")}</Label>
            <Button
              className="add-btn"
              onClick={() => {
                formik.setFieldValue("details", [
                  ...formik.values.details,
                  {
                    id: formik.values.details.length,
                    key: "",
                    value: "",
                  },
                ]);
              }}
            >
              {t("addDetail")}
            </Button>
          </div>

          {formik.values.details.map(({ key, value }, index) => (
            <div className="flex flex-wrap -mx-3 justify-between">
              <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
                <Input
                  disabled={!isEditable}
                  placeholder="Key"
                  value={key}
                  onChange={(e) => {
                    formik.setFieldValue(
                      `details[${index}].key`,
                      e.target.value
                    );
                  }}
                />
              </div>
              <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
                <Input
                  disabled={!isEditable}
                  placeholder="value"
                  value={value}
                  onChange={(e) => {
                    formik.setFieldValue(
                      `details[${index}].value`,
                      e.target.value
                    );
                  }}
                />
              </div>
            </div>
          ))}
        </div>

        {isEditable && (
          <Button type="submit" disabled={isLoading}>
            {isLoading ? <ButtonLoader text={t("saving")} /> : t("save")}
          </Button>
        )}
      </form>
    </div>
  );
};

export const CreateCustomerForm = ({ organization, onSuccess }) => {
  const { t } = useTranslation();
  const { isLoading, mutate: createCustomerFn } = useMutation(
    (values) => createCustomer(values),
    {
      onSuccess: (data) => {
        onSuccess(data);
        notify(t("customerCreated"), "success");
        // navigate("/organizations", { replace: true });
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      business: organization.id,
      country: null,
      city: null,
      is_active: false,
      details: [],
    },
    validationSchema: CustomerSchema,
    validateOnChange: false,
    onSubmit: createCustomerFn,
  });

  return (
    <div className="tab-content">
      <div className="section-head">
        <h1>{t("createCustomer")}</h1>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-wrap -mx-3 justify-between">
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("name")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.name,
                },
              ])}
            >
              <Input
                placeholder={t("name")}
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </div>
            {formik.errors.name && (
              <span className="form-error">{formik.errors.name}</span>
            )}
          </div>
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("email")}</Label>
            <div
              className={classNames([
                {
                  "is-invalid": formik.errors.email,
                },
              ])}
            >
              <Input
                placeholder={t("email")}
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </div>
            {formik.errors.email && (
              <span className="form-error">{formik.errors.email}</span>
            )}
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 justify-between">
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("country")}</Label>

            <CountrySelect
              className={`form-control country-select select required ${
                formik.errors.country ? "is-invalid" : ""
              }`}
              onChange={(countryInfo) => {
                formik.setFieldValue("country", countryInfo.value);
                formik.setFieldValue("city", "");
              }}
              value={formik.values.country}
              placeholder="SelectCountry"
            />
            {formik.errors.country && (
              <span className="form-error">{formik.errors.country}</span>
            )}
          </div>
          <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
            <Label>{t("city")}</Label>
            <CitySelect
              className={`form-control city-select select required ${
                formik.errors.city ? "is-invalid" : ""
              }`}
              countryId={formik.values.country}
              onChange={(value) => formik.setFieldValue("city", value)}
              value={formik.values.city}
              placeholder="SelectCity"
            />
            {formik.errors.city && (
              <span className="form-error">{formik.errors.city}</span>
            )}
          </div>
        </div>

        <div className={classNames("w-full md:w-1/2 mb-6 ")}>
          <Label>{t("organization")}</Label>
          <div
            className={classNames([
              {
                "is-invalid": formik.errors.business,
              },
            ])}
          >
            <Input
              disabled
              placeholder={t("organization")}
              value={organization.name}
            />
          </div>
        </div>

        <div className="form-group">
          <Label>{t("status")}</Label>
          <Switch
            name="is_active"
            isChecked={formik.values.is_active}
            onChange={formik.handleChange}
          />
        </div>

        <div className="form-group">
          <div className="section-head">
            <Label>{t("details")}</Label>
            <Button
              className="add-btn"
              onClick={() => {
                formik.setFieldValue("details", [
                  ...formik.values.details,
                  {
                    id: formik.values.details.length,
                    key: "",
                    value: "",
                  },
                ]);
              }}
            >
              {t("addDetail")}
            </Button>
          </div>

          {formik.values.details.map(({ key, value }, index) => (
            <div className="flex flex-wrap -mx-3 justify-between">
              <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
                <Input
                  placeholder={t("key")}
                  value={key}
                  onChange={(e) => {
                    formik.setFieldValue(
                      `details[${index}].key`,
                      e.target.value
                    );
                  }}
                />
              </div>
              <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
                <Input
                  placeholder={t("value")}
                  value={value}
                  onChange={(e) => {
                    formik.setFieldValue(
                      `details[${index}].value`,
                      e.target.value
                    );
                  }}
                />
              </div>
              <span
                className="keyDelete"
                onClick={() =>
                  formik.setFieldValue(
                    "details",
                    formik.values.details.filter(({ id }) => index !== id)
                  )
                }
              >
                {SVG.delete}
              </span>
            </div>
          ))}
        </div>

        <Button type="submit" disabled={isLoading}>
          {isLoading ? <ButtonLoader text={t("saving")} /> : t("save")}
        </Button>
      </form>
    </div>
  );
};
