import { useContext } from "react";
import { Link } from "react-router-dom";
import { WALLET_ADDED } from "../../constant/constant";
import { SyncWalletContext } from "../../context/SyncWalletContext";
import Button from "../../../common/form-controls/Button";
const ConnectWallet = () => {
  const { setScreen } = useContext(SyncWalletContext);

  const onclick = () => {
    setScreen(WALLET_ADDED);
  };

  return (
    <div className="card">
      <div className="card-header">Welcome to SAPPAY</div>
      <p>
        Connect all your wallets and exchanges so we can calculate your
        cryptocurrency taxes and generate your tax forrms.
      </p>
      <ul>
        <li>
          <h5>1 Exchange</h5>
          <p>Data import in progress...</p>
        </li>
      </ul>
      <Button className="w-full" onClick={onclick}>
        + Add Wallet
      </Button>
      <div className="connect-wallets__bottom">
        Psst! you can also{" "}
        <Link to="/" className="orange-link">
          manually add your tansactions
        </Link>
      </div>
    </div>
  );
};

export default ConnectWallet;
