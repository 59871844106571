module.exports = {
  REACT_APP_BACKEND_URL:
    process.env.REACT_APP_BACKEND_URL ?? "https://api.prod.sappay.net",
  REACT_APP_CHECKOUT_URL:
    process.env.REACT_APP_CHECKOUT_URL ?? "https://sandbox.checkout.sappay.net",
  REACT_APP_FUNDRAISING_URL:
    process.env.REACT_APP_FUNDRAISING_URL ??
    "https://sappay-fundraising.web.app",
  REACT_APP_COUNTRY_API_URL: "https://api.countrystatecity.in",
  REACT_APP_COUNTRY_API_KEY:
    "OWhSOW45dHdCanFkeEtkb0MxYXNydUhBQzFkMW5mdHI2NktRZ0Z4ZA==",
  CURRENCY_SYMBOL: "XOF",
  CURRENCY_SYMBOL_ON_LEFT: "false",
  FIREBASE_VAPIKEY:
    "BE59c36x3fE2Um9xuTXwuGqNbcjC8UFXRkhgDvf3fcZ5xkzZd7XIEt5DqJnV4IcpGmY_7qzgIxrfR9_9yfbxY3U",
};
