import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { BeatLoader } from "react-spinners";
import { deleteMemberMerchant, getAllMembers } from "../../common/api";
import { useNavigate } from "react-router-dom";
import Table from "../../common/table/Table";
// import numberIcon from "../../images/numbericon.svg";
// import successIcon from "../../images/successfultransactionsicon.svg";
// import unSuccessIcon from "../../images/unsuccessfultransactionsicon.svg";
// import pendingIcon from "../../images/pendingtransactionsicon.svg";
// import RoundedPillWidget from "../widgets/RoundedPillWidget";
import { notify } from "../../common/toaster/Toaster";
import { getServerError } from "../../common/utils/helpers";
import { useHeader } from "../context/UserProvider";
import { useTranslation } from "react-i18next";
// import { SVG } from "../../common/icons/Icon";
import { hasPermission } from "../../common/withRole/WithPermission";
import Modal from "../../common/modal/Modal";
import DeleteModal from "../../common/modal/DeleteModal";
import FilterBar from "../payouts/components/FilterBar";
import WithPermission from "../../common/withRole/WithPermission";
import { Link } from "react-router-dom";
import { useUser } from "../context/UserProvider";

const Members = ({ moduleName = "member" }) => {
  useHeader("members");
  const [deleteThis, setDeleteThis] = useState(null);
  const { user } = useUser();
  //   HOOKS
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [filters, setFilters] = useState({
    status: "",
    created_at__date__gte: null,
    created_at__date__lte: null,
    search: null,
    page: 1,
  });

  const { data, isLoading: areMembersLoading } = useQuery(
    [
      "getAllMembers",
      `?${filters.search}${filters.page}${filters.status}${filters.created_at__date__gte}${filters.created_at__date__lte}`,
    ],
    () => getAllMembers(filters),
    {
      retry: 1,
    }
  );

  const { isLoading, mutate: deleteMemberFn } = useMutation(
    () => deleteMemberMerchant(deleteThis),
    {
      onSuccess: () => {
        setDeleteThis(null);
        notify(t("MemberDeleted"), "success");
      },
      onSettled: () => queryClient.invalidateQueries("getAllMembers"),
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );

  const columns = [
    {
      lable: "Name",
      accessor: "name",
    },
    {
      lable: "Email",
      accessor: "email",
    },
    {
      lable: "Role",
      accessor: "role",
    },
    {
      lable: "Bussiness Name",
      accessor: "business_name",
    },
  ];

  return areMembersLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <>
      {/* <div className="widgets mb-6">
        <RoundedPillWidget img={numberIcon} label="PAYOUTS" value="03" />
        <RoundedPillWidget img={successIcon} label="SUCCESSFUL" value="01" />
        <RoundedPillWidget
          img={unSuccessIcon}
          label="UNSSUCCESSFUL"
          value="0"
        />
        <RoundedPillWidget img={pendingIcon} label="PENDING" value="01" />
      </div> */}
      <div className="flex flex-wrap justify-between my-8">
        <div className="flex searchBar md:w-1/2">
          <FilterBar
            moduleName={moduleName}
            filters={filters}
            onFilterChange={(filter) => setFilters({ ...filters, ...filter })}
          />
        </div>
        <div className="md:w-1/3.5">
          <div className="barOptions">
            {/* <button className="rounded-btn p-2">{SVG.fileDownload}</button> */}
            {hasPermission(moduleName)("add") && (
              <Link to="/members/add">
                <button data-cy="add-btn" className="btn-orange" type="button">
                  {t("addMember")}
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="boarding-card p-0 max-w-none">
        <Table
          moduleName={moduleName}
          columns={
            user.user_role === "Admin"
              ? columns
              : columns.filter((column) => column.accessor !== "business_name")
          }
          rows={(data.results ?? []).map(
            ({
              role,
              is_active,
              id,
              email,
              business_name,
              first_name,
              last_name,
            }) => ({
              id: id,
              name: `${first_name} ${last_name}`,
              email,
              business_name,
              role,
              status: is_active ? "Active" : "Inactive",
              isActive: is_active,
            })
          )}
          onEditClicked={({ id: memberID }) => {
            navigate(`/members/${memberID}`);
          }}
          onRowClicked={({ id: memberID }) => {
            navigate(`/members/${memberID}`);
          }}
          onDeleteClicked={({ id }) => {
            setDeleteThis(id);
          }}
          dataCount={data?.count}
          activePage={filters.page}
          onPageClicked={(page) => {
            setFilters({ ...filters, page: page });
          }}
        />
      </div>
      {deleteThis && (
        <Modal close={() => setDeleteThis(null)}>
          <DeleteModal
            title="Members"
            isLoading={isLoading}
            onClick={deleteMemberFn}
          />
        </Modal>
      )}
    </>
  );
};

export default WithPermission("member")("view")(Members);
