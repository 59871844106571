import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import downArrowImg from "../../../../images/downarrow.svg";
import AccordionModal from "./AccordionModal";
import Modal from "../../../../common/modal/Modal";
import g_icon from "../../../../images/g.svg";
import { formatDistance } from "date-fns";
import i18next from "i18next";
import Dropdown from "../../../../common/dropdown-menu/DropdownMenu";

const Accordion = ({ walletItem, children }) => {
  const [accordionOpen, setAccordionOpen] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const accordionRef = useRef(null);

  const accordionToggle = () => {
    accordionOpen !== null && accordionOpen
      ? setAccordionOpen(false)
      : setAccordionOpen(true);
  };

  useEffect(() => {
    if (accordionOpen !== null && !accordionOpen) {
      setTimeout(() => {
        accordionRef.current.classList.add("rounded-b-xl");
      }, 350);
      return;
    } else {
      accordionRef.current.classList.add("rounded-b-xl");
    }
  }, [accordionOpen]);

  const modalHeader = () => {
    return (
      <div className="flex items-center">
        <img src={g_icon} alt="g-icon" />
        <h3 className="heading-lg ml-4">Guiden</h3>
      </div>
    );
  };

  return (
    <div className="accordion-container relative">
      <div
        className={classnames(
          {
            "accordion-list": accordionOpen,
            "opacity-50": !walletItem.is_active,
          },
          "accordion relative"
        )}
      >
        <li
          ref={accordionRef}
          onClick={() => accordionToggle()}
          className={classnames({ open: accordionOpen }, "accordion-title")}
        >
          <div>
            <h3>{walletItem.wallet.name}</h3>
            <p>
              {i18next.t("accordion.accordionTitle", {
                value: `${formatDistance(
                  new Date(walletItem.updated_at),
                  new Date()
                )} `,
              })}
            </p>
          </div>
          <h2>
            {`${walletItem.total_balance.slice(0, 2)} ${parseFloat(
              walletItem.total_balance.slice(2)
            ).toFixed(2)}`}
            <img src={downArrowImg} alt="down-arrow" />
          </h2>
        </li>
        <div
          className={classnames(
            { " collapsed": !accordionOpen },
            "accordion-item"
          )}
        >
          {walletItem.wallet_details.map((coin, index) => (
            <li key={index}>
              <div className="accordion-item__list">
                <img src={coin.icon} alt="faircoin" />
                <div>
                  <h3>{coin.name}</h3>
                  {/* <button className="tag-design">
                    {coin.transaction_count} Transactions
                  </button> */}
                </div>
                <div className="ml-auto sm:mr-3">
                  <h2>
                    {`${coin.transaction_amount.slice(0, 2)} ${parseFloat(
                      coin.transaction_amount.slice(2)
                    ).toFixed(2)}`}
                  </h2>

                  <p>42.93 {coin.symbol}</p>
                </div>
              </div>
            </li>
          ))}
        </div>
      </div>
      <div
        onClick={(event) => event.stopPropagation(true)}
        className="accordion-dropdown"
      >
        <Dropdown>{children}</Dropdown>
      </div>

      {isModalOpen && (
        <Modal
          modalHeader={modalHeader}
          modalSize={"modal-lg"}
          close={() => setIsModalOpen(false)}
        >
          <AccordionModal />
        </Modal>
      )}
    </div>
  );
};
export default Accordion;
