import React from "react";
import Label from "../../common/form-controls/Label";

const RoundedPillWidget = ({ img, label = "", value = "" }) => {
  return (
    <div className="border shadow rounded-lg p-6 mb-3 flex gap-2">
      <img className="icon" src={img} alt={`${label} icon`} />
      <div className="detail">
        <Label>{label}</Label>
        <h5 className="widget-value">{value}</h5>
      </div>
    </div>
  );
};

export default RoundedPillWidget;
