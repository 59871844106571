import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const Selection = ({
  options,
  onChange,
  value,
  textColor,
  placeholder,
  isMulti = false,
  defaultVal = {
    value: null,
    label: null,
  },
  ...props
}) => {
  const { t } = useTranslation();
  const defaultValue = (options, value) => {
    return options
      ? Array.isArray(value)
        ? value.reduce((acc, val) => {
            const option = options.find((option) => option.value === val);
            if (option) acc.push(option);
            return acc;
          }, [])
        : options.find((option) => option.value === value)
      : defaultVal;
  };

  const styleLangSelect = {
    menu: (styles) => ({
      ...styles,
      width: "100%",
      color: "black",
    }),
    control: (base) => ({
      ...base,
      border: "0 !important",
      background: "transparent !important",
      boxShadow: "0 !important",
      cursor: "pointer",
      height: "unset !important",
      minHeight: "unset",
      padding: "0 !important",
    }),
    option: (provided) => ({
      ...provided,
      marginBottom: "4px",
      cursor: "pointer",
      overflow: "hidden",
      "&:hover": {
        background: "rgb(131 135 142 / 66%)",
      },
      "&:focus": {
        background: "transparent",
      },
    }),
    menuList: (styles) => ({
      ...styles,
      borderRadius: "0.625rem",
      paddingTop: "10px",
      paddingBottom: "10px",
    }),
    container: (styles) => ({
      ...styles,
      // margin: "-0.4rem",
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      color: textColor,
    }),
    indicatorContainer: (styles) => ({
      ...styles,
      color: textColor,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: textColor,
    }),
  };

  return (
    <Select
      placeholder={t(placeholder)}
      className={`${props.disabled ? "input-select-disabled" : ""} `}
      isDisabled={props.disabled}
      styles={styleLangSelect}
      isMulti={isMulti}
      options={options}
      onChange={(value) => onChange(value)}
      value={defaultValue(options, value)}
      defaultValue={defaultVal}
      {...props}
    />
  );
};

export default Selection;
