import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { createMemberMerchant } from "../../common/api";
import { notify } from "../../common/toaster/Toaster";
import MemberForm from "./components/MemberForm";

function MemberAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoading, mutate, error } = useMutation(
    (values) => createMemberMerchant(values),
    {
      onSuccess: () => {
        navigate("/members");
        notify(t("memberCreate"), "success");
      },
    }
  );
  return (
    <div className="tab-content">
      <div className="section-head">
        <h1>{t("createMember")}</h1>
      </div>
      <MemberForm onSubmit={mutate} isLoading={isLoading} error={error} />
    </div>
  );
}

export default MemberAdd;
