import React, { useState } from "react";
import { getAllProviders, deleteProviderById } from "../../common/api";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import Table from "../../common/table/Table";
import Modal from "../../common/modal/Modal";
import DeleteModal from "../../common/modal/DeleteModal";
import { notify } from "../../common/toaster/Toaster";
import { getServerError } from "../../common/utils/helpers";
import { useHeader } from "../context/UserProvider";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { hasPermission } from "../../common/withRole/WithPermission";
import FilterBar from "../payouts/components/FilterBar";
import WithPermission from "../../common/withRole/WithPermission";

const ProviderTable = ({ moduleName = "provider" }) => {
  useHeader("providers");
  const [pageNo, setPageNo] = useState("");
  const [deleteThis, setDeleteThis] = useState(null);
  //   HOOKS
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    status: "",
    created_at__date__gte: null,
    created_at__date__lte: null,
    search: null,
    page: 1,
  });
  const { data, isLoading: areProvidersLoading } = useQuery(
    [
      "getAllProviders",
      `?${filters.search}${filters.page}${filters.status}${filters.created_at__date__gte}${filters.created_at__date__lte}`,
    ],
    () => getAllProviders(filters),
    {
      retry: 1,
    }
  );

  const { isLoading, mutate: deleteProvider } = useMutation(
    () => deleteProviderById(deleteThis),
    {
      onSuccess: () => {
        data.results = data.results.filter(
          (provider) => provider.id !== deleteThis
        );
        setDeleteThis(null);
        notify("Provider Deleted", "success");
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );

  return areProvidersLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <>
      <div className="flex flex-wrap justify-between my-8">
        <div className="flex searchBar md:w-1/2">
          <FilterBar
            moduleName={moduleName}
            filters={filters}
            onFilterChange={(filter) => setFilters({ ...filters, ...filter })}
          />
        </div>
        <div className="md:w-1/3.5">
          <div className="barOptions">
            {/* <button className="rounded-btn p-2">{SVG.fileDownload}</button> */}
            {hasPermission(moduleName)("add") && (
              <Link to="/providers/add">
                <button className="btn-orange" type="button">
                  Add Provider
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>

      <div className="boarding-card p-0 max-w-none">
        <Table
          moduleName={moduleName}
          allowDelete={false}
          columns={[
            {
              lable: "Name",
              accessor: "name",
            },
            {
              lable: "Type",
              accessor: "provider_type",
            },
          ]}
          rows={(data?.results ?? []).map(
            ({ name, provider_type, id, active }) => ({
              id: id,
              name,
              provider_type,
              status: active ? "Active" : "Inactive",
              isActive: active,
            })
          )}
          onEditClicked={({ id }) => {
            navigate(`/providers/edit/${id}`);
          }}
          onRowClicked={({ id }) => {
            navigate(`/providers/edit/${id}`);
          }}
          onDeleteClicked={({ id }) => {
            setDeleteThis(id);
          }}
          dataCount={data?.count}
          activePage={pageNo}
          onPageClicked={(page) => {
            setPageNo(page);
          }}
        />
      </div>
      {deleteThis && (
        <Modal close={() => setDeleteThis(null)}>
          <DeleteModal
            title={"provider"}
            isLoading={isLoading}
            onClick={deleteProvider}
          />
        </Modal>
      )}
    </>
  );
};

export default WithPermission("provider")("view")(ProviderTable);
