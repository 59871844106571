import React, { useState } from "react";
import Input from "../../common/form-controls/Input";
import Label from "../../common/form-controls/Label";
import { useFormik } from "formik";
import { SimInvoiceSchema } from "../../components/invoices/validation-schema";
import Success from "./Success";
import { SVG } from "../icons/Icon";
import { useMutation } from "react-query";
import { amount, getServerError } from "../../common/utils/helpers";
import { notify } from "../../common/toaster/Toaster";
import { addInvoice } from "../../common/api";
import ButtonLoader from "../form-controls/ButtonLoader";
import { useUser } from "../../components/context/UserProvider";
import BussinessSelect from "../../components/customers/components/BussinessSelect";
import { useTranslation } from "react-i18next";
const SimInvoiceCusModal = ({ title, close }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [Tab, setTab] = useState(0);
  const [publicId, setpublicId] = useState(false);

  const { isLoading, mutate } = useMutation((values) => addInvoice(values), {
    onSuccess: (data) => {
      setTab(2);
      notify(t("invoiceCreated"), "success");
      setpublicId(data.response.public_id);
    },
    onError: (error) => {
      notify(getServerError(error), "error");
    },
  });

  const formik = useFormik({
    initialValues: {
      type: "SIMPLE",
      customer: {
        email: "",
      },
      amount: "",
      note: "",
    },
    onSubmit: (values) => mutate(values),
    validationSchema: SimInvoiceSchema,
    validateOnChange: false,
  });

  return (
    <div className="invoiceModal">
      {Tab === 0 && (
        <>
          <div className="modalIcon">{SVG.message}</div>
          <h3>{title}</h3>
          <form>
            <div className="form-group">
              <BussinessSelect
                className="flex flex-wrap"
                name="business"
                value={parseInt(formik.values.business)}
                onChange={(value) =>
                  formik.setFieldValue("business", value.value)
                }
                error={formik.errors.business}
              />
            </div>
            <div className="form-group">
              <Label>{t("email")}</Label>
              <Input
                required
                name="customer.email"
                onChange={formik.handleChange}
                value={formik.values.customer.email}
              />
              {formik.errors.customer?.email && (
                <span className="form-error">
                  {formik.errors.customer.email}
                </span>
              )}
            </div>
            <div className="form-group">
              <Label>{t("amount")}</Label>
              <Input
                required
                name="amount"
                onChange={formik.handleChange}
                value={formik.values.amount}
              />
              {formik.errors.amount && (
                <span className="form-error">{formik.errors.amount}</span>
              )}
            </div>
            <div className="form-group">
              <Label>{t("note")}</Label>
              <textarea
                name="note"
                onChange={formik.handleChange}
                value={formik.values.note}
                className="form-control"
              />
              {formik.errors.note && (
                <span className="form-error">{formik.errors.note}</span>
              )}
            </div>
            <button
              data-cy="send-request"
              className="btn-orange"
              type="button"
              onClick={() => {
                formik
                  .validateForm()
                  .then(
                    (errors) => Object.keys(errors).length === 0 && setTab(1)
                  );
              }}
            >
              {t("sendRequest")}
            </button>
            <button
              style={{ width: "100%" }}
              className="btn-brightgray mt-4"
              type="button"
              onClick={close}
            >
              {t("close")}
            </button>
          </form>
        </>
      )}
      {Tab === 1 && (
        <>
          <div className="modalIcon">{SVG.review}</div>
          <h3>{t("reviewRequest")}</h3>
          <form onSubmit={formik.handleSubmit}>
            <div className="modalInline">
              <h4>
                <b>{t("senderinvoice")}</b> <span>{user.email}</span>
              </h4>
              <h4>
                <b>{t("receiverInvoice")} </b>{" "}
                <span>{formik.values.customer.email}</span>
              </h4>
              <h4>
                <b>{t("totalInvoice")} </b>{" "}
                <span>{amount(formik.values.amount)}</span>
              </h4>
            </div>
            <button className="btn-orange" type="submit">
              {isLoading ? (
                <ButtonLoader text={t("sending")} />
              ) : (
                `${t("send")}`
              )}
            </button>
            <button
              style={{ width: "100%" }}
              className="btn-brightgray mt-4"
              type="button"
              onClick={() => setTab(0)}
            >
              {t("editRequest")}
            </button>
          </form>
        </>
      )}
      {Tab === 2 && (
        <Success
          title={t("submitted")}
          msg={`${t("requestMessage")} ${publicId} ${t("sentMessage")} ${
            formik.values.customer.email
          }`}
          id={publicId}
        />
      )}
    </div>
  );
};

export default SimInvoiceCusModal;
