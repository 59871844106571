import { MenuItem } from "@szhsin/react-menu";
import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import {
  walletDelete,
  walletsDeactivate,
  walletsReactive,
} from "../../../../common/api";
import DeleteModal from "../../../../common/modal/DeleteModal";
import Modal from "../../../../common/modal/Modal";
import Switch from "../../../../common/switch/Switch";
import { notify } from "../../../../common/toaster/Toaster";
import delete_icon from "../../../../images/delete.svg";

const WalletActions = ({
  id,
  isActive,
  deleteWalletAccordion,
  reActiveWalletAcoordion,
  deActiveWalletAcoordion,
}) => {
  const { t } = useTranslation();

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const { mutate: deActivateWallet, isError, isLoading } = useMutation(
    (value) => walletsDeactivate(value),
    {
      onSuccess: () => {
        deActiveWalletAcoordion(id);
        notify("successfully deactivated");
      },
      onError: (error) => {
        notify(error.response.data.message, "error");
      },
    }
  );

  const { mutate: reActiveWallet } = useMutation(
    (value) => walletsReactive(value),
    {
      onSuccess: () => {
        reActiveWalletAcoordion(id);
        notify("successfully reactivated");
      },
      onError: (error) => {
        notify(error.response.data.message, "error");
      },
    }
  );

  const { mutate: deleteWallet } = useMutation((value) => walletDelete(value), {
    onSuccess: () => {
      deleteWalletAccordion(id);
      notify("successfully deleted");
      setIsDeleteOpen(false);
    },
    onError: (error) => {
      notify(error.response.data.message, "error");
    },
  });

  const switchHandle = (e) => {
    const checked = e.target.checked;
    if (!checked) {
      deActivateWallet({ id });
    } else {
      reActiveWallet({ id });
    }
  };

  return (
    <div>
      <MenuItem>
        <Switch
          isError={isError}
          onChange={switchHandle}
          isChecked={isActive}
          className="switch-on-off"
          name="accordion-switch"
        />
        <div
          className={classNames(
            isActive ? "text-seagreen" : "text-cinnabar",
            "switch-on-off__label"
          )}
        >
          {" "}
          {isActive ? "Activated" : "Deactivated"}
        </div>
      </MenuItem>
      <MenuItem onClick={() => setIsDeleteOpen(true)}>
        <img src={delete_icon} alt="delete_icon" />
        Delete
      </MenuItem>
      {isDeleteOpen && (
        <Modal close={() => setIsDeleteOpen(false)}>
          <DeleteModal
            title={t("deleteModal.walletAccountTitle")}
            onClick={() => deleteWallet({ id })}
            isLoading={isLoading}
          />
        </Modal>
      )}
    </div>
  );
};

export default WalletActions;
