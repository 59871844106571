import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { editMemberMerchant, getMemberMerchant } from "../../common/api";
import { notify } from "../../common/toaster/Toaster";
import MemberForm from "./components/MemberForm";

const MemberEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(["member", id], () =>
    getMemberMerchant(id)
  );

  const { mutate, error } = useMutation((values) => editMemberMerchant(id, values), {
    onSuccess: (data) => {
      notify(t("memberUpdate"), "success");
      navigate("/members");
    }
  });
  return isLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <div className="tab-content">
      <div className="section-head">
        <h1>{t("editMember")}</h1>
      </div>
      <MemberForm initialValues={data?.response} onSubmit={mutate} error = {error} />
    </div>
  );
};

export default MemberEdit;
