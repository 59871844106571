import React from "react";
import { useUser } from "../../components/context/UserProvider";

export const hasPermission = (moduleName) => (permissionsName) => {
  const {
    user: { permissions },
  } = useUser();

  if (
    permissions.hasOwnProperty(moduleName) &&
    Array.isArray(permissionsName)
  ) {
    return permissions[moduleName].some((permission) =>
      permissionsName.some((name) => permission.startsWith(name))
    );
  }
  if (
    permissions.hasOwnProperty(moduleName) &&
    typeof permissionsName === "string"
  ) {
    return permissions[moduleName].some((e) => e.startsWith(permissionsName));
  }
  return false;
};

const WithPermission =
  (moduleName) => (permissionsName) => (Component) => (props) => {
    if (hasPermission(moduleName)(permissionsName)) {
      return <Component {...props} />;
    }
    return "No permission";
  };

export default WithPermission;
