import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import AsyncSelect from "react-select/async";
import { getCities } from "../../../common/api";
import { useTranslation } from "react-i18next";

const convertToOptions = (cities) => {
  return cities.map((city) => ({
    label: city.name,
    value: city.id,
  }));
};

const CitySelect = ({
  placeholder,
  onChange,
  className,
  textColor,
  countryId,
  displaceMenu = "-50px",
  value,
}) => {
  const { t } = useTranslation();

  const styleLangSelect = {
    menu: (styles) => ({
      ...styles,
      width: "100%",
      transform: `translateX(${displaceMenu})`,
      color: "black",
    }),
    control: (base) => ({
      ...base,
      border: "0 !important",
      background: "transparent !important",
      boxShadow: "0 !important",
      cursor: "pointer",
      height: "unset !important",
      minHeight: "unset",
      padding: "0 !important",
    }),
    option: (provided) => ({
      ...provided,
      marginBottom: "4px",
      cursor: "pointer",
      overflow: "hidden",
      "&:hover": {
        background: "rgb(131 135 142 / 66%)",
      },
      "&:focus": {
        background: "transparent",
      },
    }),
    menuList: (styles) => ({
      ...styles,
      borderRadius: "0.625rem",
      paddingTop: "10px",
      paddingBottom: "10px",
    }),
    container: (styles) => ({
      ...styles,
      // margin: "-0.4rem",
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      color: textColor,
    }),
    indicatorContainer: (styles) => ({
      ...styles,
      color: textColor,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: textColor,
    }),
  };

  const { data: initialCities } = useQuery(
    ["cities", countryId],
    () => getCities("", countryId),
    {
      retry: 1,
    }
  );

  const [selectedCity, setSelectedCity] = useState();

  const initialCityOptions = useMemo(() => {
    if (value) {
      if (value !== selectedCity?.value) {
        getCities("", countryId, value).then((cityResult) =>
          setSelectedCity(convertToOptions(cityResult.response)[0])
        );
      }
    } else {
      setSelectedCity(null);
    }

    return convertToOptions(initialCities?.response ?? []).filter(
      (city) => city.value !== value
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCities?.response, value]);

  const loadOptions = async (inputValue, callback) => {
    // perform a request
    inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    const results = await getCities(inputValue, countryId).then((res) => {
      return res;
    });

    if (results?.response.length ?? 0) {
      callback(convertToOptions(results.response));
    }
    callback(initialCityOptions);
  };

  return (
    <AsyncSelect
      placeholder={t(placeholder)}
      isSearchable={true}
      className={className}
      styles={styleLangSelect}
      defaultOptions={
        selectedCity
          ? [selectedCity, ...initialCityOptions]
          : initialCityOptions
      }
      loadOptions={loadOptions}
      name="city"
      onChange={(option) => {
        setSelectedCity(option);
        onChange(option.value);
      }}
      value={selectedCity}
    />
  );
};

export default CitySelect;
