import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getOrganizationById } from "../../common/api";
import withRole from "../../common/withRole/WithRole";
import { BeatLoader } from "react-spinners";
import { useHeader } from "../context/UserProvider";
import TabNav from "../tab-nav/TabNav";
import ProfileTab from "./tabs/ProfileTab";
import MembersTab from "./tabs/Members/MembersTab";
import CustomersTab from "./tabs/Customers/CustomersTab";
import { useTranslation } from "react-i18next";

const OrganizationDetail = () => {
  const { t } = useTranslation();

  useHeader(" ");
  const { id } = useParams();
  const { data, isLoading, refetch } = useQuery(
    ["getOrganizationById", id],
    () => getOrganizationById(id),
    {
      retry: 1,
    }
  );

  return isLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <TabNav
      heads={[
        {
          tabName: t("profile"),
          tabContent: <ProfileTab data={data} />,
        },
        {
          tabName: t("members"),
          tabContent: <MembersTab data={data} refetch={refetch} />,
        },
        {
          tabName: t("customers"),
          tabContent: <CustomersTab data={data} refetch={refetch} />,
        },
      ]}
    />
  );
};

export default withRole("Admin")(OrganizationDetail);
