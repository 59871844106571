import React from "react";
import emailcon from "../../images/email.svg";
import userIcon from "../../images/user.svg";
import Input from "../../common/form-controls/Input";
import Label from "../../common/form-controls/Label";
import Button from "../../common/form-controls/Button";
import Switch from "../../common/switch/Switch";
import { adduser } from "../../common/api";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { getServerError } from "../../common/utils/helpers";
import ButtonLoader from "../../common/form-controls/ButtonLoader";
import { UserSchema } from "./validation-schema";
import { notify } from "../../common/toaster/Toaster";
import { useHeader } from "../context/UserProvider";
import { useTranslation } from "react-i18next";
const AddUser = () => {
  useHeader("adduser");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLoading, mutate: addUserFn } = useMutation(
    (values) =>
      adduser({ ...values, username: values.first_name + values.last_name }),
    {
      onSuccess: () => {
        notify("User Created!", "success");
        navigate("/users", { replace: true });
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      username: "",
      user_role: "",
      email: "",
      is_active: true,
    },
    onSubmit: addUserFn,
    validationSchema: UserSchema,
    validateOnChange: false,
  });

  return (
    <section className="addUser grid md:grid-cols-2">
      <form onSubmit={formik.handleSubmit}>
        <div className="heading-lg" style={{ marginBottom: "2rem" }}>
          {t("userProfile")}
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label>{t("firstName")}</Label>
            <div className="form-control-addon">
              <img src={userIcon} alt="user-icon" />
              <Input
                placeholder={t("firstName")}
                name="first_name"
                onChange={formik.handleChange}
                value={formik.values.first_name}
                className={classNames({
                  "is-invalid": formik.errors.first_name,
                })}
              />
            </div>
            {formik.errors.first_name && (
              <span className="form-error">{formik.errors.first_name}</span>
            )}
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label>{t("lastName")}</Label>
            <div className="form-control-addon">
              <img src={userIcon} alt="user-icon" />
              <Input
                placeholder={t("lastName")}
                name="last_name"
                onChange={formik.handleChange}
                value={formik.values.last_name}
                className={classNames({
                  "is-invalid": formik.errors.last_name,
                })}
              />
            </div>
            {formik.errors.last_name && (
              <span className="form-error">{formik.errors.last_name}</span>
            )}
          </div>
        </div>
        <div className="form-group">
          <Label>{t("email")}</Label>
          <div className="form-control-addon">
            <img src={emailcon} alt="email-icon" />
            <Input
              placeholder={t("email")}
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              className={classNames({ "is-invalid": formik.errors.email })}
            />
          </div>
          {formik.errors.email && (
            <span className="form-error">{formik.errors.email}</span>
          )}
        </div>
        <div className="form-group">
          <Label>{t("role")}</Label>
          <div className="form-control-addon">
            <img src={userIcon} alt="user-icon" />
            <select
              name="user_role"
              value={formik.values.user_role}
              onChange={formik.handleChange}
              className={classNames([
                "form-control",
                { "is-invalid": formik.errors.user_role },
              ])}
            >
              <option value="">{t("select")}</option>
              <option value="Merchant">{t("merchant")}</option>
              <option value="Admin">{t("admin")}</option>
            </select>
          </div>
          {formik.errors.user_role && (
            <span className="form-error">{formik.errors.user_role}</span>
          )}
        </div>
        <div className="form-group">
          <label className="form-label">{t("approve")}</label>
          <Switch
            className="table-action"
            name="is_active"
            onChange={formik.handleChange}
            isChecked={formik.values.is_active}
          />
        </div>

        <Button
          className={classNames("md:mb-6 mb-4 disabled:opacity-50")}
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <ButtonLoader text={t("saving")} /> : t("save")}
        </Button>
      </form>
    </section>
  );
};

export default AddUser;
