import Cookies from "js-cookie";
import { TokenKey } from "../../components/constant/constant";
import { first, values } from "lodash";
import _ from "lodash";
import {
  CURRENCY_SYMBOL_ON_LEFT,
  CURRENCY_SYMBOL as currencySymbol,
} from "../../config";

const getToken = () => {
  return Cookies.get(TokenKey);
};

const setToken = (token) => {
  return Cookies.set(TokenKey, token);
};

const removeToken = () => {
  return Cookies.remove(TokenKey);
};

const createMarkup = (encodedHtml) => ({
  __html: _.unescape(encodedHtml),
});

const emailAddressRegex = /\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const formatter = new Intl.RelativeTimeFormat(undefined, {
  numeric: "auto",
  style: "narrow",
});

const DIVISIONS = [
  { amount: 60, name: "seconds" },
  { amount: 60, name: "minutes" },
  { amount: 24, name: "hours" },
  { amount: 7, name: "days" },
  { amount: 4.34524, name: "weeks" },
  { amount: 12, name: "months" },
  { amount: Number.POSITIVE_INFINITY, name: "years" },
];

function formatTimeAgo(date) {
  let duration = (date - new Date()) / 1000;

  for (let i = 0; i <= DIVISIONS.length; i++) {
    const division = DIVISIONS[i];
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name);
    }
    duration /= division.amount;
  }
}

const getServerError = (error) => {
  if (
    error.response.status === 400 &&
    !_.isString(first(values(error.response.data)[0]))
  ) {
    return error.response.data.message;
  } else if (error.response.status === 400) {
    return first(values(error.response.data)[0]);
  } else return error.response.statusText;
};

const CURRENCY_SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL || currencySymbol;

const isNegative = (num) => Math.sign(num) === -1;
const amount = (amount) =>
  CURRENCY_SYMBOL_ON_LEFT === "true"
    ? `${CURRENCY_SYMBOL} ${Number(amount).toFixed(2) ?? "__"}`
    : `${Number(amount).toFixed(2) ?? "__"} ${CURRENCY_SYMBOL}`;

const fontFamily = [
  { family: `GothamBook` },
  { family: `GothamMedium` },
  { family: `GothamBold` },
  { family: `Rubik` },
];

export {
  setToken,
  getToken,
  removeToken,
  emailAddressRegex,
  getServerError,
  createMarkup,
  isNegative,
  fontFamily,
  amount,
  CURRENCY_SYMBOL,
  formatTimeAgo,
};
