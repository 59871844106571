import React from 'react';

const AssetCard = ({item}) => {
  return (
    <>
        <div className='dashboard-card mb-4 p-5'>
            <div className='asset-card'>
                <div className='asset-card-content'>
                    <img src={item.icon.replace(/ /g, "")}  alt={item.name.toLowerCase()} />
                    <div className='ml-2'>
                        <h5 className='text-safetyorange mb-0.5'>{item.name}</h5>
                    </div>
                </div>
                <h4>{`${item.holdings_amount.slice(0, 2)} ${parseFloat(item.holdings_amount.slice(2)).toFixed(2)}`}</h4>
            </div>
            <div className='asset-bottom'>
                <div className='asset-bottom-left relative w-1/2'>
                    <h4>{item.holding_volume.toFixed(2)} {item.symbol}</h4>
                    <h5>Holdings</h5>
                </div>
                <div className='w-1/2 '>
                    <h6>${item.price.toFixed(2)}</h6>
                    <h5>Price</h5>
                </div>
            </div>
        </div>
    </>
  )
}

export default AssetCard;
