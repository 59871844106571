import { useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import { updatePayout } from "../../../common/api";
import Input from "../../../common/form-controls/Input";
import Label from "../../../common/form-controls/Label";
import { SVG } from "../../../common/icons/Icon";
import { notify } from "../../../common/toaster/Toaster";
import { getServerError } from "../../../common/utils/helpers";

const ApprovePayoutModel = ({ title, payout, onClose }) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation((values) => updatePayout(payout.id, values), {
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries(["getPayoutById"]);
      notify("Payout Approved", "success");
    },
    onError: (error) => {
      notify(getServerError(error), "error");
    },
  });

  const formik = useFormik({
    initialValues: {
      business: payout.business,
      status: "APPROVED",
      settled_amount: payout.request_amount,
      request_amount: payout.request_amount,
    },
    validationSchema: null,
    validateOnChange: false,
    onSubmit: mutate,
  });

  return (
    <div className="invoiceModal">
      <div className="modalIcon">{SVG.payout}</div>
      <h3>{title}</h3>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Label>REQUEST AMOUNT</Label>
        <strong>{payout.request_amount}</strong>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <Label>SETTLEMENT AMOUNT</Label>
          <Input
            name="settled_amount"
            placeholder="How much ?"
            type="number"
            onChange={formik.handleChange}
            value={formik.values.settled_amount}
            max={payout.request_amount}
          />
          {formik.errors.settled_amount && (
            <span className="form-error">{formik.errors.settled_amount}</span>
          )}
        </div>
        <button className="btn-orange" type="submit">
          Confirm
        </button>
        <button
          style={{ width: "100%" }}
          className="btn-brightgray mt-4"
          type="button"
          onClick={onClose}
        >
          Cancel
        </button>
      </form>
    </div>
  );
};

export default ApprovePayoutModel;
