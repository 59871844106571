import classNames from "classnames";
import React from "react";
import { useQuery } from "react-query";
import { getAllEmployees } from "../../../common/api";
import Label from "../../../common/form-controls/Label";
import Selection from "../../../common/form-controls/Selection";
import { useTranslation } from "react-i18next";
const EmployeeSelect = ({
  name,
  value = "",
  onChange,
  error = null,
  className = "",
  filters,
  ...props
}) => {
  const { t } = useTranslation();
  const { data } = useQuery(
    ["getAllEmployees", filters],
    () =>
      getAllEmployees(null, {
        ...filters,
        pagination: "False",
      }),
    {
      retry: 1,
    }
  );

  return (
    <div className={className} {...props}>
      <Label>{t("employee")}</Label>
      <Selection
        name={name || "employee"}
        options={data?.response.map(({ id, name }) => ({
          value: id,
          label: name,
        }))}
        value={value}
        onChange={onChange}
        className={classNames(["form-control", { "is-invalid": error }])}
      />
      {error && <span className="form-error">{error}</span>}
    </div>
  );
};

export default EmployeeSelect;
