import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logoImg from "../../images/sappayPurple-logo.svg";
import emailcon from "../../images/email.svg";
import keyIcon from "../../images/key.svg";
import userIcon from "../../images/user.svg";
import phoneIcon from "../../images/phone.svg";
import countryIcon from "../../images/country.svg";
import cityIcon from "../../images/city.svg";
import { Link } from "react-router-dom";
import Label from "../../common/form-controls/Label";
import Input from "../../common/form-controls/Input";
import Button from "../../common/form-controls/Button";
import classNames from "classnames";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { signUp } from "../../common/api";
import { getServerError } from "../../common/utils/helpers";
import ButtonLoader from "../../common/form-controls/ButtonLoader";
import { SignUpSchema } from "./validation-schema";
import { notify } from "../../common/toaster/Toaster";
import { SVG } from "../../common/icons/Icon";
import CountrySelect from "./components/CountrySelect";
import CitySelect from "./components/CitySelect";
import PhoneNumber from "./components/PhoneNumber";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "../../common/language-dropdown/LanguageDropdown";

const SignUp = () => {
  const { t } = useTranslation();
  const [showTermsAndCondition, setShowTermsAndCondition] = useState(false);
  const navigate = useNavigate();

  const {
    isLoading,
    isError,
    mutate: signupFn,
  } = useMutation((values) => signUp(values), {
    onSuccess: () => {
      navigate("/signup-success", { replace: true });
    },
    onError: (error) => {
      setShowTermsAndCondition(false);
      notify(getServerError(error), "error");
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirm_password: "",
      first_name: "",
      last_name: "",
      phone: "",
      country: "",
      city: "",
      is_read_privacy_policy: null,
    },
    onSubmit: signupFn,
    validationSchema: SignUpSchema,
    validateOnChange: false,
  });

  const [countryCode, setCountryCode] = useState("");

  return (
    <section className="signup grid items-center signBg">
      <div className="signup-content">
        <div className="signup-card">
          <div className="logoImg">
            <img src={logoImg} alt="logo" />
          </div>
          <div class="flex flex-wrap">
            <div className="w-full md:w-1/2">
              <h3>{t("signUp")}</h3>
            </div>
            <div className="w-full md:w-1/2 flex justify-end">
              <LanguageDropdown />
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            {showTermsAndCondition && <TermsAndCondition />}
            {!showTermsAndCondition && (
              <>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <Label>{t("firstName")}</Label>
                    <div className="form-control-addon">
                      <img src={userIcon} alt="email-icon" />
                      <Input
                        required
                        placeholder={t("firstName")}
                        name="first_name"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                        className={classNames({
                          "is-invalid": formik.errors.first_name,
                        })}
                      />
                    </div>
                    {formik.errors.first_name && (
                      <span className="form-error">
                        {formik.errors.first_name}
                      </span>
                    )}
                  </div>
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <Label>{t("lastName")}</Label>
                    <div className="form-control-addon">
                      <img src={userIcon} alt="email-icon" />
                      <Input
                        required
                        placeholder={t("lastName")}
                        name="last_name"
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                        className={classNames({
                          "is-invalid": formik.errors.last_name,
                        })}
                      />
                    </div>
                    {formik.errors.last_name && (
                      <span className="form-error">
                        {formik.errors.last_name}
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <Label>{t("country")}</Label>
                    <div className="form-control-addon autoSearch">
                      <img src={countryIcon} alt="phone-icon" />
                      <CountrySelect
                        className={`form-control select required ${
                          formik.errors.country ? "is-invalid" : ""
                        }`}
                        onChange={(countryInfo) => {
                          if (countryInfo.value !== formik.values.country) {
                            formik.setFieldValue("country", countryInfo.value);
                            formik.setFieldValue("city", null);
                            setCountryCode(countryInfo.countryCode);
                          }
                        }}
                        value={formik.values.country}
                      />
                    </div>
                    {formik.errors.country && (
                      <span className="form-error">
                        {formik.errors.country}
                      </span>
                    )}
                  </div>
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <Label>{t("city")}</Label>
                    <div className="form-control-addon autoSearch">
                      <img src={cityIcon} alt="phone-icon" />
                      <CitySelect
                        className={`form-control select required ${
                          formik.errors.city ? "is-invalid" : ""
                        }`}
                        countryId={formik.values.country}
                        onChange={(value) =>
                          formik.setFieldValue("city", value)
                        }
                        value={formik.values.city}
                      />
                    </div>
                    {formik.errors.city && (
                      <span className="form-error">{formik.errors.city}</span>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <Label>{t("email")}</Label>
                    <div className="form-control-addon">
                      <img src={emailcon} alt="email-icon" />
                      <Input
                        required
                        placeholder={t("email")}
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        className={classNames({
                          "is-invalid": formik.errors.email,
                        })}
                      />
                    </div>
                    {formik.errors.email && (
                      <span className="form-error">{formik.errors.email}</span>
                    )}
                  </div>
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <Label>{t("phone")}</Label>
                    <div className="form-control-addon">
                      <img src={phoneIcon} alt="phone-icon" />
                      <PhoneNumber
                        required
                        countryCode={countryCode}
                        onChange={(value) =>
                          formik.setFieldValue("phone", value)
                        }
                        value={formik.values.phone}
                        error={formik.errors.phone}
                      />
                    </div>
                    {formik.errors.phone && (
                      <span className="form-error">{formik.errors.phone}</span>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <Label>{t("password")}</Label>
                  <div className="form-control-addon">
                    <img src={keyIcon} alt="icon" />
                    <Input
                      required
                      visiblePassword={true}
                      type="password"
                      placeholder={t("password")}
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      className={classNames({
                        "is-invalid": formik.errors.password,
                      })}
                    />
                  </div>
                  {formik.errors.password && (
                    <span className="form-error">{formik.errors.password}</span>
                  )}
                </div>
                <div className="form-group">
                  <Label>{t("confirmPassword")}</Label>
                  <div className="form-control-addon">
                    <img src={keyIcon} alt="icon" />
                    <Input
                      visiblePassword={true}
                      required
                      type="password"
                      placeholder={t("confirmPassword")}
                      name="confirm_password"
                      value={formik.values.confirm_password}
                      onChange={formik.handleChange}
                      className={classNames({
                        "is-invalid": formik.errors.confirm_password,
                      })}
                    />
                  </div>
                  {formik.errors.confirm_password && (
                    <span className="form-error">
                      {formik.errors.confirm_password}
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <Label className="mb-0">
                    <div className="form-control-addon flex items-center">
                      <Input
                        formControl={false}
                        style={{ width: "30px" }}
                        type="checkbox"
                        name="is_read_privacy_policy"
                        value={formik.values.is_read_privacy_policy}
                        checked={formik.values.is_read_privacy_policy}
                        onChange={formik.handleChange}
                        className={classNames([
                          {
                            "is-invalid": formik.errors.is_read_privacy_policy,
                          },
                          "inline-block",
                        ])}
                      />
                      <small
                        className="font-xs"
                        style={{
                          fontFamily: "GothamBook",
                          marginTop: "-2px",
                        }}
                      >
                        {t("iHaveReadAndAcceptThe")}{" "}
                        <span
                          className="orange-link cursor-pointer"
                          onClick={() => setShowTermsAndCondition(true)}
                        >
                          {t("TermsAndConditions")}
                        </span>
                        .
                      </small>
                    </div>
                  </Label>
                  {formik.errors.is_read_privacy_policy && (
                    <span className="form-error">
                      {formik.errors.is_read_privacy_policy}
                    </span>
                  )}
                </div>
                <Button
                  className={classNames("mb-2 disabled:opacity-50")}
                  type={
                    formik.values.is_read_privacy_policy ? "submit" : "button"
                  }
                  onClick={() => {
                    formik.validateForm();
                  }}
                  disabled={isLoading || !formik.values.is_read_privacy_policy}
                >
                  {isLoading ? (
                    <ButtonLoader text={"Creating..."} />
                  ) : (
                    t("createYourAccount")
                  )}
                </Button>
              </>
            )}

            <div
              className={classNames(
                { "mt-3.5": isError },
                "flex justify-between",
                "mt-6"
              )}
            >
              {showTermsAndCondition && (
                <strong
                  className="flex items-center cursor-pointer leading-7"
                  onClick={() => setShowTermsAndCondition(false)}
                >
                  {SVG.arrowLeft}
                  {t("back")}
                </strong>
              )}
              <div>
                <span className="font-xs">{t("alreadyHaveAnAccount?")}</span>
                <Link className="ml-1 orange-link" to="/">
                  {t("signInHere")}
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SignUp;

export const TermsAndCondition = () => {
  const { t } = useTranslation();
  return (
    <div
      className="terms-and-condition"
      dangerouslySetInnerHTML={{
        __html: `
        <h3>${t("Terms And Condition")}</h3>
        <ol class="list-decimal">
        <li>${t("termsAndConditionNote")}</li>
        <li>${t("termsAndConditionNote")}</li>
        <li>${t("termsAndConditionNote")}</li>
        <li>${t("termsAndConditionNote")}</li>
        <li>${t("termsAndConditionNote")}</li>
        <li>${t("termsAndConditionNote")}</li>
        <li>${t("termsAndConditionNote")}</li>
        </ol>
        `,
      }}
    ></div>
  );
};
