import React from "react";
import Input from "../../common/form-controls/Input";
import Label from "../../common/form-controls/Label";
import { useFormik } from "formik";
import { getInvoiceById } from "../../common/api";
import { SVG } from "../icons/Icon";
import { useQuery } from "react-query";
import { useMutation } from "react-query";
import { getServerError } from "../../common/utils/helpers";
import { notify } from "../../common/toaster/Toaster";
import { editInvoice } from "../../common/api";
import ButtonLoader from "../form-controls/ButtonLoader";
import BussinessSelect from "../../components/customers/components/BussinessSelect";
import { useUser } from "../../components/context/UserProvider";
import { useTranslation } from "react-i18next";
const SimInvoiceEdit = ({ id, title, onSuccess, close }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const { data } = useQuery(["getInvoiceById", id], getInvoiceById, {
    retry: 1,
  });

  const { isLoading, mutate: editInvoiceFn } = useMutation(
    (values) => editInvoice(id, values),
    {
      onSuccess: () => {
        close();
        notify(t("invoiceUpdated"), "success");
        onSuccess();
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );
  const formik = useFormik({
    initialValues: {
      amount: data?.amount ?? "",
      business: data?.business ?? "",
      note: data?.note ?? "",
    },
    onSubmit: editInvoiceFn,
    enableReinitialize: true,
    // validationSchema: SimInvoiceSchema,
    validateOnChange: false,
  });

  return (
    <div className="invoiceModal">
      <div className="modalIcon">{SVG.message}</div>
      <h3>{title}</h3>
      <form onSubmit={formik.handleSubmit}>
        {user.user_role === "Admin" && (
          <div className="w-full mb-6">
            <BussinessSelect
              className="flex flex-wrap mb-6"
              name="business"
              value={parseInt(formik.values.business)}
              onChange={(value) =>
                formik.setFieldValue("business", value.value)
              }
              error={formik.errors.business}
            />
          </div>
        )}

        <div className="form-group">
          <Label>{t("amount")} </Label>
          <Input
            name="amount"
            onChange={formik.handleChange}
            value={formik.values.amount}
          />
          {formik.errors.amount && (
            <span className="form-error">{formik.errors.amount}</span>
          )}
        </div>
        <div className="form-group">
          <Label>{t("note")} </Label>
          <textarea
            name="note"
            onChange={formik.handleChange}
            value={formik.values.note}
            className="form-control"
          />
          {formik.errors.note && (
            <span className="form-error">{formik.errors.note}</span>
          )}
        </div>

        <button className="btn-orange" type="submit">
          {isLoading ? (
            <ButtonLoader text={t("submitting")} />
          ) : (
            `${t("submit")}`
          )}
        </button>
        <button
          style={{ width: "100%" }}
          className="btn-brightgray mt-4"
          type="button"
          onClick={close}
        >
          {t("close")}
        </button>
      </form>
    </div>
  );
};

export default SimInvoiceEdit;
