import { useEffect } from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import React from "react";
import Button from "../../common/form-controls/Button";
import Input from "../../common/form-controls/Input";
import Label from "../../common/form-controls/Label";
import ButtonLoader from "../../common/form-controls/ButtonLoader";
import { SVG } from "../../common/icons/Icon";
import Switch from "../../common/switch/Switch";
import { EmployeeSchema } from "./validation-schema";
import CountrySelect from "../signup/components/CountrySelect";
import CitySelect from "../signup/components/CitySelect";
import { useTranslation } from "react-i18next";
import BussinessSelect from "../customers/components/BussinessSelect";
import { useUser } from "../context/UserProvider";

const EmployeeForm = ({
  initialValues = {
    name: "",
    business: null,
    email: "",
    country: "",
    city: "",
    is_active: false,
    details: [],
  },
  onSubmit,
  validationSchema,
  isLoading,
  error,
}) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema ?? EmployeeSchema,
    validateOnChange: false,
    onSubmit: (values) => onSubmit(values),
  });
  useEffect(() => {
    error &&
      Object.entries(error.response.data.error).map(([key, value]) =>
        formik.setFieldError(key, value.error_string)
      );
  }, [error, formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {user.user_role === "Admin" && (
        <div className="w-full">
          <BussinessSelect
            className="flex flex-wrap mb-6"
            name="business"
            value={parseInt(formik.values.business)}
            onChange={(value) => formik.setFieldValue("business", value.value)}
            error={formik.errors.business}
          />
        </div>
      )}
      <div className="flex flex-wrap -mx-3 justify-between">
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("name")}</Label>
          <div
            className={classNames([
              {
                "is-invalid": formik.errors.name,
              },
            ])}
          >
            <Input
              required
              placeholder={t("name")}
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </div>
          {formik.errors.name && (
            <span className="form-error">{formik.errors.name}</span>
          )}
        </div>
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("email")}</Label>
          <div
            className={classNames([
              {
                "is-invalid": formik.errors.email,
              },
            ])}
          >
            <Input
              required
              placeholder={t("email")}
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </div>
          {formik.errors.email && (
            <span className="form-error">{formik.errors.email}</span>
          )}
        </div>
      </div>

      <div className="flex flex-wrap -mx-3 justify-between">
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("country")}</Label>
          <CountrySelect
            className={`form-control country-select select required ${
              formik.errors.country ? "is-invalid" : ""
            }`}
            onChange={(countryInfo) => {
              formik.setFieldValue("country", countryInfo.value);

              formik.setFieldValue("city", "");
            }}
            value={formik.values.country}
            placeholder="SelectCountry"
          />
          {formik.errors.country && (
            <span className="form-error">{formik.errors.country}</span>
          )}
        </div>
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("city")}</Label>
          <CitySelect
            className={`form-control city-select select required ${
              formik.errors.city ? "is-invalid" : ""
            }`}
            countryId={formik.values.country}
            onChange={(value) => formik.setFieldValue("city", value)}
            value={formik.values.city}
            placeholder="SelectCity"
          />
          {formik.errors.city && (
            <span className="form-error">{formik.errors.city}</span>
          )}
        </div>
      </div>
      <div className="form-group">
        <Label className="required form-label">{t("status")}</Label>
        <Switch
          name="is_active"
          isChecked={formik.values.is_active}
          onChange={formik.handleChange}
        />
      </div>

      <div className="form-group">
        <div className="section-head">
          <Label>{t("detail")}</Label>
          <Button
            className="add-btn"
            onClick={() => {
              formik.setFieldValue("details", [
                ...formik.values.details,
                {
                  id: formik.values.details.length,
                  key: "",
                  value: "",
                },
              ]);
            }}
          >
            {t("addDetail")}
          </Button>
        </div>

        {(formik.values.details ?? []).map(({ key, value }, index) => (
          <div className="flex flex-wrap -mx-3 justify-between">
            <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
              <Input
                placeholder={t("key")}
                value={key}
                onChange={(e) => {
                  formik.setFieldValue(`details[${index}].key`, e.target.value);
                }}
              />
            </div>
            <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
              <Input
                placeholder={t("value")}
                value={value}
                onChange={(e) => {
                  formik.setFieldValue(
                    `details[${index}].value`,
                    e.target.value
                  );
                }}
              />
            </div>
            <span
              className="keyDelete"
              onClick={() =>
                formik.setFieldValue(
                  "details",
                  formik.values.details.filter(({ id }) => index !== id)
                )
              }
            >
              {SVG.delete}
            </span>
          </div>
        ))}
      </div>
      <Button
        className={classNames("md:mb-6 mb-4 disabled:opacity-50")}
        type="submit"
        disabled={isLoading}
      >
        {isLoading ? <ButtonLoader text={t("saving")} /> : t("save")}
      </Button>
    </form>
  );
};

export default EmployeeForm;
