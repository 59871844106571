import React from "react";
import Input from "../../common/form-controls/Input";
import Label from "../../common/form-controls/Label";
import Button from "../../common/form-controls/Button";
import { addprovider } from "../../common/api";
import classNames from "classnames";
import { getProviderCountry } from "../../common/api";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { useQuery } from "react-query";
import { SVG } from "../../../src/common/icons/Icon";

import { getServerError } from "../../common/utils/helpers";
import ButtonLoader from "../../common/form-controls/ButtonLoader";
import { ProviderSchema } from "./validation-schema";
import Switch from "../../common/switch/Switch";
import { notify } from "../../common/toaster/Toaster";
import { useHeader } from "../context/UserProvider";
import { useTranslation } from "react-i18next";
const AddProvider = () => {
  useHeader("addprovider");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useQuery("getProviderCountry", getProviderCountry, {
    retry: 1,
  });
  const { isLoading, mutate: addProviderFn } = useMutation(
    (values) =>
      addprovider({
        ...values,
        meta_config: Object.values(values.meta_config).reduce((acc, curr) => {
          acc[curr.key] = curr.value;
          return acc;
        }, {}),
      }),
    {
      onSuccess: () => {
        notify("Provider Created!", "success");
        navigate("/providers", { replace: true });
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );
  const formik = useFormik({
    initialValues: {
      name: "",
      provider_type: "",
      country: "",
      active: true,
      meta_config: [{ key: "", value: "" }],
    },
    onSubmit: addProviderFn,
    validationSchema: ProviderSchema,
    validateOnChange: false,
  });
  return (
    <section className="addUser grid md:grid-cols-2">
      <form onSubmit={formik.handleSubmit}>
        <div className="heading-lg" style={{ marginBottom: "2rem" }}>
          {t("addProvider")}
        </div>
        <div className="form-group">
          <Label>{t("name")}</Label>
          <Input
            placeholder={t("typeHere")}
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            className={classNames({ "is-invalid": formik.errors.name })}
          />
          {formik.errors.name && (
            <span className="form-error">{formik.errors.name}</span>
          )}
        </div>
        <div className="flex flex-wrap -mx-3 justify-between">
          <div className="w-full md:w-1/2 px-3 mb-6 ">
            <Label>{t("type")}</Label>
            <select
              name="provider_type"
              value={formik.values.provider_type}
              onChange={formik.handleChange}
              className={classNames([
                "form-control",
                { "is-invalid": formik.errors.provider_type },
              ])}
            >
              <option value="">{t("select")}</option>
              <option value="Wallet">Wallet</option>
              <option value="Bank">{t("bank")} </option>
            </select>
            {formik.errors.provider_type && (
              <span className="form-error">{formik.errors.provider_type}</span>
            )}
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 ">
            <Label>{t("country")}</Label>
            <select
              name="country"
              value={formik.values.country}
              onChange={formik.handleChange}
              className={classNames([
                "form-control",
                { "is-invalid": formik.errors.country },
              ])}
            >
              <option value="">Select</option>
              {data?.response.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </select>
            {formik.errors.country && (
              <span className="form-error">{formik.errors.country}</span>
            )}
          </div>
        </div>
        <Label>{t("configs")}</Label>
        {formik.values.meta_config.map(({ key, value }, index) => (
          <div className="flex flex-wrap -mx-3 justify-between" key={index}>
            <div className="w-full md:w-1/2 px-3 mb-6 ">
              <Input
                placeholder={t("key")}
                className={classNames({ "is-invalid": formik.errors.metaKey })}
                value={key}
                onChange={(e) => {
                  formik.setFieldValue(
                    `meta_config[${index}].key`,
                    e.target.value
                  );
                }}
              />
              {formik.errors.metaKey && (
                <span className="form-error">{formik.errors.metaKey}</span>
              )}
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 ">
              <Input
                placeholder={t("value")}
                value={value}
                className={classNames({
                  "is-invalid": formik.errors.metaValue,
                })}
                onChange={(e) => {
                  formik.setFieldValue(
                    `meta_config[${index}].value`,
                    e.target.value
                  );
                }}
              />
              {formik.errors.metaValue && (
                <span className="form-error">{formik.errors.metaValue}</span>
              )}
            </div>
            <span
              className="keyDelete"
              onClick={() =>
                formik.setFieldValue(
                  "meta_config",
                  formik.values.meta_config.filter(({ id }) => index !== id)
                )
              }
            >
              {SVG.delete}
            </span>
          </div>
        ))}
        <div className="section-head">
          <Button
            className="add-btn"
            onClick={() =>
              formik.setFieldValue("meta_config", [
                ...formik.values.meta_config,
                {
                  id: formik.values.meta_config.length,
                  key: "",
                  value: "",
                },
              ])
            }
          >
            {t("addConfig")}
          </Button>
        </div>
        <div className="form-group">
          <Label>{t("approve")}</Label>
          <Switch
            className="table-action"
            name="active"
            onChange={formik.handleChange}
            isChecked={formik.values.active}
          />
        </div>
        <Button
          className={classNames("md:mb-6 mb-4 disabled:opacity-50")}
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <ButtonLoader text={t("saving")} /> : t("save")}
        </Button>
      </form>
    </section>
  );
};
export default AddProvider;
