import React, { useState } from "react";
import Label from "../../../../common/form-controls/Label";
import classNames from "classnames";
import Input from "../../../../common/form-controls/Input";
import TextArea from "../../../../common/form-controls/TextArea";
import Selection from "../../../../common/form-controls/Selection";
import CitySelect from "../../../signup/components/CitySelect";
import CountrySelect from "../../../signup/components/CountrySelect";
import PhoneNumber from "../../../signup/components/PhoneNumber";
import { getCountries } from "../../../../common/api";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
const ProfileForm = ({ formik }) => {
  const { t } = useTranslation();
  const [countryCode, setCountryCode] = useState("");
  useQuery(
    ["country", formik.values.country],
    () => getCountries("", formik.values.country.toString()),
    {
      onSuccess: (data) => {
        setCountryCode(data?.response[0].phone);
      },
    }
  );
  return (
    <>
      <div className="flex flex-wrap -mx-3 justify-between">
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("name")} </Label>
          <div
            className={classNames([
              {
                "is-invalid": formik.errors.business_name,
              },
            ])}
          >
            <Input
              required
              placeholder={t("name")}
              name="business_name"
              onChange={formik.handleChange}
              value={formik.values.business_name}
            />
          </div>
          {formik.errors.business_name && (
            <span className="form-error">{formik.errors.business_name}</span>
          )}
        </div>

        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("type")} </Label>
          <div
            className={classNames([
              "form-control",
              {
                "is-invalid": formik.errors.business_type,
              },
            ])}
          >
            <Selection
              className="required"
              placeholder={t("selectType")}
              options={[
                { value: "Individual", label: "Individual" },
                { value: "Private Sector", label: "Private Sector" },
                { value: "Public Sector", label: "Public Sector" },
                { value: "NGOs", label: "NGOs" },
              ]}
              value={formik.values.business_type}
              onChange={(value) =>
                formik.setFieldValue("business_type", value.value)
              }
            />
          </div>
          {formik.errors.business_type && (
            <span className="form-error">{formik.errors.business_type}</span>
          )}
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 justify-between">
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("email")} </Label>
          <div
            className={classNames([
              {
                "is-invalid": formik.errors.email,
              },
            ])}
          >
            <Input
              required
              placeholder={t("emailPlaceholder")}
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </div>
          {formik.errors.email && (
            <span className="form-error">{formik.errors.email}</span>
          )}
        </div>
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("phone")} </Label>
          <PhoneNumber
            required
            countryCode={countryCode}
            onChange={(value) => formik.setFieldValue("phone", value)}
            value={formik.values.phone}
            error={formik.errors.phone}
          />
          {formik.errors.phone && (
            <span className="form-error">{formik.errors.phone}</span>
          )}
        </div>
      </div>

      <div className="form-group">
        <Label>{t("website")} </Label>
        <div
          className={classNames([
            {
              "is-invalid": formik.errors.website,
            },
          ])}
        >
          <Input
            placeholder={t("exampleWebsite")}
            name="website"
            onChange={formik.handleChange}
            value={formik.values.website}
          />
        </div>
        {formik.errors.website && (
          <span className="form-error">{formik.errors.website}</span>
        )}
      </div>

      <div className="flex flex-wrap -mx-3  justify-start">
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("country")} </Label>
          <CountrySelect
            displaceMenu={0}
            className={`form-control required ${
              formik.errors.country ? "is-invalid" : ""
            }`}
            countryId={formik.values.country}
            value={formik.values.country}
            onChange={(countryInfo) => {
              if (countryInfo.value !== formik.values.country) {
                formik.setFieldValue("city", null);
                formik.setFieldValue("country", countryInfo.value);
              }
            }}
          />
          {formik.errors.country && (
            <span className="form-error">{formik.errors.country}</span>
          )}
        </div>
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("city")} </Label>
          <CitySelect
            displaceMenu={0}
            className={`form-control required ${
              formik.errors.city ? "is-invalid" : ""
            }`}
            countryId={formik.values.country}
            value={formik.values.city}
            onChange={(value) => formik.setFieldValue("city", value)}
          />
          {formik.errors.city && (
            <span className="form-error">{formik.errors.city}</span>
          )}
        </div>
      </div>

      <div className="form-group">
        <Label>{t("streetAddress")} </Label>
        <div
          className={classNames([
            {
              "is-invalid": formik.errors.address,
            },
          ])}
        >
          <Input
            required
            placeholder={t("houseNumber")}
            name="address"
            onChange={formik.handleChange}
            value={formik.values.address}
          />
        </div>
        {formik.errors.address && (
          <span className="form-error">{formik.errors.address}</span>
        )}
      </div>
      <div className="form-group">
        <Label>{t("description")} </Label>
        <div
          className={classNames([
            {
              "is-invalid": formik.errors.description,
            },
          ])}
        >
          <TextArea
            placeholder={t("description")}
            name="description"
            onChange={formik.handleChange}
            value={formik.values.description}
          />
        </div>
        {formik.errors.description && (
          <span className="form-error">{formik.errors.description}</span>
        )}
      </div>
    </>
  );
};

export default ProfileForm;
