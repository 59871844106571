import { Menu } from "@szhsin/react-menu";
import React from "react";
import { SVG } from "../../common/icons/Icon";
import { formatTimeAgo } from "../../common/utils/helpers";
import { useNotification } from "../context/NotificationProvider";
import { useTranslation } from "react-i18next";
const Notifications = () => {
  const { stats, notifications, refetchNotifications } = useNotification();

  return (
    <Menu
      direction="bottom"
      position="anchor"
      align="end"
      arrow={true}
      transition
      onMenuChange={({ open }) => {
        if (!open) {
          refetchNotifications();
        }
      }}
      menuButton={
        <span
          style={{
            position: "relative",
            pointerEvents: notifications.length ? "all" : "none",
          }}
          className="mr-3 p-2 rounded-full border font-semibold text-sm flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease"
        >
          {SVG.notification}
          {(stats.unread.length ?? 0) > 0 ? (
            <span className="notificationCount">{stats.unread.length}</span>
          ) : (
            ""
          )}
        </span>
      }
    >
      <NotificationItemHolder />
    </Menu>
  );
};

export default Notifications;

export const NotificationItemHolder = () => {
  const { t } = useTranslation();
  const { notifications, markAllAsRead, stats } = useNotification();
  return (
    <div className="notification-wrapper pt-4 pl-4 pr-3">
      {(notifications ?? []).map(
        ({ id, name, description, is_read, created_at }) => (
          <NotificationItem
            key={id}
            title={name}
            body={description}
            id={id}
            is_read={is_read}
            created_at={created_at}
          />
        )
      )}
      {stats.unread.length > 0 && (
        <div className="read-all-holder">
          <button class="btn-orange" type="button" onClick={markAllAsRead}>
            {t("markAllAsRead")}
          </button>
        </div>
      )}
    </div>
  );
};

export const NotificationItem = ({ title, body, id, is_read, created_at }) => {
  return (
    <div key={id} class="space-y-5">
      <div
        class={`relative mx-auto max-w-[400px] rounded-md border ${
          !is_read ? "bg-slate-200" : ""
        } mb-2 bg-white p-4 text-sm shadow-lg`}
      >
        <div class="flex space-x-4">
          {SVG.notificationBell}
          <div class="ml-0 flex-1">
            <h4 class="pr-6 font-medium font-bold text-slate-900">
              {title}{" "}
              <span
                class="ml-2 text-xs"
                style={{ color: is_read ? "#4b5563" : "#7F2CEA" }}
              >
                {formatTimeAgo(new Date(created_at))}
              </span>
            </h4>
            <div class="mt-1 text-slate-500">
              {body.length > 95 ? `${body.substring(0, 95)}...` : body}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
