import React from "react";
import { useDarkMode } from "usehooks-ts";
import logoImg from "../../images/sappayPurple-logo.svg";
import logoImgDark from "../../images/sappayWhite-logo.svg";

const Logo = ({ svgColor, textColor }) => {
  const { isDarkMode } = useDarkMode();
  return (
    <div className="brand-logo">
      <div className="sideBarLogo">
        <img
          src={logoImgDark}
          alt="logo"
          style={{ marginTop: "0px", display: !isDarkMode ? "none" : "block" }}
        />
        <img
          src={logoImg}
          alt="logo"
          style={{ marginTop: "0px", display: isDarkMode ? "none" : "block" }}
        />
      </div>
    </div>
  );
};

export default Logo;
