import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { verifyEmail } from "../../common/api";
import { BeatLoader } from "react-spinners";
import classNames from "classnames";
import verified from "../../images/tick.svg";

const ConfirmEmail = () => {
  const { token } = useParams();

  const navigate = useNavigate();

  const [message, setMessage] = useState(null);

  const navigateToDashboard = () => {
    navigate("/", { replace: true });
  };

  const { isLoading, isSuccess } = useQuery(
    "verifyEmail",
    () => verifyEmail(token),
    {
      onSuccess: () => {
        setMessage("Email verified successfully.");
        setTimeout(navigateToDashboard, 3000);
      },
      onError: (error) => {
        setMessage(error.response.data.message);
        setTimeout(navigateToDashboard, 3000);
      },
    }
  );

  return (
    <div className="verify-email">
      <section className="message-section">
        {isLoading && !message && (
          <BeatLoader color="#7F2CEA" className="text-center" />
        )}
        <div className="msg-img">
          {isSuccess && <img src={verified} alt="loader" />}
          <h1
            className={classNames(
              { "ml-2": isSuccess },
              "text-xl maxSm:text-lg"
            )}
          >
            {message ? message : isLoading ? "Verifying" : ""}
          </h1>
        </div>
        {isSuccess && <div className="text-center">redirecting...</div>}
      </section>
    </div>
  );
};

export default ConfirmEmail;
