import React, { useState } from "react";
import Input from "../../common/form-controls/Input";
import Label from "../../common/form-controls/Label";
import Button from "../../common/form-controls/Button";
import { addkey } from "../../common/api";
import classNames from "classnames";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import ButtonLoader from "../../common/form-controls/ButtonLoader";
import { KeySchema } from "./validation-schema";
import { notify } from "../../common/toaster/Toaster";
import { useHeader } from "../context/UserProvider";
import KeyModal from "../../common/modal/KeyModal";
import Modal from "../../common/modal/Modal";
import { useNavigate } from "react-router-dom";
import BussinessSelect from "../customers/components/BussinessSelect";
import { useTranslation } from "react-i18next";
const AddKey = () => {
  const { t } = useTranslation();
  useHeader("newAPIKey");
  const navigate = useNavigate();
  const [secretKey, setsecretKey] = useState({
    secret_key: null,
  });
  const { isLoading, mutate: addKeyFn } = useMutation(
    (values) => addkey(values),
    {
      onSuccess: (data) => {
        notify(t("keyCreated"), "success");
        setsecretKey({
          access_key: data.response.access_key,
          secret_key: data.response.secret_key,
        });
      },
      onError: (error) => {
        Object.entries(error.response.data.error).map(([key, value]) =>
          formik.setFieldError(key, value.error_string)
        );
      },
    }
  );
  const formik = useFormik({
    initialValues: {
      business: "",
      name: "",
      type: "",
      platform_url: "",
      redirect_uris: "",
      mode: "PRODUCTION",
    },
    onSubmit: addKeyFn,
    validationSchema: KeySchema,
    validateOnChange: false,
  });

  return (
    <section className="addUser grid md:grid-cols-2">
      <form onSubmit={formik.handleSubmit}>
        <BussinessSelect
          className="flex flex-wrap mb-6"
          name="business"
          value={parseInt(formik.values.business)}
          onChange={(value) => formik.setFieldValue("business", value.value)}
          error={formik.errors.business}
        />

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label>{t("name")}</Label>
            <Input
              placeholder={t("name")}
              name="name"
              required
              onChange={formik.handleChange}
              value={formik.values.name}
              className={classNames({ "is-invalid": formik.errors.name })}
            />
            {formik.errors.name && (
              <span className="form-error">{formik.errors.name}</span>
            )}
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label>{t("type")}</Label>
            <select
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
              className={classNames([
                "form-control",
                "required",
                { "is-invalid": formik.errors.type },
              ])}
            >
              <option value="">{t("select")}</option>
              <option value="E_COMMERCE">{t("eCommerce")}</option>
              <option value="POS">{t("pos")}</option>
            </select>
            {formik.errors.type && (
              <span className="form-error">{formik.errors.type}</span>
            )}
          </div>
        </div>
        {formik.values.type === "E_COMMERCE" && (
          <>
            <div className="form-group">
              <Label>{t("platformURL")}</Label>
              <Input
                required
                placeholder="https://example.com"
                name="platform_url"
                onChange={formik.handleChange}
                value={formik.values.platform_url}
                className={classNames({
                  "is-invalid": formik.errors.platform_url,
                })}
              />
              {formik.errors.platform_url && (
                <span className="form-error">{formik.errors.platform_url}</span>
              )}
            </div>
            <div className="form-group">
              <Label>{t("redirectURL")}</Label>
              <Input
                required
                placeholder="https://example.com/redirect"
                name="redirect_uris"
                onChange={formik.handleChange}
                value={formik.values.redirect_uris}
                className={classNames({
                  "is-invalid": formik.errors.redirect_uris,
                })}
              />
              {formik.errors.redirect_uris && (
                <span className="form-error">
                  {formik.errors.redirect_uris}
                </span>
              )}
            </div>
          </>
        )}

        <div className="noticeBox">
          <h4>
            <b>{t("note")}</b>
          </h4>
          <p>{t("apiKeyParagraph")}</p>
          <ul>
            <li>{t("apiKeyList1")}</li>
            <li>{t("apiKeyList2")}</li>
            <li>{t("apiKeyList3")}</li>
          </ul>
        </div>
        <Button
          className={classNames("md:mb-6 mb-4 disabled:opacity-50")}
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <ButtonLoader text={t("saving")} /> : t("save")}
        </Button>
      </form>
      {secretKey?.secret_key && (
        <Modal close={() => navigate("/api-keys")}>
          <KeyModal
            title={t("keyGenerated")}
            ApiCre={secretKey}
            isLoading={isLoading}
          />
        </Modal>
      )}
    </section>
  );
};

export default AddKey;
