import { useContext, useMemo, useState } from "react";
import { CONNECT_WALLET } from "../../constant/constant";
import { SyncWalletContext } from "../../context/SyncWalletContext";
import SelectWallet from "./select-wallet/SelectWallet";
import Label from "../../../common/form-controls/Label";
import Input from "../../../common/form-controls/Input";
import Button from "../../../common/form-controls/Button";
import { useHeader } from "../../context/UserProvider";
import { walletsAdd, walletsConnect } from "../../../common/api";
import { useMutation, useQuery } from "react-query";
import { createMarkup, getServerError } from "../../../common/utils/helpers";
import { useFormik } from "formik";
import { validate } from "./validation-schema";
import { useTranslation } from "react-i18next";
import { notify } from "../../../common/toaster/Toaster";
import ButtonLoader from "../../../common/form-controls/ButtonLoader";

const AddWallet = () => {
  const { setScreen } = useContext(SyncWalletContext);
  useHeader("wallets");
  const [wallet, setWallet] = useState(null);
  const { t } = useTranslation();
  const { data } = useQuery("walletsAdd", walletsAdd, { retry: 1 });
  const { mutate, isLoading } = useMutation(
    (values) => walletsConnect(values),
    {
      onSuccess: () => setScreen(CONNECT_WALLET),
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );

  const options = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.results.map((row) => ({
      value: row.name,
      label: row.name,
      icon: row.logo,
      ...row,
    }));
  }, [data]);

  const inputs = useMemo(() => {
    if (!wallet) {
      return [];
    }
    return wallet.configuration.map((conf) => {
      return {
        wallet_id: wallet.id,
        label: conf.label,
        value: conf.value,
        key: conf.key,
      };
    });
  }, [wallet]);

  const onAddWallet = () => {
    let meta = {};
    formik.values.conf.forEach((conf) => {
      meta[conf.key] = conf.value;
    });
    mutate({
      wallet: formik.values.wallet_id,
      user_meta: meta,
    });
  };

  const formik = useFormik({
    initialValues: {
      wallet_id: wallet?.id,
      conf: inputs,
    },
    onSubmit: onAddWallet,
    enableReinitialize: true,
    validate,
    validateOnChange: false,
  });

  return (
    <section className="wallet">
      <div className="wallet-card">
        <div className="wallet-left">
          <h3>{t("add_wallet.add_wallet_heading")}</h3>
          <p className="text-taupegray lg:mb-8 md:mb-5 mb-4">
            {t("add_wallet.add_wallet_paragraph")}
          </p>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <Label>Wallet Type</Label>
              <div className="form-select-arrow">
                <SelectWallet
                  options={options}
                  onChange={setWallet}
                  value={wallet}
                  placeholder={t("add_wallet.add_wallet_placeholder")}
                />
                {formik.errors.wallet && (
                  <span className="form-error">{t(formik.errors.wallet)}</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <Label>API Sync</Label>
              <p className="text-taupegray">
                {t("add_wallet.add_wallet_sync_paragraph")}
              </p>
            </div>
            <div className="relative">
              {inputs.map((input, index) => (
                <div
                  className="form-group"
                  key={`${input.wallet_id}_${input.key}`}
                >
                  <Label>{input.label}</Label>
                  <Input
                    name={`conf[${index}].value`}
                    type={input.key === "secret" ? "password" : "text"}
                    placeholder={input.label}
                    onChange={formik.handleChange}
                    value={input.value}
                  />
                  {formik.errors[input.label] && (
                    <span className="form-error">
                      {t(formik.errors[input.label])}
                    </span>
                  )}
                </div>
              ))}
            </div>
            <Button
              type="submit"
              className="mb-6 disabled:opacity-50"
              disabled={isLoading}
            >
              {isLoading ? (
                <ButtonLoader text={t("add_wallet.add_wallet_button_loader")} />
              ) : (
                t("add_wallet.add_wallet_button")
              )}
            </Button>
          </form>
        </div>
        <div className="wallet-right">
          <h3>{t("add_wallet.tack-crypto_heading")}</h3>
          {wallet && (
            <div
              className="mt-8"
              dangerouslySetInnerHTML={createMarkup(wallet.description)}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default AddWallet;
