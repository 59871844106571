import classNames from "classnames";
import React from "react";
import { SVG } from "../../../../../common/icons/Icon";
import { REACT_APP_BACKEND_URL } from "../../../../../config";
import fileIcon from "../../../../../images/file.svg";
import { useTranslation } from "react-i18next";
const displayName = (file) => {
  if (!file) {
    return null;
  }
  if (typeof file === "string") {
    return file.substring(file.lastIndexOf("/") + 1);
  }
  return file.name;
};

const FileSelect = ({
  file,
  onChange,
  error,
  htmlFor = "file-select",
  height = "auto",
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="form-control-addon">
        {file && (
          <img src={fileIcon} alt="phone-icon" style={{ top: "-26px" }} />
        )}
        <div
          className={classNames([
            "form-control",
            {
              "is-invalid": error,
            },
          ])}
          style={
            file
              ? {
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: "1rem",
                  overflow: "hidden",
                  height: height,
                }
              : {
                  border: "1px dashed",
                  textAlign: "center",
                  padding: "0.5rem 0rem",
                  overflow: "hidden",
                  height: height,
                }
          }
        >
          <input
            type="file"
            onChange={onChange}
            style={{ display: "none" }}
            id={htmlFor}
            {...props}
          />
          <label
            htmlFor={htmlFor}
            style={{
              cursor: "pointer",
              display: "inline-block",
              width: "100%",
            }}
          >
            {file ? (
              <>
                {displayName(file)}{" "}
                <a
                  rel="noreferrer"
                  className="orange-link"
                  target="_blank"
                  href={
                    typeof file === "string"
                      ? `${REACT_APP_BACKEND_URL}${file}`
                      : URL.createObjectURL(file)
                  }
                >
                  {SVG.link}
                </a>
              </>
            ) : (
              "Choose file"
            )}
          </label>
        </div>
        {file && (
          <label
            htmlFor={htmlFor}
            className="orange-link"
            style={{ cursor: "pointer" }}
          >
            {t("replace")}
          </label>
        )}
      </div>
      {error && <p className="form-error">{error}</p>}
    </>
  );
};

export default FileSelect;
