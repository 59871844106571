import React, { useState, Children, cloneElement, useEffect } from "react";
import { BeatLoader } from "react-spinners";
import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import Button from "../../common/form-controls/Button";
import ButtonLoader from "../../common/form-controls/ButtonLoader";
import classNames from "classnames";
import { notify } from "../../common/toaster/Toaster";
import {
  submitProfile,
  submitDocuments,
  submitAccount,
  getProfileData,
} from "../../common/api";
import { ProfileSchema } from "./children/profile-form/validation-schema";
import { DocumentsSchema } from "./children/documents-form/validation-schema";
import { AccountSchema } from "./children/account-form/validation-schema";
import { SVG } from "../../common/icons/Icon";
import _ from "lodash";
import { useTranslation } from "react-i18next";
const ProfileDataForm = ({ children, ...props }) => {
  const { t } = useTranslation();
  const childrenArray = Children.toArray(children);
  const validationSchemaArray = [ProfileSchema, DocumentsSchema, AccountSchema];
  const submitFns = [submitProfile, submitDocuments, submitAccount];
  //   eslint-disable-next-line
  const initialValues = {
    profile: {
      business_name: "",
      phone: "",
      email: "",
      website: "",
      description: "",
      address: "",
      business_type: "",
      country: "",
      city: "",
    },
    documents: {
      business_logo: "",
      indentification_document_front: "",
      indentification_document_back: "",
    },
    bank_account: {
      account_type: "Bank",
      account_name: "",
      account_ibn: "",
      bank_name: "",
      branch_code: "",
      card_number: "",
      card_expiry_date: null,
      cvv: "",
    },
  };
  const stepsMetaData = [
    {
      title: t("profile"),
      imgSrc: SVG.business,
    },
    {
      title: t("documents"),
      imgSrc: SVG.identity,
    },
    {
      title: t("accounts"),
      imgSrc: SVG.bank,
    },
  ];
  const [step, setStep] = useState(0);
  const [isContentLoading, setisContentLoading] = useState(true);
  const isLastStep = () => step === childrenArray.length - 1;

  //   HOOKS
  const { data } = useQuery("getProfileData", getProfileData, {
    retry: 1,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setisContentLoading(false);
      const { profile, documents, bank_account } = data?.response;
      if (!profile.is_submit) {
        return setStep(0);
      }
      if (!documents.is_submit) {
        return setStep(1);
      }
      if (!bank_account.is_submit) {
        return setStep(2);
      }

      setStep(-1);
    },
  });

  const { isLoading, mutate: submitProfileDataFn } = useMutation(
    (values) => {
      return submitFns.at(step)(values);
    },
    {
      onSuccess: (res) => {
        notify(t("updatedSeccessfully"), "success");
        if (!isLastStep()) {
          setStep(step + 1);
        } else {
          // props.onSubmit();
          setStep(-1);
        }
      },
      onError: (error) => {
        Object.entries(error.response.data.error).map(([key, value])=>(
          formik.setFieldError(key, value.error_string)
        ));
      },
    }
  );

  const formik = useFormik({
    initialValues: initialValues[Object.keys(initialValues)[step]],
    onSubmit: submitProfileDataFn,
    validationSchema: validationSchemaArray[step],
    validateOnChange: false,
  });

  useEffect(() => {
    if (data?.response) {
      formik.setValues({
        ...initialValues[Object.keys(initialValues)[step]],
        ..._.omitBy(data.response[Object.keys(initialValues)[step]], _.isNull),
      });
    }
    // eslint-disable-next-line
  }, [data, step]);

  if (isContentLoading) {
    return (
      <BeatLoader
        color="#7F2CEA"
        className="text-center"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -100%)",
        }}
      />
    );
  }

  //   TEMPS
  if (step === -1) {
    return (
      <div className="boarding-card w-5/12 mx-auto relative text-center">
        <div className="modalIcon">{SVG.sent}</div>
        <h1 className="text-3xl my-4">
          <b>{t("submitted")} </b>
        </h1>
        <p className="text-left">
          {t("submittesParagraphOne")}
          <br />
          <br /> {t("submittesParagraphTwo")}
        </p>
        <button
          style={{ width: "100%" }}
          className="secondary mt-4"
          type="button"
          onClick={() => setStep(0)}
        >
          {t("editDetails")}
        </button>
      </div>
    );
  }
  return (
    <div className="boarding-card mx-auto relative">
      <div class="flex items-center mb-12">
        {stepsMetaData.map((stepMetaData, index) => (
          <>
            <div
              class={`step ${
                index === step ? "active" : ""
              } flex items-center relative`}
            >
              <div class="step-circle rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 text-center">
                {stepMetaData.imgSrc}
              </div>
              <div class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase">
                {stepMetaData.title}
              </div>
            </div>
            {index < stepsMetaData.length - 1 && (
              <div
                class={`step-line ${
                  index < step ? "active" : ""
                } flex-auto border-t-2 transition duration-500 ease-in-out`}
              ></div>
            )}
          </>
        ))}
      </div>

      <form onSubmit={formik.handleSubmit}>
        {cloneElement(childrenArray[step], {
          formik,
        })}
        {/* {childrenArray[step](formik)} */}
        <Button
          className={classNames("md:mb-6 mb-4 disabled:opacity-50")}
          type="submit"
          onClick={formik.validateForm}
          disabled={isLoading}
        >
          {isLoading ? (
            <ButtonLoader text={t("saving")} />
          ) : isLastStep() ? (
            t("submit")
          ) : (
            t("next")
          )}
        </Button>
        {step > 0 && step < childrenArray.length && (
          <button
            className="btn-back disabled:opacity-50"
            type="button"
            onClick={() => setStep(step - 1)}
          >
            {t("back")}
          </button>
        )}
      </form>
    </div>
  );
};

export default ProfileDataForm;
