// import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { SVG } from "../icons/Icon";
import { useTranslation } from "react-i18next";
import { REACT_APP_CHECKOUT_URL } from "../../config";
const Success = ({ title, msg, id, close }) => {
  // const navigate = useNavigate();
  const { t } = useTranslation();
  const [copyIcon, setCopyIcon] = useState(SVG.contentCopy);
  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    setCopyIcon(SVG.tick);
    setTimeout(() => {
      setCopyIcon(SVG.contentCopy);
    }, 1000);
  };

  return (
    <div className="invoiceModal">
      <div className="modalIcon">{SVG.sent}</div>
      <h3>{title}</h3>
      <p style={{ textAlign: "center" }}>{msg}</p>
      {id && (
        <>
          <h3 className="pt-6">{t("share")}</h3>
          <div className="shareable-links mt-1 mb-6">
            <button
              onClick={() =>
                window.open(
                  `https://wa.me/?text=${encodeURIComponent(
                    `Invoice Id : ${id}\nInvoice Link : ${REACT_APP_CHECKOUT_URL}/${id}`
                  )}`
                )
              }
            >
              {SVG.whatsApp}
              <span>{t("whatsApp")}</span>
            </button>
            <button
              onClick={() =>
                window.open(
                  `mailto:?subject=${encodeURIComponent(
                    `Sappay Invoice : ${id}`
                  )}&body=${encodeURIComponent(
                    `Invoice Id : ${id}\nInvoice Link : ${REACT_APP_CHECKOUT_URL}/${id}`
                  )}`
                )
              }
            >
              {SVG.email}
              <span>{t("email")}</span>
            </button>
            <button
              className="copyIcon"
              onClick={() => handleCopy(`${REACT_APP_CHECKOUT_URL}/${id}`)}
            >
              {copyIcon}
              <span>{t("copy")}</span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Success;
