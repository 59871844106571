export const validate = (values, props) => {
  let errors = {};

  const inputsForValidate = values.conf;

  if (!values.wallet_id) {
    errors = { ...errors, wallet: "add_wallet.wallet_type_message" };
  }

  for (let conf of inputsForValidate) {
    if (!(conf.value || "").trim()) {
      errors = { ...errors, [conf.label]: "add_wallet.field_message" };
    }
  }
  return errors;
};
