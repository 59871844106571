import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { notify } from "../../../common/toaster/Toaster";

import { editSpecficEvent, getSpecificEvent } from "../../../common/api";
import CampaignForm from "./campaignForm";

export default function CampaignEdit() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery([`campaign`, id], () =>
    getSpecificEvent(id)
  );

  const { mutate, error } = useMutation(
    (values) => editSpecficEvent(id, values),
    {
      onSuccess: (data) => {
        notify(t("campaignUpdated"), "success");
        navigate("/fund-raising");
      },
    }
  );
  return isLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <div className="tab-content">
      <div className="section-head">
        <h1>{t("editCampaign")}</h1>
      </div>
      <CampaignForm
        initialValues={data?.response}
        onSubmit={mutate}
        error={error}
      />
    </div>
  );
}
