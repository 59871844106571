import React from "react";
import { amount } from "../../../../common/utils/helpers";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

const RevenueChart = ({ data, totalRevenue }) => {
  const { t } = useTranslation();
  const dataBarChart = {
    // labels: data.labels ?? [],
    labels: data?.at(0) ?? [
      "2016-12-25",
      "2016-12-26",
      "2016-12-27",
      "2016-12-28",
    ],
    datasets: [
      {
        backgroundColor: "hsl(252, 82.9%, 67.8%)",
        borderColor: "hsl(252, 82.9%, 67.8%)",
        data: data?.at(1) ?? [2, 10, 15, 20],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <div class="shadow rounded-lg border h-full overflow-hidden p-8">
      <h3 className="font-bold">{t("dashboard.Total Revenue")}</h3>
      <h1 className="text-xl font-bold mt-3 mb-5">{amount(totalRevenue)}</h1>
      <Line data={dataBarChart} options={options} />
    </div>
  );
};

export default RevenueChart;
