import Select, { components } from "react-select";
import React from "react";
import { useTranslation } from "react-i18next";
import enLangImg from "../../images/eng-lang.svg";
import frLangImg from "../../images/fr-lang.svg";
import { notify } from "../toaster/Toaster";
import { useUser } from "../../components/context/UserProvider";
import { useMutation } from "react-query";
import { changeLng } from "../api";
import { useDarkMode } from "usehooks-ts";

const options = [
  { id: "en", value: "ENGLISH", label: "English", icon: enLangImg },
  { id: "fr", value: "FRENCH", label: "French", icon: frLangImg },
];

const Placeholder = (props) => <components.Placeholder {...props} />;

const getOptionLabel = (e) => {
  return (
    <div className="flex items-center">
      {e.icon && <img src={e.icon} style={{ width: 20 }} alt={e.label} />}
      <p className="ml-2">{e.label}</p>
    </div>
  );
};

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <div
        className={
          props.theme.label ? "flex border rounded px-2 py-2 w-72" : ""
        }
      >
        <img
          src={props.data.icon}
          style={{ width: 20 }}
          alt={props.data.label}
        />
        {props.theme.label && <p className="pl-2">{props.data.label}</p>}
      </div>
    </components.SingleValue>
  );
};

// const DropdownIndicator = () => (
//   <div className="down-arrow" style={{ width: 22, height: 22 }} />
// );

const LanguageDropdown = ({ withLable }) => {
  const { user } = useUser();
  const { i18n, t } = useTranslation();
  const { isDarkMode } = useDarkMode();
  const onChange = (e) => {
    i18n.changeLanguage(e.id);
    user.user_role && mutate({ language: e.value });
  };
  const { mutate } = useMutation((values) => changeLng(values), {
    onSuccess: () => {
      notify(t("messages.changeLng"), "success");
    },
  });
  const initialValue = () => {
    return options.find((item) => item.id === "i18n.language");
  };

  const styleLangSelect = {
    menu: (styles) => ({
      ...styles,
      width: "10rem",
      background: isDarkMode ? "gray" : "white",
      color: "black",
    }),
    control: (base) => ({
      ...base,
      border: "0 !important",
      background: "transparent !important",
      boxShadow: "0 !important",
      cursor: "pointer",
      marginRight: "0",
    }),
    option: (provided) => ({
      ...provided,
      marginBottom: "4px",
      cursor: "pointer",
      overflow: "hidden",
      "&:hover": {
        background: "rgb(131 135 142 / 66%)",
      },
      "&:focus": {
        background: "",
      },
    }),
    menuList: (styles) => ({
      ...styles,
      borderRadius: "0.625rem",
      paddingTop: "10px",
    }),
  };

  return (
    <div className="lang-dropdown">
      <Select
        styles={styleLangSelect}
        placeholder={
          <img
            src={i18n.language === "fr" ? frLangImg : enLangImg}
            style={{ width: 20 }}
            alt={options[0].label}
          />
        }
        options={options}
        menuPlacement="auto"
        isSearchable={false}
        getOptionLabel={getOptionLabel}
        defaultValue={initialValue()}
        onChange={onChange}
        isDisabled={false}
        components={{
          Placeholder,
          SingleValue,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        theme={(theme) => ({
          ...theme,
          label: withLable ? withLable : false,
          colors: {
            ...theme.colors,
            primary: "rgb(131 135 142 / 66%)",
          },
        })}
      />
    </div>
  );
};

export default LanguageDropdown;
