import * as Yup from "yup";

export const ChangePasswordSchema = Yup.object().shape({
  old_password: Yup.string()
    .trim()
    .required("changePasswordModal.old_password_message"),
  new_password: Yup.string()
    .trim()
    .required("changePasswordModal.new_password_message"),
  
  confirm_password:   Yup.string().trim()
  .test('changePasswordModal.password_match', 'changePasswordModal.password_match', function(value){
    return this.parent.new_password === value
  }),
});
