import React from "react";
import TableHead from "./TableHead";
import TableRow from "./TableRow";
import { useDarkMode } from "usehooks-ts";
import { PageButton, TableButton } from "./PageButton";
import { SVG } from "../icons/Icon";
import { useMemo } from "react";
import { hasPermission } from "../withRole/WithPermission";
import { useTranslation } from "react-i18next";

const Table = ({
  rows = [],
  columns = [],
  allowEdit = true,
  allowDelete = true,
  allowApprove = false,
  showStatus = true,
  onEditClicked = () => console.log("Edit Clicked"),
  onDeleteClicked = () => console.log("Delete Clicked"),
  onApproveClicked = () => console.log("Approve Clicked"),
  onRowClicked = () => console.log("Row Clicked"),
  onPageClicked = () => console.log("Page Clicked"),
  dataCount = 1,
  activePage = 1,
  moduleName,
}) => {
  const { t } = useTranslation();
  const { isDarkMode } = useDarkMode();
  const canPreviousPage = false;
  const canNextPage = false;
  const currentPage = activePage === "" ? 1 : activePage;
  const pageLength = Math.ceil(dataCount / 10);
  const pageLimit = 6;
  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  };

  const { canEdit, canDelete } = useMemo(() => {
    if (moduleName) {
      return {
        canEdit: allowEdit && hasPermission(moduleName)("change"),
        canDelete: allowDelete && hasPermission(moduleName)("delete"),
      };
    }
    return {
      canEdit: allowEdit,
      canDelete: allowDelete,
    };
  }, [moduleName, allowDelete, allowEdit]);

  return (
    <>
      <div className="mt-4 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 sappayTable">
                <thead className={isDarkMode ? "bg-black" : "bg-gray-50"}>
                  <tr>
                    {columns.map(({ lable }, index) => {
                      return (
                        <TableHead
                          key={index}
                          text={t(`${lable?.toLowerCase()}`).toUpperCase()}
                          style={{
                            width: `${
                              rows.length > 0
                                ? 50
                                : columns.length <= 3
                                ? 40
                                : 25
                            }%`,
                          }}
                        />
                      );
                    })}
                    {showStatus && <TableHead text="Status" />}
                    {allowApprove && <TableHead text="Approve" />}
                    {canEdit && <TableHead text="Edit" />}
                    {canDelete && <TableHead text="Delete" />}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {rows.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        className="table-row"
                        columnsList={columns}
                        values={row}
                        allowApprove={allowApprove}
                        showStatus={showStatus}
                        allowEdit={canEdit}
                        allowDelete={canDelete}
                        onClick={() => onRowClicked(row)}
                        onEditClicked={onEditClicked}
                        onDeleteClicked={onDeleteClicked}
                        onApproveClicked={onApproveClicked}
                      />
                    );
                  })}
                </tbody>
              </table>
              {rows.length === 0 && (
                <div className="text-center p-12 emptyTable">
                  {SVG.emptyTable} <span>No Data Found!</span>
                </div>
              )}
              {pageLength > 1 && (
                <>
                  <hr />
                  <div className="py-2 px-6 flex items-center justify-between">
                    <div className="flex-1 flex justify-between sm:hidden">
                      <TableButton disabled={!canPreviousPage}>
                        Previous
                      </TableButton>
                      <TableButton disabled={!canNextPage}>Next</TableButton>
                    </div>
                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <div className="flex gap-x-2 items-baseline">
                        <span className="text-sm text-gray-700">
                          Page{" "}
                          <span className="font-medium">{currentPage}</span> of{" "}
                          <span className="font-medium">{pageLength}</span>
                        </span>
                      </div>
                      <div>
                        <nav
                          className="table-pagenation relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                          aria-label="Pagination"
                        >
                          <PageButton onClick={() => onPageClicked(1)}>
                            <span className="sr-only">First</span>
                            {SVG.doubleArrowLeft}
                          </PageButton>
                          <PageButton
                            onClick={() => onPageClicked(currentPage - 1)}
                            disabled={currentPage === 1 && "disabled"}
                          >
                            <span className="sr-only">Previous</span>
                            {SVG.arrowLeft}
                          </PageButton>
                          {getPaginationGroup().map((pageNumber, index) => (
                            <>
                              {pageLength >= pageNumber && (
                                <PageButton
                                  className={`pageNumber ${
                                    pageNumber === currentPage && "active"
                                  }`}
                                  onClick={() => onPageClicked(pageNumber)}
                                  key={index}
                                >
                                  {pageNumber}
                                </PageButton>
                              )}
                            </>
                          ))}
                          {pageLength > pageLimit &&
                            currentPage !== pageLength && (
                              <>
                                <PageButton
                                  className="pageNumber"
                                  onClick={() => onPageClicked(currentPage + 1)}
                                >
                                  ...
                                </PageButton>
                              </>
                            )}
                          <PageButton
                            onClick={() => onPageClicked(currentPage + 1)}
                            disabled={pageLength === currentPage && "disabled"}
                          >
                            <span className="sr-only">Next</span>
                            {SVG.arrowRight}
                          </PageButton>
                          <PageButton
                            className="rounded-r-md"
                            onClick={() => onPageClicked(pageLength)}
                          >
                            <span className="sr-only">Last</span>
                            {SVG.doubleArrowRight}
                          </PageButton>
                        </nav>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
