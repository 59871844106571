import React from "react";
import cross from "../../images/cross.svg";
import { useState } from "react";

const WarningMsg = (props) => {
  const [show, setShow] = useState(true);
  return (
    <>
      {show && (
        <div className="warning">
          <p className="dark:text-black">{props.warningText}</p>
          <button className="warning-btn" onClick={() => setShow(false)}>
            <img src={cross} alt="cross-img" />
          </button>
        </div>
      )}
    </>
  );
};

export default WarningMsg;
