import React from "react";
import { useTranslation } from "react-i18next";
// import LanguageDropdown from "../../common/language-dropdown/LanguageDropdown";
import Logo from "../logo/Logo";
import ThemeSwitch from "../theme-switch/ThemeSwitch";
import { useHeader, useUser } from "../context/UserProvider";
import { Link } from "react-router-dom";
import ModeChip from "../../common/mode-chip/ModeChip";
import LiveBalance from "./LiveBalance";
import LanguageDropdown from "../../../src/common/language-dropdown/LanguageDropdown";
import Notifications from "./Notifications";

const Header = () => {
  const { t } = useTranslation();
  const { headerTitle } = useHeader();
  const {
    user: { business_approved },
  } = useUser();

  return (
    <header>
      <div className="heading-lg">{t(headerTitle)}</div>
      <Link to={"/"}>
        <Logo
          svgColor={"#FA6D00"}
          textColor={"text-catalinablue dark:text-white"}
        />
      </Link>
      <div className="header-right">
        <LiveBalance />
        <Notifications />
        <LanguageDropdown />
        <ModeChip isLive={business_approved === "Approved"} />
        <ThemeSwitch />
      </div>
    </header>
  );
};

export default Header;
