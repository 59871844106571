import React from "react";

const TableHead = ({ text, ...props }) => {
  return (
    <th
      scope="col"
      className="group px-6 py-4 text-left text-xs font-medium  uppercase tracking-wider"
      {...props}
    >
      <div className="flex items-center justify-between">
        <span>
          <p>{text}</p>
        </span>
      </div>
    </th>
  );
};

export default TableHead;
