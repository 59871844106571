import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { registerFCMDevice } from "./common/api";
import { FIREBASE_VAPIKEY } from "./config";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyAhl0t3IvYQFatmvi6zOfTwrnvPZoJP40k",
  authDomain: "sap-pay-local-dashboard.firebaseapp.com",
  projectId: "sap-pay-local-dashboard",
  storageBucket: "sap-pay-local-dashboard.appspot.com",
  messagingSenderId: "978611340321",
  appId: "1:978611340321:web:8ae67906767e69f7c94f60",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

const requestPermission = async () => {
  try {
    return await Notification.requestPermission();
  } catch (error) {
    console.error(error);
  }
};

export const getFCMToken = () => {
  return getToken(messaging, {
    vapidKey: FIREBASE_VAPIKEY,
  })
    .then(async (currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        console.log("Token: ", currentToken);
        return await registerFCMDevice({
          name: "string",
          registration_id: currentToken,
          device_id: currentToken,
          active: true,
          type: "web",
        });
      } else {
        if ((await requestPermission()) === "granted") {
          console.log("Notification permission granted.");
          //   registerToken();
        } else {
          console.log("Unable to get permission to notify.");
        }
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
