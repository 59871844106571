import React, { useState } from "react";
import { getAllUsers, deleteUserById } from "../../common/api";
import { useQuery, useMutation } from "react-query";
import Table from "../../common/table/Table";
import Modal from "../../common/modal/Modal";
import DeleteModal from "../../common/modal/DeleteModal";
import { notify } from "../../common/toaster/Toaster";
import { getServerError } from "../../common/utils/helpers";
import { useHeader } from "../context/UserProvider";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
// import numberIcon from "../../images/numbericon.svg";
// import successIcon from "../../images/successfultransactionsicon.svg";
// import unSuccessIcon from "../../images/unsuccessfultransactionsicon.svg";
// import pendingIcon from "../../images/pendingtransactionsicon.svg";
// import RoundedPillWidget from "../widgets/RoundedPillWidget";
// import { SVG } from "../../common/icons/Icon";
import FilterBar from "../payouts/components/FilterBar";
import { Link } from "react-router-dom";
import { hasPermission } from "../../common/withRole/WithPermission";
import { useTranslation } from "react-i18next";
const UsersTable = ({ moduleName = "user" }) => {
  useHeader("users");
  const { t } = useTranslation();
  const [deleteThis, setDeleteThis] = useState(null);
  //   HOOKS
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    status: "",
    created_at__date__gte: null,
    created_at__date__lte: null,
    search: null,
    page: 1,
  });

  const { data, isLoading: areUsersLoading } = useQuery(
    [
      "getAllUsers",
      `?${filters.search}${filters.page}${filters.status}${filters.created_at__date__gte}${filters.created_at__date__lte}`,
    ],
    () => getAllUsers(filters),
    {
      retry: 1,
    }
  );

  const { isLoading, mutate: deleteUser } = useMutation(
    () => deleteUserById(deleteThis),
    {
      onSuccess: () => {
        data.results = data.results.filter((user) => user.id !== deleteThis);
        setDeleteThis(null);
        notify(t("userDeleted"), "success");
        navigate("/users");
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );

  return areUsersLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <>
      {/* <div className="widgets mb-6">
        <RoundedPillWidget img={numberIcon} label="PAYOUTS" value="03" />
        <RoundedPillWidget img={successIcon} label="SUCCESSFUL" value="01" />
        <RoundedPillWidget
          img={unSuccessIcon}
          label="UNSSUCCESSFUL"
          value="0"
        />
        <RoundedPillWidget img={pendingIcon} label="PENDING" value="01" />
      </div> */}
      <div className="flex flex-wrap justify-between my-8">
        <div className="flex searchBar md:w-1/2">
          <FilterBar
            filters={filters}
            onFilterChange={(filter) => setFilters({ ...filters, ...filter })}
            isFillter={false}
          />
        </div>
        <div className="md:w-1/3.5">
          <div className="barOptions">
            {/* <button className="rounded-btn p-2">{SVG.fileDownload}</button> */}
            {hasPermission(moduleName)("add") && (
              <Link to="/users/add">
                <button data-cy="add-btn" className="btn-orange" type="button">
                  {t("addUser")}
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="boarding-card p-0 max-w-none">
        <Table
          columns={[
            {
              lable: "Name",
              accessor: "userName",
            },
            {
              lable: "Email",
              accessor: "email",
            },
            {
              lable: "Role",
              accessor: "user_role",
            },
          ]}
          rows={(data?.results ?? []).map(
            ({ first_name, last_name, email, user_role, is_active, id }) => ({
              id: id,
              userName: `${first_name} ${last_name}`,
              email,
              user_role,
              status: is_active ? "Active" : "Inactive",
              isActive: is_active,
            })
          )}
          onEditClicked={({ id }) => {
            navigate(`/users/edit/${id}`);
          }}
          onRowClicked={({ id }) => {
            navigate(`/users/edit/${id}`);
          }}
          onDeleteClicked={({ id }) => {
            setDeleteThis(id);
          }}
          dataCount={data?.count}
          activePage={filters.page}
          onPageClicked={(page) => {
            setFilters({ ...filters, page: page });
          }}
        />
      </div>
      {deleteThis && (
        <Modal close={() => setDeleteThis(null)}>
          <DeleteModal
            title={"user"}
            isLoading={isLoading}
            onClick={deleteUser}
          />
        </Modal>
      )}
    </>
  );
};

export default UsersTable;
