import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { getProfileData } from "../../common/api";
import { useHeader, useUser } from "../context/UserProvider";
import AccountForm from "../profile-data-form/children/account-form/AccountForm";
import DocumentsForm from "../profile-data-form/children/documents-form/DocumentsForm";
import ProfileForm from "../profile-data-form/children/profile-form/ProfileForm";
import ProfileDataForm from "../profile-data-form/ProfileDataForm";

const Compliance = () => {
  useHeader("compliance");
  const navigate = useNavigate();
  const { user, setBussiness } = useUser();
  const [isPending, setIsPending] = useState(null);

  useEffect(() => {
    if (user && user.user_role === "Admin") {
      setIsPending(false);
    }

    if (user && user.user_role === "Merchant") {
      getProfileData().then(({ response }) => {
        setBussiness(response);
        if (response.profile.status === "Approved") {
          setIsPending(false);
        } else {
          setIsPending(true);
        }
      });
    }
    // eslint-disable-next-line
  }, [user]);

  return isPending == null ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : isPending ? (
    <ProfileDataForm>
      <ProfileForm />
      <DocumentsForm />
      <AccountForm />
    </ProfileDataForm>
  ) : (
    navigate("/", { replace: true })
  );
};

export default Compliance;
