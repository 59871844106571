import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Button from "../../../common/form-controls/Button";
import phoneIcon from "../../../images/phone.svg";
import countryIcon from "../../../images/country.svg";
import cityIcon from "../../../images/city.svg";
import { MemberSchema } from "../../Organizations/tabs/Members/validation-schema";
import Label from "../../../common/form-controls/Label";
import Switch from "../../../common/switch/Switch";
import classNames from "classnames";
import Input from "../../../common/form-controls/Input";
import CountrySelect from "../../signup/components/CountrySelect";
import CitySelect from "../../signup/components/CitySelect";
import GroupSelect from "./GroupSelect";
import PhoneNumber from "../../signup/components/PhoneNumber";
import { useQuery } from "react-query";
import ButtonLoader from "../../../common/form-controls/ButtonLoader";
import { getCountries } from "../../../common/api";
import { useTranslation } from "react-i18next";
import BussinessSelect from "../../customers/components/BussinessSelect";
import { useUser } from "../../context/UserProvider";

function MemberForm({
  initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    business: null,
    groups: [],
    country: "",
    city: "",
    is_active: false,
  },
  onSubmit,
  isLoading,
  validationSchema,
  error,
}) {
  const { t } = useTranslation();
  const { user } = useUser();
  const [countryCode, setCountryCode] = useState("");
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema ?? MemberSchema,
    validateOnChange: false,
    onSubmit: (values) => onSubmit(values),
  });

  useEffect(() => {
    error &&  Object.entries(error.response.data.error).map(([key, value]) =>
        formik.setFieldError(key, value.error_string)
      );
  }, [error, formik]);

  useQuery(
    ["country", formik.values.country],
    () => getCountries("", formik.values.country.toString()),
    {
      onSuccess: (data) => {
        setCountryCode(data?.response[0].phone);
      },
    }
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      {user.user_role === "Admin" && (
        <div className={classNames("w-full mb-6")}>
          <BussinessSelect
            className="flex flex-wrap"
            name="business"
            value={parseInt(formik.values.business)}
            onChange={(value) => formik.setFieldValue("business", value.value)}
            error={formik.errors.business}
          />
        </div>
      )}
      <div className="flex flex-wrap -mx-3 justify-between">
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("firstName")}</Label>
          <Input
            required
            placeholder={t("firstName")}
            name="first_name"
            onChange={formik.handleChange}
            value={formik.values.first_name}
            className={classNames({
              "is-invalid": formik.errors.first_name,
            })}
          />
          {formik.errors.first_name && (
            <span className="form-error">{formik.errors.first_name}</span>
          )}
        </div>
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("lastName")}</Label>
          <div
            className={classNames([
              {
                "is-invalid": formik.errors.last_name,
              },
            ])}
          >
            <Input
              placeholder={t("lastName")}
              name="last_name"
              onChange={formik.handleChange}
              value={formik.values.last_name}
            />
          </div>
          {formik.errors.last_name && (
            <span className="form-error">{formik.errors.last_name}</span>
          )}
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 justify-between">
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("country")}</Label>
          <div className="form-control-addon autoSearch">
            <img src={countryIcon} alt="phone-icon" />
            <CountrySelect
              className={`form-control country-select select required ${
                formik.errors.country ? "is-invalid" : ""
              }`}
              onChange={(countryInfo) => {
                if (countryInfo.value !== formik.values.country) {
                  formik.setFieldValue("country", countryInfo.value);
                  formik.setFieldValue("city", null);
                  setCountryCode(countryInfo.countryCode);
                }
              }}
              value={formik.values.country}
              placeholder="SelectCountry"
            />
          </div>
          {formik.errors.country && (
            <span className="form-error">{formik.errors.country}</span>
          )}
        </div>
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("city")}</Label>
          <div className="form-control-addon autoSearch">
            <img src={cityIcon} alt="phone-icon" />
            <CitySelect
              placeholder="SelectCity"
              className={`form-control city-select select required ${
                formik.errors.city ? "is-invalid" : ""
              }`}
              countryId={formik.values.country}
              onChange={(value) => formik.setFieldValue("city", value)}
              value={formik.values.city}
            />
          </div>
          {formik.errors.city && (
            <span className="form-error">{formik.errors.city}</span>
          )}
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 justify-between">
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("email")}</Label>
          <div
            className={classNames([
              {
                "is-invalid": formik.errors.email,
              },
            ])}
          >
            <Input
              placeholder={t("email")}
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </div>
          {formik.errors.email && (
            <span className="form-error">{formik.errors.email}</span>
          )}
        </div>
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("phone")}</Label>
          <div className="form-control-addon">
            <img src={phoneIcon} alt="phone-icon" />
            <PhoneNumber
              countryCode={countryCode}
              onChange={(value) => formik.setFieldValue("phone", value)}
              value={formik.values.phone}
              error={formik.errors.phone}
            />
          </div>
          {formik.errors.phone && (
            <span className="form-error">{formik.errors.phone}</span>
          )}
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 justify-between">
        <div className={classNames("w-full md:w-1/2 px-3 mb-6 ")}>
          <Label>{t("roles")}</Label>
          <div
            className={classNames([
              "form-control select",
              {
                "is-invalid": formik.errors.groups,
              },
            ])}
          >
            <GroupSelect
              value={formik.values.groups}
              onChange={(values) => {
                formik.setFieldValue(
                  "groups",
                  values.map((group) => group.value)
                );
              }}
              placeholder="SelectGroups"
            />
          </div>
          {formik.errors.groups && (
            <span className="form-error">{formik.errors.groups}</span>
          )}
        </div>
      </div>
      <div className="form-group">
        <Label>{t("status")}</Label>
        <Switch
          name="is_active"
          isChecked={formik.values.is_active}
          onChange={formik.handleChange}
        />
      </div>

      <Button type="submit" onClick={() => formik.validateForm()}>
        {isLoading ? <ButtonLoader text={t("sending")} /> : t("save")}
      </Button>
    </form>
  );
}

export default MemberForm;
