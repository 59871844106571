import React from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { editCustomerMerchant, getCustomerMerchant } from "../../common/api";
import { notify } from "../../common/toaster/Toaster";
import CustomerForm from "./components/CustomerForm";
import { useTranslation } from "react-i18next";
const CustomerEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(["customer", id], () =>
    getCustomerMerchant(id)
  );

  const { mutate, isLoading: postisLoading, error } = useMutation(
    (values) => editCustomerMerchant(id, values),
    {
      onSuccess: (data) => {
        notify(t("customerUpdated"), "success");
        navigate("/customers");
      }
    }
  );

  return isLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <div className="tab-content">
      <div className="section-head">
        <h1>{t("editCustomer")}</h1>
      </div>
      <CustomerForm
        initialValues={data?.response}
        onSubmit={mutate}
        isLoading={postisLoading}
        error = {error}
      />
    </div>
  );
};

export default CustomerEdit;
