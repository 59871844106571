import { useEffect, useRef } from "react";

const Switch = ({
  onChange,
  className,
  isChecked = false,
  isError = false,
  name,
  ...props
}) => {
  const checkedRef = useRef();

  useEffect(() => {
    if (!isError) {
      return;
    }
    checkedRef.current.checked = true;
  });

  return (
    <label
      className={className ? `switch-default ${className}` : `switch-default `}
      onClick={(e) => e.stopPropagation(true)}
    >
      <input
        type="checkbox"
        onChange={onChange}
        ref={checkedRef}
        name={name}
        defaultChecked={isChecked}
        {...props}
      />
      <span className="slider round"></span>
    </label>
  );
};

export default Switch;
