export const ADD_WALLET = "add-wallet";

export const WALLET_ADDED = "wallet-added";

export const CONNECT_WALLET = "connect-wallet";

export const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const TokenKey = "Token";

export const LOGOUT = "logout";
