import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { EditEmployeeById, getEmployeeById } from "../../common/api";
import { notify } from "../../common/toaster/Toaster";
import EmployeeForm from "./EmployeeForm";

const EditEmployee = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(["getEmployeeById", id], () =>
    getEmployeeById(id)
  );

  const {
    isLoading: Loading,
    mutate,
    error,
  } = useMutation((values) => EditEmployeeById(id, values), {
    onSuccess: (data) => {
      notify(t("employeeUpdated"), "success");
      navigate("/employees");
    },
  });

  return isLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <div className="tab-content">
      <div className="section-head">
        <h1>{t("editEmployee")}</h1>
      </div>
      <EmployeeForm
        initialValues={data?.response}
        isLoading={Loading}
        onSubmit={mutate}
        error={error}
      />
    </div>
  );
};

export default EditEmployee;
