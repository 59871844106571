import React, { useState } from "react";
import Label from "../../../common/form-controls/Label";
import Selection from "../../../common/form-controls/Selection";
import Input from "../../../common/form-controls/Input";
import search from "../../../images/search.svg";
import { SVG } from "../../../common/icons/Icon";
import { useTranslation } from "react-i18next";

const FilterBar = ({
  filters = {
    status: "",
    created_at__date__gte: "",
    created_at__date__lte: "",
    search: "",
  },
  options,
  onFilterChange,
  isFillter = true,
}) => {
  const initialFilters = {
    status: "",
    created_at__date__gte: null,
    created_at__date__lte: null,
    search: null,
    page: 1,
  };
  const [filterOpen, setFilterOpen] = useState(false);
  const [localFilters, setlocalFilters] = useState(filters);

  const { t } = useTranslation();
  return (
    <>
      <div className="w-full">
        <img
          src={search}
          alt="search-icon"
          className="absolute"
          style={{ margin: "8px 14px", zIndex: "9" }}
        />
        <Input
          type="search"
          className="pl-12"
          onChange={(e) => {
            setlocalFilters({ ...localFilters, search: e.target.value });
          }}
          value={localFilters.search}
          onKeyPress={(event) =>
            event.key === "Enter" && onFilterChange(localFilters)
          }
          placeholder={t("search")}
          style={{
            width: "100%",
            height: "100%",
            background: "transparent",
          }}
        />
      </div>
      {isFillter && (
        <button
          className="flex secondary-btn w-32 pl-2.5 pr-1 ml-2 rounded-r-0"
          onClick={() => setFilterOpen(!filterOpen)}
        >
          {SVG.filter}&nbsp;
          {t("filterBar.filter")}
          {/* {SVG.plus} */}
        </button>
      )}
      {/* <button
        className="secondary-btn w-auto rounded ml-4"
        onClick={() => {
          onFilterChange(localFilters);
        }}
      >
        Search
      </button> */}
      {filterOpen && (
        <div className="filter-bar__filter">
          {options && (
            <div className="form-group">
              <Label>{t("filterBar.status")}</Label>
              <Selection
                name="status"
                placeholder="How much ?"
                className="form-control select"
                options={options}
                value={localFilters.status}
                onChange={(e) => {
                  setlocalFilters({ ...localFilters, status: e.value });
                }}
              />
            </div>
          )}
          <div className="form-group">
            <Label>{t("filterBar.dateRange")}</Label>
            <div className="date-range">
              <input
                type="date"
                className="form-control"
                name="start-date"
                max={new Date().toISOString().split("T")[0]}
                value={localFilters.created_at__date__gte}
                onChange={(e) => {
                  setlocalFilters({
                    ...localFilters,
                    created_at__date__gte: e.target.value,
                  });
                }}
              />
              <input
                type="date"
                className="form-control"
                name="created_at__date__lte"
                max={new Date().toISOString().split("T")[0]}
                value={localFilters.created_at__date__lte}
                onChange={(e) => {
                  setlocalFilters({
                    ...localFilters,
                    created_at__date__lte: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="actions">
            <button
              className="secondary-btn w-auto px-4"
              onClick={() => {
                setlocalFilters(initialFilters);
                onFilterChange(initialFilters);
                setFilterOpen(false);
              }}
            >
              {t("filterBar.reset")}
            </button>
            <button
              className="primary-btn w-auto px-4"
              onClick={() => {
                onFilterChange(localFilters);
                setFilterOpen(false);
              }}
            >
              {t("filterBar.filter")}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FilterBar;
