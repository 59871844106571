import React, { useState } from "react";
import { getBeneficiaries, deleteBeneficiaryById } from "../../common/api";
import { useMutation, useQuery } from "react-query";
import Table from "../../common/table/Table";
// import numberIcon from "../../images/numbericon.svg";
// import successIcon from "../../images/successfultransactionsicon.svg";
// import unSuccessIcon from "../../images/unsuccessfultransactionsicon.svg";
// import pendingIcon from "../../images/pendingtransactionsicon.svg";
// import RoundedPillWidget from "../widgets/RoundedPillWidget";
import Modal from "../../common/modal/Modal";
import DeleteModal from "../../common/modal/DeleteModal";
import { notify } from "../../common/toaster/Toaster";
import { getServerError } from "../../common/utils/helpers";
import { useHeader } from "../context/UserProvider";
import { BeatLoader } from "react-spinners";
// import { SVG } from "../../common/icons/Icon";
import { hasPermission } from "../../common/withRole/WithPermission";
import FilterBar from "../payouts/components/FilterBar";
import AddBeneficiaryModal from "./AddBeneficiaryModal";
import WithPermission from "../../common/withRole/WithPermission";
import { useTranslation } from "react-i18next";
import { useUser } from "../context/UserProvider";

const BeneficiariesTable = ({ moduleName = "payoutbeneficiary" }) => {
  useHeader("beneficiaries");
  const { user } = useUser();
  const { t } = useTranslation();
  const [Tab, setTab] = useState(null);
  const [deleteThis, setDeleteThis] = useState(null);
  const [filters, setFilters] = useState({
    status: "",
    created_at__date__gte: null,
    created_at__date__lte: null,
    search: null,
    page: 1,
  });

  const {
    data,
    isLoading: areBenLoading,
    refetch,
  } = useQuery(
    [
      "getBeneficiaries",
      `?${filters.search}${filters.page}${filters.status}${filters.created_at__date__gte}${filters.created_at__date__lte}`,
    ],
    () => getBeneficiaries(filters),
    {
      retry: 1,
    }
  );

  const { isLoading, mutate: deleteBeneficiary } = useMutation(
    () => deleteBeneficiaryById(deleteThis),
    {
      onSuccess: () => {
        refetch();
        setDeleteThis(null);
        notify(t("beneficiaryDeleted"), "success");
      },
      onError: (error) => {
        notify(getServerError(error), "error");
      },
    }
  );
  const columns = [
    {
      lable: "Name",
      accessor: "name",
    },
    {
      lable: "Bank",
      accessor: "bank_name",
    },
    {
      lable: "Account Number",
      accessor: "iban",
    },
    {
      lable: "Bussiness Name",
      accessor: "business_name",
    },
    {
      lable: "Created At",
      accessor: "created_at",
    },
  ];
  return areBenLoading ? (
    <BeatLoader
      color="#7F2CEA"
      className="text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    />
  ) : (
    <>
      {/* <div className="widgets mb-6">
        <RoundedPillWidget img={numberIcon} label="PAYOUTS" value="03" />
        <RoundedPillWidget img={successIcon} label="SUCCESSFUL" value="01" />
        <RoundedPillWidget
          img={unSuccessIcon}
          label="UNSSUCCESSFUL"
          value="0"
        />
        <RoundedPillWidget img={pendingIcon} label="PENDING" value="01" />
      </div> */}
      <div className="flex flex-wrap justify-between my-8">
        <div className="flex searchBar md:w-1/2">
          <FilterBar
            moduleName={moduleName}
            filters={filters}
            onFilterChange={(filter) => setFilters({ ...filters, ...filter })}
          />
        </div>
        <div className="md:w-1/3.5">
          <div className="barOptions">
            {/* <button className="rounded-btn p-2">{SVG.fileDownload}</button> */}
            {hasPermission(moduleName)("add") && (
              <button
                data-cy="add-btn"
                className="btn-orange"
                type="button"
                onClick={() => setTab(1)}
              >
                {t("addBeneficiaries")}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="boarding-card p-0 max-w-none">
        <Table
          moduleName={moduleName}
          showStatus={false}
          allowEdit={false}
          columns={
            user.user_role === "Admin"
              ? columns
              : columns.filter((column) => column.accessor !== "business_name")
          }
          rows={(data?.results ?? []).map(
            ({ id, name, bank_name, business_name, iban, created_at }) => ({
              id: id,
              name,
              bank_name,
              business_name,
              iban,
              created_at:
                new Date(created_at)
                  .toDateString()
                  .split(" ")
                  .slice(1)
                  .join(" ") +
                ", " +
                new Date(created_at).toLocaleTimeString(),
            })
          )}
          onDeleteClicked={({ id }) => {
            setDeleteThis(id);
          }}
          dataCount={data?.response?.count}
          activePage={filters.page}
          onPageClicked={(page) => {
            setFilters({ ...filters, page: page });
          }}
        />
      </div>
      {deleteThis && (
        <Modal close={() => setDeleteThis(null)}>
          <DeleteModal
            title={"Beneficiary"}
            isLoading={isLoading}
            onClick={deleteBeneficiary}
          />
        </Modal>
      )}
      {Tab && (
        <Modal close={() => setTab(null)}>
          <AddBeneficiaryModal
            title={t("newBeneficiary")}
            onSuccess={() => {
              refetch();
              setTab(null);
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default WithPermission("payoutbeneficiary")("view")(BeneficiariesTable);
