import * as Yup from "yup";

export const SignUpSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
  confirm_password: Yup.string().test(
    "passwords-match",
    "Passwords must be same",
    function (value) {
      return this.parent.password === value;
    }
  ),
  first_name: Yup.string().trim().required("Firstname is required"),
  last_name: Yup.string().trim().required("Lastname is required"),
  phone: Yup.string().trim().required("Phone is required"),
  country: Yup.string().required("Country is required"),
  city: Yup.string().required("City is required"),
  is_read_privacy_policy: Yup.boolean().required("PrivacyPolicy is required"),
});
