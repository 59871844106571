import React from "react";
import ReactDOM from "react-dom/client";
import "./css/app.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { QueryClient, QueryClientProvider } from "react-query";
import { NotificationProvider } from "./components/context/NotificationProvider";
import { Toaster } from "./common/toaster/Toaster";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <NotificationProvider>
          <App />
          <Toaster />
        </NotificationProvider>
      </QueryClientProvider>
    </React.StrictMode>
  </BrowserRouter>
);
