import React from "react";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import AsyncSelect from "react-select/async";
import { getCountries } from "../../../common/api";
import { useTranslation } from "react-i18next";

const convertToOptions = (countries) => {
  return countries.map((country) => ({
    label: country.name,
    value: country.id,
    countryCode: country.phone,
  }));
};

const CountrySelect = ({
  placeholder,
  onChange,
  className,
  textColor,
  displaceMenu = "-50px",
  value,
}) => {
  const { t } = useTranslation();

  const styleLangSelect = {
    menu: (styles) => ({
      ...styles,
      width: "100%",
      transform: `translateX(${displaceMenu})`,
      color: "black",
    }),
    control: (base) => ({
      ...base,
      border: "0 !important",
      background: "transparent !important",
      boxShadow: "0 !important",
      cursor: "pointer",
      height: "unset !important",
      minHeight: "unset",
      padding: "0 !important",
    }),
    option: (provided) => ({
      ...provided,
      marginBottom: "4px",
      cursor: "pointer",
      overflow: "hidden",
      "&:hover": {
        background: "rgb(131 135 142 / 66%)",
      },
      "&:focus": {
        background: "transparent",
      },
    }),
    menuList: (styles) => ({
      ...styles,
      borderRadius: "0.625rem",
      paddingTop: "10px",
      paddingBottom: "10px",
    }),
    container: (styles) => ({
      ...styles,
      // margin: "-0.4rem",
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      color: textColor,
    }),
    indicatorContainer: (styles) => ({
      ...styles,
      color: textColor,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: textColor,
    }),
  };

  const [selectedCountry, setSelectedCountry] = useState();
  const { data: initialCountries } = useQuery("countries", getCountries, {
    retry: 1,
  });

  const initialCountryOptions = useMemo(
    () =>
      convertToOptions(initialCountries?.response ?? []).filter((country) => {
        if (country.value === value?.value || country.value === value) {
          setSelectedCountry(country);
          return false;
        }
        return true;
      }),
    [initialCountries?.response, value]
  );

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length >= 3) {
      // perform a request
      inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
      const results = await getCountries(inputValue).then((res) => res);

      if (results?.response.length ?? 0) {
        callback(convertToOptions(results.response));
      }
    }
    callback(initialCountryOptions);
  };

  return (
    <AsyncSelect
      placeholder={t(placeholder)}
      isSearchable={true}
      className={className}
      styles={styleLangSelect}
      defaultOptions={
        selectedCountry
          ? [selectedCountry, ...initialCountryOptions]
          : initialCountryOptions
      }
      loadOptions={loadOptions}
      name="country"
      onChange={(countryInfo) => {
        setSelectedCountry(countryInfo);
        onChange(countryInfo);
      }}
      value={selectedCountry}
    />
  );
};

export default CountrySelect;
